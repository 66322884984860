import axios from "axios";
import {
  RegisterDidFilteringRequest,
  RegisterDidFilteringResponse,
  RegisterProposalRequest,
  RegisterProposalResponse,
} from "protobuf/OpenApiServerV3";
import { TAxiosReturn } from "types/common";
import {
  TDidFilterBoolData,
  TDidFilterListData,
  TMarketPostProposal,
  TMarketProposalData,
  TMarketResponse,
  TPiZipPayload,
  TPiZipStatusData,
} from "types/market";
import { TProposalStatic } from "types/proposal";
import { fetchProtobuf } from "./baseFetch";

import { Axios } from "./base";

export const getMarketList = async (): Promise<TMarketResponse> => {
  const { data } = await Axios.get<TAxiosReturn<TMarketResponse>>("/api/market/v2/list");
  return data.data;
};

// export const getMarketProducts = async (): Promise<TMarketProductData[]> => {
// const { data } = await Axios.get<TAxiosReturn<TMarketProductData[]>>("/market/mock/products");
// return marketProducts as any;
// };

// export const getMarketPrivacyProvider = async (): Promise<TMarketProviderData[]> => {
// const { data } = await Axios.get<TAxiosReturn<TMarketProductData[]>>("/market/mock/provider");
// return marketPrivacyProvider as any;
// };

export const deleteMarketProposal = async (requestId: number): Promise<boolean> => {
  const { data } = await Axios.delete<TAxiosReturn<{ result: boolean }>>("/api/market/v2/delete", {
    data: {
      requestId,
    },
  });
  return data.data.result;
};

export const revokeMarketProposal = async ({
  ProposalID,
  token,
  did,
}: {
  ProposalID: string;
  token: string;
  did: string;
}) => {
  const { data } = await axios.post(
    `${window.API_URL}/proposal/revoke`,
    {
      command: "revokeProposal",
      did,
      payload: {
        ProposalID,
      },
    },
    {
      headers: {
        "X-Auth-Token": token,
      },
    }
  );

  return data;
};

export const getMyAccount = async () => {
  const { data } = await Axios.get<TAxiosReturn<{ result: number }>>("/api/reward/v2/amount");
  return data.data;
};

export const postMarketProposal = async (submitData: TMarketPostProposal) => {
  const { data } = await Axios.post("/api/market/v2/write-proposal", {
    ...submitData,
  });
  return data.data;
};

export const getMarketProposal = async (proposalId: string) => {
  const { data } = await Axios.post<TAxiosReturn<TMarketProposalData>>(`/api/market/v2/detail`, {
    proposalId,
  });

  return data.data;
};

export const postProposalStatic = async (data: { proposalRequestId: number; proposalStatic: TProposalStatic }) => {
  const registerProposalRequest = RegisterProposalRequest.create(data);
  const protobuf = RegisterProposalRequest.encode(registerProposalRequest).finish();

  const result = await fetchProtobuf({
    url: "/register-proposal/register-static",
    data: protobuf,
  });

  const res = RegisterProposalResponse.decode(result);

  return res;
};

export const getPiZipStatus = async ({ did, proposalId, piiUrl, token }: TPiZipPayload) => {
  const { data } = await axios.post<TPiZipStatusData>(
    `${piiUrl}/pi/zip/status`,
    {
      did,
      proposalId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
    }
  );
  return data;
};

export const getPiZip = async ({ did, proposalId, piiUrl, token }: TPiZipPayload) => {
  const { data } = await axios.post(
    `${piiUrl}/pi/zip/download`,
    {
      did,
      proposalId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": token,
      },
      responseType: "blob",
    }
  );
  return data;
};

export const postPiZipJob = async ({ did, proposalId, piiUrl, token }: TPiZipPayload) => {
  const data = await axios.post(
    `${piiUrl}/pi/zip/job`,
    {
      did,
      proposalId,
    },
    {
      headers: {
        "X-Auth-Token": token,
      },
    }
  );
  return data;
};

export const postJsonJobStart = async ({
  did,
  token,
  proposalId,
  piiUrl,
}: {
  did: string;
  token: string;
  proposalId: string;
  piiUrl: string;
}) => {
  const dataTokenUrl = `${piiUrl}/pi/json/job`;

  const headers = {
    "Content-Type": "application/json",
    "X-Auth-Token": token,
  };

  // parameter i (0, 1, 2 ,3)은 api 별 정상 및 에러 케이스 테스트 가능. 0 은 정상, 1,2,3 은 에러 케이스
  // 참고 (https://confluence.snplab.io/pages/viewpage.action?pageId=135566180)
  const dataTokenClaimBody = {
    did,
    proposalId,
  };

  const dataTokenResponse = await axios.post<{ dataToken: string }>(dataTokenUrl, dataTokenClaimBody, { headers });

  return dataTokenResponse.data.dataToken;
};

export const getBgJobStatus = async (checkUrl: string) => {
  const { data } = await Axios.get<{ result: string; message: string }>(checkUrl);

  return data;
};

// 아래 did filtering 관련 api 는 APIServer 에 정의된 controller 이기 때문에 window.API_URL 로 요청
export const postDidFilterRequest = async (request: RegisterDidFilteringRequest) => {
  const paramBuf = RegisterDidFilteringRequest.encode(request).finish();
  const res = await fetchProtobuf({
    url: `/v3/proposal/did-filtering`,
    data: paramBuf,
    init: { method: "POST" },
  });

  const response = RegisterDidFilteringResponse.decode(res);

  return response;
};

export const deleteDidFilterRequest = async (didFilterId: string) => {
  const res = await Axios.delete<TDidFilterBoolData>(
    `/api/v3/proposal/did-filtering?didFilterId=${didFilterId}`,
    {}
  );

  return res;
};

export const applyDidFiltering = async () => {
  const res = await Axios.get<TDidFilterBoolData>(`/api/v3/proposal/apply-did-filtering`);

  return res.data;
};

export const getDidFilteringListByProposalId = async (proposalId: string) => {
  const { data } = await Axios.get<TDidFilterListData>(`/api/v3/proposal/get-did-filtring-list`, {
    params: {
      proposalId,
    },
  });

  return data.data.data;
};
