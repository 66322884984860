import { RadioBox } from "common/input";
import { CheckTitle } from "pages/market/marketComponents";
import { useRecoilState } from "recoil";
import { marketTargetAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/selectDidFilterTF.module.scss";
import { TStrBoolean } from "types/market";

export default function SelectDidFilterTF() {
  const [{ isApplyDidFiltering }, setMarketTargetState] = useRecoilState(marketTargetAtom);

  const handleChange = (value: TStrBoolean) => {
    setMarketTargetState((prev) => ({ ...prev, isApplyDidFiltering: value }));
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>DID 필터링</p>
      <div className={styles.filtering_wrapper}>
        <CheckTitle label="필터링" height="34px" />
        <RadioBox
          dataList={[
            { label: "비활성", value: "false" },
            { label: "활성", value: "true" },
          ]}
          name="DID 필터링"
          handleCheck={handleChange}
          value={isApplyDidFiltering}
        />
      </div>
    </div>
  );
}
