import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import cx from "classnames";
import { rewardCharge } from "recoils/reward";
import { getOnlyNumber, getDelCommas } from "utils/validation";
import styles from "style/pages/reward/charge/rewardInput.module.scss";

const rewardLimit = 100_000;

export default function RewardInput() {
  const setReward = useSetRecoilState(rewardCharge);
  const [priceInput, setpriceInput] = useState(0);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    const sum = priceInput + Number(value);

    if (sum > rewardLimit) {
      return ;
    }
    setpriceInput(sum);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const onlyValueNumber = Number(getDelCommas(value));
    
    if (onlyValueNumber > rewardLimit) { // 최소 1000원 단위 이므로 
      return;
    }
    // exponential 방지
    if (!isNaN(onlyValueNumber)) {
      setpriceInput(onlyValueNumber);
    }
  };

  useEffect(() => {
    setReward({
      price: Number(`${priceInput}000`),
      chargePoint: Number(`${priceInput}000`) * 0.8,
    });
  }, [priceInput, setReward]);

  return (
    <>
      <div>
        <button type="button" className={styles.priceButton} value="500" onClick={handleButtonClick}>
          + 500,000 원
        </button>
        <button type="button" className={styles.priceButton} value="1000" onClick={handleButtonClick}>
          + 1,000,000 원
        </button>
        <button type="button" className={styles.priceButton} value="5000" onClick={handleButtonClick}>
          + 5,000,000 원
        </button>
        <button type="button" className={styles.priceButton} value="10000" onClick={handleButtonClick}>
          + 10,000,000 원
        </button>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.formatWrapper}>
          <input className={cx(styles.priceInput, styles.mockInput)} value="" disabled />
          <input
            className={cx(styles.priceInput, { [styles.formatting]: priceInput })}
            type="text"
            id="price"
            autoComplete="off"
            placeholder="최소 1,000원 단위로 입력 가능"
            value={priceInput ? getOnlyNumber(priceInput.toString()).toLocaleString() : ""}
            onChange={handleInputChange}
          />
          <label htmlFor="price" className={cx(styles.hidden, { [styles.format]: priceInput })}>
            ,000
          </label>
        </div>
        <label htmlFor="price" className={styles.priceInputLabel}>
          원
        </label>
        <div className={styles.note}>(부가세 별도, 한번에 최대 1억원까지 충전 가능)</div>
      </div>
    </>
  );
}
