import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import cx from "classnames";

import { Label, InputWithLabel } from "pages/advertisement/adUI";
import { AccumulateBarChart, DonutChart } from "./chart";
import { TAdDetail } from "types/advertisement";
import { formatDuration } from "utils/ad";

import styles from "style/pages/advertisement/adDetail/adInProgress.module.scss";

type TProps = {
  data: TAdDetail;
  totalClick: number; // 클릭수
  totalExps: number; // 노출수
  clickPercent: number; // 클릭률
};

const tabArr = [
  { idx: 0, title: "대시보드" },
  { idx: 1, title: "광고 정보" },
];

// 광고 상세 대시보드/광고정보 (진행중/종료 상태에서 표시)
export default function AdInProgress({ data, totalClick, totalExps, clickPercent }: TProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIdx = searchParams.get("tab");
  const tab = tabIdx !== "1";

  const handleClickTab = (idx: number) => {
    if (idx === 0) searchParams.set("tab", "0");
    else if (idx === 1) searchParams.set("tab", "1");

    setSearchParams(searchParams);
  };

  const { fileUrl, title, createDate, approvedDate, notBefore, notAfter, type, loc, webUrl } = data.advertisements;
  const fileName = fileUrl.split("/");

  return (
    <div className={styles.container}>
      <div className={styles.tabContainer}>
        {tabArr.map((item) => (
          <button
            type="button"
            key={item.idx}
            onClick={() => handleClickTab(item.idx)}
            className={cx(
              styles.tabItem,
              tab === Boolean(item.idx) || styles.focused,
              item.idx === 0 ? styles.left : styles.right,
            )}
          >
            {item.title}
          </button>
        ))}
      </div>

      <div className={cx(styles.contentContainer, { [styles.infoContainer]: tabIdx === "1" })}>
        {tabIdx === "1" ? (
          <div className={styles.detailInfoContainer}>
            <div className={styles.labelContainer}>
              <Label must={false}>{title}</Label>

              <div className={styles.dateContainer}>
                <div>신청일 {dayjs(createDate).format("YYYY.MM.DD")}</div>
                <div>승인일 {approvedDate ? dayjs(approvedDate).format("YYYY.MM.DD") : "-"}</div>
              </div>
            </div>

            <InputWithLabel label="광고 기간">{formatDuration(notBefore, notAfter)}</InputWithLabel>

            <InputWithLabel label="광고 유형">{type === "BANNER" ? "배너 광고" : ""}</InputWithLabel>

            <InputWithLabel label="노출 위치">
              {`${loc === "DATA_MARKET" ? "자사 진행 거래" : ""} / ${data.proposals.title}`}
            </InputWithLabel>

            <InputWithLabel label="배너 이미지" url={fileUrl}>
              {data && fileName[fileName.length - 1]}
            </InputWithLabel>

            <InputWithLabel label="랜딩 URL" url={webUrl}>
              {webUrl || "-"}
            </InputWithLabel>
          </div>
        ) : (
          <>
            <Label must={false}>누적 성과</Label>
            <div className={styles.chartBox}>
              <AccumulateBarChart click={totalClick} exps={totalExps} />
              <DonutChart clickPercent={clickPercent} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
