import cx from "classnames";
import { CheckInput } from "common/input";
import { MouseEventHandler } from "react";
import styles from "style/pages/market/didFilterPage/didFilteredList.module.scss";
import { TDidFilterRevokeInfo } from "types/market";
import Label from "./components/Label";

type TProps = {
  didFilteredList: TDidFilterRevokeInfo[];
  handleClickItem: MouseEventHandler<HTMLButtonElement>;
  selectedDidFilterId: string;
};

export default function DidFilteredList({ didFilteredList, handleClickItem, selectedDidFilterId }: TProps) {
  const isBlockListMode = (mode: string) => mode.toLowerCase().includes("block");

  if (didFilteredList.length === 0) {
    return <div className={styles.empty_text}>현재 이 제안서에 적용된 DID 필터링 요청이 없습니다</div>;
  }

  return (
    <ul className={styles.wrapper}>
      {didFilteredList.map((item) => (
        <li
          key={item.id}
          className={cx(
            styles.item,
            { [styles.selected]: selectedDidFilterId === String(item.id) },
            { [styles.non_selectable]: item.processed } // processed true 인것(필터링이 apply 된 것)은 취소할 수 없다
          )}
        >
          <button
            onClick={handleClickItem}
            className={cx(
              { [styles.non_selectable]: item.processed } // processed true 인것(필터링이 apply 된 것)은 취소할 수 없다
            )}
            type="button"
            disabled={item.processed}
            id={String(item.id)}
          >
            {/* 해당 필터링 선택했다는 표시 기능만 함 */}
            <CheckInput
              text=""
              handleCheck={() => {}}
              name={String(item.id)}
              value={String(item.id) === selectedDidFilterId}
            />
            <section className={styles.right}>
              <div className={styles.revoke_info_row}>
                <div className={styles.revoke_info_item}>
                  <Label>필터링 적용 상태</Label>
                  {item.processed ? "적용 완료 (취소 불가능)" : "적용 전 (취소 가능)"}
                </div>
                <div className={styles.revoke_info_item}>
                  <Label>DID 리스트</Label>
                  <p className={styles.content}>{item.didFilter.join(", ")}</p>
                </div>
              </div>
              <div className={styles.revoke_info_row}>
                <div className={styles.revoke_info_item}>
                  <Label>필터링 모드</Label>
                  <p
                    className={cx(
                      { [styles.block]: isBlockListMode(item.mode) },
                      { [styles.allow]: !isBlockListMode(item.mode) }
                    )}
                  >
                    {isBlockListMode(item.mode) ? "블랙 리스트" : "화이트 리스트"}
                  </p>
                </div>
                <div className={styles.revoke_info_item}>
                  <Label>제안서 ID 리스트</Label>
                  <p className={styles.content}>{item.proposalFilter.join(", ")}</p>
                </div>
              </div>
            </section>
          </button>
        </li>
      ))}
    </ul>
  );
}
