import { ArrowPreviousIcon } from "assets/svgs/icon";
import styles from "style/pages/market/previewSidebar/uiComponents/previewHeader.module.scss";

export default function PreviewHeader() {
  return (
    <header className={styles.wrapper}>
      <div className={styles.headerTop}>
        <ArrowPreviousIcon width={24} height={24} />
        거래
      </div>
      <div className={styles.progress}>
        <div className={styles.progressBar}>
          <div />
          <p>거래 조회</p>
        </div>
        <div className={styles.progressBar}>
          <div />
          <p>동의</p>
        </div>
        <div className={styles.progressBar}>
          <div />
          <p>거래 완료</p>
        </div>
      </div>
    </header>
  );
}
