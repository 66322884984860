import { collectDataList, TARGET_MAX_AGE, TARGET_MIN_AGE, translateTargetMap } from "constants/market";
import dayjs from "dayjs";
import { TMarketBudgetData } from "types/market";

export const formatAgeGroup = (ageGroup: number[]): string => {
  let [min, max] = ageGroup;
  if (min + max === 0) return "all";
  min = min === 0 ? TARGET_MIN_AGE : min;
  max = max === 0 ? TARGET_MAX_AGE : max;
  if (min + max === TARGET_MIN_AGE + TARGET_MAX_AGE) return "all";
  return `${min}-${max}`;
};

export const translateCollectData = (collectData: number[]) => {
  const translatedData = collectData?.map((num) => {
    const name = collectDataList[num - 1].label;
    return translateTargetMap[name];
  });
  return translatedData;
};

export const formatCollectDataGroup = (data: string[]) => {
  const medical = [];
  const finance = [];
  const life = [];
  for (const name of data) {
    if (["medicalCheckup", "medicationRecord", "patientEMR", "exerciseResult"].includes(name)) medical.push(name);
    if (["bankTransaction", "card", "securities", "insurance"].includes(name)) finance.push(name);
    if (["googleYoutubeKeyword", "shoppingMallOrder"].includes(name)) life.push(name);
  }
  return {
    medical: medical.join(","),
    finance: finance.join(","),
    life: life.join(","),
  };
};

export const formatSubmitDate = (budgetState: TMarketBudgetData) => {
  const { transactionPeriod, transactionTime } = budgetState;
  const from = dayjs(`${transactionPeriod[0]}${transactionTime}`).format("YYYY-MM-DDTHH:mm:ssZ");
  const to = dayjs(`${transactionPeriod[1]}`).format("YYYY-MM-DDTHH:mm:ssZ");
  return [from, to];
};
