type TPayload = {
  marketTitleList: {
    exptype: {
      name: string;
      title: string[];
    }[];
  };
  showFinelabOptions: boolean;
  titleType: string;
  dataType: string;
  point: string;
  intro?: string;
  maxIssuable: number;
};

export const transactionNaming = ({
  marketTitleList,
  showFinelabOptions,
  titleType,
  dataType = "",
  intro,
  point,
  maxIssuable,
}: TPayload) => {
  const [titleListItem] = marketTitleList?.exptype.filter(({ name }) => name === titleType) || [];
  const { title: titleList } = titleListItem || {};
  const additionalTitleListForFinelabPrivateOpen =
    showFinelabOptions && titleType === "프로모션형"
      ? titleList.concat("보험 상담하고 총 20,000P 받자! 통화 예약만 해도 2,040P가?!😲")
      : titleList;

  const handleIntro = (value?: string) => {
    if (value === "선착순") return "[선착순]";
    if (value === "이벤트") return "[이벤트]";
    if (value === "목표 인원 수") return `[${maxIssuable.toLocaleString()}명]`;
    return "";
  };
  const replaceTitle = (title: string) => title.replace(/{\$POINT}/g, point).replace(/{\$TYPE}/g, dataType);

  const handledTitle = additionalTitleListForFinelabPrivateOpen?.map((title) =>
    `${handleIntro(intro)} ${replaceTitle(title)}`.trim()
  );
  return handledTitle;
};
