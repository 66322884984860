import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import { setPrimaryColor } from "utils/config";
import { setB2BEnv } from "utils";
// import { worker } from "mock/browser";

import "style/config/index.scss";

document.documentElement.style.setProperty("--primary-color", "#1890ff");

// if (window.location.hostname === "localhost")
//   worker.start({
//     onUnhandledRequest: "bypass",
//   });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

setB2BEnv(); // B2B URL 설정
setPrimaryColor();

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>
);
