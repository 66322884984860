import { serverClientDataCategoryMap } from "constants/market";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const getBannerUrl = (img: string) => {
  if (!img) return "";
  if (!img.includes("http")) return `${window.CDN_URL}/snplab/b2b-static/images/${img}.png`;
  return img;
};

export const isProgressNow = (start: dayjs.Dayjs) => {
  const nowDate = dayjs();
  return nowDate.isSameOrAfter(start);
};

export const isIncludedPeriod = (target: dayjs.Dayjs, start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  return target.isSameOrAfter(start, "day") && target.isSameOrBefore(end, "day");
};

export const syncProgressPeriod = (date: dayjs.Dayjs, type?: "decode") => {
  /** 거래 등록 시 진행기간이 23:59 까지여야 하기 때문에 서버에서 임의로 날짜를 + 1 해서 저장합니다.
   *  때문에 보여줄 때에는 서버에서 받아온 진행기간 notAfter에서 하루를 빼줘야 합니다.  */
  return date.subtract(type === "decode" ? -1 : 1, "day");
};

export const excludeExtraDataCategory = (selectData?: string) => {
  if (!selectData) return []; // typescript guard

  const selectDataList = selectData.split(",");
  const allCategories = Object.keys(serverClientDataCategoryMap);
  const isValidCategory = (item: string) => allCategories.includes(item);

  return selectDataList.filter(isValidCategory);
};

export const splitTextList = (str: string) => {
  const arr = str.split(",");

  return arr.map((item) => item.trim());
};
