import { Axios } from "./base";
import { TStaff } from "types/common";
import { TChangePw, TMypageModify } from "types/mypage";
import { errorHandling } from "./errorHandling";
import { ErrorMessage } from "constants/errorCode";

// 비밀번호 확인 POST
export const postConfirmPassword = async (json: { password: string }): Promise<{ message: string; status: number }> => {
  const { data } = await Axios.post(`/api/v2/password/confirm`, { json });
  return data;
};

// mypage 정보 조회
export const getMypage = async (): Promise<TStaff> => {
  try {
    const { data } = await Axios.get(`/api/v2/mypage/modify`);
    return data.data.staff;
  } catch {
    throw errorHandling(Error(`마이페이지 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

// mypage 정보 수정
export const updateMypage = async (staffMypageModify: TMypageModify) => {
  try {
    const { data } = await Axios.post(`/api/v2/mypage/modify`, { staffMypageModify });
    return data.data.resultStaff;
  } catch {
    throw errorHandling(
      Error(
        `마이페이지 수정 중 오류가 발생했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`,
      ),
    );
  }
};

// 비밀번호 변경
export const changePassword = async (json: { password: string; passwordCheck: string }): Promise<TChangePw> => {
  const { password, passwordCheck } = json;

  try {
    const { data } = await Axios.post(`/api/v2/mypage/password/change`, { password, passwordCheck });
    return data;
  } catch (error) {
    if (error instanceof Error && error.message !== ErrorMessage[3038]) {
      throw errorHandling(
        Error(`비밀번호를 변경하지 못했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`),
      );
    } else if (error instanceof Error && error.message === ErrorMessage[6006]) {
      throw errorHandling(Error(`비밀번호가 일치하지 않습니다.\n다시 확인해 주세요.`));
    } else {
      throw error;
    }
  }
};
