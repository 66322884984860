import React, { ChangeEventHandler, useRef, useState } from "react";
import { Path, FieldValues, useWatch, Controller, useFormContext } from "react-hook-form";
import cx from "classnames";

import { megbytesToBytes } from "utils/file";
import { useModal } from "hooks";
import { Dialog } from "common/modal";

import styles from "style/common/input/fileInput.module.scss";

type TProps = {
  id: Path<FieldValues>;
  btnText?: string;
  isRequired?: boolean;
  accept?: string;
  sizeLimit?: number;
  limitWidthHeight?: number[];
};

/**
 * @params id - file id
 * @params children - text in label
 * @params buttonText - text in button
 * @params isNull - is null value (default: false)
 * @params isRequired - must required (default: true)
 * @params accept - file extension (default: '.jpg, .jpeg, .png')
 * @params sizeLimit - size limit(mb)
 * @params widthHeight - width & height array (e.g. [width, height])
 *  */
export default function FileInput({
  id,
  btnText = "파일 선택",
  isRequired = true,
  accept = ".jpg, .jpeg, .png",
  sizeLimit = 1,
  limitWidthHeight,
}: TProps) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const fileRef = useRef<HTMLInputElement>(null);
  const imgData = useWatch({ control, name: id });

  const [isErrorModal, setIsErrorModal] = useModal();
  const [errorMsg, setErrorMsg] = useState("");

  const isCdnUrl = typeof imgData === "string";

  const allowedFileTypes = accept.split(", ").map((ext) => {
    const extension = ext.replace(/^\./, "");
    const type = extension === "pdf" ? "application" : "image";
    return `${type}/${extension}`;
  });

  const checkFileType = (allowedTypes: string[], type: string) => {
    if (!allowedTypes.includes(type)) {
      const extStr = allowedTypes.map((t) => t.split("/")[1]).join(", ");

      setIsErrorModal();
      setErrorMsg(`${extStr}\n파일 형식만 업로드 가능합니다`);
      return false;
    }
    return true;
  };

  const handleChangeImg: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const { files } = e.currentTarget;
    if (!files) return;
    const file = files[0];

    if (sizeLimit) {
      const isUploadableSize = file.size <= megbytesToBytes(sizeLimit);

      if (!isUploadableSize) {
        setIsErrorModal();
        setErrorMsg(`${sizeLimit}MB 이상의 파일은 올릴 수 없습니다.`);
        return;
      }

      if (!checkFileType(allowedFileTypes, file.type)) return;
    }

    if (limitWidthHeight) {
      const [w, h] = limitWidthHeight;

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = (event) => {
        const image = new Image();
        image.src = event.target?.result as string;

        image.onload = () => {
          const { width, height } = image;

          if (width === w && height === h) {
            setValue(e.target.id, file);
          } else {
            setIsErrorModal();
            setErrorMsg(`${w}*${h}px 사이즈의 이미지만 등록 가능합니다.`);
            return;
          }

          e.target.value = ""; // 동일한 파일 업로드 가능하도록 파일 정보 초기화
        };
      };
    } else {
      setValue(e.target.id, file);
    }
  };

  const handleClickCancel = () => {
    if (!fileRef?.current) return;

    setValue(id, null);
    fileRef.current!.value = "";
  };

  return (
    <div className={styles.uploadBox}>
      <label htmlFor={id} className={cx({ [styles.nullStyle]: !!errors[id] })}>
        {btnText}
      </label>

      <Controller
        name={id}
        control={control}
        rules={{ required: isRequired }}
        render={() => <input type="file" id={id} ref={fileRef} accept={accept} onChange={handleChangeImg} />}
      />

      {imgData && (
        <div className={cx(styles.imgBox)}>
          {isCdnUrl ? imgData.split("/").pop() : imgData?.name}
          <div>
            <button type="button" className={styles.cancelBtn} onClick={handleClickCancel}>
              &times;
            </button>
          </div>
        </div>
      )}
      {isErrorModal && <Dialog description={errorMsg} btnType="confirm" handleClose={setIsErrorModal} />}
    </div>

    // </div>
  );
}
