import { isProgressNow } from "utils/market";
import dayjs from "dayjs";

type TParam = {
  status: number;
  startDate: string;
  revokedAt: string;
};

/**
 * 0: ["진행중", "inProgress"]
 * 1: ["예정", "notStarted"]
 * 2: ["철회", "revoked"]
 * 3: ["종료", "ended"]
 * 4: ["종료", "ended"]
 * 5: ["임시저장", "temporary"]
 */
const overrideStatus = ({ status, startDate, revokedAt }: TParam) => {
  if (status === 0) {
    return isProgressNow(dayjs(startDate)) ? 0 : 1;
  }

  if (revokedAt && status === 2) {
    return 2;
  }

  return status;
};

export default overrideStatus;
