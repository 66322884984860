import { getDidFilteringListByProposalId } from "api/market";
import queryKey from "constants/queryKey";
import { useQuery, useQueryClient } from "react-query";
import { TDidFilterItem } from "types/market";

export default function useDidFilterListQuery(proposalId: string) {
  const filterCanceledFilterRequest = (item: TDidFilterItem) => !item.disabled;
  const parseFilterListStringToArray = (item: TDidFilterItem) => ({
    ...item,
    didFilter: item.didFilter ? JSON.parse(item.didFilter) : [],
    proposalFilter: item.proposalFilter ? JSON.parse(item.proposalFilter) : [],
  });

  const { data } = useQuery([queryKey.GET_DID_FILTER_LIST], () => getDidFilteringListByProposalId(proposalId), {
    select: (queryData) => queryData.filter(filterCanceledFilterRequest).map(parseFilterListStringToArray),
  });
  const qc = useQueryClient();

  const invalidateDidFilterQuery = () => qc.invalidateQueries([queryKey.GET_DID_FILTER_LIST]);

  return {
    invalidateDidFilterQuery,
    data,
  };
}
