import CheckTitle from "pages/market/marketComponents/CheckTitle";
import { ChangeEventHandler } from "react";
import styles from "style/pages/market/createMarketPage/sectionTargetData/targetFiltering/targetRadioBox.module.scss";

interface Props {
  name: string;
  dataList: {
    label: string;
    value: string;
  }[];
  value: string;
  handleCheck: (value: string) => void;
  disabled?: boolean;
}

export default function TargetRadioBox({ name, dataList, value: checked, handleCheck, disabled }: Props) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    handleCheck(e.currentTarget.value);
  };

  return (
    <div className={styles.wrapper}>
      <CheckTitle label={name} />
      {dataList.map(({ label, value }) => (
        <div key={name + value} className={styles.radio}>
          <input
            type="radio"
            id={name + value}
            name={name}
            value={value}
            onChange={handleChange}
            checked={checked === value}
            disabled={disabled}
          />
          <label htmlFor={name + value}>{label}</label>
        </div>
      ))}
    </div>
  );
}
