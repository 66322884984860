import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import StaffEditor from "./StaffEditor";
import StaffDetailItem from "./StaffDetailItem";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { Title } from "common/other";
import { Wrapper } from "common/container";
import { deleteUserData, getSingleUserData } from "api/management";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import ROLE from "constants/role";

export default function StaffDetail() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id = "" } = useParams();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useModal();
  const [isClickConfirm, setIsClickConfirm] = useModal();
  const [isErrorShowing, setIsErrorShowing] = useModal();

  // 담당자 상세정보 조회 API 연동
  const { data, isLoading, isFetching, error } = useQuery(
    [queryKey.GET_DETAIL_STAFF, id],
    () => getSingleUserData(id),
    { onError: setIsErrorShowing }
  );
  const isB2bAdmin = data?.role === ROLE.B2B_ADMIN;

  // 담당자 계정 삭제 API 연동
  const { mutate: deleteMutation, error: deleteError } = useMutation(deleteUserData);

  const handleDelete = () => {
    deleteMutation(id, {
      onSuccess: () => {
        setIsDeleteModal();
        setIsClickConfirm();
        queryClient.invalidateQueries([queryKey.GET_STAFF_LIST]);
      },
      onError: setIsErrorShowing,
    });
  };

  if (isLoading || isFetching) return <LoadingModal />;

  return (
    <Wrapper>
      <Title text={`${isB2bAdmin ? "대표" : ""}담당자 ${isEditMode ? "정보 수정" : "상세정보"}`} />

      {isEditMode ? (
        <StaffEditor data={data!} setIsEditMode={setIsEditMode} />
      ) : (
        <StaffDetailItem data={data!} setIsEditMode={setIsEditMode} setIsDeleteModal={setIsDeleteModal} />
      )}

      {/* Modals */}
      {isClickConfirm && (
        <Dialog
          description="삭제가 완료되었습니다."
          btnType="confirm"
          handleClose={() => navigate("/management", { replace: true })}
        />
      )}

      {isDeleteModal && (
        <Dialog
          title="담당자를 삭제하시겠습니까?"
          description={`삭제하실 경우 복구하실 수 없습니다.\n계속 진행하시겠습니까?`}
          btnType="submit"
          handleClose={setIsDeleteModal}
          handleSubmit={handleDelete}
        />
      )}

      {isErrorShowing && <ErrorModal error={error || deleteError} onConfirmHandler={setIsErrorShowing} />}
    </Wrapper>
  );
}
