import cx from "classnames";

import { useState } from "react";
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { JSONTree } from "react-json-tree";
import { useMutation } from "react-query";

import styles from "style/pages/market/didFilterPage/didFilteringBoard.module.scss";
import { TDidFilterForm } from "types/market";
import Input from "./components/Input";
import Label from "./components/Label";
import Radio from "./components/Radio";
import Textarea from "./components/Textarea";

import { applyDidFiltering, postDidFilterRequest } from "api/market";
import { DefaultBtn } from "common/button";
import { Content } from "common/container";
import { Title } from "common/other";
import { useModal } from "hooks";
import { DidFilteringMode, RegisterDidFilteringRequest } from "protobuf/OpenApiServerV3";
import { useSearchParams } from "react-router-dom";
import { splitTextList } from "utils/market";

import { Dialog } from "common/modal";
import DidFilterRevoke from "./DidFilterRevoke";
import useDidFilterListQuery from "./useDidFilterListQuery";

export default function DidFilteringBoard() {
  const [sp] = useSearchParams();
  const defaultProposalId = sp.get("proposalId") || "";

  const { control, handleSubmit, watch } = useForm<TDidFilterForm>({
    shouldFocusError: true,
    defaultValues: {
      proposalId: defaultProposalId,
      mode: "blocklist",
      didList: "",
      proposalList: "",
    },
  });
  const [errMsg, setErrMsg] = useState("");

  const { mutateAsync: registerMuta, data: registeredFilteringId } = useMutation({
    mutationFn: async () => (await postDidFilterRequest(request)).filteringId,
    onSuccess: () => invalidateDidFilterQuery(),
    onError: () => {
      setErrMsg("DID 필터링 요청 등록에 실패했습니다");
    },
  });
  const { mutateAsync: applyMuta, isLoading: isApplyLoading } = useMutation({
    mutationFn: () => applyDidFiltering(),
    onSuccess: () => invalidateDidFilterQuery(),
    onError: () => {
      setErrMsg("DID 필터링 적용에 실패했습니다");
    },
    onSettled: () => {
      onApplyNotifyToggle();
    },
  });
  const { invalidateDidFilterQuery } = useDidFilterListQuery(defaultProposalId);

  const [isConfirmModalOpen, onConfirmToggle] = useModal();
  const [isApplyNotifyOpen, onApplyNotifyToggle] = useModal();
  const [isRegisterNotifyOpen, onRegisterNotifyToggle] = useModal();

  const handleRegisterClick = async () => {
    await registerMuta();
    onRegisterNotifyToggle();
  };

  const handleApplyClick: SubmitHandler<TDidFilterForm> = () => {
    applyMuta();
  };
  const handleError: SubmitErrorHandler<TDidFilterForm> = (errs) => {
    console.error(errs);
  };
  const handleFormSubmit = () => {
    handleSubmit(handleApplyClick, handleError)();
    onConfirmToggle();
  };
  const resetErrMsg = () => setErrMsg("");

  const handleNotifyToggle = () => {
    onApplyNotifyToggle();
    resetErrMsg();
  };

  const getDedupedList = (list: string[]) => {
    return [...new Set(list)];
  };

  const createDidFilteringRequest = (
    proposalId: string,
    mode: string,
    didList: string,
    proposalList: string
  ): RegisterDidFilteringRequest => {
    const registerDidFilteringRequest: RegisterDidFilteringRequest = RegisterDidFilteringRequest.create();

    registerDidFilteringRequest.proposalId = proposalId;
    if (mode === "blocklist") {
      registerDidFilteringRequest.mode = DidFilteringMode.FILTER_BLOCKLIST;
    } else {
      registerDidFilteringRequest.mode = DidFilteringMode.FILTER_ALLOWLIST;
    }

    const didArray = getDedupedList(splitTextList(didList));
    const proposalArray = getDedupedList(splitTextList(proposalList));

    didArray.forEach((v) => {
      if (v !== "") {
        registerDidFilteringRequest.didFilter.push(v);
      }
    });

    proposalArray.forEach((v) => {
      if (v !== "") {
        registerDidFilteringRequest.proposalFilter.push(v);
      }
    });

    // registerDidFilteringRequest.didFilter = didArray;
    // registerDidFilteringRequest.proposalFilter = proposalArray;

    return registerDidFilteringRequest;
  };

  const mode = watch("mode");
  const proposalId = watch("proposalId");
  const didList = watch("didList");
  const proposalList = watch("proposalList");

  const request = createDidFilteringRequest(proposalId, mode, didList, proposalList);

  return (
    <div className={styles.container}>
      <Title text="DID 필터링 적용" />
      <Content>
        <form className={styles.panel} onSubmit={handleSubmit(onConfirmToggle, handleError)}>
          <div className={styles.input_container}>
            <Label htmlFor="proposalId">제안서 ID</Label>
            <Controller
              name="proposalId"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  readOnly
                  id="proposalId"
                  className={cx({ [styles.error_input]: error })}
                  placeholder="Proposal Id 를 입력해주세요"
                />
              )}
            />
          </div>

          <div className={styles.input_container}>
            <Label>필터링 모드</Label>
            <Controller
              name="mode"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, disabled, name, value } }) => (
                <div className={styles.radio_container}>
                  <label htmlFor="blocklist" className={styles.radio}>
                    <span>블랙 리스트</span>
                    <Radio
                      id="blocklist"
                      onChange={onChange}
                      disabled={disabled}
                      name={name}
                      value="blocklist"
                      checked={value === "blocklist"}
                    />
                  </label>
                  <label htmlFor="allowlist" className={styles.radio}>
                    <span>화이트 리스트</span>
                    <Radio
                      id="allowlist"
                      onChange={onChange}
                      disabled={disabled}
                      name={name}
                      value="allowlist"
                      checked={value === "allowlist"}
                    />
                  </label>
                </div>
              )}
            />
          </div>

          <div className={styles.input_container}>
            <Label htmlFor="didList">DID 리스트</Label>
            <Controller
              name="didList"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Textarea
                  {...field}
                  className={cx({ [styles.error_input]: error })}
                  placeholder="(i.e. did:snplab:xxxx, did:snplab:yyyy)"
                />
              )}
            />
          </div>

          <div className={styles.input_container}>
            <Label htmlFor="proposalList">제안서 ID 리스트</Label>
            <Controller
              name="proposalList"
              control={control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  placeholder="(i.e. mEtSgzGxZAVVMR3k7eKK9kGFT8S3WgzGimyHAuv2VS8UsD8T4, mEtSgzGxZAVVMR3k7eKK9est6Xd65jDgjqnBkRaAi5gspqVxQ)"
                />
              )}
            />
          </div>

          <div className={styles.input_container}>
            <Label>DID 필터링 ID</Label>
            <div className={styles.register}>
              ID: {registeredFilteringId || "DID 필터 등록 전입니다"}
              <DefaultBtn onClick={handleRegisterClick} type="button">
                등록
              </DefaultBtn>
            </div>
          </div>
          <div className={styles.json_tree}>
            <Label>요청 포맷 미리보기</Label>
            <JSONTree data={request} />
          </div>
          <div className={styles.did_filtering_container}>
            <DefaultBtn size="medium" type="submit">
              DID 필터링 적용
            </DefaultBtn>
          </div>
        </form>
      </Content>
      {isRegisterNotifyOpen && (
        <Dialog
          btnType="confirm"
          title="요청이 완료되었습니다"
          description={`DID 필터링 요청이 등록 되었습니다.\n적용까지 완료해야 필터링 요청이 완료됩니다`}
          submitBtnName="확인"
          handleClose={onRegisterNotifyToggle}
        />
      )}
      {isConfirmModalOpen && (
        <Dialog
          btnType="submit"
          title="적용하시겠습니까?"
          description={`입력 정보에 따라 DID 필터링을 적용합니다.\n적용 후에는 취소할 수 없습니다`}
          submitBtnName="확인"
          handleClose={onConfirmToggle}
          handleSubmit={handleFormSubmit}
        />
      )}
      <DidFilterRevoke proposalId={proposalId} />
      {isApplyNotifyOpen && (
        <Dialog
          btnType="confirm"
          title="요청이 완료되었습니다"
          description={errMsg || "DID 필터링이 적용 되었습니다"}
          submitBtnName="확인"
          handleClose={handleNotifyToggle}
        />
      )}
    </div>
  );
}
