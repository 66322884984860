import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";

import { AdDashboard, AdDetailInfo, AdApprovalReason } from "./index";
import { SidebarLayout, DetailPreviewSidebar } from "../previewSidebar";
import { ButtonBox, Title } from "common/other";
import { Wrapper } from "common/container";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import queryKey from "constants/queryKey";
import { cancelAd, getAdDetail } from "api/advertisement";
import { isProgressNow } from "utils/market";
import useModal from "hooks/useModal";
import { adPageAtom } from "recoils/ad";

// ad status에 따라 보여줄 상세정보 컴포넌트들을 조합하는 상위 컴포넌트
export default function AdDetail() {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { status: pageStatus, pageNum } = useRecoilValue(adPageAtom);

  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isCheckModal, setIsCheckModal] = useModal();
  const [isConfirmModal, setIsConfirmModal] = useModal();

  const {
    data,
    isLoading,
    error: getError,
  } = useQuery([queryKey.GET_DETAIL_AD, id], () => getAdDetail(+id), {
    onError: setIsErrorShowing,
  });

  const { approved, status, notBefore } = data?.advertisements || {};

  // status filter
  const notStartedCondition = approved && status === 0 && !isProgressNow(dayjs(notBefore)); // 예정
  const inProgressCondition = approved && status === 0 && isProgressNow(dayjs(notBefore)); // 진행중
  const endedCondition = approved && status === 1; // 종료
  const pendingCondition = !approved && status === 1; // 승인요청
  const cancelCondition = !approved && status === 2; // 요청취소
  const rejectCondition = !approved && status === 3; // 반려

  // ad status 변경 API
  const { mutate: cancelMutation, error: cancelError } = useMutation(cancelAd, {
    onSuccess: () => {
      setIsConfirmModal();
      setIsCheckModal();
      queryClient.invalidateQueries([queryKey.GET_AD_LIST]);
    },
    onError: setIsErrorShowing,
  });

  const handleClickNext = () => {
    if (notStartedCondition || inProgressCondition) {
      const body = { id: Number(id), status: 1 }; // 종료로 status 변경
      cancelMutation(body);
    } else if (pendingCondition) {
      const body = { id: Number(id), status: 2 }; // 요청취소로 status 변경
      cancelMutation(body);
    }
  };

  const navigateCondition = () => {
    if (endedCondition || inProgressCondition) {
      navigate(`/advertise?${pageStatus && `status=${pageStatus}`}&pageNum=${pageNum}`);
    } else {
      navigate(-1);
    }
  };

  const handleCheckModal = () => {
    setIsCheckModal();
    navigateCondition();
  };

  // 버튼 박스 분기
  const buttonBox = () => {
    if (pendingCondition) {
      return <ButtonBox type="submit" back="확인" next="요청 취소" handleClickNext={setIsConfirmModal} />;
    }

    if (inProgressCondition || notStartedCondition) {
      return (
        <ButtonBox
          type="submit"
          back="목록으로"
          next="광고 철회"
          handleClickPrev={navigateCondition}
          handleClickNext={setIsConfirmModal}
        />
      );
    }

    return <ButtonBox type="confirm" handleClickConfirm={navigateCondition} />;
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Wrapper>
      <Title text="상세 정보" />
      {(pendingCondition || notStartedCondition || rejectCondition || cancelCondition) && <AdDetailInfo data={data!} />}
      {(notStartedCondition || rejectCondition) && <AdApprovalReason data={data!} />}
      {(inProgressCondition || endedCondition) && <AdDashboard data={data!} />}
      {buttonBox()}

      <SidebarLayout defaultOpen={false}>
        <DetailPreviewSidebar data={data!} />
      </SidebarLayout>

      {isConfirmModal && (
        <Dialog
          title={pendingCondition ? "광고 승인 요청을 취소하시겠습니까?" : "광고를 철회하시겠습니까?"}
          description={
            pendingCondition
              ? `취소하실 경우 복구하실 수 없습니다.\n계속 진행하시겠습니까?`
              : "철회한 광고는 재개할 수 없습니다."
          }
          btnType="submit"
          handleClose={setIsConfirmModal}
          handleSubmit={handleClickNext}
        />
      )}

      {isCheckModal && (
        <Dialog
          description={
            pendingCondition ? "광고 승인 요청이 취소되었습니다." : "진행 중인 거래에 광고가 노출되지 않습니다."
          }
          btnType="confirm"
          handleClose={handleCheckModal}
        />
      )}

      {isErrorShowing && <ErrorModal error={getError || cancelError} onConfirmHandler={setIsErrorShowing} />}
    </Wrapper>
  );
}
