import { ChangeEventHandler } from "react";
import styles from "style/common/input/radioBox.module.scss";

interface Props {
  name: string;
  dataList: {
    label: string;
    value: any;
  }[];
  value: any;
  handleCheck: (value: any) => void;
  disabled?: boolean;
}

export default function RadioBox({ name, dataList, value: checked, handleCheck, disabled }: Props) {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => handleCheck(e.currentTarget.value);

  return (
    <div className={styles.wrapper}>
      {dataList.map(({ label, value }) => (
        <div key={value} className={styles.radio}>
          <input
            type="radio"
            id={value}
            name={name}
            value={value}
            onChange={handleChange}
            checked={checked === value}
            disabled={disabled}
          />
          <label htmlFor={value}>{label}</label>
        </div>
      ))}
    </div>
  );
}
