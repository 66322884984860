import { ReactNode, useMemo } from "react";
import cx from "classnames";
import { TableTabContext, useTableTabContext } from "./Context";
import styles from "./tableTab.module.scss";
import { useSearchParams } from "react-router-dom";

type TTableTab = {
  defaultTab: string;
  children: ReactNode;
};

export function Main({ defaultTab, children }: TTableTab) {
  const value = useMemo(() => ({ defaultTab }), [defaultTab]);

  return (
    <TableTabContext.Provider value={value}>
      <div className={styles.wrapper}>{children}</div>
    </TableTabContext.Provider>
  );
}

type TTabBox = {
  children: ReactNode;
};

export function TabBox({ children }: TTabBox) {
  return <div className={styles.tab_box}>{children}</div>;
}

type TTab = {
  label: string;
  value: string;
};

export function Tab({ label, value }: TTab) {
  const { defaultTab } = useTableTabContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || defaultTab;

  const handleClick = () => {
    setSearchParams({ tab: value });
  };

  return (
    <button type="button" onClick={handleClick} className={cx(styles.tab, { [styles.active]: activeTab === value })}>
      {label}
    </button>
  );
}

type TPanel = {
  value: string;
  children: ReactNode;
};

export function Panel({ value, children }: TPanel) {
  const { defaultTab } = useTableTabContext();
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab") || defaultTab;

  if (activeTab !== value) {
    return null;
  }

  return <div className={styles.pannel}>{children}</div>;
}

const TableTab = Object.assign(Main, {
  TabBox,
  Tab,
  Panel,
});
export default TableTab;
