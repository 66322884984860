/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { CommonStatusCode, commonStatusCodeFromJSON, commonStatusCodeToJSON } from "./CommonStatusCode";
import {
  Proposal,
  ProposalActiveClient,
  ProposalStatic,
  RewardTransactionContentForApp,
} from "./OpenApiServerV3DataTypes";
import { RewardTransactionContent } from "./OpenApiServerV3Middleware";
import Long from "long";

export const protobufPackage = "io.snplab.myd.protocol.v3.schema";

/** Asynchronous request status */
export enum AsyncRequestStatus {
  /** STATUS_ASYNC_REQUEST_BEGIN - place holder for 0 */
  STATUS_ASYNC_REQUEST_BEGIN = 0,
  STATUS_ASYNC_REQUEST_ACCEPTED = 1,
  STATUS_ASYNC_REQUEST_INPROGRESS = 2,
  STATUS_ASYNC_REQUEST_PROCESSED = 3,
  STATUS_ASYNC_REQUEST_FAILED = 4,
  STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND = 5,
  STATUS_ASYNC_REQUEST_DUPLICATED = 6,
  UNRECOGNIZED = -1,
}

export function asyncRequestStatusFromJSON(object: any): AsyncRequestStatus {
  switch (object) {
    case 0:
    case "STATUS_ASYNC_REQUEST_BEGIN":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_BEGIN;
    case 1:
    case "STATUS_ASYNC_REQUEST_ACCEPTED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_ACCEPTED;
    case 2:
    case "STATUS_ASYNC_REQUEST_INPROGRESS":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_INPROGRESS;
    case 3:
    case "STATUS_ASYNC_REQUEST_PROCESSED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_PROCESSED;
    case 4:
    case "STATUS_ASYNC_REQUEST_FAILED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_FAILED;
    case 5:
    case "STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND;
    case 6:
    case "STATUS_ASYNC_REQUEST_DUPLICATED":
      return AsyncRequestStatus.STATUS_ASYNC_REQUEST_DUPLICATED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AsyncRequestStatus.UNRECOGNIZED;
  }
}

export function asyncRequestStatusToJSON(object: AsyncRequestStatus): string {
  switch (object) {
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_BEGIN:
      return "STATUS_ASYNC_REQUEST_BEGIN";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_ACCEPTED:
      return "STATUS_ASYNC_REQUEST_ACCEPTED";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_INPROGRESS:
      return "STATUS_ASYNC_REQUEST_INPROGRESS";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_PROCESSED:
      return "STATUS_ASYNC_REQUEST_PROCESSED";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_FAILED:
      return "STATUS_ASYNC_REQUEST_FAILED";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND:
      return "STATUS_ASYNC_REQUEST_REQUEST_NOT_FOUND";
    case AsyncRequestStatus.STATUS_ASYNC_REQUEST_DUPLICATED:
      return "STATUS_ASYNC_REQUEST_DUPLICATED";
    case AsyncRequestStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** List reward history request type */
export enum ListRewardHistoryRequestType {
  /** LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN - place holder for 0 */
  LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN = 0,
  LIST_REWARD_HISTORY_REQUEST_TYPE_ALL = 1,
  LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE = 2,
  LIST_REWARD_HISTORY_REQUEST_TYPE_USE = 3,
  UNRECOGNIZED = -1,
}

export function listRewardHistoryRequestTypeFromJSON(object: any): ListRewardHistoryRequestType {
  switch (object) {
    case 0:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN;
    case 1:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_ALL":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_ALL;
    case 2:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE;
    case 3:
    case "LIST_REWARD_HISTORY_REQUEST_TYPE_USE":
      return ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_USE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListRewardHistoryRequestType.UNRECOGNIZED;
  }
}

export function listRewardHistoryRequestTypeToJSON(object: ListRewardHistoryRequestType): string {
  switch (object) {
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_BEGIN";
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_ALL:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_ALL";
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_SAVE";
    case ListRewardHistoryRequestType.LIST_REWARD_HISTORY_REQUEST_TYPE_USE:
      return "LIST_REWARD_HISTORY_REQUEST_TYPE_USE";
    case ListRewardHistoryRequestType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Coupon status */
export enum CouponStatus {
  /** COUPON_STATUS_BEGIN - place holder for 0 */
  COUPON_STATUS_BEGIN = 0,
  COUPON_STATUS_PUBLISHED = 1,
  COUPON_STATUS_USED = 2,
  COUPON_STATUS_RETRUN = 3,
  COUPON_STATUS_MANAGED_DISPOSAL = 4,
  COUPON_STATUS_REFUND = 5,
  COUPON_STATUS_REPUBLISHED = 6,
  COUPON_STATUS_CANCELLED = 7,
  COUPON_STATUS_EXPIRED = 8,
  COUPON_STATUS_INACTIVE_VOUCHER = 9,
  COUPON_STATUS_REFUNDED_10 = 10,
  COUPON_STATUS_EXPIRED_BALANCE_11 = 11,
  COUPON_STATUS_CANCELLED_EXPIRED_12 = 12,
  COUPON_STATUS_EXCHANGED_13 = 13,
  COUPON_STATUS_REFUND_14 = 14,
  COUPON_STATUS_REFUND_15 = 15,
  COUPON_STATUS_CANCELLED_16 = 16,
  COUPON_STATUS_REGISTERED_21 = 21,
  COUPON_STATUS_CANCELLED_22 = 22,
  COUPON_STATUS_OCCUPIED_23 = 23,
  COUPON_STATUS_TEMPORARY_24 = 24,
  COUPON_STATUS_ID_READY = 100,
  UNRECOGNIZED = -1,
}

export function couponStatusFromJSON(object: any): CouponStatus {
  switch (object) {
    case 0:
    case "COUPON_STATUS_BEGIN":
      return CouponStatus.COUPON_STATUS_BEGIN;
    case 1:
    case "COUPON_STATUS_PUBLISHED":
      return CouponStatus.COUPON_STATUS_PUBLISHED;
    case 2:
    case "COUPON_STATUS_USED":
      return CouponStatus.COUPON_STATUS_USED;
    case 3:
    case "COUPON_STATUS_RETRUN":
      return CouponStatus.COUPON_STATUS_RETRUN;
    case 4:
    case "COUPON_STATUS_MANAGED_DISPOSAL":
      return CouponStatus.COUPON_STATUS_MANAGED_DISPOSAL;
    case 5:
    case "COUPON_STATUS_REFUND":
      return CouponStatus.COUPON_STATUS_REFUND;
    case 6:
    case "COUPON_STATUS_REPUBLISHED":
      return CouponStatus.COUPON_STATUS_REPUBLISHED;
    case 7:
    case "COUPON_STATUS_CANCELLED":
      return CouponStatus.COUPON_STATUS_CANCELLED;
    case 8:
    case "COUPON_STATUS_EXPIRED":
      return CouponStatus.COUPON_STATUS_EXPIRED;
    case 9:
    case "COUPON_STATUS_INACTIVE_VOUCHER":
      return CouponStatus.COUPON_STATUS_INACTIVE_VOUCHER;
    case 10:
    case "COUPON_STATUS_REFUNDED_10":
      return CouponStatus.COUPON_STATUS_REFUNDED_10;
    case 11:
    case "COUPON_STATUS_EXPIRED_BALANCE_11":
      return CouponStatus.COUPON_STATUS_EXPIRED_BALANCE_11;
    case 12:
    case "COUPON_STATUS_CANCELLED_EXPIRED_12":
      return CouponStatus.COUPON_STATUS_CANCELLED_EXPIRED_12;
    case 13:
    case "COUPON_STATUS_EXCHANGED_13":
      return CouponStatus.COUPON_STATUS_EXCHANGED_13;
    case 14:
    case "COUPON_STATUS_REFUND_14":
      return CouponStatus.COUPON_STATUS_REFUND_14;
    case 15:
    case "COUPON_STATUS_REFUND_15":
      return CouponStatus.COUPON_STATUS_REFUND_15;
    case 16:
    case "COUPON_STATUS_CANCELLED_16":
      return CouponStatus.COUPON_STATUS_CANCELLED_16;
    case 21:
    case "COUPON_STATUS_REGISTERED_21":
      return CouponStatus.COUPON_STATUS_REGISTERED_21;
    case 22:
    case "COUPON_STATUS_CANCELLED_22":
      return CouponStatus.COUPON_STATUS_CANCELLED_22;
    case 23:
    case "COUPON_STATUS_OCCUPIED_23":
      return CouponStatus.COUPON_STATUS_OCCUPIED_23;
    case 24:
    case "COUPON_STATUS_TEMPORARY_24":
      return CouponStatus.COUPON_STATUS_TEMPORARY_24;
    case 100:
    case "COUPON_STATUS_ID_READY":
      return CouponStatus.COUPON_STATUS_ID_READY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CouponStatus.UNRECOGNIZED;
  }
}

export function couponStatusToJSON(object: CouponStatus): string {
  switch (object) {
    case CouponStatus.COUPON_STATUS_BEGIN:
      return "COUPON_STATUS_BEGIN";
    case CouponStatus.COUPON_STATUS_PUBLISHED:
      return "COUPON_STATUS_PUBLISHED";
    case CouponStatus.COUPON_STATUS_USED:
      return "COUPON_STATUS_USED";
    case CouponStatus.COUPON_STATUS_RETRUN:
      return "COUPON_STATUS_RETRUN";
    case CouponStatus.COUPON_STATUS_MANAGED_DISPOSAL:
      return "COUPON_STATUS_MANAGED_DISPOSAL";
    case CouponStatus.COUPON_STATUS_REFUND:
      return "COUPON_STATUS_REFUND";
    case CouponStatus.COUPON_STATUS_REPUBLISHED:
      return "COUPON_STATUS_REPUBLISHED";
    case CouponStatus.COUPON_STATUS_CANCELLED:
      return "COUPON_STATUS_CANCELLED";
    case CouponStatus.COUPON_STATUS_EXPIRED:
      return "COUPON_STATUS_EXPIRED";
    case CouponStatus.COUPON_STATUS_INACTIVE_VOUCHER:
      return "COUPON_STATUS_INACTIVE_VOUCHER";
    case CouponStatus.COUPON_STATUS_REFUNDED_10:
      return "COUPON_STATUS_REFUNDED_10";
    case CouponStatus.COUPON_STATUS_EXPIRED_BALANCE_11:
      return "COUPON_STATUS_EXPIRED_BALANCE_11";
    case CouponStatus.COUPON_STATUS_CANCELLED_EXPIRED_12:
      return "COUPON_STATUS_CANCELLED_EXPIRED_12";
    case CouponStatus.COUPON_STATUS_EXCHANGED_13:
      return "COUPON_STATUS_EXCHANGED_13";
    case CouponStatus.COUPON_STATUS_REFUND_14:
      return "COUPON_STATUS_REFUND_14";
    case CouponStatus.COUPON_STATUS_REFUND_15:
      return "COUPON_STATUS_REFUND_15";
    case CouponStatus.COUPON_STATUS_CANCELLED_16:
      return "COUPON_STATUS_CANCELLED_16";
    case CouponStatus.COUPON_STATUS_REGISTERED_21:
      return "COUPON_STATUS_REGISTERED_21";
    case CouponStatus.COUPON_STATUS_CANCELLED_22:
      return "COUPON_STATUS_CANCELLED_22";
    case CouponStatus.COUPON_STATUS_OCCUPIED_23:
      return "COUPON_STATUS_OCCUPIED_23";
    case CouponStatus.COUPON_STATUS_TEMPORARY_24:
      return "COUPON_STATUS_TEMPORARY_24";
    case CouponStatus.COUPON_STATUS_ID_READY:
      return "COUPON_STATUS_ID_READY";
    case CouponStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Blockchain role */
export enum BlockchainRole {
  /** ADMIN - Blockchain administrator */
  ADMIN = 0,
  /** PLATFORM_MANAGER - MyD platform manager */
  PLATFORM_MANAGER = 1,
  /** PLATFORM_OPERATOR - MyD platform operator */
  PLATFORM_OPERATOR = 2,
  /** ROLE_MAINTAINER - Role maintainer (global) */
  ROLE_MAINTAINER = 3,
  /** ENDORSER - DID endorser (one for each server) */
  ENDORSER = 4,
  /** DATA_VERIFIER - MyD data verifier */
  DATA_VERIFIER = 5,
  /** GAS_STATIONER - Gas stationer */
  GAS_STATIONER = 6,
  /** COMPANY - Company */
  COMPANY = 7,
  /** PROPOSAL - Proposal */
  PROPOSAL = 8,
  /** SNPLAB_BIS - SNPLAB BIS (token holder) */
  SNPLAB_BIS = 9,
  /** BIS_MINTER - BIS MINTER (token spender) */
  BIS_MINTER = 10,
  /** UNKNOWN - UNKNOWN */
  UNKNOWN = 99,
  UNRECOGNIZED = -1,
}

export function blockchainRoleFromJSON(object: any): BlockchainRole {
  switch (object) {
    case 0:
    case "ADMIN":
      return BlockchainRole.ADMIN;
    case 1:
    case "PLATFORM_MANAGER":
      return BlockchainRole.PLATFORM_MANAGER;
    case 2:
    case "PLATFORM_OPERATOR":
      return BlockchainRole.PLATFORM_OPERATOR;
    case 3:
    case "ROLE_MAINTAINER":
      return BlockchainRole.ROLE_MAINTAINER;
    case 4:
    case "ENDORSER":
      return BlockchainRole.ENDORSER;
    case 5:
    case "DATA_VERIFIER":
      return BlockchainRole.DATA_VERIFIER;
    case 6:
    case "GAS_STATIONER":
      return BlockchainRole.GAS_STATIONER;
    case 7:
    case "COMPANY":
      return BlockchainRole.COMPANY;
    case 8:
    case "PROPOSAL":
      return BlockchainRole.PROPOSAL;
    case 9:
    case "SNPLAB_BIS":
      return BlockchainRole.SNPLAB_BIS;
    case 10:
    case "BIS_MINTER":
      return BlockchainRole.BIS_MINTER;
    case 99:
    case "UNKNOWN":
      return BlockchainRole.UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BlockchainRole.UNRECOGNIZED;
  }
}

export function blockchainRoleToJSON(object: BlockchainRole): string {
  switch (object) {
    case BlockchainRole.ADMIN:
      return "ADMIN";
    case BlockchainRole.PLATFORM_MANAGER:
      return "PLATFORM_MANAGER";
    case BlockchainRole.PLATFORM_OPERATOR:
      return "PLATFORM_OPERATOR";
    case BlockchainRole.ROLE_MAINTAINER:
      return "ROLE_MAINTAINER";
    case BlockchainRole.ENDORSER:
      return "ENDORSER";
    case BlockchainRole.DATA_VERIFIER:
      return "DATA_VERIFIER";
    case BlockchainRole.GAS_STATIONER:
      return "GAS_STATIONER";
    case BlockchainRole.COMPANY:
      return "COMPANY";
    case BlockchainRole.PROPOSAL:
      return "PROPOSAL";
    case BlockchainRole.SNPLAB_BIS:
      return "SNPLAB_BIS";
    case BlockchainRole.BIS_MINTER:
      return "BIS_MINTER";
    case BlockchainRole.UNKNOWN:
      return "UNKNOWN";
    case BlockchainRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * Did filtering mode
 * Allowlist: the proposal is hidden by default, only allowed did can see the proposal
 * Blocklist: the proposal is visible by default, only few people cannot see the proposal
 */
export enum DidFilteringMode {
  /** FILTER_BEGIN - place holder for 0 */
  FILTER_BEGIN = 0,
  FILTER_ALLOWLIST = 1,
  FILTER_BLOCKLIST = 2,
  UNRECOGNIZED = -1,
}

export function didFilteringModeFromJSON(object: any): DidFilteringMode {
  switch (object) {
    case 0:
    case "FILTER_BEGIN":
      return DidFilteringMode.FILTER_BEGIN;
    case 1:
    case "FILTER_ALLOWLIST":
      return DidFilteringMode.FILTER_ALLOWLIST;
    case 2:
    case "FILTER_BLOCKLIST":
      return DidFilteringMode.FILTER_BLOCKLIST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return DidFilteringMode.UNRECOGNIZED;
  }
}

export function didFilteringModeToJSON(object: DidFilteringMode): string {
  switch (object) {
    case DidFilteringMode.FILTER_BEGIN:
      return "FILTER_BEGIN";
    case DidFilteringMode.FILTER_ALLOWLIST:
      return "FILTER_ALLOWLIST";
    case DidFilteringMode.FILTER_BLOCKLIST:
      return "FILTER_BLOCKLIST";
    case DidFilteringMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Simple user type */
export enum SimpleUserType {
  /** USER_TYPE_BEGIN - place holder for 0 */
  USER_TYPE_BEGIN = 0,
  USER_MYD3 = 1,
  USER_MYD2 = 2,
  USER_NOT_FOUND = 3,
  UNRECOGNIZED = -1,
}

export function simpleUserTypeFromJSON(object: any): SimpleUserType {
  switch (object) {
    case 0:
    case "USER_TYPE_BEGIN":
      return SimpleUserType.USER_TYPE_BEGIN;
    case 1:
    case "USER_MYD3":
      return SimpleUserType.USER_MYD3;
    case 2:
    case "USER_MYD2":
      return SimpleUserType.USER_MYD2;
    case 3:
    case "USER_NOT_FOUND":
      return SimpleUserType.USER_NOT_FOUND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SimpleUserType.UNRECOGNIZED;
  }
}

export function simpleUserTypeToJSON(object: SimpleUserType): string {
  switch (object) {
    case SimpleUserType.USER_TYPE_BEGIN:
      return "USER_TYPE_BEGIN";
    case SimpleUserType.USER_MYD3:
      return "USER_MYD3";
    case SimpleUserType.USER_MYD2:
      return "USER_MYD2";
    case SimpleUserType.USER_NOT_FOUND:
      return "USER_NOT_FOUND";
    case SimpleUserType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HomeBannerCardType {
  /** TYPE_BEGIN - place holder for 0 */
  TYPE_BEGIN = 0,
  SIMPLE_BANNER_LANDING = 1,
  SINGLE_PROPOSAL = 2,
  CATEGORY_PROPOSAL_PURPOSE = 3,
  UNRECOGNIZED = -1,
}

export function homeBannerCardTypeFromJSON(object: any): HomeBannerCardType {
  switch (object) {
    case 0:
    case "TYPE_BEGIN":
      return HomeBannerCardType.TYPE_BEGIN;
    case 1:
    case "SIMPLE_BANNER_LANDING":
      return HomeBannerCardType.SIMPLE_BANNER_LANDING;
    case 2:
    case "SINGLE_PROPOSAL":
      return HomeBannerCardType.SINGLE_PROPOSAL;
    case 3:
    case "CATEGORY_PROPOSAL_PURPOSE":
      return HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HomeBannerCardType.UNRECOGNIZED;
  }
}

export function homeBannerCardTypeToJSON(object: HomeBannerCardType): string {
  switch (object) {
    case HomeBannerCardType.TYPE_BEGIN:
      return "TYPE_BEGIN";
    case HomeBannerCardType.SIMPLE_BANNER_LANDING:
      return "SIMPLE_BANNER_LANDING";
    case HomeBannerCardType.SINGLE_PROPOSAL:
      return "SINGLE_PROPOSAL";
    case HomeBannerCardType.CATEGORY_PROPOSAL_PURPOSE:
      return "CATEGORY_PROPOSAL_PURPOSE";
    case HomeBannerCardType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HomeBannerSupportedPlatform {
  /** SUPPORT_BEGIN - place holder for 0 */
  SUPPORT_BEGIN = 0,
  SUPPORT_ALL = 1,
  SUPPORT_ANDROID = 2,
  SUPPORT_IOS = 3,
  UNRECOGNIZED = -1,
}

export function homeBannerSupportedPlatformFromJSON(object: any): HomeBannerSupportedPlatform {
  switch (object) {
    case 0:
    case "SUPPORT_BEGIN":
      return HomeBannerSupportedPlatform.SUPPORT_BEGIN;
    case 1:
    case "SUPPORT_ALL":
      return HomeBannerSupportedPlatform.SUPPORT_ALL;
    case 2:
    case "SUPPORT_ANDROID":
      return HomeBannerSupportedPlatform.SUPPORT_ANDROID;
    case 3:
    case "SUPPORT_IOS":
      return HomeBannerSupportedPlatform.SUPPORT_IOS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HomeBannerSupportedPlatform.UNRECOGNIZED;
  }
}

export function homeBannerSupportedPlatformToJSON(object: HomeBannerSupportedPlatform): string {
  switch (object) {
    case HomeBannerSupportedPlatform.SUPPORT_BEGIN:
      return "SUPPORT_BEGIN";
    case HomeBannerSupportedPlatform.SUPPORT_ALL:
      return "SUPPORT_ALL";
    case HomeBannerSupportedPlatform.SUPPORT_ANDROID:
      return "SUPPORT_ANDROID";
    case HomeBannerSupportedPlatform.SUPPORT_IOS:
      return "SUPPORT_IOS";
    case HomeBannerSupportedPlatform.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** DID registration material */
export interface RegisterRequestMaterial {
  /** constant prefix for register request */
  materialPrefix: string;
  /** did */
  did: string;
  /** besu address */
  besuAddress: string;
  /** nonce generated by the server */
  nonce: string;
  /** secp256k1 public key */
  publicKey: Uint8Array;
  /** ed25519 public key; only for the migration case */
  verkey?: Uint8Array | undefined;
  /** DID document to register */
  document?: string | undefined;
}

/** Request of the device attestation */
export interface DeviceAttestationRequest {
  /** app version string */
  appVersion: string;
  /** parcel data */
  parcel: string;
  /** client random */
  clientKeyMaterial: Uint8Array;
  /** registration message */
  registrationMessage: RegisterRequestMaterial | undefined;
  /** signature of registration message */
  registrationSignature: Uint8Array;
  /** signature of migration message */
  migrationSignature?: Uint8Array | undefined;
}

/** Response to DeviceAttestationRequest */
export interface DeviceAttestationResponse {
  /** status */
  code: CommonStatusCode;
  /** server random */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** encrypted app key */
  encryptedAppKey: Uint8Array;
  /** secret key to protect app configuration */
  encryptedSecretKey: Uint8Array;
  /** iv to protect app configuration */
  encryptedInitialVector: Uint8Array;
}

/** Request of the device registration (for attestation exceptions) */
export interface RegisterDeviceRequest {
  /** app version string */
  appVersion: string;
  /** client key material */
  clientKeyMaterial: Uint8Array;
  /** verification message using app-key */
  verificationMessage: Uint8Array;
  /** registration message */
  registrationMessage: RegisterRequestMaterial | undefined;
  /** signature of registration message */
  registrationSignature: Uint8Array;
  /** signature of migration message */
  migrationSignature?: Uint8Array | undefined;
}

/** Response to RegisterDeviceRequest */
export interface RegisterDeviceResponse {
  /** status */
  code: CommonStatusCode;
  /** server random */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** secret key to protect app configuration */
  encryptedSecretKey: Uint8Array;
  /** iv to protect app configuration */
  encryptedInitialVector: Uint8Array;
}

/**
 * Get last RequestId for asynchronous requests
 * RequestId is a sequential 1-based number generated by the DID owner (client).
 * It targets to assure a safe transaction from a network delay, failure or logical faults.
 *
 * The number should be maintained by client unless the application is cleaned up from a device.
 * The client could obtain the last RequestId for the DID when it is lost.
 * Thus, the client should use "last request id + 1" for the next request.
 *
 * X-Auth-Token is required.
 */
export interface GetLastRequestIdRequest {}

/** Response to GetLastRequestIdRequest */
export interface GetLastRequestIdResponse {
  /** request id used last time */
  lastRequestId: number;
}

/**
 * Client acknowledge the response is received.
 * The server would clear the request information from the database.
 *
 * X-Auth-Token is required.
 */
export interface AckRequestIdRequest {
  requestId: number;
}

/** Response to AckRequestIdRequest */
export interface AckRequestIdResponse {
  status: AsyncRequestStatus;
}

/**
 * Get nonce before requesting the access token
 * The client need to register the DID first.
 */
export interface GetAuthNonceRequest {
  besuAddress: string;
}

/** Response to GetAuthNonceRequest */
export interface GetAuthNonceResponse {
  nonce: string;
}

/**
 * Get an access token from the server.
 * Nonce is requred before asking an access token.
 */
export interface GetAuthAccessTokenRequest {
  appVersion: string;
  did: string;
  besuAddress: string;
  mid?: string | undefined;
  nonce: string;
  /** Hex string */
  verifyCode: string;
  /** Hex string */
  signature: string;
}

/** Response to GetAuthAccessTokenRequest */
export interface GetAuthAccessTokenResponse {
  command: string;
  accessToken: string;
  midCount: number;
  statusCode: CommonStatusCode;
}

/** Get an access token based on the wallet. */
export interface GetAuthWalletBasedAccessTokenRequest {
  besuAddress: string;
  nonce: string;
  signature: string;
}

/** Response to GetAuthWalletBasedAccessTokenRequest */
export interface GetAuthWalletBasedAccessTokenResponse {
  accessToken: string;
}

/**
 * Get an access token to connect On-Premise server (PI)
 *
 * X-Auth-Token is required.
 */
export interface GetPiAccessTokenRequest {
  pdsId: string;
}

/** Response to GetPiAccessTokenRequest */
export interface GetPiAccessTokenResponse {
  piAccessToken: string;
}

/**
 * Get the ticket ID to consume
 * No request argument needed since X-Auth-Token already has the pdsId within.
 *
 * X-Auth-Token is required.
 */
export interface GetTicketIdToConsumeRequest {}

/** Response to GetTicketIdToConsumeRequest */
export interface GetTicketIdToConsumeResponse {
  ticketId: string;
}

/** List proposal and ticket information */
export interface ListProposalRequest {
  /** empty to fetch all proposals */
  updatedAfter?: string | undefined;
  /** fetch static */
  fetchStatic: boolean;
  /** fetch active global */
  fetchActiveGlobal: boolean;
  /** fetch active client */
  fetchActiveClient: boolean;
  /** fetch specific purpose only; fetch all when empty */
  purposes: number[];
  /** fetch specific proposals; fetch all when empty */
  proposalIds: string[];
  /**
   * fetch finished proposals only
   * "Finished" means the proposal is totally completed and refunded so no need to be shown to user
   * it will fetch only the finished proposals, please set it as false if it is not necessary.
   */
  finishedOnly: boolean;
}

/** Response to ListProposalRequest */
export interface ListProposalResponse {
  /** requested proposal information */
  proposals: Proposal[];
}

/** Issue ticket request material */
export interface IssueTicketRequestMaterial {
  materialPrefix: string;
  did: string;
  /** Proposal ID to issue a ticket */
  proposalId: string;
  /** Requested created at */
  createdAt: string;
}

/**
 * Issue a ticket for a proposal to be used for the DID.
 *
 * X-Auth-Token is required.
 */
export interface IssueTicketRequest {
  /** message to prove issue ticket message */
  issueTicketRequestMaterial: IssueTicketRequestMaterial | undefined;
  /** signature to verify issue request */
  signature: Uint8Array;
}

/** Response to IssueTicketRequest */
export interface IssueTicketResponse {
  /** Proposal ID */
  proposalId: string;
  /** Issue status */
  code?: CommonStatusCode | undefined;
  /** status when accepted or done */
  proposalActiveClient?: ProposalActiveClient | undefined;
}

/**
 * Upload PI request from client to On-Premise server.
 * This is a synchronous call.
 * The server would continue consuming unless the connection is lost BEFORE data upload.
 * It doesn't check the payload for the second REST API call and simply return the acceptance status.
 *
 * X-Auth-Token is required.
 */
export interface UploadPiRequest {
  /** Onetime data ID identifies each data upload request */
  pdsId: string;
  /** Proposal ID for the data upload request */
  proposalId: string;
  /** GZipped json-data payload */
  gzippedPayload: Uint8Array;
}

/** Response to UploadPiRequest */
export interface UploadPiResponse {
  /** Onetime data ID uploaded */
  pdsId: string;
  /** Issue status */
  code: CommonStatusCode;
}

/** Preparing ConsumeTicketRequest from on-premise side */
export interface PreConsumePayload {
  /** timestamp when the payload is generated and signed */
  timestamp: number;
  /** pdsId */
  pdsId: string;
}

/**
 * Preparing ConsumeTicketRequest from on-premise side
 * No X-Auth-Token required. Authentication is done by signature.
 */
export interface PreConsumeRequest {
  /** pdsId and timestamp */
  preConsumePayload: PreConsumePayload | undefined;
  /** address of the on-premise wallet */
  besuAddress: string;
  /** signature of preConsumePayload generated by the on-premise wallet */
  signature: Uint8Array;
}

/** Pds information and blockchain nonce to be used for the consuming. */
export interface PreConsumeResponse {
  blockchainNonce: number;
  nextRequestId: number;
  contractAddress: string;
  pdsId: string;
  ticketId: string;
  purpose: number;
}

/**
 * Consume ticket request from On-Premise server to Middleware.
 * This is a syncrhonous call since On-Premise and Middleware are always online.
 * No X-Auth-Token required. Authentication is done by signature.
 */
export interface ConsumeTicketRequest {
  /**
   * RequestId should be managed by client and unique for the requests happening simultaneously.
   * Use GetLastRequestIdRequest if the client doesn't have the previous number.
   */
  requestId: number;
  /** onetime id */
  pdsId: string;
  /** blockchain raw transaction */
  rawTransaction: Uint8Array;
  /** besu address */
  besuAddress: string;
}

/** Response to ConsumeTicketRequest */
export interface ConsumeTicketResponse {
  /** async-request status */
  status: AsyncRequestStatus;
  /** Issue status */
  code?: CommonStatusCode | undefined;
  /**
   * Parameter to recreate RegisterDidRequest when the blockchain-nonce is too far or too low
   * Please recreate RegisterDidRequest when retryWithNewBlockChainNonce is true with NEW-REQUEST-ID
   */
  retryWithNewBlockChainNonce: boolean;
  /**
   * New blockchain nonce to recreate RegisterDidRequest
   * Ignore when retryWithNewBlockChainNonce is false
   */
  newBlockChainNonce: number;
  /** Duplicated request id when STATUS_ASYNC_REQUEST_DUPLICATED is returned */
  duplicatedRequestId?: number | undefined;
}

/** Data upload receipt payload */
export interface DataReceiptPayload {
  /** timestamp when the data is received */
  timestamp: number;
  /** pdsId */
  pdsId: string;
}

/** Request to inform the data upload receipt from the data-server */
export interface DataReceiptNotification {
  /** data receipt payload */
  payload: DataReceiptPayload | undefined;
  /** address of the on-premise wallet */
  besuAddress: string;
  /** signature of DataReceiptPayload generated by the on-premise wallet */
  signature: Uint8Array;
}

/** Response to DataReceiptNotification */
export interface DataReceiptNotificationResponse {
  /** data upload receipt status */
  code: CommonStatusCode;
  message?: string | undefined;
}

/**
 * Request to list the data receipt up.
 * Middleware sends this request to On-Premise server
 */
export interface ListDataReceiptRequest {
  pdsIds: string[];
}

/** Response to ListDataReceiptRequest */
export interface ListDataReceiptResponse {
  /** data upload receipts */
  notifications: DataReceiptNotification[];
}

/** List reward history of the user */
export interface ListRewardHistoryRequest {
  /** did of the user */
  did: string;
  /** RFC-3339 start date (lower date) */
  startAt: string;
  /** RFC-3339 end date (greater date) */
  endAt: string;
  /** requested history type */
  requestType: ListRewardHistoryRequestType;
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
}

/** Response to the ListRewardHistoryRequest */
export interface ListRewardHistoryResponse {
  /** did of the user */
  did: string;
  /** total count of the content */
  totalCount: number;
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
  /** balance of the did */
  balance: number;
  /** amount expires within 1month */
  amountExpiresWithin1m: number;
  /** list of content */
  contents: RewardTransactionContentForApp[];
}

/** Coupon id request */
export interface GetCouponIdRequest {
  /** did of the user */
  did: string;
}

/** Response to GetCouponIdRequest */
export interface GetCouponIdResponse {
  /** did of the user */
  did: string;
  /** coupon transaction id for external shop */
  couponTransactionId: string;
  /** blockchain request id */
  blockchainRequestId: Uint8Array;
  /** blockchain reward transfer nonce */
  blockchainRewardTransferNonce: number;
  /** blockchain reward receiver address */
  blockchainRewardReceiverAddress: string;
  /** blockchain reward contract address */
  blockchainRewardContractAddress: string;
  /** status code */
  code: CommonStatusCode;
}

/** Issue coupon request */
export interface IssueCouponRequest {
  /** did of the user */
  did: string;
  /** purchase request material = abi.encode("world.myd.purchase-coupon", blockchainRewardContractAddress, besuAddress, blockchainRewardReceiverAddress, amount, blockchainRewardTransferNonce, blockchainRequestId) */
  purchaseRequestMaterial: Uint8Array;
  /** signature for the blockchain includes r, s, v */
  purchaseRequestMaterialSignature: Uint8Array;
  /** goods code to purchase */
  goodsCode: string;
  /** client random */
  clientKeyMaterial: Uint8Array;
}

/** Response to IssueCouponRequest */
export interface IssueCouponResponse {
  /** did of the user */
  did: string;
  /** coupon transaction id for external shop */
  couponTransactionId: string;
  /** status code */
  code: CommonStatusCode;
  /** server random */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** encrypted coupon data */
  encryptedCouponData: Uint8Array;
}

/** List coupon request */
export interface ListCouponRequest {
  /** did of the user */
  did: string;
  /** list of coupon transaction id; 0 element to get all coupons */
  couponTransactionIds: string[];
}

export interface CouponInfoDetails {
  /** goods code */
  goodsCode: string;
  /** pin status code */
  pinStatusCode: string;
  /** goods name */
  goodsName: string;
  /** selling price */
  sellPriceAmount: string;
  /** tel no of sender */
  senderTelNo: string;
  /** normal sales price */
  cnsmPriceAmount: string;
  /** transaction no */
  sendRstCode: string;
  /** pin status code name */
  pinStatusName: string;
  /** brand thumbnail img */
  mmsBrandThumImg: string;
  /** brand name */
  brandName: string;
  /** send status msg */
  sendRstMsg: string;
  /** change date */
  correcDtm: string;
  /** receiver tel no */
  recverTelNo: string;
  /** expiration date */
  expirationAt: string;
  /** basic code */
  sendBasicCode: string;
  /** send status code */
  sendStatusCode: string;
}

export interface ListCouponDetailsResponse {
  /** status code */
  code: CommonStatusCode;
  details: CouponInfoDetails[];
}

export interface ListCouponDetailsRequest {
  /** did of the user */
  did: string;
  /** coupon transaction id */
  couponTransactionIds: string[];
}

/** Coupon info from ListCouponRequest */
export interface CouponInfo {
  /** did of the user */
  did: string;
  /** encrypted pin code */
  encryptedPinCode: string;
  /** encrypted image url */
  encryptedImageUrl: string;
  /** encrypted raw response from the shop */
  encryptedInfo: string;
  /** coupon transaction id */
  couponTransactionId: string;
  /** coupon order number */
  orderNo: string;
  /** price of the coupon */
  price: number;
  /** published timestamp in RFC-3339 */
  publishedAt: string;
  /** disposed timestamp in RFC-3339 */
  disposedAt: string;
  /** status of the coupon */
  status: CouponStatus;
  /** coupon cancel requested by API */
  cancelRequested: boolean;
  /** client key material */
  clientKeyMaterial: Uint8Array;
  /** server key material */
  serverKeyMaterial: Uint8Array;
  /** ephemeral public key for pre-master secret */
  ephemeralPublicKey: Uint8Array;
  /** goods code of coupon */
  goodsCode: string;
}

/** Response to ListCouponRequest */
export interface ListCouponResponse {
  /** status code */
  code: CommonStatusCode;
  /** coupon info */
  coupons: CouponInfo[];
}

/** Self-managed wallet address */
export interface SelfManagedAddress {
  address: string;
  role: BlockchainRole;
  createdAt: string;
  team: string;
  username: string;
}

/** Response to get self-managed-addresses */
export interface GetAllSelfManagedAddressesResponse {
  selfManagedAddresses: SelfManagedAddress[];
}

/**
 * Create or update self-managed wallet address.
 * The key is address.
 */
export interface CreateOrUpdateSelfManagedWalletRequest {
  wallet: SelfManagedAddress | undefined;
}

/** Response to CreateOrUpdateSelfManagedWalletRequest */
export interface CreateOrUpdateSelfManagedWalletResponse {
  /** status code */
  code: CommonStatusCode;
}

/** Create a wallet key pair for a role for which no wallet key pair has been created. */
export interface CreateWalletKeyPairRequest {
  did: string;
}

/** Response of key pair creation. */
export interface CreateWalletKeyPairResponse {
  isCreated: boolean;
  address: string;
}

/** get list of companies */
export interface GetCompaniesResponse {
  companies: Companies[];
}

export interface Companies {
  did: string;
  name: string;
  address: string;
}

/**
 * Managed wallet address.
 * The key is address.
 */
export interface ManagedAddress {
  address: string;
  role: BlockchainRole;
  publicKey: string;
  hostname: string;
  createdAt: string;
}

/** Response to get managed-addresses */
export interface GetAllManagedAddressesResponse {
  managedAddresses: ManagedAddress[];
}

/**
 * Create or update managed wallet address.
 * The key is address.
 */
export interface CreateOrUpdateManagedWalletRequest {
  wallet: ManagedAddress | undefined;
}

/** Response to CreateOrUpdateManagedWalletRequest */
export interface CreateOrUpdateManagedWalletResponse {
  /** status code */
  code: CommonStatusCode;
}

/** Declare transaction content to be committed */
export interface DeclareRewardTransactionContentRequest {
  content: RewardTransactionContent | undefined;
  blockchainRequestId: Uint8Array;
}

/** Response to DeclareRewardTransactionContentRequest */
export interface DeclareRewardTransactionContentResponse {
  /** status code */
  code: CommonStatusCode;
}

/**
 * Create proposal from the admin console (wallet based)
 * This proposal doesn't have company entity.
 */
export interface CreateSpecialProposalRequest {
  proposal: ProposalStatic | undefined;
}

/** Response to CreateSpecialProposalRequest */
export interface CreateSpecialProposalResponse {
  /** status code */
  code: CommonStatusCode;
  /** proposal id */
  proposalId: string;
}

export interface InviteFriendHostRequest {
  did: string;
  mid: string;
}

export interface InviteFriendGuestRequest {
  did: string;
  mid: string;
  invitationCode: string;
  proposalId: string;
}

export interface InviteFriendHostResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  invitationCode?: string | undefined;
  usedCount?: number | undefined;
}

export interface InviteFriendGuestResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  date?: string | undefined;
  dueTo?: string | undefined;
  tokenId?: string | undefined;
  amount?: number | undefined;
  invitationCode?: string | undefined;
}

export interface AttendanceRollCallRequest {
  did: string;
  mid: string;
  proposalId: string;
}

export interface AttendanceSubmitRequest {
  did: string;
  mid: string;
  proposalId: string;
}

export interface AttendanceRollCallResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  dayofweeks: boolean[];
  complete?: boolean | undefined;
}

export interface AttendanceSubmitResponse {
  statusCode: CommonStatusCode;
  message?: string | undefined;
  reward?: number | undefined;
}

export interface LikeGoodsResponse {
  code: CommonStatusCode;
}

export interface LikeGoodsRequest {
  did: string;
  goodsCodes: string[];
}

export interface ListGoodsLikeResponse {
  code: CommonStatusCode;
  goodsCodes: string[];
}

export interface ListGoodsLikeRequest {
  did: string;
}

/** Registering did filtering request */
export interface RegisterDidFilteringRequest {
  /** proposal ID to apply filtering */
  proposalId: string;
  /** allowlist or blocklist */
  mode: DidFilteringMode;
  /** add specific dids to the filtering */
  didFilter: string[];
  /** add dids whose owner issued any ticket to specific proposals */
  proposalFilter: string[];
}

/** Response to RegisterDidFilteringRequest */
export interface RegisterDidFilteringResponse {
  statusCode: CommonStatusCode;
  filteringId: number;
}

/** User info for bulk mint */
export interface SimpleUserInfo {
  /** did of the user */
  did: string;
  /** user type if it is myd3 user or not */
  userType: SimpleUserType;
  /** null for USER_MYD2 or USER_NOT_FOUND */
  besuAddress?: string | undefined;
}

/** List address of users for the given dids */
export interface LookupUsersRequest {
  dids: string[];
}

/** Response to LookupUsersRequest */
export interface LookupUsersResponse {
  users: SimpleUserInfo[];
}

/** Bulk mint (transfer) to the specific users */
export interface BulkMintRequest {
  reqs: DeclareRewardTransactionContentRequest[];
}

/** Response to BulkMintRequest */
export interface BulkMintResponse {
  resps: DeclareRewardTransactionContentResponse[];
}

export interface HomeBannerCard {
  /** mandatory */
  type: HomeBannerCardType;
  enabled: boolean;
  supportedPlatforms: HomeBannerSupportedPlatform;
  title: string;
  /** SIMPLE_BANNER_LANDING */
  bannerImageUrl?: string | undefined;
  landingPageUrl?: string | undefined;
  notBefore?: string | undefined;
  notAfter?: string | undefined;
  /** need to pass access token via url (only myd.world url) */
  requiresAccessTokenInGetMethod?: boolean | undefined;
  /** SINGLE_PROPOSAL */
  proposalId?: string | undefined;
  /** CATEGORY_PROPOSAL_PURPOSE */
  purpose?: number | undefined;
}

export interface HomeBannerConfig {
  cards: HomeBannerCard[];
}

/** Save or update home banner config */
export interface HomeBannerSaveRequest {
  /** null for new banner */
  bannerNo?: number | undefined;
  /** content to save */
  config: HomeBannerConfig | undefined;
  /** read only */
  setReadOnly: boolean;
  /** set hidden */
  setHidden: boolean;
}

/** Response to HomeBannerSaveRequest */
export interface HomeBannerSaveResponse {
  code: CommonStatusCode;
  bannerNo: number;
}

/** Saved home banner config */
export interface SavedHomeBannerConfig {
  bannerNo: number;
  isReadOnly: boolean;
  isHidden: boolean;
  createdBy: string;
  config?: HomeBannerConfig | undefined;
}

/** Home banner list request */
export interface HomeBannerListRequest {
  /** page size */
  pageSize: number;
  /** page number */
  pageNum: number;
}

/** Response to HomeBannerListRequest */
export interface HomeBannerListResponse {
  configNumbers: SavedHomeBannerConfig[];
}

/**
 * Home banner publish request
 * Published banner will become read-only
 */
export interface HomeBannerPublishRequest {
  bannerNo: number;
}

/** Response to HomeBannerPublishRequest */
export interface HomeBannerPublishResponse {
  code: CommonStatusCode;
}

/** Response Burn Point And DID List */
export interface BurnPointResponse {
  burnInfo: { [key: string]: number };
}

export interface BurnPointResponse_BurnInfoEntry {
  key: string;
  value: number;
}

export interface RegisterProposalRequest {
  proposalRequestId: number;
  proposalStatic: ProposalStatic | undefined;
}

export interface RegisterProposalResponse {
  statusCode: CommonStatusCode;
  /** only for STATUS_SUCCESS */
  proposalId: string;
}

function createBaseRegisterRequestMaterial(): RegisterRequestMaterial {
  return {
    materialPrefix: "",
    did: "",
    besuAddress: "",
    nonce: "",
    publicKey: new Uint8Array(0),
    verkey: undefined,
    document: undefined,
  };
}

export const RegisterRequestMaterial = {
  encode(message: RegisterRequestMaterial, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.materialPrefix !== "") {
      writer.uint32(10).string(message.materialPrefix);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.besuAddress !== "") {
      writer.uint32(26).string(message.besuAddress);
    }
    if (message.nonce !== "") {
      writer.uint32(34).string(message.nonce);
    }
    if (message.publicKey.length !== 0) {
      writer.uint32(42).bytes(message.publicKey);
    }
    if (message.verkey !== undefined) {
      writer.uint32(50).bytes(message.verkey);
    }
    if (message.document !== undefined) {
      writer.uint32(58).string(message.document);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterRequestMaterial {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterRequestMaterial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.materialPrefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.publicKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.verkey = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.document = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterRequestMaterial {
    return {
      materialPrefix: isSet(object.materialPrefix) ? String(object.materialPrefix) : "",
      did: isSet(object.did) ? String(object.did) : "",
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "",
      nonce: isSet(object.nonce) ? String(object.nonce) : "",
      publicKey: isSet(object.publicKey) ? bytesFromBase64(object.publicKey) : new Uint8Array(0),
      verkey: isSet(object.verkey) ? bytesFromBase64(object.verkey) : undefined,
      document: isSet(object.document) ? String(object.document) : undefined,
    };
  },

  toJSON(message: RegisterRequestMaterial): unknown {
    const obj: any = {};
    if (message.materialPrefix !== "") {
      obj.materialPrefix = message.materialPrefix;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.publicKey.length !== 0) {
      obj.publicKey = base64FromBytes(message.publicKey);
    }
    if (message.verkey !== undefined) {
      obj.verkey = base64FromBytes(message.verkey);
    }
    if (message.document !== undefined) {
      obj.document = message.document;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterRequestMaterial>, I>>(base?: I): RegisterRequestMaterial {
    return RegisterRequestMaterial.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterRequestMaterial>, I>>(object: I): RegisterRequestMaterial {
    const message = createBaseRegisterRequestMaterial();
    message.materialPrefix = object.materialPrefix ?? "";
    message.did = object.did ?? "";
    message.besuAddress = object.besuAddress ?? "";
    message.nonce = object.nonce ?? "";
    message.publicKey = object.publicKey ?? new Uint8Array(0);
    message.verkey = object.verkey ?? undefined;
    message.document = object.document ?? undefined;
    return message;
  },
};

function createBaseDeviceAttestationRequest(): DeviceAttestationRequest {
  return {
    appVersion: "",
    parcel: "",
    clientKeyMaterial: new Uint8Array(0),
    registrationMessage: undefined,
    registrationSignature: new Uint8Array(0),
    migrationSignature: undefined,
  };
}

export const DeviceAttestationRequest = {
  encode(message: DeviceAttestationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersion !== "") {
      writer.uint32(10).string(message.appVersion);
    }
    if (message.parcel !== "") {
      writer.uint32(18).string(message.parcel);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(26).bytes(message.clientKeyMaterial);
    }
    if (message.registrationMessage !== undefined) {
      RegisterRequestMaterial.encode(message.registrationMessage, writer.uint32(34).fork()).ldelim();
    }
    if (message.registrationSignature.length !== 0) {
      writer.uint32(42).bytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      writer.uint32(50).bytes(message.migrationSignature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceAttestationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceAttestationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parcel = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.registrationMessage = RegisterRequestMaterial.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.registrationSignature = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.migrationSignature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceAttestationRequest {
    return {
      appVersion: isSet(object.appVersion) ? String(object.appVersion) : "",
      parcel: isSet(object.parcel) ? String(object.parcel) : "",
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
      registrationMessage: isSet(object.registrationMessage)
        ? RegisterRequestMaterial.fromJSON(object.registrationMessage)
        : undefined,
      registrationSignature: isSet(object.registrationSignature)
        ? bytesFromBase64(object.registrationSignature)
        : new Uint8Array(0),
      migrationSignature: isSet(object.migrationSignature) ? bytesFromBase64(object.migrationSignature) : undefined,
    };
  },

  toJSON(message: DeviceAttestationRequest): unknown {
    const obj: any = {};
    if (message.appVersion !== "") {
      obj.appVersion = message.appVersion;
    }
    if (message.parcel !== "") {
      obj.parcel = message.parcel;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    if (message.registrationMessage !== undefined) {
      obj.registrationMessage = RegisterRequestMaterial.toJSON(message.registrationMessage);
    }
    if (message.registrationSignature.length !== 0) {
      obj.registrationSignature = base64FromBytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      obj.migrationSignature = base64FromBytes(message.migrationSignature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceAttestationRequest>, I>>(base?: I): DeviceAttestationRequest {
    return DeviceAttestationRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceAttestationRequest>, I>>(object: I): DeviceAttestationRequest {
    const message = createBaseDeviceAttestationRequest();
    message.appVersion = object.appVersion ?? "";
    message.parcel = object.parcel ?? "";
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    message.registrationMessage =
      object.registrationMessage !== undefined && object.registrationMessage !== null
        ? RegisterRequestMaterial.fromPartial(object.registrationMessage)
        : undefined;
    message.registrationSignature = object.registrationSignature ?? new Uint8Array(0);
    message.migrationSignature = object.migrationSignature ?? undefined;
    return message;
  },
};

function createBaseDeviceAttestationResponse(): DeviceAttestationResponse {
  return {
    code: 0,
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    encryptedAppKey: new Uint8Array(0),
    encryptedSecretKey: new Uint8Array(0),
    encryptedInitialVector: new Uint8Array(0),
  };
}

export const DeviceAttestationResponse = {
  encode(message: DeviceAttestationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(18).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(26).bytes(message.ephemeralPublicKey);
    }
    if (message.encryptedAppKey.length !== 0) {
      writer.uint32(34).bytes(message.encryptedAppKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      writer.uint32(42).bytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      writer.uint32(50).bytes(message.encryptedInitialVector);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceAttestationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceAttestationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encryptedAppKey = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.encryptedSecretKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.encryptedInitialVector = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceAttestationResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      encryptedAppKey: isSet(object.encryptedAppKey) ? bytesFromBase64(object.encryptedAppKey) : new Uint8Array(0),
      encryptedSecretKey: isSet(object.encryptedSecretKey)
        ? bytesFromBase64(object.encryptedSecretKey)
        : new Uint8Array(0),
      encryptedInitialVector: isSet(object.encryptedInitialVector)
        ? bytesFromBase64(object.encryptedInitialVector)
        : new Uint8Array(0),
    };
  },

  toJSON(message: DeviceAttestationResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.encryptedAppKey.length !== 0) {
      obj.encryptedAppKey = base64FromBytes(message.encryptedAppKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      obj.encryptedSecretKey = base64FromBytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      obj.encryptedInitialVector = base64FromBytes(message.encryptedInitialVector);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceAttestationResponse>, I>>(base?: I): DeviceAttestationResponse {
    return DeviceAttestationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceAttestationResponse>, I>>(object: I): DeviceAttestationResponse {
    const message = createBaseDeviceAttestationResponse();
    message.code = object.code ?? 0;
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.encryptedAppKey = object.encryptedAppKey ?? new Uint8Array(0);
    message.encryptedSecretKey = object.encryptedSecretKey ?? new Uint8Array(0);
    message.encryptedInitialVector = object.encryptedInitialVector ?? new Uint8Array(0);
    return message;
  },
};

function createBaseRegisterDeviceRequest(): RegisterDeviceRequest {
  return {
    appVersion: "",
    clientKeyMaterial: new Uint8Array(0),
    verificationMessage: new Uint8Array(0),
    registrationMessage: undefined,
    registrationSignature: new Uint8Array(0),
    migrationSignature: undefined,
  };
}

export const RegisterDeviceRequest = {
  encode(message: RegisterDeviceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersion !== "") {
      writer.uint32(10).string(message.appVersion);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(18).bytes(message.clientKeyMaterial);
    }
    if (message.verificationMessage.length !== 0) {
      writer.uint32(26).bytes(message.verificationMessage);
    }
    if (message.registrationMessage !== undefined) {
      RegisterRequestMaterial.encode(message.registrationMessage, writer.uint32(34).fork()).ldelim();
    }
    if (message.registrationSignature.length !== 0) {
      writer.uint32(42).bytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      writer.uint32(50).bytes(message.migrationSignature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDeviceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDeviceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.verificationMessage = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.registrationMessage = RegisterRequestMaterial.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.registrationSignature = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.migrationSignature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDeviceRequest {
    return {
      appVersion: isSet(object.appVersion) ? String(object.appVersion) : "",
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
      verificationMessage: isSet(object.verificationMessage)
        ? bytesFromBase64(object.verificationMessage)
        : new Uint8Array(0),
      registrationMessage: isSet(object.registrationMessage)
        ? RegisterRequestMaterial.fromJSON(object.registrationMessage)
        : undefined,
      registrationSignature: isSet(object.registrationSignature)
        ? bytesFromBase64(object.registrationSignature)
        : new Uint8Array(0),
      migrationSignature: isSet(object.migrationSignature) ? bytesFromBase64(object.migrationSignature) : undefined,
    };
  },

  toJSON(message: RegisterDeviceRequest): unknown {
    const obj: any = {};
    if (message.appVersion !== "") {
      obj.appVersion = message.appVersion;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    if (message.verificationMessage.length !== 0) {
      obj.verificationMessage = base64FromBytes(message.verificationMessage);
    }
    if (message.registrationMessage !== undefined) {
      obj.registrationMessage = RegisterRequestMaterial.toJSON(message.registrationMessage);
    }
    if (message.registrationSignature.length !== 0) {
      obj.registrationSignature = base64FromBytes(message.registrationSignature);
    }
    if (message.migrationSignature !== undefined) {
      obj.migrationSignature = base64FromBytes(message.migrationSignature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDeviceRequest>, I>>(base?: I): RegisterDeviceRequest {
    return RegisterDeviceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDeviceRequest>, I>>(object: I): RegisterDeviceRequest {
    const message = createBaseRegisterDeviceRequest();
    message.appVersion = object.appVersion ?? "";
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    message.verificationMessage = object.verificationMessage ?? new Uint8Array(0);
    message.registrationMessage =
      object.registrationMessage !== undefined && object.registrationMessage !== null
        ? RegisterRequestMaterial.fromPartial(object.registrationMessage)
        : undefined;
    message.registrationSignature = object.registrationSignature ?? new Uint8Array(0);
    message.migrationSignature = object.migrationSignature ?? undefined;
    return message;
  },
};

function createBaseRegisterDeviceResponse(): RegisterDeviceResponse {
  return {
    code: 0,
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    encryptedSecretKey: new Uint8Array(0),
    encryptedInitialVector: new Uint8Array(0),
  };
}

export const RegisterDeviceResponse = {
  encode(message: RegisterDeviceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(18).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(26).bytes(message.ephemeralPublicKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      writer.uint32(34).bytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      writer.uint32(42).bytes(message.encryptedInitialVector);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDeviceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDeviceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encryptedSecretKey = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.encryptedInitialVector = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDeviceResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      encryptedSecretKey: isSet(object.encryptedSecretKey)
        ? bytesFromBase64(object.encryptedSecretKey)
        : new Uint8Array(0),
      encryptedInitialVector: isSet(object.encryptedInitialVector)
        ? bytesFromBase64(object.encryptedInitialVector)
        : new Uint8Array(0),
    };
  },

  toJSON(message: RegisterDeviceResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.encryptedSecretKey.length !== 0) {
      obj.encryptedSecretKey = base64FromBytes(message.encryptedSecretKey);
    }
    if (message.encryptedInitialVector.length !== 0) {
      obj.encryptedInitialVector = base64FromBytes(message.encryptedInitialVector);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDeviceResponse>, I>>(base?: I): RegisterDeviceResponse {
    return RegisterDeviceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDeviceResponse>, I>>(object: I): RegisterDeviceResponse {
    const message = createBaseRegisterDeviceResponse();
    message.code = object.code ?? 0;
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.encryptedSecretKey = object.encryptedSecretKey ?? new Uint8Array(0);
    message.encryptedInitialVector = object.encryptedInitialVector ?? new Uint8Array(0);
    return message;
  },
};

function createBaseGetLastRequestIdRequest(): GetLastRequestIdRequest {
  return {};
}

export const GetLastRequestIdRequest = {
  encode(_: GetLastRequestIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLastRequestIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLastRequestIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetLastRequestIdRequest {
    return {};
  },

  toJSON(_: GetLastRequestIdRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLastRequestIdRequest>, I>>(base?: I): GetLastRequestIdRequest {
    return GetLastRequestIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLastRequestIdRequest>, I>>(_: I): GetLastRequestIdRequest {
    const message = createBaseGetLastRequestIdRequest();
    return message;
  },
};

function createBaseGetLastRequestIdResponse(): GetLastRequestIdResponse {
  return { lastRequestId: 0 };
}

export const GetLastRequestIdResponse = {
  encode(message: GetLastRequestIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lastRequestId !== 0) {
      writer.uint32(8).uint32(message.lastRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLastRequestIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLastRequestIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.lastRequestId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLastRequestIdResponse {
    return { lastRequestId: isSet(object.lastRequestId) ? Number(object.lastRequestId) : 0 };
  },

  toJSON(message: GetLastRequestIdResponse): unknown {
    const obj: any = {};
    if (message.lastRequestId !== 0) {
      obj.lastRequestId = Math.round(message.lastRequestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLastRequestIdResponse>, I>>(base?: I): GetLastRequestIdResponse {
    return GetLastRequestIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLastRequestIdResponse>, I>>(object: I): GetLastRequestIdResponse {
    const message = createBaseGetLastRequestIdResponse();
    message.lastRequestId = object.lastRequestId ?? 0;
    return message;
  },
};

function createBaseAckRequestIdRequest(): AckRequestIdRequest {
  return { requestId: 0 };
}

export const AckRequestIdRequest = {
  encode(message: AckRequestIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AckRequestIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAckRequestIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AckRequestIdRequest {
    return { requestId: isSet(object.requestId) ? Number(object.requestId) : 0 };
  },

  toJSON(message: AckRequestIdRequest): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AckRequestIdRequest>, I>>(base?: I): AckRequestIdRequest {
    return AckRequestIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AckRequestIdRequest>, I>>(object: I): AckRequestIdRequest {
    const message = createBaseAckRequestIdRequest();
    message.requestId = object.requestId ?? 0;
    return message;
  },
};

function createBaseAckRequestIdResponse(): AckRequestIdResponse {
  return { status: 0 };
}

export const AckRequestIdResponse = {
  encode(message: AckRequestIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AckRequestIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAckRequestIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AckRequestIdResponse {
    return { status: isSet(object.status) ? asyncRequestStatusFromJSON(object.status) : 0 };
  },

  toJSON(message: AckRequestIdResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = asyncRequestStatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AckRequestIdResponse>, I>>(base?: I): AckRequestIdResponse {
    return AckRequestIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AckRequestIdResponse>, I>>(object: I): AckRequestIdResponse {
    const message = createBaseAckRequestIdResponse();
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseGetAuthNonceRequest(): GetAuthNonceRequest {
  return { besuAddress: "" };
}

export const GetAuthNonceRequest = {
  encode(message: GetAuthNonceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.besuAddress !== "") {
      writer.uint32(10).string(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthNonceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthNonceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthNonceRequest {
    return { besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "" };
  },

  toJSON(message: GetAuthNonceRequest): unknown {
    const obj: any = {};
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthNonceRequest>, I>>(base?: I): GetAuthNonceRequest {
    return GetAuthNonceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthNonceRequest>, I>>(object: I): GetAuthNonceRequest {
    const message = createBaseGetAuthNonceRequest();
    message.besuAddress = object.besuAddress ?? "";
    return message;
  },
};

function createBaseGetAuthNonceResponse(): GetAuthNonceResponse {
  return { nonce: "" };
}

export const GetAuthNonceResponse = {
  encode(message: GetAuthNonceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nonce !== "") {
      writer.uint32(10).string(message.nonce);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthNonceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthNonceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nonce = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthNonceResponse {
    return { nonce: isSet(object.nonce) ? String(object.nonce) : "" };
  },

  toJSON(message: GetAuthNonceResponse): unknown {
    const obj: any = {};
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthNonceResponse>, I>>(base?: I): GetAuthNonceResponse {
    return GetAuthNonceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthNonceResponse>, I>>(object: I): GetAuthNonceResponse {
    const message = createBaseGetAuthNonceResponse();
    message.nonce = object.nonce ?? "";
    return message;
  },
};

function createBaseGetAuthAccessTokenRequest(): GetAuthAccessTokenRequest {
  return { appVersion: "", did: "", besuAddress: "", mid: undefined, nonce: "", verifyCode: "", signature: "" };
}

export const GetAuthAccessTokenRequest = {
  encode(message: GetAuthAccessTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.appVersion !== "") {
      writer.uint32(10).string(message.appVersion);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.besuAddress !== "") {
      writer.uint32(26).string(message.besuAddress);
    }
    if (message.mid !== undefined) {
      writer.uint32(34).string(message.mid);
    }
    if (message.nonce !== "") {
      writer.uint32(42).string(message.nonce);
    }
    if (message.verifyCode !== "") {
      writer.uint32(50).string(message.verifyCode);
    }
    if (message.signature !== "") {
      writer.uint32(58).string(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthAccessTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthAccessTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.appVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.verifyCode = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.signature = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthAccessTokenRequest {
    return {
      appVersion: isSet(object.appVersion) ? String(object.appVersion) : "",
      did: isSet(object.did) ? String(object.did) : "",
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "",
      mid: isSet(object.mid) ? String(object.mid) : undefined,
      nonce: isSet(object.nonce) ? String(object.nonce) : "",
      verifyCode: isSet(object.verifyCode) ? String(object.verifyCode) : "",
      signature: isSet(object.signature) ? String(object.signature) : "",
    };
  },

  toJSON(message: GetAuthAccessTokenRequest): unknown {
    const obj: any = {};
    if (message.appVersion !== "") {
      obj.appVersion = message.appVersion;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.mid !== undefined) {
      obj.mid = message.mid;
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.verifyCode !== "") {
      obj.verifyCode = message.verifyCode;
    }
    if (message.signature !== "") {
      obj.signature = message.signature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthAccessTokenRequest>, I>>(base?: I): GetAuthAccessTokenRequest {
    return GetAuthAccessTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthAccessTokenRequest>, I>>(object: I): GetAuthAccessTokenRequest {
    const message = createBaseGetAuthAccessTokenRequest();
    message.appVersion = object.appVersion ?? "";
    message.did = object.did ?? "";
    message.besuAddress = object.besuAddress ?? "";
    message.mid = object.mid ?? undefined;
    message.nonce = object.nonce ?? "";
    message.verifyCode = object.verifyCode ?? "";
    message.signature = object.signature ?? "";
    return message;
  },
};

function createBaseGetAuthAccessTokenResponse(): GetAuthAccessTokenResponse {
  return { command: "", accessToken: "", midCount: 0, statusCode: 0 };
}

export const GetAuthAccessTokenResponse = {
  encode(message: GetAuthAccessTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.command !== "") {
      writer.uint32(10).string(message.command);
    }
    if (message.accessToken !== "") {
      writer.uint32(18).string(message.accessToken);
    }
    if (message.midCount !== 0) {
      writer.uint32(24).uint64(message.midCount);
    }
    if (message.statusCode !== 0) {
      writer.uint32(32).int32(message.statusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthAccessTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthAccessTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.command = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accessToken = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.midCount = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthAccessTokenResponse {
    return {
      command: isSet(object.command) ? String(object.command) : "",
      accessToken: isSet(object.accessToken) ? String(object.accessToken) : "",
      midCount: isSet(object.midCount) ? Number(object.midCount) : 0,
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
    };
  },

  toJSON(message: GetAuthAccessTokenResponse): unknown {
    const obj: any = {};
    if (message.command !== "") {
      obj.command = message.command;
    }
    if (message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    if (message.midCount !== 0) {
      obj.midCount = Math.round(message.midCount);
    }
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthAccessTokenResponse>, I>>(base?: I): GetAuthAccessTokenResponse {
    return GetAuthAccessTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthAccessTokenResponse>, I>>(object: I): GetAuthAccessTokenResponse {
    const message = createBaseGetAuthAccessTokenResponse();
    message.command = object.command ?? "";
    message.accessToken = object.accessToken ?? "";
    message.midCount = object.midCount ?? 0;
    message.statusCode = object.statusCode ?? 0;
    return message;
  },
};

function createBaseGetAuthWalletBasedAccessTokenRequest(): GetAuthWalletBasedAccessTokenRequest {
  return { besuAddress: "", nonce: "", signature: "" };
}

export const GetAuthWalletBasedAccessTokenRequest = {
  encode(message: GetAuthWalletBasedAccessTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.besuAddress !== "") {
      writer.uint32(10).string(message.besuAddress);
    }
    if (message.nonce !== "") {
      writer.uint32(18).string(message.nonce);
    }
    if (message.signature !== "") {
      writer.uint32(26).string(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthWalletBasedAccessTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthWalletBasedAccessTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nonce = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthWalletBasedAccessTokenRequest {
    return {
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "",
      nonce: isSet(object.nonce) ? String(object.nonce) : "",
      signature: isSet(object.signature) ? String(object.signature) : "",
    };
  },

  toJSON(message: GetAuthWalletBasedAccessTokenRequest): unknown {
    const obj: any = {};
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.nonce !== "") {
      obj.nonce = message.nonce;
    }
    if (message.signature !== "") {
      obj.signature = message.signature;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenRequest>, I>>(
    base?: I
  ): GetAuthWalletBasedAccessTokenRequest {
    return GetAuthWalletBasedAccessTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenRequest>, I>>(
    object: I
  ): GetAuthWalletBasedAccessTokenRequest {
    const message = createBaseGetAuthWalletBasedAccessTokenRequest();
    message.besuAddress = object.besuAddress ?? "";
    message.nonce = object.nonce ?? "";
    message.signature = object.signature ?? "";
    return message;
  },
};

function createBaseGetAuthWalletBasedAccessTokenResponse(): GetAuthWalletBasedAccessTokenResponse {
  return { accessToken: "" };
}

export const GetAuthWalletBasedAccessTokenResponse = {
  encode(message: GetAuthWalletBasedAccessTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accessToken !== "") {
      writer.uint32(18).string(message.accessToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAuthWalletBasedAccessTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAuthWalletBasedAccessTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accessToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAuthWalletBasedAccessTokenResponse {
    return { accessToken: isSet(object.accessToken) ? String(object.accessToken) : "" };
  },

  toJSON(message: GetAuthWalletBasedAccessTokenResponse): unknown {
    const obj: any = {};
    if (message.accessToken !== "") {
      obj.accessToken = message.accessToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenResponse>, I>>(
    base?: I
  ): GetAuthWalletBasedAccessTokenResponse {
    return GetAuthWalletBasedAccessTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAuthWalletBasedAccessTokenResponse>, I>>(
    object: I
  ): GetAuthWalletBasedAccessTokenResponse {
    const message = createBaseGetAuthWalletBasedAccessTokenResponse();
    message.accessToken = object.accessToken ?? "";
    return message;
  },
};

function createBaseGetPiAccessTokenRequest(): GetPiAccessTokenRequest {
  return { pdsId: "" };
}

export const GetPiAccessTokenRequest = {
  encode(message: GetPiAccessTokenRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPiAccessTokenRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPiAccessTokenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPiAccessTokenRequest {
    return { pdsId: isSet(object.pdsId) ? String(object.pdsId) : "" };
  },

  toJSON(message: GetPiAccessTokenRequest): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPiAccessTokenRequest>, I>>(base?: I): GetPiAccessTokenRequest {
    return GetPiAccessTokenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPiAccessTokenRequest>, I>>(object: I): GetPiAccessTokenRequest {
    const message = createBaseGetPiAccessTokenRequest();
    message.pdsId = object.pdsId ?? "";
    return message;
  },
};

function createBaseGetPiAccessTokenResponse(): GetPiAccessTokenResponse {
  return { piAccessToken: "" };
}

export const GetPiAccessTokenResponse = {
  encode(message: GetPiAccessTokenResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.piAccessToken !== "") {
      writer.uint32(10).string(message.piAccessToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPiAccessTokenResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPiAccessTokenResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.piAccessToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPiAccessTokenResponse {
    return { piAccessToken: isSet(object.piAccessToken) ? String(object.piAccessToken) : "" };
  },

  toJSON(message: GetPiAccessTokenResponse): unknown {
    const obj: any = {};
    if (message.piAccessToken !== "") {
      obj.piAccessToken = message.piAccessToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPiAccessTokenResponse>, I>>(base?: I): GetPiAccessTokenResponse {
    return GetPiAccessTokenResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPiAccessTokenResponse>, I>>(object: I): GetPiAccessTokenResponse {
    const message = createBaseGetPiAccessTokenResponse();
    message.piAccessToken = object.piAccessToken ?? "";
    return message;
  },
};

function createBaseGetTicketIdToConsumeRequest(): GetTicketIdToConsumeRequest {
  return {};
}

export const GetTicketIdToConsumeRequest = {
  encode(_: GetTicketIdToConsumeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTicketIdToConsumeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTicketIdToConsumeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetTicketIdToConsumeRequest {
    return {};
  },

  toJSON(_: GetTicketIdToConsumeRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTicketIdToConsumeRequest>, I>>(base?: I): GetTicketIdToConsumeRequest {
    return GetTicketIdToConsumeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTicketIdToConsumeRequest>, I>>(_: I): GetTicketIdToConsumeRequest {
    const message = createBaseGetTicketIdToConsumeRequest();
    return message;
  },
};

function createBaseGetTicketIdToConsumeResponse(): GetTicketIdToConsumeResponse {
  return { ticketId: "" };
}

export const GetTicketIdToConsumeResponse = {
  encode(message: GetTicketIdToConsumeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ticketId !== "") {
      writer.uint32(10).string(message.ticketId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTicketIdToConsumeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTicketIdToConsumeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ticketId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTicketIdToConsumeResponse {
    return { ticketId: isSet(object.ticketId) ? String(object.ticketId) : "" };
  },

  toJSON(message: GetTicketIdToConsumeResponse): unknown {
    const obj: any = {};
    if (message.ticketId !== "") {
      obj.ticketId = message.ticketId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTicketIdToConsumeResponse>, I>>(base?: I): GetTicketIdToConsumeResponse {
    return GetTicketIdToConsumeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTicketIdToConsumeResponse>, I>>(object: I): GetTicketIdToConsumeResponse {
    const message = createBaseGetTicketIdToConsumeResponse();
    message.ticketId = object.ticketId ?? "";
    return message;
  },
};

function createBaseListProposalRequest(): ListProposalRequest {
  return {
    updatedAfter: undefined,
    fetchStatic: false,
    fetchActiveGlobal: false,
    fetchActiveClient: false,
    purposes: [],
    proposalIds: [],
    finishedOnly: false,
  };
}

export const ListProposalRequest = {
  encode(message: ListProposalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.updatedAfter !== undefined) {
      writer.uint32(10).string(message.updatedAfter);
    }
    if (message.fetchStatic === true) {
      writer.uint32(16).bool(message.fetchStatic);
    }
    if (message.fetchActiveGlobal === true) {
      writer.uint32(24).bool(message.fetchActiveGlobal);
    }
    if (message.fetchActiveClient === true) {
      writer.uint32(32).bool(message.fetchActiveClient);
    }
    writer.uint32(42).fork();
    for (const v of message.purposes) {
      writer.uint32(v);
    }
    writer.ldelim();
    for (const v of message.proposalIds) {
      writer.uint32(50).string(v!);
    }
    if (message.finishedOnly === true) {
      writer.uint32(64).bool(message.finishedOnly);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProposalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.updatedAfter = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fetchStatic = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fetchActiveGlobal = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fetchActiveClient = reader.bool();
          continue;
        case 5:
          if (tag === 40) {
            message.purposes.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.purposes.push(reader.uint32());
            }

            continue;
          }

          break;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.proposalIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.finishedOnly = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProposalRequest {
    return {
      updatedAfter: isSet(object.updatedAfter) ? String(object.updatedAfter) : undefined,
      fetchStatic: isSet(object.fetchStatic) ? Boolean(object.fetchStatic) : false,
      fetchActiveGlobal: isSet(object.fetchActiveGlobal) ? Boolean(object.fetchActiveGlobal) : false,
      fetchActiveClient: isSet(object.fetchActiveClient) ? Boolean(object.fetchActiveClient) : false,
      purposes: Array.isArray(object?.purposes) ? object.purposes.map((e: any) => Number(e)) : [],
      proposalIds: Array.isArray(object?.proposalIds) ? object.proposalIds.map((e: any) => String(e)) : [],
      finishedOnly: isSet(object.finishedOnly) ? Boolean(object.finishedOnly) : false,
    };
  },

  toJSON(message: ListProposalRequest): unknown {
    const obj: any = {};
    if (message.updatedAfter !== undefined) {
      obj.updatedAfter = message.updatedAfter;
    }
    if (message.fetchStatic === true) {
      obj.fetchStatic = message.fetchStatic;
    }
    if (message.fetchActiveGlobal === true) {
      obj.fetchActiveGlobal = message.fetchActiveGlobal;
    }
    if (message.fetchActiveClient === true) {
      obj.fetchActiveClient = message.fetchActiveClient;
    }
    if (message.purposes?.length) {
      obj.purposes = message.purposes.map((e) => Math.round(e));
    }
    if (message.proposalIds?.length) {
      obj.proposalIds = message.proposalIds;
    }
    if (message.finishedOnly === true) {
      obj.finishedOnly = message.finishedOnly;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProposalRequest>, I>>(base?: I): ListProposalRequest {
    return ListProposalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProposalRequest>, I>>(object: I): ListProposalRequest {
    const message = createBaseListProposalRequest();
    message.updatedAfter = object.updatedAfter ?? undefined;
    message.fetchStatic = object.fetchStatic ?? false;
    message.fetchActiveGlobal = object.fetchActiveGlobal ?? false;
    message.fetchActiveClient = object.fetchActiveClient ?? false;
    message.purposes = object.purposes?.map((e) => e) || [];
    message.proposalIds = object.proposalIds?.map((e) => e) || [];
    message.finishedOnly = object.finishedOnly ?? false;
    return message;
  },
};

function createBaseListProposalResponse(): ListProposalResponse {
  return { proposals: [] };
}

export const ListProposalResponse = {
  encode(message: ListProposalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.proposals) {
      Proposal.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProposalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposals.push(Proposal.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProposalResponse {
    return {
      proposals: Array.isArray(object?.proposals) ? object.proposals.map((e: any) => Proposal.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListProposalResponse): unknown {
    const obj: any = {};
    if (message.proposals?.length) {
      obj.proposals = message.proposals.map((e) => Proposal.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProposalResponse>, I>>(base?: I): ListProposalResponse {
    return ListProposalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProposalResponse>, I>>(object: I): ListProposalResponse {
    const message = createBaseListProposalResponse();
    message.proposals = object.proposals?.map((e) => Proposal.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIssueTicketRequestMaterial(): IssueTicketRequestMaterial {
  return { materialPrefix: "", did: "", proposalId: "", createdAt: "" };
}

export const IssueTicketRequestMaterial = {
  encode(message: IssueTicketRequestMaterial, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.materialPrefix !== "") {
      writer.uint32(10).string(message.materialPrefix);
    }
    if (message.did !== "") {
      writer.uint32(18).string(message.did);
    }
    if (message.proposalId !== "") {
      writer.uint32(26).string(message.proposalId);
    }
    if (message.createdAt !== "") {
      writer.uint32(34).string(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTicketRequestMaterial {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTicketRequestMaterial();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.materialPrefix = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.did = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTicketRequestMaterial {
    return {
      materialPrefix: isSet(object.materialPrefix) ? String(object.materialPrefix) : "",
      did: isSet(object.did) ? String(object.did) : "",
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
    };
  },

  toJSON(message: IssueTicketRequestMaterial): unknown {
    const obj: any = {};
    if (message.materialPrefix !== "") {
      obj.materialPrefix = message.materialPrefix;
    }
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTicketRequestMaterial>, I>>(base?: I): IssueTicketRequestMaterial {
    return IssueTicketRequestMaterial.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueTicketRequestMaterial>, I>>(object: I): IssueTicketRequestMaterial {
    const message = createBaseIssueTicketRequestMaterial();
    message.materialPrefix = object.materialPrefix ?? "";
    message.did = object.did ?? "";
    message.proposalId = object.proposalId ?? "";
    message.createdAt = object.createdAt ?? "";
    return message;
  },
};

function createBaseIssueTicketRequest(): IssueTicketRequest {
  return { issueTicketRequestMaterial: undefined, signature: new Uint8Array(0) };
}

export const IssueTicketRequest = {
  encode(message: IssueTicketRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.issueTicketRequestMaterial !== undefined) {
      IssueTicketRequestMaterial.encode(message.issueTicketRequestMaterial, writer.uint32(90).fork()).ldelim();
    }
    if (message.signature.length !== 0) {
      writer.uint32(98).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTicketRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTicketRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 11:
          if (tag !== 90) {
            break;
          }

          message.issueTicketRequestMaterial = IssueTicketRequestMaterial.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTicketRequest {
    return {
      issueTicketRequestMaterial: isSet(object.issueTicketRequestMaterial)
        ? IssueTicketRequestMaterial.fromJSON(object.issueTicketRequestMaterial)
        : undefined,
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
    };
  },

  toJSON(message: IssueTicketRequest): unknown {
    const obj: any = {};
    if (message.issueTicketRequestMaterial !== undefined) {
      obj.issueTicketRequestMaterial = IssueTicketRequestMaterial.toJSON(message.issueTicketRequestMaterial);
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTicketRequest>, I>>(base?: I): IssueTicketRequest {
    return IssueTicketRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueTicketRequest>, I>>(object: I): IssueTicketRequest {
    const message = createBaseIssueTicketRequest();
    message.issueTicketRequestMaterial =
      object.issueTicketRequestMaterial !== undefined && object.issueTicketRequestMaterial !== null
        ? IssueTicketRequestMaterial.fromPartial(object.issueTicketRequestMaterial)
        : undefined;
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

function createBaseIssueTicketResponse(): IssueTicketResponse {
  return { proposalId: "", code: undefined, proposalActiveClient: undefined };
}

export const IssueTicketResponse = {
  encode(message: IssueTicketResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    if (message.code !== undefined) {
      writer.uint32(24).int32(message.code);
    }
    if (message.proposalActiveClient !== undefined) {
      ProposalActiveClient.encode(message.proposalActiveClient, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueTicketResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueTicketResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalActiveClient = ProposalActiveClient.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueTicketResponse {
    return {
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : undefined,
      proposalActiveClient: isSet(object.proposalActiveClient)
        ? ProposalActiveClient.fromJSON(object.proposalActiveClient)
        : undefined,
    };
  },

  toJSON(message: IssueTicketResponse): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.code !== undefined) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.proposalActiveClient !== undefined) {
      obj.proposalActiveClient = ProposalActiveClient.toJSON(message.proposalActiveClient);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueTicketResponse>, I>>(base?: I): IssueTicketResponse {
    return IssueTicketResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueTicketResponse>, I>>(object: I): IssueTicketResponse {
    const message = createBaseIssueTicketResponse();
    message.proposalId = object.proposalId ?? "";
    message.code = object.code ?? undefined;
    message.proposalActiveClient =
      object.proposalActiveClient !== undefined && object.proposalActiveClient !== null
        ? ProposalActiveClient.fromPartial(object.proposalActiveClient)
        : undefined;
    return message;
  },
};

function createBaseUploadPiRequest(): UploadPiRequest {
  return { pdsId: "", proposalId: "", gzippedPayload: new Uint8Array(0) };
}

export const UploadPiRequest = {
  encode(message: UploadPiRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    if (message.gzippedPayload.length !== 0) {
      writer.uint32(26).bytes(message.gzippedPayload);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadPiRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadPiRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.gzippedPayload = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadPiRequest {
    return {
      pdsId: isSet(object.pdsId) ? String(object.pdsId) : "",
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
      gzippedPayload: isSet(object.gzippedPayload) ? bytesFromBase64(object.gzippedPayload) : new Uint8Array(0),
    };
  },

  toJSON(message: UploadPiRequest): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.gzippedPayload.length !== 0) {
      obj.gzippedPayload = base64FromBytes(message.gzippedPayload);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadPiRequest>, I>>(base?: I): UploadPiRequest {
    return UploadPiRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadPiRequest>, I>>(object: I): UploadPiRequest {
    const message = createBaseUploadPiRequest();
    message.pdsId = object.pdsId ?? "";
    message.proposalId = object.proposalId ?? "";
    message.gzippedPayload = object.gzippedPayload ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadPiResponse(): UploadPiResponse {
  return { pdsId: "", code: 0 };
}

export const UploadPiResponse = {
  encode(message: UploadPiResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pdsId !== "") {
      writer.uint32(10).string(message.pdsId);
    }
    if (message.code !== 0) {
      writer.uint32(24).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadPiResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadPiResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UploadPiResponse {
    return {
      pdsId: isSet(object.pdsId) ? String(object.pdsId) : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
    };
  },

  toJSON(message: UploadPiResponse): unknown {
    const obj: any = {};
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UploadPiResponse>, I>>(base?: I): UploadPiResponse {
    return UploadPiResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadPiResponse>, I>>(object: I): UploadPiResponse {
    const message = createBaseUploadPiResponse();
    message.pdsId = object.pdsId ?? "";
    message.code = object.code ?? 0;
    return message;
  },
};

function createBasePreConsumePayload(): PreConsumePayload {
  return { timestamp: 0, pdsId: "" };
}

export const PreConsumePayload = {
  encode(message: PreConsumePayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== 0) {
      writer.uint32(8).uint64(message.timestamp);
    }
    if (message.pdsId !== "") {
      writer.uint32(18).string(message.pdsId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreConsumePayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreConsumePayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestamp = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdsId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreConsumePayload {
    return {
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : 0,
      pdsId: isSet(object.pdsId) ? String(object.pdsId) : "",
    };
  },

  toJSON(message: PreConsumePayload): unknown {
    const obj: any = {};
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreConsumePayload>, I>>(base?: I): PreConsumePayload {
    return PreConsumePayload.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreConsumePayload>, I>>(object: I): PreConsumePayload {
    const message = createBasePreConsumePayload();
    message.timestamp = object.timestamp ?? 0;
    message.pdsId = object.pdsId ?? "";
    return message;
  },
};

function createBasePreConsumeRequest(): PreConsumeRequest {
  return { preConsumePayload: undefined, besuAddress: "", signature: new Uint8Array(0) };
}

export const PreConsumeRequest = {
  encode(message: PreConsumeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.preConsumePayload !== undefined) {
      PreConsumePayload.encode(message.preConsumePayload, writer.uint32(10).fork()).ldelim();
    }
    if (message.besuAddress !== "") {
      writer.uint32(18).string(message.besuAddress);
    }
    if (message.signature.length !== 0) {
      writer.uint32(26).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreConsumeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreConsumeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.preConsumePayload = PreConsumePayload.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreConsumeRequest {
    return {
      preConsumePayload: isSet(object.preConsumePayload)
        ? PreConsumePayload.fromJSON(object.preConsumePayload)
        : undefined,
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "",
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
    };
  },

  toJSON(message: PreConsumeRequest): unknown {
    const obj: any = {};
    if (message.preConsumePayload !== undefined) {
      obj.preConsumePayload = PreConsumePayload.toJSON(message.preConsumePayload);
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreConsumeRequest>, I>>(base?: I): PreConsumeRequest {
    return PreConsumeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreConsumeRequest>, I>>(object: I): PreConsumeRequest {
    const message = createBasePreConsumeRequest();
    message.preConsumePayload =
      object.preConsumePayload !== undefined && object.preConsumePayload !== null
        ? PreConsumePayload.fromPartial(object.preConsumePayload)
        : undefined;
    message.besuAddress = object.besuAddress ?? "";
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

function createBasePreConsumeResponse(): PreConsumeResponse {
  return { blockchainNonce: 0, nextRequestId: 0, contractAddress: "", pdsId: "", ticketId: "", purpose: 0 };
}

export const PreConsumeResponse = {
  encode(message: PreConsumeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.blockchainNonce !== 0) {
      writer.uint32(8).uint64(message.blockchainNonce);
    }
    if (message.nextRequestId !== 0) {
      writer.uint32(16).uint32(message.nextRequestId);
    }
    if (message.contractAddress !== "") {
      writer.uint32(26).string(message.contractAddress);
    }
    if (message.pdsId !== "") {
      writer.uint32(34).string(message.pdsId);
    }
    if (message.ticketId !== "") {
      writer.uint32(42).string(message.ticketId);
    }
    if (message.purpose !== 0) {
      writer.uint32(48).int32(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PreConsumeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePreConsumeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.blockchainNonce = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.nextRequestId = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contractAddress = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ticketId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.purpose = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PreConsumeResponse {
    return {
      blockchainNonce: isSet(object.blockchainNonce) ? Number(object.blockchainNonce) : 0,
      nextRequestId: isSet(object.nextRequestId) ? Number(object.nextRequestId) : 0,
      contractAddress: isSet(object.contractAddress) ? String(object.contractAddress) : "",
      pdsId: isSet(object.pdsId) ? String(object.pdsId) : "",
      ticketId: isSet(object.ticketId) ? String(object.ticketId) : "",
      purpose: isSet(object.purpose) ? Number(object.purpose) : 0,
    };
  },

  toJSON(message: PreConsumeResponse): unknown {
    const obj: any = {};
    if (message.blockchainNonce !== 0) {
      obj.blockchainNonce = Math.round(message.blockchainNonce);
    }
    if (message.nextRequestId !== 0) {
      obj.nextRequestId = Math.round(message.nextRequestId);
    }
    if (message.contractAddress !== "") {
      obj.contractAddress = message.contractAddress;
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.ticketId !== "") {
      obj.ticketId = message.ticketId;
    }
    if (message.purpose !== 0) {
      obj.purpose = Math.round(message.purpose);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PreConsumeResponse>, I>>(base?: I): PreConsumeResponse {
    return PreConsumeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PreConsumeResponse>, I>>(object: I): PreConsumeResponse {
    const message = createBasePreConsumeResponse();
    message.blockchainNonce = object.blockchainNonce ?? 0;
    message.nextRequestId = object.nextRequestId ?? 0;
    message.contractAddress = object.contractAddress ?? "";
    message.pdsId = object.pdsId ?? "";
    message.ticketId = object.ticketId ?? "";
    message.purpose = object.purpose ?? 0;
    return message;
  },
};

function createBaseConsumeTicketRequest(): ConsumeTicketRequest {
  return { requestId: 0, pdsId: "", rawTransaction: new Uint8Array(0), besuAddress: "" };
}

export const ConsumeTicketRequest = {
  encode(message: ConsumeTicketRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.requestId !== 0) {
      writer.uint32(8).uint32(message.requestId);
    }
    if (message.pdsId !== "") {
      writer.uint32(18).string(message.pdsId);
    }
    if (message.rawTransaction.length !== 0) {
      writer.uint32(26).bytes(message.rawTransaction);
    }
    if (message.besuAddress !== "") {
      writer.uint32(34).string(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumeTicketRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumeTicketRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.requestId = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdsId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rawTransaction = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConsumeTicketRequest {
    return {
      requestId: isSet(object.requestId) ? Number(object.requestId) : 0,
      pdsId: isSet(object.pdsId) ? String(object.pdsId) : "",
      rawTransaction: isSet(object.rawTransaction) ? bytesFromBase64(object.rawTransaction) : new Uint8Array(0),
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "",
    };
  },

  toJSON(message: ConsumeTicketRequest): unknown {
    const obj: any = {};
    if (message.requestId !== 0) {
      obj.requestId = Math.round(message.requestId);
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    if (message.rawTransaction.length !== 0) {
      obj.rawTransaction = base64FromBytes(message.rawTransaction);
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumeTicketRequest>, I>>(base?: I): ConsumeTicketRequest {
    return ConsumeTicketRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConsumeTicketRequest>, I>>(object: I): ConsumeTicketRequest {
    const message = createBaseConsumeTicketRequest();
    message.requestId = object.requestId ?? 0;
    message.pdsId = object.pdsId ?? "";
    message.rawTransaction = object.rawTransaction ?? new Uint8Array(0);
    message.besuAddress = object.besuAddress ?? "";
    return message;
  },
};

function createBaseConsumeTicketResponse(): ConsumeTicketResponse {
  return {
    status: 0,
    code: undefined,
    retryWithNewBlockChainNonce: false,
    newBlockChainNonce: 0,
    duplicatedRequestId: undefined,
  };
}

export const ConsumeTicketResponse = {
  encode(message: ConsumeTicketResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.code !== undefined) {
      writer.uint32(24).int32(message.code);
    }
    if (message.retryWithNewBlockChainNonce === true) {
      writer.uint32(40).bool(message.retryWithNewBlockChainNonce);
    }
    if (message.newBlockChainNonce !== 0) {
      writer.uint32(48).uint64(message.newBlockChainNonce);
    }
    if (message.duplicatedRequestId !== undefined) {
      writer.uint32(56).uint32(message.duplicatedRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ConsumeTicketResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConsumeTicketResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.retryWithNewBlockChainNonce = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.newBlockChainNonce = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.duplicatedRequestId = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ConsumeTicketResponse {
    return {
      status: isSet(object.status) ? asyncRequestStatusFromJSON(object.status) : 0,
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : undefined,
      retryWithNewBlockChainNonce: isSet(object.retryWithNewBlockChainNonce)
        ? Boolean(object.retryWithNewBlockChainNonce)
        : false,
      newBlockChainNonce: isSet(object.newBlockChainNonce) ? Number(object.newBlockChainNonce) : 0,
      duplicatedRequestId: isSet(object.duplicatedRequestId) ? Number(object.duplicatedRequestId) : undefined,
    };
  },

  toJSON(message: ConsumeTicketResponse): unknown {
    const obj: any = {};
    if (message.status !== 0) {
      obj.status = asyncRequestStatusToJSON(message.status);
    }
    if (message.code !== undefined) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.retryWithNewBlockChainNonce === true) {
      obj.retryWithNewBlockChainNonce = message.retryWithNewBlockChainNonce;
    }
    if (message.newBlockChainNonce !== 0) {
      obj.newBlockChainNonce = Math.round(message.newBlockChainNonce);
    }
    if (message.duplicatedRequestId !== undefined) {
      obj.duplicatedRequestId = Math.round(message.duplicatedRequestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ConsumeTicketResponse>, I>>(base?: I): ConsumeTicketResponse {
    return ConsumeTicketResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ConsumeTicketResponse>, I>>(object: I): ConsumeTicketResponse {
    const message = createBaseConsumeTicketResponse();
    message.status = object.status ?? 0;
    message.code = object.code ?? undefined;
    message.retryWithNewBlockChainNonce = object.retryWithNewBlockChainNonce ?? false;
    message.newBlockChainNonce = object.newBlockChainNonce ?? 0;
    message.duplicatedRequestId = object.duplicatedRequestId ?? undefined;
    return message;
  },
};

function createBaseDataReceiptPayload(): DataReceiptPayload {
  return { timestamp: 0, pdsId: "" };
}

export const DataReceiptPayload = {
  encode(message: DataReceiptPayload, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.timestamp !== 0) {
      writer.uint32(8).uint64(message.timestamp);
    }
    if (message.pdsId !== "") {
      writer.uint32(18).string(message.pdsId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptPayload {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptPayload();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.timestamp = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pdsId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptPayload {
    return {
      timestamp: isSet(object.timestamp) ? Number(object.timestamp) : 0,
      pdsId: isSet(object.pdsId) ? String(object.pdsId) : "",
    };
  },

  toJSON(message: DataReceiptPayload): unknown {
    const obj: any = {};
    if (message.timestamp !== 0) {
      obj.timestamp = Math.round(message.timestamp);
    }
    if (message.pdsId !== "") {
      obj.pdsId = message.pdsId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptPayload>, I>>(base?: I): DataReceiptPayload {
    return DataReceiptPayload.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptPayload>, I>>(object: I): DataReceiptPayload {
    const message = createBaseDataReceiptPayload();
    message.timestamp = object.timestamp ?? 0;
    message.pdsId = object.pdsId ?? "";
    return message;
  },
};

function createBaseDataReceiptNotification(): DataReceiptNotification {
  return { payload: undefined, besuAddress: "", signature: new Uint8Array(0) };
}

export const DataReceiptNotification = {
  encode(message: DataReceiptNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.payload !== undefined) {
      DataReceiptPayload.encode(message.payload, writer.uint32(10).fork()).ldelim();
    }
    if (message.besuAddress !== "") {
      writer.uint32(18).string(message.besuAddress);
    }
    if (message.signature.length !== 0) {
      writer.uint32(26).bytes(message.signature);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.payload = DataReceiptPayload.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signature = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptNotification {
    return {
      payload: isSet(object.payload) ? DataReceiptPayload.fromJSON(object.payload) : undefined,
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : "",
      signature: isSet(object.signature) ? bytesFromBase64(object.signature) : new Uint8Array(0),
    };
  },

  toJSON(message: DataReceiptNotification): unknown {
    const obj: any = {};
    if (message.payload !== undefined) {
      obj.payload = DataReceiptPayload.toJSON(message.payload);
    }
    if (message.besuAddress !== "") {
      obj.besuAddress = message.besuAddress;
    }
    if (message.signature.length !== 0) {
      obj.signature = base64FromBytes(message.signature);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptNotification>, I>>(base?: I): DataReceiptNotification {
    return DataReceiptNotification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptNotification>, I>>(object: I): DataReceiptNotification {
    const message = createBaseDataReceiptNotification();
    message.payload =
      object.payload !== undefined && object.payload !== null
        ? DataReceiptPayload.fromPartial(object.payload)
        : undefined;
    message.besuAddress = object.besuAddress ?? "";
    message.signature = object.signature ?? new Uint8Array(0);
    return message;
  },
};

function createBaseDataReceiptNotificationResponse(): DataReceiptNotificationResponse {
  return { code: 0, message: undefined };
}

export const DataReceiptNotificationResponse = {
  encode(message: DataReceiptNotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DataReceiptNotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataReceiptNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DataReceiptNotificationResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      message: isSet(object.message) ? String(object.message) : undefined,
    };
  },

  toJSON(message: DataReceiptNotificationResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DataReceiptNotificationResponse>, I>>(base?: I): DataReceiptNotificationResponse {
    return DataReceiptNotificationResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataReceiptNotificationResponse>, I>>(
    object: I
  ): DataReceiptNotificationResponse {
    const message = createBaseDataReceiptNotificationResponse();
    message.code = object.code ?? 0;
    message.message = object.message ?? undefined;
    return message;
  },
};

function createBaseListDataReceiptRequest(): ListDataReceiptRequest {
  return { pdsIds: [] };
}

export const ListDataReceiptRequest = {
  encode(message: ListDataReceiptRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.pdsIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDataReceiptRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDataReceiptRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pdsIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDataReceiptRequest {
    return { pdsIds: Array.isArray(object?.pdsIds) ? object.pdsIds.map((e: any) => String(e)) : [] };
  },

  toJSON(message: ListDataReceiptRequest): unknown {
    const obj: any = {};
    if (message.pdsIds?.length) {
      obj.pdsIds = message.pdsIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDataReceiptRequest>, I>>(base?: I): ListDataReceiptRequest {
    return ListDataReceiptRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDataReceiptRequest>, I>>(object: I): ListDataReceiptRequest {
    const message = createBaseListDataReceiptRequest();
    message.pdsIds = object.pdsIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseListDataReceiptResponse(): ListDataReceiptResponse {
  return { notifications: [] };
}

export const ListDataReceiptResponse = {
  encode(message: ListDataReceiptResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      DataReceiptNotification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListDataReceiptResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListDataReceiptResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(DataReceiptNotification.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListDataReceiptResponse {
    return {
      notifications: Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => DataReceiptNotification.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListDataReceiptResponse): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => DataReceiptNotification.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListDataReceiptResponse>, I>>(base?: I): ListDataReceiptResponse {
    return ListDataReceiptResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListDataReceiptResponse>, I>>(object: I): ListDataReceiptResponse {
    const message = createBaseListDataReceiptResponse();
    message.notifications = object.notifications?.map((e) => DataReceiptNotification.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListRewardHistoryRequest(): ListRewardHistoryRequest {
  return { did: "", startAt: "", endAt: "", requestType: 0, pageSize: 0, pageNum: 0 };
}

export const ListRewardHistoryRequest = {
  encode(message: ListRewardHistoryRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.startAt !== "") {
      writer.uint32(18).string(message.startAt);
    }
    if (message.endAt !== "") {
      writer.uint32(26).string(message.endAt);
    }
    if (message.requestType !== 0) {
      writer.uint32(32).int32(message.requestType);
    }
    if (message.pageSize !== 0) {
      writer.uint32(40).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(48).uint32(message.pageNum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRewardHistoryRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRewardHistoryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.startAt = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.endAt = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.requestType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRewardHistoryRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      startAt: isSet(object.startAt) ? String(object.startAt) : "",
      endAt: isSet(object.endAt) ? String(object.endAt) : "",
      requestType: isSet(object.requestType) ? listRewardHistoryRequestTypeFromJSON(object.requestType) : 0,
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? Number(object.pageNum) : 0,
    };
  },

  toJSON(message: ListRewardHistoryRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.startAt !== "") {
      obj.startAt = message.startAt;
    }
    if (message.endAt !== "") {
      obj.endAt = message.endAt;
    }
    if (message.requestType !== 0) {
      obj.requestType = listRewardHistoryRequestTypeToJSON(message.requestType);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRewardHistoryRequest>, I>>(base?: I): ListRewardHistoryRequest {
    return ListRewardHistoryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRewardHistoryRequest>, I>>(object: I): ListRewardHistoryRequest {
    const message = createBaseListRewardHistoryRequest();
    message.did = object.did ?? "";
    message.startAt = object.startAt ?? "";
    message.endAt = object.endAt ?? "";
    message.requestType = object.requestType ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    return message;
  },
};

function createBaseListRewardHistoryResponse(): ListRewardHistoryResponse {
  return { did: "", totalCount: 0, pageSize: 0, pageNum: 0, balance: 0, amountExpiresWithin1m: 0, contents: [] };
}

export const ListRewardHistoryResponse = {
  encode(message: ListRewardHistoryResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.totalCount !== 0) {
      writer.uint32(16).uint64(message.totalCount);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(32).uint32(message.pageNum);
    }
    if (message.balance !== 0) {
      writer.uint32(48).uint64(message.balance);
    }
    if (message.amountExpiresWithin1m !== 0) {
      writer.uint32(56).uint64(message.amountExpiresWithin1m);
    }
    for (const v of message.contents) {
      RewardTransactionContentForApp.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListRewardHistoryResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListRewardHistoryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCount = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.balance = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.amountExpiresWithin1m = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.contents.push(RewardTransactionContentForApp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListRewardHistoryResponse {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      totalCount: isSet(object.totalCount) ? Number(object.totalCount) : 0,
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? Number(object.pageNum) : 0,
      balance: isSet(object.balance) ? Number(object.balance) : 0,
      amountExpiresWithin1m: isSet(object.amountExpiresWithin1m) ? Number(object.amountExpiresWithin1m) : 0,
      contents: Array.isArray(object?.contents)
        ? object.contents.map((e: any) => RewardTransactionContentForApp.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ListRewardHistoryResponse): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    if (message.balance !== 0) {
      obj.balance = Math.round(message.balance);
    }
    if (message.amountExpiresWithin1m !== 0) {
      obj.amountExpiresWithin1m = Math.round(message.amountExpiresWithin1m);
    }
    if (message.contents?.length) {
      obj.contents = message.contents.map((e) => RewardTransactionContentForApp.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListRewardHistoryResponse>, I>>(base?: I): ListRewardHistoryResponse {
    return ListRewardHistoryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListRewardHistoryResponse>, I>>(object: I): ListRewardHistoryResponse {
    const message = createBaseListRewardHistoryResponse();
    message.did = object.did ?? "";
    message.totalCount = object.totalCount ?? 0;
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    message.balance = object.balance ?? 0;
    message.amountExpiresWithin1m = object.amountExpiresWithin1m ?? 0;
    message.contents = object.contents?.map((e) => RewardTransactionContentForApp.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCouponIdRequest(): GetCouponIdRequest {
  return { did: "" };
}

export const GetCouponIdRequest = {
  encode(message: GetCouponIdRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCouponIdRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponIdRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCouponIdRequest {
    return { did: isSet(object.did) ? String(object.did) : "" };
  },

  toJSON(message: GetCouponIdRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCouponIdRequest>, I>>(base?: I): GetCouponIdRequest {
    return GetCouponIdRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCouponIdRequest>, I>>(object: I): GetCouponIdRequest {
    const message = createBaseGetCouponIdRequest();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseGetCouponIdResponse(): GetCouponIdResponse {
  return {
    did: "",
    couponTransactionId: "",
    blockchainRequestId: new Uint8Array(0),
    blockchainRewardTransferNonce: 0,
    blockchainRewardReceiverAddress: "",
    blockchainRewardContractAddress: "",
    code: 0,
  };
}

export const GetCouponIdResponse = {
  encode(message: GetCouponIdResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(18).string(message.couponTransactionId);
    }
    if (message.blockchainRequestId.length !== 0) {
      writer.uint32(26).bytes(message.blockchainRequestId);
    }
    if (message.blockchainRewardTransferNonce !== 0) {
      writer.uint32(32).uint64(message.blockchainRewardTransferNonce);
    }
    if (message.blockchainRewardReceiverAddress !== "") {
      writer.uint32(42).string(message.blockchainRewardReceiverAddress);
    }
    if (message.blockchainRewardContractAddress !== "") {
      writer.uint32(50).string(message.blockchainRewardContractAddress);
    }
    if (message.code !== 0) {
      writer.uint32(56).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCouponIdResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCouponIdResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.blockchainRequestId = reader.bytes();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.blockchainRewardTransferNonce = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.blockchainRewardReceiverAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.blockchainRewardContractAddress = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCouponIdResponse {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? String(object.couponTransactionId) : "",
      blockchainRequestId: isSet(object.blockchainRequestId)
        ? bytesFromBase64(object.blockchainRequestId)
        : new Uint8Array(0),
      blockchainRewardTransferNonce: isSet(object.blockchainRewardTransferNonce)
        ? Number(object.blockchainRewardTransferNonce)
        : 0,
      blockchainRewardReceiverAddress: isSet(object.blockchainRewardReceiverAddress)
        ? String(object.blockchainRewardReceiverAddress)
        : "",
      blockchainRewardContractAddress: isSet(object.blockchainRewardContractAddress)
        ? String(object.blockchainRewardContractAddress)
        : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
    };
  },

  toJSON(message: GetCouponIdResponse): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.blockchainRequestId.length !== 0) {
      obj.blockchainRequestId = base64FromBytes(message.blockchainRequestId);
    }
    if (message.blockchainRewardTransferNonce !== 0) {
      obj.blockchainRewardTransferNonce = Math.round(message.blockchainRewardTransferNonce);
    }
    if (message.blockchainRewardReceiverAddress !== "") {
      obj.blockchainRewardReceiverAddress = message.blockchainRewardReceiverAddress;
    }
    if (message.blockchainRewardContractAddress !== "") {
      obj.blockchainRewardContractAddress = message.blockchainRewardContractAddress;
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCouponIdResponse>, I>>(base?: I): GetCouponIdResponse {
    return GetCouponIdResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCouponIdResponse>, I>>(object: I): GetCouponIdResponse {
    const message = createBaseGetCouponIdResponse();
    message.did = object.did ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.blockchainRequestId = object.blockchainRequestId ?? new Uint8Array(0);
    message.blockchainRewardTransferNonce = object.blockchainRewardTransferNonce ?? 0;
    message.blockchainRewardReceiverAddress = object.blockchainRewardReceiverAddress ?? "";
    message.blockchainRewardContractAddress = object.blockchainRewardContractAddress ?? "";
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseIssueCouponRequest(): IssueCouponRequest {
  return {
    did: "",
    purchaseRequestMaterial: new Uint8Array(0),
    purchaseRequestMaterialSignature: new Uint8Array(0),
    goodsCode: "",
    clientKeyMaterial: new Uint8Array(0),
  };
}

export const IssueCouponRequest = {
  encode(message: IssueCouponRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.purchaseRequestMaterial.length !== 0) {
      writer.uint32(18).bytes(message.purchaseRequestMaterial);
    }
    if (message.purchaseRequestMaterialSignature.length !== 0) {
      writer.uint32(26).bytes(message.purchaseRequestMaterialSignature);
    }
    if (message.goodsCode !== "") {
      writer.uint32(34).string(message.goodsCode);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(42).bytes(message.clientKeyMaterial);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueCouponRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueCouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.purchaseRequestMaterial = reader.bytes();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.purchaseRequestMaterialSignature = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueCouponRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      purchaseRequestMaterial: isSet(object.purchaseRequestMaterial)
        ? bytesFromBase64(object.purchaseRequestMaterial)
        : new Uint8Array(0),
      purchaseRequestMaterialSignature: isSet(object.purchaseRequestMaterialSignature)
        ? bytesFromBase64(object.purchaseRequestMaterialSignature)
        : new Uint8Array(0),
      goodsCode: isSet(object.goodsCode) ? String(object.goodsCode) : "",
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
    };
  },

  toJSON(message: IssueCouponRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.purchaseRequestMaterial.length !== 0) {
      obj.purchaseRequestMaterial = base64FromBytes(message.purchaseRequestMaterial);
    }
    if (message.purchaseRequestMaterialSignature.length !== 0) {
      obj.purchaseRequestMaterialSignature = base64FromBytes(message.purchaseRequestMaterialSignature);
    }
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueCouponRequest>, I>>(base?: I): IssueCouponRequest {
    return IssueCouponRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueCouponRequest>, I>>(object: I): IssueCouponRequest {
    const message = createBaseIssueCouponRequest();
    message.did = object.did ?? "";
    message.purchaseRequestMaterial = object.purchaseRequestMaterial ?? new Uint8Array(0);
    message.purchaseRequestMaterialSignature = object.purchaseRequestMaterialSignature ?? new Uint8Array(0);
    message.goodsCode = object.goodsCode ?? "";
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    return message;
  },
};

function createBaseIssueCouponResponse(): IssueCouponResponse {
  return {
    did: "",
    couponTransactionId: "",
    code: 0,
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    encryptedCouponData: new Uint8Array(0),
  };
}

export const IssueCouponResponse = {
  encode(message: IssueCouponResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(18).string(message.couponTransactionId);
    }
    if (message.code !== 0) {
      writer.uint32(24).int32(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(34).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(42).bytes(message.ephemeralPublicKey);
    }
    if (message.encryptedCouponData.length !== 0) {
      writer.uint32(50).bytes(message.encryptedCouponData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IssueCouponResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIssueCouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.encryptedCouponData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IssueCouponResponse {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? String(object.couponTransactionId) : "",
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      encryptedCouponData: isSet(object.encryptedCouponData)
        ? bytesFromBase64(object.encryptedCouponData)
        : new Uint8Array(0),
    };
  },

  toJSON(message: IssueCouponResponse): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.encryptedCouponData.length !== 0) {
      obj.encryptedCouponData = base64FromBytes(message.encryptedCouponData);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IssueCouponResponse>, I>>(base?: I): IssueCouponResponse {
    return IssueCouponResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IssueCouponResponse>, I>>(object: I): IssueCouponResponse {
    const message = createBaseIssueCouponResponse();
    message.did = object.did ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.code = object.code ?? 0;
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.encryptedCouponData = object.encryptedCouponData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseListCouponRequest(): ListCouponRequest {
  return { did: "", couponTransactionIds: [] };
}

export const ListCouponRequest = {
  encode(message: ListCouponRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.couponTransactionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      couponTransactionIds: Array.isArray(object?.couponTransactionIds)
        ? object.couponTransactionIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ListCouponRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionIds?.length) {
      obj.couponTransactionIds = message.couponTransactionIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponRequest>, I>>(base?: I): ListCouponRequest {
    return ListCouponRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponRequest>, I>>(object: I): ListCouponRequest {
    const message = createBaseListCouponRequest();
    message.did = object.did ?? "";
    message.couponTransactionIds = object.couponTransactionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCouponInfoDetails(): CouponInfoDetails {
  return {
    goodsCode: "",
    pinStatusCode: "",
    goodsName: "",
    sellPriceAmount: "",
    senderTelNo: "",
    cnsmPriceAmount: "",
    sendRstCode: "",
    pinStatusName: "",
    mmsBrandThumImg: "",
    brandName: "",
    sendRstMsg: "",
    correcDtm: "",
    recverTelNo: "",
    expirationAt: "",
    sendBasicCode: "",
    sendStatusCode: "",
  };
}

export const CouponInfoDetails = {
  encode(message: CouponInfoDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.goodsCode !== "") {
      writer.uint32(10).string(message.goodsCode);
    }
    if (message.pinStatusCode !== "") {
      writer.uint32(18).string(message.pinStatusCode);
    }
    if (message.goodsName !== "") {
      writer.uint32(26).string(message.goodsName);
    }
    if (message.sellPriceAmount !== "") {
      writer.uint32(34).string(message.sellPriceAmount);
    }
    if (message.senderTelNo !== "") {
      writer.uint32(42).string(message.senderTelNo);
    }
    if (message.cnsmPriceAmount !== "") {
      writer.uint32(50).string(message.cnsmPriceAmount);
    }
    if (message.sendRstCode !== "") {
      writer.uint32(58).string(message.sendRstCode);
    }
    if (message.pinStatusName !== "") {
      writer.uint32(66).string(message.pinStatusName);
    }
    if (message.mmsBrandThumImg !== "") {
      writer.uint32(74).string(message.mmsBrandThumImg);
    }
    if (message.brandName !== "") {
      writer.uint32(82).string(message.brandName);
    }
    if (message.sendRstMsg !== "") {
      writer.uint32(90).string(message.sendRstMsg);
    }
    if (message.correcDtm !== "") {
      writer.uint32(98).string(message.correcDtm);
    }
    if (message.recverTelNo !== "") {
      writer.uint32(106).string(message.recverTelNo);
    }
    if (message.expirationAt !== "") {
      writer.uint32(114).string(message.expirationAt);
    }
    if (message.sendBasicCode !== "") {
      writer.uint32(122).string(message.sendBasicCode);
    }
    if (message.sendStatusCode !== "") {
      writer.uint32(130).string(message.sendStatusCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponInfoDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponInfoDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pinStatusCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.goodsName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sellPriceAmount = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.senderTelNo = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cnsmPriceAmount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sendRstCode = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pinStatusName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.mmsBrandThumImg = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.brandName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sendRstMsg = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.correcDtm = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.recverTelNo = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.expirationAt = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.sendBasicCode = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.sendStatusCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponInfoDetails {
    return {
      goodsCode: isSet(object.goodsCode) ? String(object.goodsCode) : "",
      pinStatusCode: isSet(object.pinStatusCode) ? String(object.pinStatusCode) : "",
      goodsName: isSet(object.goodsName) ? String(object.goodsName) : "",
      sellPriceAmount: isSet(object.sellPriceAmount) ? String(object.sellPriceAmount) : "",
      senderTelNo: isSet(object.senderTelNo) ? String(object.senderTelNo) : "",
      cnsmPriceAmount: isSet(object.cnsmPriceAmount) ? String(object.cnsmPriceAmount) : "",
      sendRstCode: isSet(object.sendRstCode) ? String(object.sendRstCode) : "",
      pinStatusName: isSet(object.pinStatusName) ? String(object.pinStatusName) : "",
      mmsBrandThumImg: isSet(object.mmsBrandThumImg) ? String(object.mmsBrandThumImg) : "",
      brandName: isSet(object.brandName) ? String(object.brandName) : "",
      sendRstMsg: isSet(object.sendRstMsg) ? String(object.sendRstMsg) : "",
      correcDtm: isSet(object.correcDtm) ? String(object.correcDtm) : "",
      recverTelNo: isSet(object.recverTelNo) ? String(object.recverTelNo) : "",
      expirationAt: isSet(object.expirationAt) ? String(object.expirationAt) : "",
      sendBasicCode: isSet(object.sendBasicCode) ? String(object.sendBasicCode) : "",
      sendStatusCode: isSet(object.sendStatusCode) ? String(object.sendStatusCode) : "",
    };
  },

  toJSON(message: CouponInfoDetails): unknown {
    const obj: any = {};
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    if (message.pinStatusCode !== "") {
      obj.pinStatusCode = message.pinStatusCode;
    }
    if (message.goodsName !== "") {
      obj.goodsName = message.goodsName;
    }
    if (message.sellPriceAmount !== "") {
      obj.sellPriceAmount = message.sellPriceAmount;
    }
    if (message.senderTelNo !== "") {
      obj.senderTelNo = message.senderTelNo;
    }
    if (message.cnsmPriceAmount !== "") {
      obj.cnsmPriceAmount = message.cnsmPriceAmount;
    }
    if (message.sendRstCode !== "") {
      obj.sendRstCode = message.sendRstCode;
    }
    if (message.pinStatusName !== "") {
      obj.pinStatusName = message.pinStatusName;
    }
    if (message.mmsBrandThumImg !== "") {
      obj.mmsBrandThumImg = message.mmsBrandThumImg;
    }
    if (message.brandName !== "") {
      obj.brandName = message.brandName;
    }
    if (message.sendRstMsg !== "") {
      obj.sendRstMsg = message.sendRstMsg;
    }
    if (message.correcDtm !== "") {
      obj.correcDtm = message.correcDtm;
    }
    if (message.recverTelNo !== "") {
      obj.recverTelNo = message.recverTelNo;
    }
    if (message.expirationAt !== "") {
      obj.expirationAt = message.expirationAt;
    }
    if (message.sendBasicCode !== "") {
      obj.sendBasicCode = message.sendBasicCode;
    }
    if (message.sendStatusCode !== "") {
      obj.sendStatusCode = message.sendStatusCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponInfoDetails>, I>>(base?: I): CouponInfoDetails {
    return CouponInfoDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponInfoDetails>, I>>(object: I): CouponInfoDetails {
    const message = createBaseCouponInfoDetails();
    message.goodsCode = object.goodsCode ?? "";
    message.pinStatusCode = object.pinStatusCode ?? "";
    message.goodsName = object.goodsName ?? "";
    message.sellPriceAmount = object.sellPriceAmount ?? "";
    message.senderTelNo = object.senderTelNo ?? "";
    message.cnsmPriceAmount = object.cnsmPriceAmount ?? "";
    message.sendRstCode = object.sendRstCode ?? "";
    message.pinStatusName = object.pinStatusName ?? "";
    message.mmsBrandThumImg = object.mmsBrandThumImg ?? "";
    message.brandName = object.brandName ?? "";
    message.sendRstMsg = object.sendRstMsg ?? "";
    message.correcDtm = object.correcDtm ?? "";
    message.recverTelNo = object.recverTelNo ?? "";
    message.expirationAt = object.expirationAt ?? "";
    message.sendBasicCode = object.sendBasicCode ?? "";
    message.sendStatusCode = object.sendStatusCode ?? "";
    return message;
  },
};

function createBaseListCouponDetailsResponse(): ListCouponDetailsResponse {
  return { code: 0, details: [] };
}

export const ListCouponDetailsResponse = {
  encode(message: ListCouponDetailsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.details) {
      CouponInfoDetails.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponDetailsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.details.push(CouponInfoDetails.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponDetailsResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      details: Array.isArray(object?.details) ? object.details.map((e: any) => CouponInfoDetails.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCouponDetailsResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.details?.length) {
      obj.details = message.details.map((e) => CouponInfoDetails.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponDetailsResponse>, I>>(base?: I): ListCouponDetailsResponse {
    return ListCouponDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponDetailsResponse>, I>>(object: I): ListCouponDetailsResponse {
    const message = createBaseListCouponDetailsResponse();
    message.code = object.code ?? 0;
    message.details = object.details?.map((e) => CouponInfoDetails.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListCouponDetailsRequest(): ListCouponDetailsRequest {
  return { did: "", couponTransactionIds: [] };
}

export const ListCouponDetailsRequest = {
  encode(message: ListCouponDetailsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.couponTransactionIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponDetailsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.couponTransactionIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponDetailsRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      couponTransactionIds: Array.isArray(object?.couponTransactionIds)
        ? object.couponTransactionIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ListCouponDetailsRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.couponTransactionIds?.length) {
      obj.couponTransactionIds = message.couponTransactionIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponDetailsRequest>, I>>(base?: I): ListCouponDetailsRequest {
    return ListCouponDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponDetailsRequest>, I>>(object: I): ListCouponDetailsRequest {
    const message = createBaseListCouponDetailsRequest();
    message.did = object.did ?? "";
    message.couponTransactionIds = object.couponTransactionIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseCouponInfo(): CouponInfo {
  return {
    did: "",
    encryptedPinCode: "",
    encryptedImageUrl: "",
    encryptedInfo: "",
    couponTransactionId: "",
    orderNo: "",
    price: 0,
    publishedAt: "",
    disposedAt: "",
    status: 0,
    cancelRequested: false,
    clientKeyMaterial: new Uint8Array(0),
    serverKeyMaterial: new Uint8Array(0),
    ephemeralPublicKey: new Uint8Array(0),
    goodsCode: "",
  };
}

export const CouponInfo = {
  encode(message: CouponInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.encryptedPinCode !== "") {
      writer.uint32(18).string(message.encryptedPinCode);
    }
    if (message.encryptedImageUrl !== "") {
      writer.uint32(26).string(message.encryptedImageUrl);
    }
    if (message.encryptedInfo !== "") {
      writer.uint32(34).string(message.encryptedInfo);
    }
    if (message.couponTransactionId !== "") {
      writer.uint32(42).string(message.couponTransactionId);
    }
    if (message.orderNo !== "") {
      writer.uint32(50).string(message.orderNo);
    }
    if (message.price !== 0) {
      writer.uint32(56).uint64(message.price);
    }
    if (message.publishedAt !== "") {
      writer.uint32(82).string(message.publishedAt);
    }
    if (message.disposedAt !== "") {
      writer.uint32(90).string(message.disposedAt);
    }
    if (message.status !== 0) {
      writer.uint32(96).int32(message.status);
    }
    if (message.cancelRequested === true) {
      writer.uint32(104).bool(message.cancelRequested);
    }
    if (message.clientKeyMaterial.length !== 0) {
      writer.uint32(162).bytes(message.clientKeyMaterial);
    }
    if (message.serverKeyMaterial.length !== 0) {
      writer.uint32(170).bytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      writer.uint32(178).bytes(message.ephemeralPublicKey);
    }
    if (message.goodsCode !== "") {
      writer.uint32(186).string(message.goodsCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CouponInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCouponInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.encryptedPinCode = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.encryptedImageUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.encryptedInfo = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.couponTransactionId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orderNo = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.price = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.publishedAt = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.disposedAt = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.cancelRequested = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.clientKeyMaterial = reader.bytes();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.serverKeyMaterial = reader.bytes();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.ephemeralPublicKey = reader.bytes();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.goodsCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CouponInfo {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      encryptedPinCode: isSet(object.encryptedPinCode) ? String(object.encryptedPinCode) : "",
      encryptedImageUrl: isSet(object.encryptedImageUrl) ? String(object.encryptedImageUrl) : "",
      encryptedInfo: isSet(object.encryptedInfo) ? String(object.encryptedInfo) : "",
      couponTransactionId: isSet(object.couponTransactionId) ? String(object.couponTransactionId) : "",
      orderNo: isSet(object.orderNo) ? String(object.orderNo) : "",
      price: isSet(object.price) ? Number(object.price) : 0,
      publishedAt: isSet(object.publishedAt) ? String(object.publishedAt) : "",
      disposedAt: isSet(object.disposedAt) ? String(object.disposedAt) : "",
      status: isSet(object.status) ? couponStatusFromJSON(object.status) : 0,
      cancelRequested: isSet(object.cancelRequested) ? Boolean(object.cancelRequested) : false,
      clientKeyMaterial: isSet(object.clientKeyMaterial)
        ? bytesFromBase64(object.clientKeyMaterial)
        : new Uint8Array(0),
      serverKeyMaterial: isSet(object.serverKeyMaterial)
        ? bytesFromBase64(object.serverKeyMaterial)
        : new Uint8Array(0),
      ephemeralPublicKey: isSet(object.ephemeralPublicKey)
        ? bytesFromBase64(object.ephemeralPublicKey)
        : new Uint8Array(0),
      goodsCode: isSet(object.goodsCode) ? String(object.goodsCode) : "",
    };
  },

  toJSON(message: CouponInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.encryptedPinCode !== "") {
      obj.encryptedPinCode = message.encryptedPinCode;
    }
    if (message.encryptedImageUrl !== "") {
      obj.encryptedImageUrl = message.encryptedImageUrl;
    }
    if (message.encryptedInfo !== "") {
      obj.encryptedInfo = message.encryptedInfo;
    }
    if (message.couponTransactionId !== "") {
      obj.couponTransactionId = message.couponTransactionId;
    }
    if (message.orderNo !== "") {
      obj.orderNo = message.orderNo;
    }
    if (message.price !== 0) {
      obj.price = Math.round(message.price);
    }
    if (message.publishedAt !== "") {
      obj.publishedAt = message.publishedAt;
    }
    if (message.disposedAt !== "") {
      obj.disposedAt = message.disposedAt;
    }
    if (message.status !== 0) {
      obj.status = couponStatusToJSON(message.status);
    }
    if (message.cancelRequested === true) {
      obj.cancelRequested = message.cancelRequested;
    }
    if (message.clientKeyMaterial.length !== 0) {
      obj.clientKeyMaterial = base64FromBytes(message.clientKeyMaterial);
    }
    if (message.serverKeyMaterial.length !== 0) {
      obj.serverKeyMaterial = base64FromBytes(message.serverKeyMaterial);
    }
    if (message.ephemeralPublicKey.length !== 0) {
      obj.ephemeralPublicKey = base64FromBytes(message.ephemeralPublicKey);
    }
    if (message.goodsCode !== "") {
      obj.goodsCode = message.goodsCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CouponInfo>, I>>(base?: I): CouponInfo {
    return CouponInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CouponInfo>, I>>(object: I): CouponInfo {
    const message = createBaseCouponInfo();
    message.did = object.did ?? "";
    message.encryptedPinCode = object.encryptedPinCode ?? "";
    message.encryptedImageUrl = object.encryptedImageUrl ?? "";
    message.encryptedInfo = object.encryptedInfo ?? "";
    message.couponTransactionId = object.couponTransactionId ?? "";
    message.orderNo = object.orderNo ?? "";
    message.price = object.price ?? 0;
    message.publishedAt = object.publishedAt ?? "";
    message.disposedAt = object.disposedAt ?? "";
    message.status = object.status ?? 0;
    message.cancelRequested = object.cancelRequested ?? false;
    message.clientKeyMaterial = object.clientKeyMaterial ?? new Uint8Array(0);
    message.serverKeyMaterial = object.serverKeyMaterial ?? new Uint8Array(0);
    message.ephemeralPublicKey = object.ephemeralPublicKey ?? new Uint8Array(0);
    message.goodsCode = object.goodsCode ?? "";
    return message;
  },
};

function createBaseListCouponResponse(): ListCouponResponse {
  return { code: 0, coupons: [] };
}

export const ListCouponResponse = {
  encode(message: ListCouponResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.coupons) {
      CouponInfo.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListCouponResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListCouponResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.coupons.push(CouponInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListCouponResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      coupons: Array.isArray(object?.coupons) ? object.coupons.map((e: any) => CouponInfo.fromJSON(e)) : [],
    };
  },

  toJSON(message: ListCouponResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.coupons?.length) {
      obj.coupons = message.coupons.map((e) => CouponInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListCouponResponse>, I>>(base?: I): ListCouponResponse {
    return ListCouponResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListCouponResponse>, I>>(object: I): ListCouponResponse {
    const message = createBaseListCouponResponse();
    message.code = object.code ?? 0;
    message.coupons = object.coupons?.map((e) => CouponInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSelfManagedAddress(): SelfManagedAddress {
  return { address: "", role: 0, createdAt: "", team: "", username: "" };
}

export const SelfManagedAddress = {
  encode(message: SelfManagedAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    if (message.createdAt !== "") {
      writer.uint32(26).string(message.createdAt);
    }
    if (message.team !== "") {
      writer.uint32(34).string(message.team);
    }
    if (message.username !== "") {
      writer.uint32(42).string(message.username);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelfManagedAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelfManagedAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.team = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.username = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SelfManagedAddress {
    return {
      address: isSet(object.address) ? String(object.address) : "",
      role: isSet(object.role) ? blockchainRoleFromJSON(object.role) : 0,
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
      team: isSet(object.team) ? String(object.team) : "",
      username: isSet(object.username) ? String(object.username) : "",
    };
  },

  toJSON(message: SelfManagedAddress): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.role !== 0) {
      obj.role = blockchainRoleToJSON(message.role);
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    if (message.team !== "") {
      obj.team = message.team;
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SelfManagedAddress>, I>>(base?: I): SelfManagedAddress {
    return SelfManagedAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SelfManagedAddress>, I>>(object: I): SelfManagedAddress {
    const message = createBaseSelfManagedAddress();
    message.address = object.address ?? "";
    message.role = object.role ?? 0;
    message.createdAt = object.createdAt ?? "";
    message.team = object.team ?? "";
    message.username = object.username ?? "";
    return message;
  },
};

function createBaseGetAllSelfManagedAddressesResponse(): GetAllSelfManagedAddressesResponse {
  return { selfManagedAddresses: [] };
}

export const GetAllSelfManagedAddressesResponse = {
  encode(message: GetAllSelfManagedAddressesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.selfManagedAddresses) {
      SelfManagedAddress.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllSelfManagedAddressesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllSelfManagedAddressesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.selfManagedAddresses.push(SelfManagedAddress.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllSelfManagedAddressesResponse {
    return {
      selfManagedAddresses: Array.isArray(object?.selfManagedAddresses)
        ? object.selfManagedAddresses.map((e: any) => SelfManagedAddress.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllSelfManagedAddressesResponse): unknown {
    const obj: any = {};
    if (message.selfManagedAddresses?.length) {
      obj.selfManagedAddresses = message.selfManagedAddresses.map((e) => SelfManagedAddress.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllSelfManagedAddressesResponse>, I>>(
    base?: I
  ): GetAllSelfManagedAddressesResponse {
    return GetAllSelfManagedAddressesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllSelfManagedAddressesResponse>, I>>(
    object: I
  ): GetAllSelfManagedAddressesResponse {
    const message = createBaseGetAllSelfManagedAddressesResponse();
    message.selfManagedAddresses = object.selfManagedAddresses?.map((e) => SelfManagedAddress.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOrUpdateSelfManagedWalletRequest(): CreateOrUpdateSelfManagedWalletRequest {
  return { wallet: undefined };
}

export const CreateOrUpdateSelfManagedWalletRequest = {
  encode(message: CreateOrUpdateSelfManagedWalletRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wallet !== undefined) {
      SelfManagedAddress.encode(message.wallet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateSelfManagedWalletRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateSelfManagedWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallet = SelfManagedAddress.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateSelfManagedWalletRequest {
    return { wallet: isSet(object.wallet) ? SelfManagedAddress.fromJSON(object.wallet) : undefined };
  },

  toJSON(message: CreateOrUpdateSelfManagedWalletRequest): unknown {
    const obj: any = {};
    if (message.wallet !== undefined) {
      obj.wallet = SelfManagedAddress.toJSON(message.wallet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletRequest>, I>>(
    base?: I
  ): CreateOrUpdateSelfManagedWalletRequest {
    return CreateOrUpdateSelfManagedWalletRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletRequest>, I>>(
    object: I
  ): CreateOrUpdateSelfManagedWalletRequest {
    const message = createBaseCreateOrUpdateSelfManagedWalletRequest();
    message.wallet =
      object.wallet !== undefined && object.wallet !== null ? SelfManagedAddress.fromPartial(object.wallet) : undefined;
    return message;
  },
};

function createBaseCreateOrUpdateSelfManagedWalletResponse(): CreateOrUpdateSelfManagedWalletResponse {
  return { code: 0 };
}

export const CreateOrUpdateSelfManagedWalletResponse = {
  encode(message: CreateOrUpdateSelfManagedWalletResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateSelfManagedWalletResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateSelfManagedWalletResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateSelfManagedWalletResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CreateOrUpdateSelfManagedWalletResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletResponse>, I>>(
    base?: I
  ): CreateOrUpdateSelfManagedWalletResponse {
    return CreateOrUpdateSelfManagedWalletResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateSelfManagedWalletResponse>, I>>(
    object: I
  ): CreateOrUpdateSelfManagedWalletResponse {
    const message = createBaseCreateOrUpdateSelfManagedWalletResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCreateWalletKeyPairRequest(): CreateWalletKeyPairRequest {
  return { did: "" };
}

export const CreateWalletKeyPairRequest = {
  encode(message: CreateWalletKeyPairRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWalletKeyPairRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWalletKeyPairRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWalletKeyPairRequest {
    return { did: isSet(object.did) ? String(object.did) : "" };
  },

  toJSON(message: CreateWalletKeyPairRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWalletKeyPairRequest>, I>>(base?: I): CreateWalletKeyPairRequest {
    return CreateWalletKeyPairRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateWalletKeyPairRequest>, I>>(object: I): CreateWalletKeyPairRequest {
    const message = createBaseCreateWalletKeyPairRequest();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseCreateWalletKeyPairResponse(): CreateWalletKeyPairResponse {
  return { isCreated: false, address: "" };
}

export const CreateWalletKeyPairResponse = {
  encode(message: CreateWalletKeyPairResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isCreated === true) {
      writer.uint32(8).bool(message.isCreated);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateWalletKeyPairResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateWalletKeyPairResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isCreated = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateWalletKeyPairResponse {
    return {
      isCreated: isSet(object.isCreated) ? Boolean(object.isCreated) : false,
      address: isSet(object.address) ? String(object.address) : "",
    };
  },

  toJSON(message: CreateWalletKeyPairResponse): unknown {
    const obj: any = {};
    if (message.isCreated === true) {
      obj.isCreated = message.isCreated;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateWalletKeyPairResponse>, I>>(base?: I): CreateWalletKeyPairResponse {
    return CreateWalletKeyPairResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateWalletKeyPairResponse>, I>>(object: I): CreateWalletKeyPairResponse {
    const message = createBaseCreateWalletKeyPairResponse();
    message.isCreated = object.isCreated ?? false;
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCompaniesResponse(): GetCompaniesResponse {
  return { companies: [] };
}

export const GetCompaniesResponse = {
  encode(message: GetCompaniesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.companies) {
      Companies.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCompaniesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCompaniesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.companies.push(Companies.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCompaniesResponse {
    return {
      companies: Array.isArray(object?.companies) ? object.companies.map((e: any) => Companies.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetCompaniesResponse): unknown {
    const obj: any = {};
    if (message.companies?.length) {
      obj.companies = message.companies.map((e) => Companies.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCompaniesResponse>, I>>(base?: I): GetCompaniesResponse {
    return GetCompaniesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCompaniesResponse>, I>>(object: I): GetCompaniesResponse {
    const message = createBaseGetCompaniesResponse();
    message.companies = object.companies?.map((e) => Companies.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCompanies(): Companies {
  return { did: "", name: "", address: "" };
}

export const Companies = {
  encode(message: Companies, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.address !== "") {
      writer.uint32(26).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Companies {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompanies();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Companies {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      name: isSet(object.name) ? String(object.name) : "",
      address: isSet(object.address) ? String(object.address) : "",
    };
  },

  toJSON(message: Companies): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.address !== "") {
      obj.address = message.address;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Companies>, I>>(base?: I): Companies {
    return Companies.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Companies>, I>>(object: I): Companies {
    const message = createBaseCompanies();
    message.did = object.did ?? "";
    message.name = object.name ?? "";
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseManagedAddress(): ManagedAddress {
  return { address: "", role: 0, publicKey: "", hostname: "", createdAt: "" };
}

export const ManagedAddress = {
  encode(message: ManagedAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.role !== 0) {
      writer.uint32(16).int32(message.role);
    }
    if (message.publicKey !== "") {
      writer.uint32(26).string(message.publicKey);
    }
    if (message.hostname !== "") {
      writer.uint32(34).string(message.hostname);
    }
    if (message.createdAt !== "") {
      writer.uint32(42).string(message.createdAt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.publicKey = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.hostname = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdAt = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedAddress {
    return {
      address: isSet(object.address) ? String(object.address) : "",
      role: isSet(object.role) ? blockchainRoleFromJSON(object.role) : 0,
      publicKey: isSet(object.publicKey) ? String(object.publicKey) : "",
      hostname: isSet(object.hostname) ? String(object.hostname) : "",
      createdAt: isSet(object.createdAt) ? String(object.createdAt) : "",
    };
  },

  toJSON(message: ManagedAddress): unknown {
    const obj: any = {};
    if (message.address !== "") {
      obj.address = message.address;
    }
    if (message.role !== 0) {
      obj.role = blockchainRoleToJSON(message.role);
    }
    if (message.publicKey !== "") {
      obj.publicKey = message.publicKey;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.createdAt !== "") {
      obj.createdAt = message.createdAt;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedAddress>, I>>(base?: I): ManagedAddress {
    return ManagedAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedAddress>, I>>(object: I): ManagedAddress {
    const message = createBaseManagedAddress();
    message.address = object.address ?? "";
    message.role = object.role ?? 0;
    message.publicKey = object.publicKey ?? "";
    message.hostname = object.hostname ?? "";
    message.createdAt = object.createdAt ?? "";
    return message;
  },
};

function createBaseGetAllManagedAddressesResponse(): GetAllManagedAddressesResponse {
  return { managedAddresses: [] };
}

export const GetAllManagedAddressesResponse = {
  encode(message: GetAllManagedAddressesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.managedAddresses) {
      ManagedAddress.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllManagedAddressesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllManagedAddressesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.managedAddresses.push(ManagedAddress.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetAllManagedAddressesResponse {
    return {
      managedAddresses: Array.isArray(object?.managedAddresses)
        ? object.managedAddresses.map((e: any) => ManagedAddress.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetAllManagedAddressesResponse): unknown {
    const obj: any = {};
    if (message.managedAddresses?.length) {
      obj.managedAddresses = message.managedAddresses.map((e) => ManagedAddress.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetAllManagedAddressesResponse>, I>>(base?: I): GetAllManagedAddressesResponse {
    return GetAllManagedAddressesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllManagedAddressesResponse>, I>>(
    object: I
  ): GetAllManagedAddressesResponse {
    const message = createBaseGetAllManagedAddressesResponse();
    message.managedAddresses = object.managedAddresses?.map((e) => ManagedAddress.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateOrUpdateManagedWalletRequest(): CreateOrUpdateManagedWalletRequest {
  return { wallet: undefined };
}

export const CreateOrUpdateManagedWalletRequest = {
  encode(message: CreateOrUpdateManagedWalletRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wallet !== undefined) {
      ManagedAddress.encode(message.wallet, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateManagedWalletRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateManagedWalletRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wallet = ManagedAddress.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateManagedWalletRequest {
    return { wallet: isSet(object.wallet) ? ManagedAddress.fromJSON(object.wallet) : undefined };
  },

  toJSON(message: CreateOrUpdateManagedWalletRequest): unknown {
    const obj: any = {};
    if (message.wallet !== undefined) {
      obj.wallet = ManagedAddress.toJSON(message.wallet);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletRequest>, I>>(
    base?: I
  ): CreateOrUpdateManagedWalletRequest {
    return CreateOrUpdateManagedWalletRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletRequest>, I>>(
    object: I
  ): CreateOrUpdateManagedWalletRequest {
    const message = createBaseCreateOrUpdateManagedWalletRequest();
    message.wallet =
      object.wallet !== undefined && object.wallet !== null ? ManagedAddress.fromPartial(object.wallet) : undefined;
    return message;
  },
};

function createBaseCreateOrUpdateManagedWalletResponse(): CreateOrUpdateManagedWalletResponse {
  return { code: 0 };
}

export const CreateOrUpdateManagedWalletResponse = {
  encode(message: CreateOrUpdateManagedWalletResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateOrUpdateManagedWalletResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateOrUpdateManagedWalletResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateOrUpdateManagedWalletResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: CreateOrUpdateManagedWalletResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletResponse>, I>>(
    base?: I
  ): CreateOrUpdateManagedWalletResponse {
    return CreateOrUpdateManagedWalletResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateOrUpdateManagedWalletResponse>, I>>(
    object: I
  ): CreateOrUpdateManagedWalletResponse {
    const message = createBaseCreateOrUpdateManagedWalletResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseDeclareRewardTransactionContentRequest(): DeclareRewardTransactionContentRequest {
  return { content: undefined, blockchainRequestId: new Uint8Array(0) };
}

export const DeclareRewardTransactionContentRequest = {
  encode(message: DeclareRewardTransactionContentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.content !== undefined) {
      RewardTransactionContent.encode(message.content, writer.uint32(10).fork()).ldelim();
    }
    if (message.blockchainRequestId.length !== 0) {
      writer.uint32(18).bytes(message.blockchainRequestId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeclareRewardTransactionContentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeclareRewardTransactionContentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = RewardTransactionContent.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.blockchainRequestId = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeclareRewardTransactionContentRequest {
    return {
      content: isSet(object.content) ? RewardTransactionContent.fromJSON(object.content) : undefined,
      blockchainRequestId: isSet(object.blockchainRequestId)
        ? bytesFromBase64(object.blockchainRequestId)
        : new Uint8Array(0),
    };
  },

  toJSON(message: DeclareRewardTransactionContentRequest): unknown {
    const obj: any = {};
    if (message.content !== undefined) {
      obj.content = RewardTransactionContent.toJSON(message.content);
    }
    if (message.blockchainRequestId.length !== 0) {
      obj.blockchainRequestId = base64FromBytes(message.blockchainRequestId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeclareRewardTransactionContentRequest>, I>>(
    base?: I
  ): DeclareRewardTransactionContentRequest {
    return DeclareRewardTransactionContentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeclareRewardTransactionContentRequest>, I>>(
    object: I
  ): DeclareRewardTransactionContentRequest {
    const message = createBaseDeclareRewardTransactionContentRequest();
    message.content =
      object.content !== undefined && object.content !== null
        ? RewardTransactionContent.fromPartial(object.content)
        : undefined;
    message.blockchainRequestId = object.blockchainRequestId ?? new Uint8Array(0);
    return message;
  },
};

function createBaseDeclareRewardTransactionContentResponse(): DeclareRewardTransactionContentResponse {
  return { code: 0 };
}

export const DeclareRewardTransactionContentResponse = {
  encode(message: DeclareRewardTransactionContentResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeclareRewardTransactionContentResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeclareRewardTransactionContentResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeclareRewardTransactionContentResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: DeclareRewardTransactionContentResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeclareRewardTransactionContentResponse>, I>>(
    base?: I
  ): DeclareRewardTransactionContentResponse {
    return DeclareRewardTransactionContentResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeclareRewardTransactionContentResponse>, I>>(
    object: I
  ): DeclareRewardTransactionContentResponse {
    const message = createBaseDeclareRewardTransactionContentResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseCreateSpecialProposalRequest(): CreateSpecialProposalRequest {
  return { proposal: undefined };
}

export const CreateSpecialProposalRequest = {
  encode(message: CreateSpecialProposalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposal !== undefined) {
      ProposalStatic.encode(message.proposal, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSpecialProposalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSpecialProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposal = ProposalStatic.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSpecialProposalRequest {
    return { proposal: isSet(object.proposal) ? ProposalStatic.fromJSON(object.proposal) : undefined };
  },

  toJSON(message: CreateSpecialProposalRequest): unknown {
    const obj: any = {};
    if (message.proposal !== undefined) {
      obj.proposal = ProposalStatic.toJSON(message.proposal);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSpecialProposalRequest>, I>>(base?: I): CreateSpecialProposalRequest {
    return CreateSpecialProposalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSpecialProposalRequest>, I>>(object: I): CreateSpecialProposalRequest {
    const message = createBaseCreateSpecialProposalRequest();
    message.proposal =
      object.proposal !== undefined && object.proposal !== null
        ? ProposalStatic.fromPartial(object.proposal)
        : undefined;
    return message;
  },
};

function createBaseCreateSpecialProposalResponse(): CreateSpecialProposalResponse {
  return { code: 0, proposalId: "" };
}

export const CreateSpecialProposalResponse = {
  encode(message: CreateSpecialProposalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateSpecialProposalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateSpecialProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateSpecialProposalResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
    };
  },

  toJSON(message: CreateSpecialProposalResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateSpecialProposalResponse>, I>>(base?: I): CreateSpecialProposalResponse {
    return CreateSpecialProposalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateSpecialProposalResponse>, I>>(
    object: I
  ): CreateSpecialProposalResponse {
    const message = createBaseCreateSpecialProposalResponse();
    message.code = object.code ?? 0;
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseInviteFriendHostRequest(): InviteFriendHostRequest {
  return { did: "", mid: "" };
}

export const InviteFriendHostRequest = {
  encode(message: InviteFriendHostRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendHostRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendHostRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendHostRequest {
    return { did: isSet(object.did) ? String(object.did) : "", mid: isSet(object.mid) ? String(object.mid) : "" };
  },

  toJSON(message: InviteFriendHostRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendHostRequest>, I>>(base?: I): InviteFriendHostRequest {
    return InviteFriendHostRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendHostRequest>, I>>(object: I): InviteFriendHostRequest {
    const message = createBaseInviteFriendHostRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    return message;
  },
};

function createBaseInviteFriendGuestRequest(): InviteFriendGuestRequest {
  return { did: "", mid: "", invitationCode: "", proposalId: "" };
}

export const InviteFriendGuestRequest = {
  encode(message: InviteFriendGuestRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    if (message.invitationCode !== "") {
      writer.uint32(26).string(message.invitationCode);
    }
    if (message.proposalId !== "") {
      writer.uint32(34).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendGuestRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendGuestRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.invitationCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendGuestRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      mid: isSet(object.mid) ? String(object.mid) : "",
      invitationCode: isSet(object.invitationCode) ? String(object.invitationCode) : "",
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
    };
  },

  toJSON(message: InviteFriendGuestRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    if (message.invitationCode !== "") {
      obj.invitationCode = message.invitationCode;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendGuestRequest>, I>>(base?: I): InviteFriendGuestRequest {
    return InviteFriendGuestRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendGuestRequest>, I>>(object: I): InviteFriendGuestRequest {
    const message = createBaseInviteFriendGuestRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    message.invitationCode = object.invitationCode ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseInviteFriendHostResponse(): InviteFriendHostResponse {
  return { statusCode: 0, message: undefined, invitationCode: undefined, usedCount: undefined };
}

export const InviteFriendHostResponse = {
  encode(message: InviteFriendHostResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(24).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(34).string(message.message);
    }
    if (message.invitationCode !== undefined) {
      writer.uint32(10).string(message.invitationCode);
    }
    if (message.usedCount !== undefined) {
      writer.uint32(16).uint32(message.usedCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendHostResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendHostResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.invitationCode = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.usedCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendHostResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? String(object.message) : undefined,
      invitationCode: isSet(object.invitationCode) ? String(object.invitationCode) : undefined,
      usedCount: isSet(object.usedCount) ? Number(object.usedCount) : undefined,
    };
  },

  toJSON(message: InviteFriendHostResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.invitationCode !== undefined) {
      obj.invitationCode = message.invitationCode;
    }
    if (message.usedCount !== undefined) {
      obj.usedCount = Math.round(message.usedCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendHostResponse>, I>>(base?: I): InviteFriendHostResponse {
    return InviteFriendHostResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendHostResponse>, I>>(object: I): InviteFriendHostResponse {
    const message = createBaseInviteFriendHostResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.invitationCode = object.invitationCode ?? undefined;
    message.usedCount = object.usedCount ?? undefined;
    return message;
  },
};

function createBaseInviteFriendGuestResponse(): InviteFriendGuestResponse {
  return {
    statusCode: 0,
    message: undefined,
    date: undefined,
    dueTo: undefined,
    tokenId: undefined,
    amount: undefined,
    invitationCode: undefined,
  };
}

export const InviteFriendGuestResponse = {
  encode(message: InviteFriendGuestResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(48).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(58).string(message.message);
    }
    if (message.date !== undefined) {
      writer.uint32(10).string(message.date);
    }
    if (message.dueTo !== undefined) {
      writer.uint32(18).string(message.dueTo);
    }
    if (message.tokenId !== undefined) {
      writer.uint32(26).string(message.tokenId);
    }
    if (message.amount !== undefined) {
      writer.uint32(32).uint32(message.amount);
    }
    if (message.invitationCode !== undefined) {
      writer.uint32(42).string(message.invitationCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteFriendGuestResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInviteFriendGuestResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 48) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.message = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dueTo = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.amount = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.invitationCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InviteFriendGuestResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? String(object.message) : undefined,
      date: isSet(object.date) ? String(object.date) : undefined,
      dueTo: isSet(object.dueTo) ? String(object.dueTo) : undefined,
      tokenId: isSet(object.tokenId) ? String(object.tokenId) : undefined,
      amount: isSet(object.amount) ? Number(object.amount) : undefined,
      invitationCode: isSet(object.invitationCode) ? String(object.invitationCode) : undefined,
    };
  },

  toJSON(message: InviteFriendGuestResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.date !== undefined) {
      obj.date = message.date;
    }
    if (message.dueTo !== undefined) {
      obj.dueTo = message.dueTo;
    }
    if (message.tokenId !== undefined) {
      obj.tokenId = message.tokenId;
    }
    if (message.amount !== undefined) {
      obj.amount = Math.round(message.amount);
    }
    if (message.invitationCode !== undefined) {
      obj.invitationCode = message.invitationCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InviteFriendGuestResponse>, I>>(base?: I): InviteFriendGuestResponse {
    return InviteFriendGuestResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InviteFriendGuestResponse>, I>>(object: I): InviteFriendGuestResponse {
    const message = createBaseInviteFriendGuestResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.date = object.date ?? undefined;
    message.dueTo = object.dueTo ?? undefined;
    message.tokenId = object.tokenId ?? undefined;
    message.amount = object.amount ?? undefined;
    message.invitationCode = object.invitationCode ?? undefined;
    return message;
  },
};

function createBaseAttendanceRollCallRequest(): AttendanceRollCallRequest {
  return { did: "", mid: "", proposalId: "" };
}

export const AttendanceRollCallRequest = {
  encode(message: AttendanceRollCallRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    if (message.proposalId !== "") {
      writer.uint32(26).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceRollCallRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceRollCallRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceRollCallRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      mid: isSet(object.mid) ? String(object.mid) : "",
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
    };
  },

  toJSON(message: AttendanceRollCallRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceRollCallRequest>, I>>(base?: I): AttendanceRollCallRequest {
    return AttendanceRollCallRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceRollCallRequest>, I>>(object: I): AttendanceRollCallRequest {
    const message = createBaseAttendanceRollCallRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseAttendanceSubmitRequest(): AttendanceSubmitRequest {
  return { did: "", mid: "", proposalId: "" };
}

export const AttendanceSubmitRequest = {
  encode(message: AttendanceSubmitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.mid !== "") {
      writer.uint32(18).string(message.mid);
    }
    if (message.proposalId !== "") {
      writer.uint32(26).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceSubmitRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceSubmitRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mid = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceSubmitRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      mid: isSet(object.mid) ? String(object.mid) : "",
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
    };
  },

  toJSON(message: AttendanceSubmitRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.mid !== "") {
      obj.mid = message.mid;
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceSubmitRequest>, I>>(base?: I): AttendanceSubmitRequest {
    return AttendanceSubmitRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceSubmitRequest>, I>>(object: I): AttendanceSubmitRequest {
    const message = createBaseAttendanceSubmitRequest();
    message.did = object.did ?? "";
    message.mid = object.mid ?? "";
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

function createBaseAttendanceRollCallResponse(): AttendanceRollCallResponse {
  return { statusCode: 0, message: undefined, dayofweeks: [], complete: undefined };
}

export const AttendanceRollCallResponse = {
  encode(message: AttendanceRollCallResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    writer.uint32(26).fork();
    for (const v of message.dayofweeks) {
      writer.bool(v);
    }
    writer.ldelim();
    if (message.complete !== undefined) {
      writer.uint32(32).bool(message.complete);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceRollCallResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceRollCallResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag === 24) {
            message.dayofweeks.push(reader.bool());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.dayofweeks.push(reader.bool());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.complete = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceRollCallResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? String(object.message) : undefined,
      dayofweeks: Array.isArray(object?.dayofweeks) ? object.dayofweeks.map((e: any) => Boolean(e)) : [],
      complete: isSet(object.complete) ? Boolean(object.complete) : undefined,
    };
  },

  toJSON(message: AttendanceRollCallResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.dayofweeks?.length) {
      obj.dayofweeks = message.dayofweeks;
    }
    if (message.complete !== undefined) {
      obj.complete = message.complete;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceRollCallResponse>, I>>(base?: I): AttendanceRollCallResponse {
    return AttendanceRollCallResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceRollCallResponse>, I>>(object: I): AttendanceRollCallResponse {
    const message = createBaseAttendanceRollCallResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.dayofweeks = object.dayofweeks?.map((e) => e) || [];
    message.complete = object.complete ?? undefined;
    return message;
  },
};

function createBaseAttendanceSubmitResponse(): AttendanceSubmitResponse {
  return { statusCode: 0, message: undefined, reward: undefined };
}

export const AttendanceSubmitResponse = {
  encode(message: AttendanceSubmitResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.message !== undefined) {
      writer.uint32(18).string(message.message);
    }
    if (message.reward !== undefined) {
      writer.uint32(24).uint32(message.reward);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AttendanceSubmitResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttendanceSubmitResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reward = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttendanceSubmitResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      message: isSet(object.message) ? String(object.message) : undefined,
      reward: isSet(object.reward) ? Number(object.reward) : undefined,
    };
  },

  toJSON(message: AttendanceSubmitResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.message !== undefined) {
      obj.message = message.message;
    }
    if (message.reward !== undefined) {
      obj.reward = Math.round(message.reward);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AttendanceSubmitResponse>, I>>(base?: I): AttendanceSubmitResponse {
    return AttendanceSubmitResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AttendanceSubmitResponse>, I>>(object: I): AttendanceSubmitResponse {
    const message = createBaseAttendanceSubmitResponse();
    message.statusCode = object.statusCode ?? 0;
    message.message = object.message ?? undefined;
    message.reward = object.reward ?? undefined;
    return message;
  },
};

function createBaseLikeGoodsResponse(): LikeGoodsResponse {
  return { code: 0 };
}

export const LikeGoodsResponse = {
  encode(message: LikeGoodsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LikeGoodsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLikeGoodsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LikeGoodsResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: LikeGoodsResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LikeGoodsResponse>, I>>(base?: I): LikeGoodsResponse {
    return LikeGoodsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LikeGoodsResponse>, I>>(object: I): LikeGoodsResponse {
    const message = createBaseLikeGoodsResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseLikeGoodsRequest(): LikeGoodsRequest {
  return { did: "", goodsCodes: [] };
}

export const LikeGoodsRequest = {
  encode(message: LikeGoodsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    for (const v of message.goodsCodes) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LikeGoodsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLikeGoodsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.goodsCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LikeGoodsRequest {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      goodsCodes: Array.isArray(object?.goodsCodes) ? object.goodsCodes.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: LikeGoodsRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.goodsCodes?.length) {
      obj.goodsCodes = message.goodsCodes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LikeGoodsRequest>, I>>(base?: I): LikeGoodsRequest {
    return LikeGoodsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LikeGoodsRequest>, I>>(object: I): LikeGoodsRequest {
    const message = createBaseLikeGoodsRequest();
    message.did = object.did ?? "";
    message.goodsCodes = object.goodsCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseListGoodsLikeResponse(): ListGoodsLikeResponse {
  return { code: 0, goodsCodes: [] };
}

export const ListGoodsLikeResponse = {
  encode(message: ListGoodsLikeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    for (const v of message.goodsCodes) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGoodsLikeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGoodsLikeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.goodsCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGoodsLikeResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      goodsCodes: Array.isArray(object?.goodsCodes) ? object.goodsCodes.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: ListGoodsLikeResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.goodsCodes?.length) {
      obj.goodsCodes = message.goodsCodes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGoodsLikeResponse>, I>>(base?: I): ListGoodsLikeResponse {
    return ListGoodsLikeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGoodsLikeResponse>, I>>(object: I): ListGoodsLikeResponse {
    const message = createBaseListGoodsLikeResponse();
    message.code = object.code ?? 0;
    message.goodsCodes = object.goodsCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseListGoodsLikeRequest(): ListGoodsLikeRequest {
  return { did: "" };
}

export const ListGoodsLikeRequest = {
  encode(message: ListGoodsLikeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListGoodsLikeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListGoodsLikeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListGoodsLikeRequest {
    return { did: isSet(object.did) ? String(object.did) : "" };
  },

  toJSON(message: ListGoodsLikeRequest): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListGoodsLikeRequest>, I>>(base?: I): ListGoodsLikeRequest {
    return ListGoodsLikeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListGoodsLikeRequest>, I>>(object: I): ListGoodsLikeRequest {
    const message = createBaseListGoodsLikeRequest();
    message.did = object.did ?? "";
    return message;
  },
};

function createBaseRegisterDidFilteringRequest(): RegisterDidFilteringRequest {
  return { proposalId: "", mode: 0, didFilter: [], proposalFilter: [] };
}

export const RegisterDidFilteringRequest = {
  encode(message: RegisterDidFilteringRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalId !== "") {
      writer.uint32(10).string(message.proposalId);
    }
    if (message.mode !== 0) {
      writer.uint32(16).int32(message.mode);
    }
    for (const v of message.didFilter) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.proposalFilter) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDidFilteringRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDidFilteringRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.mode = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.didFilter.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proposalFilter.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDidFilteringRequest {
    return {
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
      mode: isSet(object.mode) ? didFilteringModeFromJSON(object.mode) : 0,
      didFilter: Array.isArray(object?.didFilter) ? object.didFilter.map((e: any) => String(e)) : [],
      proposalFilter: Array.isArray(object?.proposalFilter) ? object.proposalFilter.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: RegisterDidFilteringRequest): unknown {
    const obj: any = {};
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    if (message.mode !== 0) {
      obj.mode = didFilteringModeToJSON(message.mode);
    }
    if (message.didFilter?.length) {
      obj.didFilter = message.didFilter;
    }
    if (message.proposalFilter?.length) {
      obj.proposalFilter = message.proposalFilter;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDidFilteringRequest>, I>>(base?: I): RegisterDidFilteringRequest {
    return RegisterDidFilteringRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDidFilteringRequest>, I>>(object: I): RegisterDidFilteringRequest {
    const message = createBaseRegisterDidFilteringRequest();
    message.proposalId = object.proposalId ?? "";
    message.mode = object.mode ?? 0;
    message.didFilter = object.didFilter?.map((e) => e) || [];
    message.proposalFilter = object.proposalFilter?.map((e) => e) || [];
    return message;
  },
};

function createBaseRegisterDidFilteringResponse(): RegisterDidFilteringResponse {
  return { statusCode: 0, filteringId: 0 };
}

export const RegisterDidFilteringResponse = {
  encode(message: RegisterDidFilteringResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.filteringId !== 0) {
      writer.uint32(16).uint64(message.filteringId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterDidFilteringResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterDidFilteringResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.filteringId = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterDidFilteringResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      filteringId: isSet(object.filteringId) ? Number(object.filteringId) : 0,
    };
  },

  toJSON(message: RegisterDidFilteringResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.filteringId !== 0) {
      obj.filteringId = Math.round(message.filteringId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterDidFilteringResponse>, I>>(base?: I): RegisterDidFilteringResponse {
    return RegisterDidFilteringResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterDidFilteringResponse>, I>>(object: I): RegisterDidFilteringResponse {
    const message = createBaseRegisterDidFilteringResponse();
    message.statusCode = object.statusCode ?? 0;
    message.filteringId = object.filteringId ?? 0;
    return message;
  },
};

function createBaseSimpleUserInfo(): SimpleUserInfo {
  return { did: "", userType: 0, besuAddress: undefined };
}

export const SimpleUserInfo = {
  encode(message: SimpleUserInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.did !== "") {
      writer.uint32(10).string(message.did);
    }
    if (message.userType !== 0) {
      writer.uint32(16).int32(message.userType);
    }
    if (message.besuAddress !== undefined) {
      writer.uint32(26).string(message.besuAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimpleUserInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimpleUserInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.did = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.userType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.besuAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SimpleUserInfo {
    return {
      did: isSet(object.did) ? String(object.did) : "",
      userType: isSet(object.userType) ? simpleUserTypeFromJSON(object.userType) : 0,
      besuAddress: isSet(object.besuAddress) ? String(object.besuAddress) : undefined,
    };
  },

  toJSON(message: SimpleUserInfo): unknown {
    const obj: any = {};
    if (message.did !== "") {
      obj.did = message.did;
    }
    if (message.userType !== 0) {
      obj.userType = simpleUserTypeToJSON(message.userType);
    }
    if (message.besuAddress !== undefined) {
      obj.besuAddress = message.besuAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SimpleUserInfo>, I>>(base?: I): SimpleUserInfo {
    return SimpleUserInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SimpleUserInfo>, I>>(object: I): SimpleUserInfo {
    const message = createBaseSimpleUserInfo();
    message.did = object.did ?? "";
    message.userType = object.userType ?? 0;
    message.besuAddress = object.besuAddress ?? undefined;
    return message;
  },
};

function createBaseLookupUsersRequest(): LookupUsersRequest {
  return { dids: [] };
}

export const LookupUsersRequest = {
  encode(message: LookupUsersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dids) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupUsersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupUsersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dids.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupUsersRequest {
    return { dids: Array.isArray(object?.dids) ? object.dids.map((e: any) => String(e)) : [] };
  },

  toJSON(message: LookupUsersRequest): unknown {
    const obj: any = {};
    if (message.dids?.length) {
      obj.dids = message.dids;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupUsersRequest>, I>>(base?: I): LookupUsersRequest {
    return LookupUsersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupUsersRequest>, I>>(object: I): LookupUsersRequest {
    const message = createBaseLookupUsersRequest();
    message.dids = object.dids?.map((e) => e) || [];
    return message;
  },
};

function createBaseLookupUsersResponse(): LookupUsersResponse {
  return { users: [] };
}

export const LookupUsersResponse = {
  encode(message: LookupUsersResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      SimpleUserInfo.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LookupUsersResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLookupUsersResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(SimpleUserInfo.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LookupUsersResponse {
    return { users: Array.isArray(object?.users) ? object.users.map((e: any) => SimpleUserInfo.fromJSON(e)) : [] };
  },

  toJSON(message: LookupUsersResponse): unknown {
    const obj: any = {};
    if (message.users?.length) {
      obj.users = message.users.map((e) => SimpleUserInfo.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LookupUsersResponse>, I>>(base?: I): LookupUsersResponse {
    return LookupUsersResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LookupUsersResponse>, I>>(object: I): LookupUsersResponse {
    const message = createBaseLookupUsersResponse();
    message.users = object.users?.map((e) => SimpleUserInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkMintRequest(): BulkMintRequest {
  return { reqs: [] };
}

export const BulkMintRequest = {
  encode(message: BulkMintRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.reqs) {
      DeclareRewardTransactionContentRequest.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkMintRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkMintRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.reqs.push(DeclareRewardTransactionContentRequest.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkMintRequest {
    return {
      reqs: Array.isArray(object?.reqs)
        ? object.reqs.map((e: any) => DeclareRewardTransactionContentRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkMintRequest): unknown {
    const obj: any = {};
    if (message.reqs?.length) {
      obj.reqs = message.reqs.map((e) => DeclareRewardTransactionContentRequest.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkMintRequest>, I>>(base?: I): BulkMintRequest {
    return BulkMintRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkMintRequest>, I>>(object: I): BulkMintRequest {
    const message = createBaseBulkMintRequest();
    message.reqs = object.reqs?.map((e) => DeclareRewardTransactionContentRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkMintResponse(): BulkMintResponse {
  return { resps: [] };
}

export const BulkMintResponse = {
  encode(message: BulkMintResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.resps) {
      DeclareRewardTransactionContentResponse.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BulkMintResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkMintResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.resps.push(DeclareRewardTransactionContentResponse.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BulkMintResponse {
    return {
      resps: Array.isArray(object?.resps)
        ? object.resps.map((e: any) => DeclareRewardTransactionContentResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BulkMintResponse): unknown {
    const obj: any = {};
    if (message.resps?.length) {
      obj.resps = message.resps.map((e) => DeclareRewardTransactionContentResponse.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BulkMintResponse>, I>>(base?: I): BulkMintResponse {
    return BulkMintResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkMintResponse>, I>>(object: I): BulkMintResponse {
    const message = createBaseBulkMintResponse();
    message.resps = object.resps?.map((e) => DeclareRewardTransactionContentResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHomeBannerCard(): HomeBannerCard {
  return {
    type: 0,
    enabled: false,
    supportedPlatforms: 0,
    title: "",
    bannerImageUrl: undefined,
    landingPageUrl: undefined,
    notBefore: undefined,
    notAfter: undefined,
    requiresAccessTokenInGetMethod: undefined,
    proposalId: undefined,
    purpose: undefined,
  };
}

export const HomeBannerCard = {
  encode(message: HomeBannerCard, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.enabled === true) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.supportedPlatforms !== 0) {
      writer.uint32(24).int32(message.supportedPlatforms);
    }
    if (message.title !== "") {
      writer.uint32(34).string(message.title);
    }
    if (message.bannerImageUrl !== undefined) {
      writer.uint32(82).string(message.bannerImageUrl);
    }
    if (message.landingPageUrl !== undefined) {
      writer.uint32(90).string(message.landingPageUrl);
    }
    if (message.notBefore !== undefined) {
      writer.uint32(106).string(message.notBefore);
    }
    if (message.notAfter !== undefined) {
      writer.uint32(114).string(message.notAfter);
    }
    if (message.requiresAccessTokenInGetMethod !== undefined) {
      writer.uint32(96).bool(message.requiresAccessTokenInGetMethod);
    }
    if (message.proposalId !== undefined) {
      writer.uint32(162).string(message.proposalId);
    }
    if (message.purpose !== undefined) {
      writer.uint32(240).uint32(message.purpose);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerCard {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerCard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.supportedPlatforms = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.title = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.bannerImageUrl = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.landingPageUrl = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.notBefore = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.notAfter = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.requiresAccessTokenInGetMethod = reader.bool();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.proposalId = reader.string();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.purpose = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerCard {
    return {
      type: isSet(object.type) ? homeBannerCardTypeFromJSON(object.type) : 0,
      enabled: isSet(object.enabled) ? Boolean(object.enabled) : false,
      supportedPlatforms: isSet(object.supportedPlatforms)
        ? homeBannerSupportedPlatformFromJSON(object.supportedPlatforms)
        : 0,
      title: isSet(object.title) ? String(object.title) : "",
      bannerImageUrl: isSet(object.bannerImageUrl) ? String(object.bannerImageUrl) : undefined,
      landingPageUrl: isSet(object.landingPageUrl) ? String(object.landingPageUrl) : undefined,
      notBefore: isSet(object.notBefore) ? String(object.notBefore) : undefined,
      notAfter: isSet(object.notAfter) ? String(object.notAfter) : undefined,
      requiresAccessTokenInGetMethod: isSet(object.requiresAccessTokenInGetMethod)
        ? Boolean(object.requiresAccessTokenInGetMethod)
        : undefined,
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : undefined,
      purpose: isSet(object.purpose) ? Number(object.purpose) : undefined,
    };
  },

  toJSON(message: HomeBannerCard): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = homeBannerCardTypeToJSON(message.type);
    }
    if (message.enabled === true) {
      obj.enabled = message.enabled;
    }
    if (message.supportedPlatforms !== 0) {
      obj.supportedPlatforms = homeBannerSupportedPlatformToJSON(message.supportedPlatforms);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.bannerImageUrl !== undefined) {
      obj.bannerImageUrl = message.bannerImageUrl;
    }
    if (message.landingPageUrl !== undefined) {
      obj.landingPageUrl = message.landingPageUrl;
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore;
    }
    if (message.notAfter !== undefined) {
      obj.notAfter = message.notAfter;
    }
    if (message.requiresAccessTokenInGetMethod !== undefined) {
      obj.requiresAccessTokenInGetMethod = message.requiresAccessTokenInGetMethod;
    }
    if (message.proposalId !== undefined) {
      obj.proposalId = message.proposalId;
    }
    if (message.purpose !== undefined) {
      obj.purpose = Math.round(message.purpose);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerCard>, I>>(base?: I): HomeBannerCard {
    return HomeBannerCard.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerCard>, I>>(object: I): HomeBannerCard {
    const message = createBaseHomeBannerCard();
    message.type = object.type ?? 0;
    message.enabled = object.enabled ?? false;
    message.supportedPlatforms = object.supportedPlatforms ?? 0;
    message.title = object.title ?? "";
    message.bannerImageUrl = object.bannerImageUrl ?? undefined;
    message.landingPageUrl = object.landingPageUrl ?? undefined;
    message.notBefore = object.notBefore ?? undefined;
    message.notAfter = object.notAfter ?? undefined;
    message.requiresAccessTokenInGetMethod = object.requiresAccessTokenInGetMethod ?? undefined;
    message.proposalId = object.proposalId ?? undefined;
    message.purpose = object.purpose ?? undefined;
    return message;
  },
};

function createBaseHomeBannerConfig(): HomeBannerConfig {
  return { cards: [] };
}

export const HomeBannerConfig = {
  encode(message: HomeBannerConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cards) {
      HomeBannerCard.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cards.push(HomeBannerCard.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerConfig {
    return { cards: Array.isArray(object?.cards) ? object.cards.map((e: any) => HomeBannerCard.fromJSON(e)) : [] };
  },

  toJSON(message: HomeBannerConfig): unknown {
    const obj: any = {};
    if (message.cards?.length) {
      obj.cards = message.cards.map((e) => HomeBannerCard.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerConfig>, I>>(base?: I): HomeBannerConfig {
    return HomeBannerConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerConfig>, I>>(object: I): HomeBannerConfig {
    const message = createBaseHomeBannerConfig();
    message.cards = object.cards?.map((e) => HomeBannerCard.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHomeBannerSaveRequest(): HomeBannerSaveRequest {
  return { bannerNo: undefined, config: undefined, setReadOnly: false, setHidden: false };
}

export const HomeBannerSaveRequest = {
  encode(message: HomeBannerSaveRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bannerNo !== undefined) {
      writer.uint32(8).uint64(message.bannerNo);
    }
    if (message.config !== undefined) {
      HomeBannerConfig.encode(message.config, writer.uint32(18).fork()).ldelim();
    }
    if (message.setReadOnly === true) {
      writer.uint32(24).bool(message.setReadOnly);
    }
    if (message.setHidden === true) {
      writer.uint32(32).bool(message.setHidden);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerSaveRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerSaveRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.config = HomeBannerConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.setReadOnly = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.setHidden = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerSaveRequest {
    return {
      bannerNo: isSet(object.bannerNo) ? Number(object.bannerNo) : undefined,
      config: isSet(object.config) ? HomeBannerConfig.fromJSON(object.config) : undefined,
      setReadOnly: isSet(object.setReadOnly) ? Boolean(object.setReadOnly) : false,
      setHidden: isSet(object.setHidden) ? Boolean(object.setHidden) : false,
    };
  },

  toJSON(message: HomeBannerSaveRequest): unknown {
    const obj: any = {};
    if (message.bannerNo !== undefined) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    if (message.config !== undefined) {
      obj.config = HomeBannerConfig.toJSON(message.config);
    }
    if (message.setReadOnly === true) {
      obj.setReadOnly = message.setReadOnly;
    }
    if (message.setHidden === true) {
      obj.setHidden = message.setHidden;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerSaveRequest>, I>>(base?: I): HomeBannerSaveRequest {
    return HomeBannerSaveRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerSaveRequest>, I>>(object: I): HomeBannerSaveRequest {
    const message = createBaseHomeBannerSaveRequest();
    message.bannerNo = object.bannerNo ?? undefined;
    message.config =
      object.config !== undefined && object.config !== null ? HomeBannerConfig.fromPartial(object.config) : undefined;
    message.setReadOnly = object.setReadOnly ?? false;
    message.setHidden = object.setHidden ?? false;
    return message;
  },
};

function createBaseHomeBannerSaveResponse(): HomeBannerSaveResponse {
  return { code: 0, bannerNo: 0 };
}

export const HomeBannerSaveResponse = {
  encode(message: HomeBannerSaveResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    if (message.bannerNo !== 0) {
      writer.uint32(16).uint64(message.bannerNo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerSaveResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerSaveResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerSaveResponse {
    return {
      code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0,
      bannerNo: isSet(object.bannerNo) ? Number(object.bannerNo) : 0,
    };
  },

  toJSON(message: HomeBannerSaveResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    if (message.bannerNo !== 0) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerSaveResponse>, I>>(base?: I): HomeBannerSaveResponse {
    return HomeBannerSaveResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerSaveResponse>, I>>(object: I): HomeBannerSaveResponse {
    const message = createBaseHomeBannerSaveResponse();
    message.code = object.code ?? 0;
    message.bannerNo = object.bannerNo ?? 0;
    return message;
  },
};

function createBaseSavedHomeBannerConfig(): SavedHomeBannerConfig {
  return { bannerNo: 0, isReadOnly: false, isHidden: false, createdBy: "", config: undefined };
}

export const SavedHomeBannerConfig = {
  encode(message: SavedHomeBannerConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bannerNo !== 0) {
      writer.uint32(8).uint64(message.bannerNo);
    }
    if (message.isReadOnly === true) {
      writer.uint32(16).bool(message.isReadOnly);
    }
    if (message.isHidden === true) {
      writer.uint32(24).bool(message.isHidden);
    }
    if (message.createdBy !== "") {
      writer.uint32(34).string(message.createdBy);
    }
    if (message.config !== undefined) {
      HomeBannerConfig.encode(message.config, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SavedHomeBannerConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSavedHomeBannerConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isReadOnly = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.isHidden = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.config = HomeBannerConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SavedHomeBannerConfig {
    return {
      bannerNo: isSet(object.bannerNo) ? Number(object.bannerNo) : 0,
      isReadOnly: isSet(object.isReadOnly) ? Boolean(object.isReadOnly) : false,
      isHidden: isSet(object.isHidden) ? Boolean(object.isHidden) : false,
      createdBy: isSet(object.createdBy) ? String(object.createdBy) : "",
      config: isSet(object.config) ? HomeBannerConfig.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: SavedHomeBannerConfig): unknown {
    const obj: any = {};
    if (message.bannerNo !== 0) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    if (message.isReadOnly === true) {
      obj.isReadOnly = message.isReadOnly;
    }
    if (message.isHidden === true) {
      obj.isHidden = message.isHidden;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.config !== undefined) {
      obj.config = HomeBannerConfig.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SavedHomeBannerConfig>, I>>(base?: I): SavedHomeBannerConfig {
    return SavedHomeBannerConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SavedHomeBannerConfig>, I>>(object: I): SavedHomeBannerConfig {
    const message = createBaseSavedHomeBannerConfig();
    message.bannerNo = object.bannerNo ?? 0;
    message.isReadOnly = object.isReadOnly ?? false;
    message.isHidden = object.isHidden ?? false;
    message.createdBy = object.createdBy ?? "";
    message.config =
      object.config !== undefined && object.config !== null ? HomeBannerConfig.fromPartial(object.config) : undefined;
    return message;
  },
};

function createBaseHomeBannerListRequest(): HomeBannerListRequest {
  return { pageSize: 0, pageNum: 0 };
}

export const HomeBannerListRequest = {
  encode(message: HomeBannerListRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).uint32(message.pageSize);
    }
    if (message.pageNum !== 0) {
      writer.uint32(16).uint32(message.pageNum);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerListRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerListRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.pageNum = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerListRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageNum: isSet(object.pageNum) ? Number(object.pageNum) : 0,
    };
  },

  toJSON(message: HomeBannerListRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNum !== 0) {
      obj.pageNum = Math.round(message.pageNum);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerListRequest>, I>>(base?: I): HomeBannerListRequest {
    return HomeBannerListRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerListRequest>, I>>(object: I): HomeBannerListRequest {
    const message = createBaseHomeBannerListRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageNum = object.pageNum ?? 0;
    return message;
  },
};

function createBaseHomeBannerListResponse(): HomeBannerListResponse {
  return { configNumbers: [] };
}

export const HomeBannerListResponse = {
  encode(message: HomeBannerListResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.configNumbers) {
      SavedHomeBannerConfig.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerListResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerListResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.configNumbers.push(SavedHomeBannerConfig.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerListResponse {
    return {
      configNumbers: Array.isArray(object?.configNumbers)
        ? object.configNumbers.map((e: any) => SavedHomeBannerConfig.fromJSON(e))
        : [],
    };
  },

  toJSON(message: HomeBannerListResponse): unknown {
    const obj: any = {};
    if (message.configNumbers?.length) {
      obj.configNumbers = message.configNumbers.map((e) => SavedHomeBannerConfig.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerListResponse>, I>>(base?: I): HomeBannerListResponse {
    return HomeBannerListResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerListResponse>, I>>(object: I): HomeBannerListResponse {
    const message = createBaseHomeBannerListResponse();
    message.configNumbers = object.configNumbers?.map((e) => SavedHomeBannerConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHomeBannerPublishRequest(): HomeBannerPublishRequest {
  return { bannerNo: 0 };
}

export const HomeBannerPublishRequest = {
  encode(message: HomeBannerPublishRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bannerNo !== 0) {
      writer.uint32(8).uint64(message.bannerNo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerPublishRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerPublishRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.bannerNo = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerPublishRequest {
    return { bannerNo: isSet(object.bannerNo) ? Number(object.bannerNo) : 0 };
  },

  toJSON(message: HomeBannerPublishRequest): unknown {
    const obj: any = {};
    if (message.bannerNo !== 0) {
      obj.bannerNo = Math.round(message.bannerNo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerPublishRequest>, I>>(base?: I): HomeBannerPublishRequest {
    return HomeBannerPublishRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerPublishRequest>, I>>(object: I): HomeBannerPublishRequest {
    const message = createBaseHomeBannerPublishRequest();
    message.bannerNo = object.bannerNo ?? 0;
    return message;
  },
};

function createBaseHomeBannerPublishResponse(): HomeBannerPublishResponse {
  return { code: 0 };
}

export const HomeBannerPublishResponse = {
  encode(message: HomeBannerPublishResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.code !== 0) {
      writer.uint32(8).int32(message.code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HomeBannerPublishResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHomeBannerPublishResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.code = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HomeBannerPublishResponse {
    return { code: isSet(object.code) ? commonStatusCodeFromJSON(object.code) : 0 };
  },

  toJSON(message: HomeBannerPublishResponse): unknown {
    const obj: any = {};
    if (message.code !== 0) {
      obj.code = commonStatusCodeToJSON(message.code);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HomeBannerPublishResponse>, I>>(base?: I): HomeBannerPublishResponse {
    return HomeBannerPublishResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HomeBannerPublishResponse>, I>>(object: I): HomeBannerPublishResponse {
    const message = createBaseHomeBannerPublishResponse();
    message.code = object.code ?? 0;
    return message;
  },
};

function createBaseBurnPointResponse(): BurnPointResponse {
  return { burnInfo: {} };
}

export const BurnPointResponse = {
  encode(message: BurnPointResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.burnInfo).forEach(([key, value]) => {
      BurnPointResponse_BurnInfoEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = BurnPointResponse_BurnInfoEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.burnInfo[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointResponse {
    return {
      burnInfo: isObject(object.burnInfo)
        ? Object.entries(object.burnInfo).reduce<{ [key: string]: number }>((acc, [key, value]) => {
            acc[key] = Number(value);
            return acc;
          }, {})
        : {},
    };
  },

  toJSON(message: BurnPointResponse): unknown {
    const obj: any = {};
    if (message.burnInfo) {
      const entries = Object.entries(message.burnInfo);
      if (entries.length > 0) {
        obj.burnInfo = {};
        entries.forEach(([k, v]) => {
          obj.burnInfo[k] = Math.round(v);
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointResponse>, I>>(base?: I): BurnPointResponse {
    return BurnPointResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointResponse>, I>>(object: I): BurnPointResponse {
    const message = createBaseBurnPointResponse();
    message.burnInfo = Object.entries(object.burnInfo ?? {}).reduce<{ [key: string]: number }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Number(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseBurnPointResponse_BurnInfoEntry(): BurnPointResponse_BurnInfoEntry {
  return { key: "", value: 0 };
}

export const BurnPointResponse_BurnInfoEntry = {
  encode(message: BurnPointResponse_BurnInfoEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(16).int64(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BurnPointResponse_BurnInfoEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBurnPointResponse_BurnInfoEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.value = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BurnPointResponse_BurnInfoEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: BurnPointResponse_BurnInfoEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = Math.round(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BurnPointResponse_BurnInfoEntry>, I>>(base?: I): BurnPointResponse_BurnInfoEntry {
    return BurnPointResponse_BurnInfoEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BurnPointResponse_BurnInfoEntry>, I>>(
    object: I
  ): BurnPointResponse_BurnInfoEntry {
    const message = createBaseBurnPointResponse_BurnInfoEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseRegisterProposalRequest(): RegisterProposalRequest {
  return { proposalRequestId: 0, proposalStatic: undefined };
}

export const RegisterProposalRequest = {
  encode(message: RegisterProposalRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proposalRequestId !== 0) {
      writer.uint32(8).uint64(message.proposalRequestId);
    }
    if (message.proposalStatic !== undefined) {
      ProposalStatic.encode(message.proposalStatic, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterProposalRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterProposalRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.proposalRequestId = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalStatic = ProposalStatic.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterProposalRequest {
    return {
      proposalRequestId: isSet(object.proposalRequestId) ? Number(object.proposalRequestId) : 0,
      proposalStatic: isSet(object.proposalStatic) ? ProposalStatic.fromJSON(object.proposalStatic) : undefined,
    };
  },

  toJSON(message: RegisterProposalRequest): unknown {
    const obj: any = {};
    if (message.proposalRequestId !== 0) {
      obj.proposalRequestId = Math.round(message.proposalRequestId);
    }
    if (message.proposalStatic !== undefined) {
      obj.proposalStatic = ProposalStatic.toJSON(message.proposalStatic);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterProposalRequest>, I>>(base?: I): RegisterProposalRequest {
    return RegisterProposalRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterProposalRequest>, I>>(object: I): RegisterProposalRequest {
    const message = createBaseRegisterProposalRequest();
    message.proposalRequestId = object.proposalRequestId ?? 0;
    message.proposalStatic =
      object.proposalStatic !== undefined && object.proposalStatic !== null
        ? ProposalStatic.fromPartial(object.proposalStatic)
        : undefined;
    return message;
  },
};

function createBaseRegisterProposalResponse(): RegisterProposalResponse {
  return { statusCode: 0, proposalId: "" };
}

export const RegisterProposalResponse = {
  encode(message: RegisterProposalResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.statusCode !== 0) {
      writer.uint32(8).int32(message.statusCode);
    }
    if (message.proposalId !== "") {
      writer.uint32(18).string(message.proposalId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RegisterProposalResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRegisterProposalResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.statusCode = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.proposalId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RegisterProposalResponse {
    return {
      statusCode: isSet(object.statusCode) ? commonStatusCodeFromJSON(object.statusCode) : 0,
      proposalId: isSet(object.proposalId) ? String(object.proposalId) : "",
    };
  },

  toJSON(message: RegisterProposalResponse): unknown {
    const obj: any = {};
    if (message.statusCode !== 0) {
      obj.statusCode = commonStatusCodeToJSON(message.statusCode);
    }
    if (message.proposalId !== "") {
      obj.proposalId = message.proposalId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RegisterProposalResponse>, I>>(base?: I): RegisterProposalResponse {
    return RegisterProposalResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RegisterProposalResponse>, I>>(object: I): RegisterProposalResponse {
    const message = createBaseRegisterProposalResponse();
    message.statusCode = object.statusCode ?? 0;
    message.proposalId = object.proposalId ?? "";
    return message;
  },
};

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

function bytesFromBase64(b64: string): Uint8Array {
  if (tsProtoGlobalThis.Buffer) {
    return Uint8Array.from(tsProtoGlobalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = tsProtoGlobalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if (tsProtoGlobalThis.Buffer) {
    return tsProtoGlobalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(String.fromCharCode(byte));
    });
    return tsProtoGlobalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin
  ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
