import styles from "./policy.module.scss";

export default function PrivatePolicy() {
  return (
    <div className={styles.wrap}>
      <p className={styles.header}>
        마이디커넥트 서비스 개인정보 처리방침
        <br />
      </p>
      <p className={styles["header-desc"]}>
        ㈜ 에스앤피랩(이하 ‘회사’라 한다)은 마이디커넥트 웹을 통하여 마이디 서비스(이하 ‘마이디커넥트 서비스’라 한다)를
        제공함에 있어 「개인정보 보호법」(이하 ‘개인정보보호법’이라 한다) 제30조에 따라 이용자의 개인정보를 보호하고
        이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
        수립‧공개합니다.
      </p>

      <ul>
        <li>
          <p>제1조 (개인정보의 처리목적)</p>
          회사는 마이디커넥트 서비스를 제공함에 있어 필수적인 개인정보만을 처리/이용합니다. 회사는 다음 목적에 따라
          필수적인 개인정보를 이용합니다.
          <br />
          <br />
          <ul>
            <li style={{ listStyleType: "-  " }}>
              마이디커넥트 서비스 이용 통계 분석
              <br />
              회사는 마이디커넥트 서비스 이용의 관리, 서비스 개선, 오류 추적을 위하여 이용자의 개인정보와 연결되는
              식별자를 제거한 비개인정보 형태로 관련 정보를 수집·이용합니다.
            </li>
            <li style={{ listStyleType: "-  " }}>
              C/S처리
              <br />
              회사는 마이디커넥트 서비스 제공 시 서비스의 동작 오류 및 포인트 지급/정산 등의 민원 처리를 위해 이용자의
              개인정보를 처리할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제2조 (개인정보의 이용 및 보유기간)</p>
          회사는 이용자의 개인정보를 처리하게 될 경우, 관련 법령에 따른 개인정보 보유‧이용기간 또는 개인정보를 수집시에
          동의받은 개인정보 보유·이용기간 내에서 해당 개인정보를 처리하며, 각각의 개인정보 처리 및 보유 기간은 다음과
          같습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              서비스 이용 통계 분석(개인정보를 제외한 비개인정보 형태로 저장): 영구
            </li>
            <li style={{ listStyleType: "-  " }}>
              리워드 제공에 대한 정보(비개인정보 형태(DID)로 블록체인에 저장): 영구
            </li>
            <li style={{ listStyleType: "-  " }}>LDP 통계 정보(비개인정보 형태로 기록): 영구</li>
            <li style={{ listStyleType: "-  " }}>C/S처리에 관한 내용(개인정보 형태로 기록) : 3년</li>
          </ul>
        </li>
        <li>
          <p>제3조 (개인정보의 제3자 제공)</p>
          회사는 마이디커넥트 서비스 제공을 위해 이용자의 개인정보를 제3자에게 제공하고 있지 않습니다.
        </li>
        <li>
          <p>제4조 (개인정보처리의 위탁)</p>
          회사는 마이디커넥트 서비스 제공을 위해 이용자의 개인정보를 제3자에게 위탁하지 않습니다. 만일 개인정보 처리
          위탁이 필요할 경우, 회사는 개인정보보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적
          보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 개인정보 처리위탁계약서 등
          문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독합니다. 회사는 개인정보 처리위탁계약 체결이
          이루어지거나, 개인정보 처리 위탁계약 체결 후 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
          처리방침을 통하여 공개합니다.
        </li>
        <li>
          <p>제5조 (이용자 및 법정대리인의 권리, 의무 및 행사방법)</p>
          이용자 및 법정대리인은 회사에 대해 언제든지 다음 각 호의 개인정보 관련 권리를 행사할 수 있습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>마이디커넥트 웹을 통한 개인정보 열람</li>
            <li style={{ listStyleType: "-  " }}>마이디커넥트 웹을 통한 오류 등이 있을 경우 정정</li>
          </ul>
          이용자 및 법정대리인은 마이디커넥트 웹을 통해 위 개인정보 열람 청구를 포함한 권리를 직접 행사할 수 있고,
          회사는 이용자 및 법정대리인의 개인정보 열람 청구 등 권리행사가 원활하게 처리되도록 노력하겠습니다. 다만,
          마이디커넥트 웹 사용에 대한 방법 및 가이드 문의는 아래 부서에서 지원하고 있습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              개인정보 열람청구 접수·처리 부서
              <ul>
                <li className={styles[".dot-list-style"]}>부서명: 마이디 서비스 처리팀</li>
                <li className={styles[".dot-list-style"]}>담당자: 이지윤</li>
                <li className={styles[".dot-list-style"]}>
                  연락처: &lt;070-5067-1224&gt;, &lt;myd@snplab.io&gt;, &lt;0507-086-2235&gt;
                </li>
              </ul>
            </li>
          </ul>
          회사는 마이디커넥트 이용자 및 법정대리인이 개인정보의 오류 등에 대한 정정 또는 삭제를 수행한 경우,
          마이디커넥트 웹에 실시간으로 정정된 개인정보가 반영됨을 보장합니다.
          <br />
          회사는 마이디커넥트 웹 구동에 대한 문의가 있을 경우 C/S를 통해 지체없이 대응하겠습니다. 회사는 이용자 및
          법정대리인이 개인정보보호법 등 관련 법령을 위반하여 마이디 마이디커넥트 웹 내 관리되는 본인의 개인정보를
          제공하거나 이용을 허가하는 경우, 이에 대해 책임을 지지 않습니다.
        </li>
        <li>
          <p>제6조 (처리하는 비개인정보 및 개인정보 항목)</p>
          회사는 다음의 목적으로 비개인정보 및 이용자의 개인정보를 처리하고 있습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              서비스 이용 통계 분석
              <ul>
                <li className={styles[".dot-list-style"]}>
                  필수항목 : 웹 페이지 진입 이력, 웹 실행 이력, 웹 메뉴 진입 이력(비개인정보)
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "-  " }}>
              LDP 통계 데이터 이용
              <ul>
                <li className={styles[".dot-list-style"]}>
                  비식별화조치를 한 정보, 단말에서 생성한 랜덤ID(비개인정보)
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "-  " }}>
              C/S처리
              <ul>
                <li className={styles[".dot-list-style"]}>필수항목: 마이디 CS창구를 통해 문의한 문의 글</li>
                <li className={styles[".dot-list-style"]}>
                  선택항목: 이용자 민원 해결을 위해 반드시 필요한 경우 개인정보(이름, 전화번호, 이메일 등)는 별도 이용자
                  동의를 받아 수집
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>제7조 (개인정보의 파기)</p>
          이용자는 자신의 개인정보를 마이디 마이디커넥트 웹을 통해 직접 파기 요청 할 수 있습니다.
          <br />
          <br />
          구체적인 개인정보 파기의 절차 및 방법은 다음과 같습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              파기절차: 이용자가 직접 마이디 앱을 통해 개인정보를 파기할 수 있습니다.
            </li>
            <li style={{ listStyleType: "-  " }}>
              파기방법:
              <ul>
                <li className={styles[".dot-list-style"]}>마이디 서비스에서 완전 삭제</li>
              </ul>
            </li>
          </ul>
          단, 회사는 C/S 처리 등을 위해 이용자로부터 별도 수집·이용 동의를 받은 개인정보에 대하여 개인정보 보유기간이
          경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우, 해당
          개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        </li>
        <li>
          <p>제8조 (개인정보의 안전성 확보조치)</p>
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              관리적 조치
              <ul>
                <li className={styles[".dot-list-style"]}>
                  회사의 C/S 등 개인정보의 처리가 필요한 경우 개인정보보호법에 따른 안전성 확보조치를 다함.
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "-  " }}>
              기술적 조치
              <ul>
                <li className={styles[".dot-list-style"]}>개인정보 암호화</li>
                <li className={styles[".dot-list-style"]}>암호키 관리</li>
                <li className={styles[".dot-list-style"]}>기술적, 관리적, 물리적 보호 조치 적용</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>제9조 (회사의 면책)</p>
          회사는 이용자 및 법정대리인이 개인정보보호법 등 관련 법령을 위반하여 본인의 개인정보를 제공하거나 이용을
          허가하는 경우, 이에 대해 책임을 지지 않습니다.
        </li>
        <li>
          <p>제10조 (개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</p>
          회사는 마이디커넥트 웹 서비스 제공 시, 이용자에게 개별적인 맞춤서비스를 제공하기 위해 ‘쿠키(cookie)’ 등의 별도
          정보 수집하는 솔루션을 사용하지 않습니다.
        </li>
        <li>
          <p>제11조 (개인정보 보호책임자)</p>
          회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제
          등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              개인정보 보호책임자
              <ul>
                <li className={styles[".dot-list-style"]}>성명: 성기찬</li>
                <li className={styles[".dot-list-style"]}>직책: CPO</li>
                <li className={styles[".dot-list-style"]}>
                  연락처: &lt;02-2038-2511&gt;, &lt;myd@snplab.io&gt;, &lt;0507-086-2235&gt;
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ※ 개인정보보호
                  담당부서로 연결됩니다.
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "-  " }}>
              개인정보 보호 담당부서
              <ul>
                <li className={styles[".dot-list-style"]}>부서명: 개인정보보호팀</li>
                <li className={styles[".dot-list-style"]}>담당자: 성기찬</li>
                <li className={styles[".dot-list-style"]}>
                  연락처: &lt;02-2038-2511&gt;, &lt;myd@snplab.io&gt;, &lt;0507-086-2235&gt;
                </li>
              </ul>
            </li>
          </ul>
          이용자 및 법정대리인은 회사의 마이디커넥트 서비스(또는 회사가 운영하는 사업)을 이용하면서 발생한 모든 개인정보
          보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보보호 책임자 및 담당부서로 문의하실 수
          있습니다.회사는 이용자 및 법정대리인의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
        </li>
        <li>
          <p>제12조 (권익침해 구제방법)</p>
          이용자 및 법정대리인은 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
          <br />
          아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 관련 민원처리, 피해구제 결과에 만족하지
          못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.
          <ul>
            <li style={{ listStyleType: "-  " }}>
              개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <ul>
                <li className={styles[".dot-list-style"]}>소관업무: 개인정보 침해사실 신고, 상담 신청</li>
                <li className={styles[".dot-list-style"]}>홈페이지: privacy.kisa.or.kr</li>
                <li className={styles[".dot-list-style"]}>전화: (국번없이) 118</li>
                <li className={styles[".dot-list-style"]}>
                  주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "-  " }}>
              개인정보 분쟁조정위원회
              <ul>
                <li className={styles[".dot-list-style"]}>
                  소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                </li>
                <li className={styles[".dot-list-style"]}>홈페이지: www.kopico.go.kr</li>
                <li className={styles[".dot-list-style"]}>전화: (국번없이) 1833-6972</li>
                <li className={styles[".dot-list-style"]}>
                  주소: (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "-  " }}>대검찰청 사이버범죄수사단: 02-3480-3573 (www.spo.go.kr)</li>
            <li style={{ listStyleType: "-  " }}>경찰청 사이버안전국: 182 (https://cyberbureau.police.go.kr)</li>
          </ul>
        </li>
        <li>
          <p>제13조 (개인정보 처리방침 변경)</p>
          이 개인정보 처리방침은 2023.06.27부터 적용됩니다.
          <br />
        </li>
      </ul>
    </div>
  );
}
