import { Axios } from "./base";
import { TCompany } from "types/common";
import { TCreateDetail } from "types/management";
import { errorHandling } from "./errorHandling";
import { ErrorMessage } from "constants/errorCode";
// 기업 상세 정보 조회
export const getCompanyInfoData = async (id: string): Promise<TCompany> => {
  try {
    const { data } = await Axios.get(`/api/v2/company/info`, { params: { id } });
    return data.data.company;
  } catch {
    throw errorHandling(Error(`기업 정보 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

// 기업 정보 등록 및 수정
export const updateCompanyData = async (companyModify: TCompany): Promise<TCompany> => {
  try {
    const { data } = await Axios.post(`/api/v2/company/update`, { companyModify });
    return data.data.resultCompany;
  } catch (error) {
    if (error instanceof Error && error.message !== ErrorMessage[6305]) {
      throw errorHandling(
        Error(
          `기업 정보 수정 중 오류가 발생했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`
        )
      );
    } else throw error;
  }
};

// 기업 정보 등록을 위한 기업 정보 조회
export const getCompanyDetail = async (): Promise<TCreateDetail> => {
  const { data } = await Axios.get(`/api/v2/company/detail`);
  return data.data;
};
