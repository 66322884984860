import dayjs from "dayjs";
import { TAdList } from "types/advertisement";
import { isProgressNow } from "utils/market";

export default function StatusFilter(data: TAdList, approved: boolean) {
  const { notBefore, status: statusValue, approved: isApproved } = data;
  if (approved) {
    // 진행중
    if (statusValue === 0 && isApproved && isProgressNow(dayjs(notBefore))) return "inProgress";

    // 예정
    if (statusValue === 0 && isApproved && !isProgressNow(dayjs(notBefore))) return "notStarted";

    // 종료
    if (statusValue === 1 && isApproved) return "ended";
  }
  // 승인 요청
  if (statusValue === 1 && !isApproved) return "pending";

  // 임시저장
  if (statusValue === 0 && !isApproved) return "temporary";

  // 요청 취소
  if (statusValue === 2 && !isApproved) return "cancel";

  // 반려
  if (statusValue === 3 && !isApproved) return "reject";

  return "";
}
