import styles from "style/common/other/percentBar.module.scss";

type TProps = {
  percent: string;
  height?: string;
  width?: string;
  borderRadius?: string;
  tootip?: boolean;
  label?: string;
};

export default function PercentBar({
  percent,
  height = "8px",
  width = "100%",
  borderRadius = "20px",
  tootip,
  label = "응답률",
}: TProps) {
  return (
    <div className={styles.container} style={{ height, width, borderRadius }}>
      <span className={styles.label}>{label}</span>
      <div className={styles.progress} style={{ width: percent, borderRadius }} />

      {tootip && (
        <div>
          <div className={styles.tooltip} style={{ left: percent }}>{`${percent}`}</div>
          <div className={styles.triangle} style={{ left: percent }} />
        </div>
      )}
    </div>
  );
}
