import { ModalPortal } from "common/other";
import { useEffect } from "react";
import styles from "style/common/modal/loadingModal.module.scss";

/**
 * LoadingModal - query or mutation 상태가 pending 일 경우 사용합니다. (그 외 사용 가능)
 *
 * @component
 * @example
 * if (isLoading) {
 *   return <LoadingModal />;
 * }
 */
export default function LoadingModal() {
  useEffect(() => {
    document.body.style.cssText = "overflow: hidden";
    return () => {
      document.body.style.cssText = "overflow: auto";
    };
  }, []);

  return (
    <ModalPortal>
      <div className={styles.indicator} />
    </ModalPortal>
  );
}
