import { BarChart, Bar, XAxis, ResponsiveContainer, LabelList, YAxis, CartesianGrid } from "recharts";
import dayjs from "dayjs";
import { TDashboard } from "types/dashboard";
import { formatByteSize } from "utils/formatByteSize";

type TProps = {
  data: TDashboard;
  chartSelect: string;
};

export default function Chart({ data, chartSelect }: TProps) {
  const choosingData = chartSelect === "월별 소진 포인트" ? data?.rewardInfoList : data?.dataTransactionList;
  let formatData = choosingData?.map((el) => el);

  const nowDate = dayjs();

  // 최근 1년 데이터 추이 그래프에서 첫번째 그래프 날짜를 구하기 위한 데이터들
  let agoYear = Number(`${nowDate.subtract(1, "year").format("YYYY")}`); // 현재 날짜 기준 1년 전의 year
  let nextMonth = Number(`${nowDate.add(1, "month").format("MM")}`); // 현재 날짜 기준 1달 후의 month

  if (nextMonth === 1) agoYear += 1; // 현재 달이 12월인 경우 예외 처리

  // 최근 1년 데이터 추이에 표시되어야 하는 모든 날짜 배열
  // ex. ['2022-06', '2022-07', '2022-08', '2022-09', '2022-10', '2022-11', '2022-12', '2023-01', '2023-02', '2023-03', '2023-04', '2023-05']
  const allMonthArr = [];
  for (let i = 0; i < 12; i++) {
    allMonthArr.push(`${agoYear}-${String(nextMonth).padStart(2, "0")}`);
    nextMonth++;
    if (nextMonth > 12) {
      agoYear++;
      nextMonth -= 12;
    }
  }

  const exisMonthArr = choosingData?.map((el) => el.createdAt); // 데이터가 존재하는 달들의 배열
  const notExistMonthArr = allMonthArr?.filter((el) => !exisMonthArr?.includes(el)); // 데이터가 존재하지 않아 그래프가 표시되지 않는 달들의 배열
  for (let i = 0; i < notExistMonthArr.length; i++) {
    if (chartSelect === "월별 소진 포인트") {
      formatData?.push({ exhaustedPoint: 0, purchasePoint: 0, createdAt: notExistMonthArr[i] });
    } else if (chartSelect === "월별 데이터 거래 인원" || chartSelect === "월별 데이터 거래량") {
      formatData?.push({ count: 0, datasize: 0, createdAt: notExistMonthArr[i] });
    }
  }

  formatData = formatData
    ?.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
    .map((el) => {
      const { length } = el.createdAt;
      return {
        ...el,
        createdAt: `${
          el.createdAt.slice(length - 2, -1) === "0" ? el.createdAt.slice(length - 1) : el.createdAt.slice(length - 2)
        }월`,
      };
    });

  const formatDataKey = (el: string) => {
    if (el === "월별 데이터 거래량") return "datasize";
    if (el === "월별 데이터 거래 인원") return "count";
    if (el === "월별 소진 포인트") return "exhaustedPoint";
    return "";
  };

  const formatLabel = (el: number) => {
    if (chartSelect === "월별 데이터 거래량") return formatByteSize(el);
    if (chartSelect === "월별 데이터 거래 인원") return `${el.toLocaleString()}명`;
    if (chartSelect === "월별 소진 포인트") return `${el.toLocaleString()}P`;
    return "";
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={formatData} margin={{ top: 30 }}>
        <CartesianGrid opacity={0.2} vertical={false} />
        <XAxis dataKey="createdAt" tickLine={false} axisLine={false} style={{ fontSize: "14px", fill: "#222" }} />
        <YAxis dataKey={formatDataKey(chartSelect)} axisLine={false} hide />
        <Bar
          dataKey={formatDataKey(chartSelect)}
          fill="#1890FF"
          animationDuration={1000}
          barSize={72}
          radius={[4, 4, 0, 0]}
        >
          <LabelList
            dataKey={formatDataKey(chartSelect)}
            position="top"
            formatter={(el: number) => formatLabel(el)}
            style={{ fontSize: "12px", fill: "#595A5E" }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}
