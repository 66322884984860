import cx from "classnames";
import { marketStatusMap } from "constants/market";
import styles from "style/common/button/statusChip.module.scss";

type TStatusChipProps = {
  status: number;
};

export default function StatusChip({ status }: TStatusChipProps) {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[marketStatusMap[status][1]])}>{marketStatusMap[status][0]}</div>
    </div>
  );
}
