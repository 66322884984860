import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";

import CompanyEditor from "./CompanyEditor";
import { ButtonBox, Title, InfoItem } from "common/other";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { Content, Wrapper } from "common/container";
import { getCompanyInfoData } from "api/company";
import queryKey from "constants/queryKey";
import { useModal } from "hooks";
import { TCompanyPrivacyPolicyInfo } from "types/common";
import ROLE from "constants/role";
import { getUserInfo } from "api/common";

export default function CompanyDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data: userInfo } = useQuery(queryKey.GET_USERINFO, getUserInfo);
  const isB2bAdminLogin = userInfo?.roles === ROLE.B2B_ADMIN; // 로그인 유저가 대표담당자인지 확인

  const tutorialStatus = true;
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.get("edit");

  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [backModal, setBackModal] = useState(false);

  // 기업 상세정보 조회 API 연동
  const { data, isLoading, isFetching, error } = useQuery([queryKey.GET_COMPANY, id], () => getCompanyInfoData(id!), {
    onError: () => setIsErrorShowing(),
  });

  const formatBankAccount = (bankAccountNumber: string, idx: number) => {
    return (bankAccountNumber || "").split(",")[idx];
  };

  const imgDiv = (url: string) => {
    const imgUrlSplit = (url || "").split("/");
    const imgFileName = imgUrlSplit[imgUrlSplit.length - 1];

    return (
      <Link to={url} target="_blank" rel="noopener noreferrer">
        {imgFileName}
      </Link>
    );
  };

  useEffect(() => {
    const popstateHandler = () => setBackModal(true);

    if (!tutorialStatus) {
      window.onpopstate = popstateHandler;
      window.history.pushState(null, "", window.location.href);
    }

    return () => {
      window.onpopstate = null;
    };
  }, [tutorialStatus, backModal]);

  const handleLogoutClick = () => {
    queryClient.clear();
    window.location.href = "/logout";
  };

  const privacyPolicyInfo: TCompanyPrivacyPolicyInfo = JSON.parse(data?.privacyPolicyInfo || "{}");

  const titleCondition = tutorialStatus ? "정보 수정" : "정보 등록";

  if (isLoading || isFetching) return <LoadingModal />;

  return (
    <Wrapper>
      <Title text={`기업 ${isEditMode ? titleCondition : "상세정보"} `} />

      {/* 수정모드 */}
      {isEditMode ? (
        <CompanyEditor data={data!} />
      ) : (
        <Content>
          <InfoItem labelWidth="290px" label="기업명" info={data?.name!} />
          <InfoItem labelWidth="290px" label="소재지" info={data?.businessType === "1" ? "해외" : "국내"} />
          <InfoItem labelWidth="290px" label="기업 DID" info={data?.did} />
          <InfoItem labelWidth="290px" label="데이터 수집 서버 URL" info={data?.piiUrl} />
          <InfoItem labelWidth="290px" label="사업자등록증" info={imgDiv(data?.businessRegistrationImageUrl!)} />
          <InfoItem labelWidth="290px" label="사업자등록번호" info={data?.businessNumber} />
          <InfoItem labelWidth="290px" label="주소" info={data?.businessHeadOfficeAddress} />
          <InfoItem labelWidth="290px" label="대표자명" info={data?.businessRepresentativeName} />
          <InfoItem labelWidth="290px" label="대표번호" info={data?.tel} />
          <InfoItem labelWidth="290px" label="개인정보 처리방침 URL" info={data?.privacyPolicyUrl} />
          <InfoItem labelWidth="290px" label="개인정보 보호 책임자명" info={privacyPolicyInfo?.privacyPolicyOfficer} />
          <InfoItem
            labelWidth="290px"
            label="개인정보 보호 책임자 연락처"
            info={privacyPolicyInfo?.privacyPolicyOfficerTel}
          />
          <InfoItem labelWidth="290px" label="고충처리 담당부서명" info={privacyPolicyInfo?.problemHandlingDept} />
          <InfoItem
            labelWidth="290px"
            label="고충처리 담당부서 연락처"
            info={privacyPolicyInfo?.problemHandlingDeptTel}
          />
          <InfoItem labelWidth="290px" label="통장 사본" info={imgDiv(data?.bankbookCopyImageUrl!)} />
          <InfoItem labelWidth="290px" label="은행명" info={formatBankAccount(data?.bankAccountNumber!, 0)} />
          <InfoItem labelWidth="290px" label="예금주명" info={formatBankAccount(data?.bankAccountNumber!, 1)} />
          <InfoItem labelWidth="290px" label="계좌번호" info={formatBankAccount(data?.bankAccountNumber!, 2)} />
          <InfoItem
            labelWidth="290px"
            label="전자세금계산서용 이메일 주소"
            info={data?.businessDigitalTaxInvoiceEmail}
          />
          <InfoItem
            labelWidth="290px"
            label="사업자 단위 과세 적용사업자 여부"
            info={data?.businessUnitTaxation ? "여" : "부"}
          />
          <InfoItem labelWidth="290px" label="홈페이지 URL " info={data?.homepageUrl} />
          <InfoItem labelWidth="290px" label="기업 로고" info={imgDiv(data?.logoUrl!)} />

          {isB2bAdminLogin ? (
            <ButtonBox
              type="submit"
              back="목록으로"
              next="수정"
              handleClickPrev={() => navigate("/management")}
              handleClickNext={() => navigate(`/management/company/info/${id}?edit=true`)}
            />
          ) : (
            <ButtonBox type="goback" text="뒤로가기" />
          )}
        </Content>
      )}

      {backModal && (
        <Dialog
          description="기업 정보 등록을 중단하고 로그아웃 합니다."
          btnType="submit"
          submitBtnName="예"
          cancelBtnName="아니오"
          handleClose={() => setBackModal(false)}
          handleSubmit={handleLogoutClick}
        />
      )}

      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={() => setIsErrorShowing()} />}
    </Wrapper>
  );
}
