import { Dispatch, SetStateAction } from "react";
import styles from "style/pages/market/createMarketPage/sectionTargetData/dataPolicyTable.module.scss";

type TProps = {
  setIsPopup: Dispatch<SetStateAction<boolean>>;
};

export default function DataPolicyTable({ setIsPopup }: TProps) {
  return (
    <div className={styles.wrapper}>
      <button type="button" onClick={() => setIsPopup(false)}>
        &times;
      </button>

      <div className={styles.title}>데이터 수집 기준 및 가격 정책표</div>

      <div className={styles.contentContainer}>
        <table>
          <thead>
            <tr>
              <th>카테고리</th>
              <th>수집 데이터</th>
              <th>수집 기준</th>
              <th>
                가격<span>(단위:원)</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => {
              return (
                <tr key={item.collectionData}>
                  <td>{item.category}</td>
                  <td>{item.collectionData}</td>
                  <td>{item.collectionStandard}</td>
                  <td>{item.price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className={styles.paraphraseTitle}>유의사항</div>
        <ul>
          <li>거래 시작 전일까지 해당 기준에 따라 데이터를 수집합니다.</li>
          <li>선택한 데이터 가격의 총합이 1인당 지급 포인트로 설정됩니다.</li>
          <li>노출 대상을 선택할 경우 타겟팅 비용 [성별 200원, 연령대 200원, 거주지 400원]이 가산됩니다.</li>
        </ul>

        <div className={styles.paraphraseTitle}>가격산정 예시</div>
        <ul>
          <li>은행 예적금 + 카드 + 관심사 = 600 + 600 + 500 = 1700원</li>
          <li>성별 + 연령대 + 거주지 + (은행 예적금 + 카드 + 관심사) = 200 + 200 + 400 + (1700원) = 2500원</li>
        </ul>
      </div>
    </div>
  );
}

const tableData = [
  { category: "건강", collectionData: "건강검진 데이터", collectionStandard: "최대 10년", price: 700 },
  { category: "건강", collectionData: "진료 및 투약 데이터", collectionStandard: "최근 180일", price: 700 },
  { category: "금융", collectionData: "은행 예적금 데이터", collectionStandard: "최근 180일", price: 600 },
  { category: "금융", collectionData: "카드 데이터", collectionStandard: "최근 180일", price: 700 },
  { category: "금융", collectionData: "증권 데이터", collectionStandard: "최근 30일 내 업데이트", price: 700 },
  { category: "금융", collectionData: "보험 데이터", collectionStandard: "최근 30일 내 업데이트", price: 700 },
  {
    category: "생활",
    collectionData: "관심사(Google검색/Youtube시청) 데이터",
    collectionStandard: "최대 200건",
    price: 500,
  },
  { category: "생활", collectionData: "쇼핑 데이터", collectionStandard: "최근 180일", price: 600 },
];
