import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DefaultBtn } from "common/button";
import { CheckInput } from "common/input";
import { ErrorModal } from "common/modal";
import { TLogin } from "types/login";
import IconInput from "./IconInput";

import { Axios } from "api/base";
import { MydLogo } from "assets/svgs/skeleton";
import ROLE from "constants/role";
import styles from "style/pages/login/login.module.scss";
import { deleteIsLogined, setIsLogined, setToken } from "utils/access";

export default function Login() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [login, setLogin] = useState<TLogin>({ username: "", password: "" });

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  const handleSubmitForm = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (login.password && login.username) {
      try {
        const response = await axios.post("/api/auth/sign-in", {
          userName: login.username,
          password: login.password,
          rememberMe: isRememberMe,
        });

        const { data } = response.data;

        if (data.role.replace("ROLE_", "") === ROLE.SNP_ADMIN || data.role.replace("ROLE_", "") === ROLE.SNP_MANAGER) {
          setIsAdmin(true);
          return;
        }

        setToken(data.accessToken);
        setIsLogined(isRememberMe);
        navigate("/", { replace: true });
      } catch (error) {
        setErrorMessage("로그인 중 오류가 발생했습니다.\n동일한 현상이 반복될 경우 고객센터로 문의해주세요.");
      }
    } else {
      setErrorMessage("로그인과 비밀번호를 모두 입력해주세요.");
    }
  };

  const handleErrorClear = () => {
    setErrorMessage("");
  };

  const handleAdminLogin = async () => {
    await Axios.post("/api/auth/sign-out");
    setToken(null);
    deleteIsLogined();
    window.location.href = window.ADMIN_URL || "/";
    setIsAdmin(false);
  };

  const handleInvalidClick = () => {
    deleteIsLogined();
    navigate("/login", { replace: true });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <form className={styles.loginWrapper} onSubmit={handleSubmitForm}>
          <div className={styles.loginContainer}>
            <div className={styles.logo}>
              <MydLogo />
            </div>

            <p className={styles.title}>{`새로운\n데이터 거래의 시작`}</p>
            <p className={styles.subtitle}>세계 최초 마이데이터 거래소, 마이디</p>

            <div className={styles.loginInputBox}>
              <IconInput
                type="text"
                placeholder="E-mail"
                name="username"
                onChange={handleChangeInput}
                value={login.username}
                iconType="email"
              />

              <IconInput
                type="password"
                placeholder="PW"
                name="password"
                onChange={handleChangeInput}
                value={login.password}
              />
            </div>

            <div className={styles.optionWrapper}>
              <CheckInput
                name="auto-login"
                text="로그인 상태유지"
                value={isRememberMe}
                handleCheck={() => setIsRememberMe((prev) => !prev)}
                mode="circle"
              />
              <Link to="/password/find" className={styles.passwordFind}>
                비밀번호 찾기
              </Link>
            </div>

            <DefaultBtn size="long" type="submit">
              로그인
            </DefaultBtn>
          </div>
        </form>
      </div>

      {errorMessage && (
        <ErrorModal title="로그인 오류!" error={new Error(errorMessage)} onConfirmHandler={handleErrorClear} />
      )}
      {isAdmin && (
        <ErrorModal
          title="로그인 오류!"
          error={new Error(`본 계정은 관리자 계정입니다.\n관리자 페이지로 이동합니다.`)}
          onConfirmHandler={handleAdminLogin}
        />
      )}
      {window.location.search.includes("invalid-token") && (
        <ErrorModal
          title="로그인 만료"
          error={new Error(`로그인 상태가 만료되었습니다. \n보안을 위해 다시 로그인해 주세요.`)}
          onConfirmHandler={handleInvalidClick}
        />
      )}
    </>
  );
}
