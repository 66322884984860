import { Link, useLocation, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { LeftArrowIcon, RightArrowIcon } from "assets/svgs/icon";
import styles from "./pagination.module.scss";

type TProps = {
  totalPages: number;
};

export default function Pagination({ totalPages }: TProps) {
  const pageRange = 5;
  const { search } = useLocation();

  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum")) || 1;

  const { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext } = getPageData({
    pageNum,
    totalPages,
    pageRange,
  });

  // location.search에 맞춰 쿼리스트링을 리턴
  const nextQuery = (num: number, base?: number) => {
    const containsPageNum = /(pageNum=)(\d+)/g.test(search);
    if (!search) return `?pageNum=${base ? base + num : num}`;
    if (containsPageNum) return search.replace(/(pageNum=)(\d+)/g, `pageNum=${base ? base + num : num}`);
    return `${search}&pageNum=${num}`;
  };

  return (
    <section className={styles.wrapper}>
      <Link to={nextQuery(-1, startNumberOfPageRange)} className={cx(styles.prev, { [styles.disabled]: !isPrev })}>
        <LeftArrowIcon stroke={isPrev ? "#595959" : "#bfbfbf"} />
      </Link>

      {range(startNumberOfPageRange, endNumberOfPageRange).map((item) => (
        <Link key={item} to={nextQuery(item)} className={cx({ [styles.active]: item === pageNum })}>
          {item}
        </Link>
      ))}

      <Link to={nextQuery(endNumberOfPageRange, 1)} className={cx(styles.next, { [styles.disabled]: !isNext })}>
        <RightArrowIcon stroke={isNext ? "#595959" : "#bfbfbf"} />
      </Link>
    </section>
  );
}

function range(start: number, end: number) {
  const array = [];
  for (let i = start; i <= end; ++i) {
    array.push(i);
  }
  return array;
}

function getPageData({ pageNum, totalPages, pageRange }: { pageNum: number; totalPages: number; pageRange: number }) {
  const startNumberOfPageRange = Math.trunc((pageNum - 1) / pageRange) * pageRange + 1;
  const tmpEnd = startNumberOfPageRange + (pageRange - 1);
  const endNumberOfPageRange = tmpEnd > totalPages ? totalPages : tmpEnd;

  const isPrev = startNumberOfPageRange > pageRange;
  const isNext = endNumberOfPageRange < totalPages;

  return { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext };
}
