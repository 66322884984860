import styles from "style/common/input/dateSearchBox.module.scss";
import { FormEventHandler, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DefaultBtn } from "common/button";
import useDateRange from "hooks/useDateRange";
import DateRange from "./DatePicker";
import { RerollIcon } from "assets/svgs/icon";

/**
 * 날짜 필터 컴포넌트
 */
export default function DateSearchBox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { endDate, onChangeDate, startDate, resetDate } = useDateRange();
  const [keywordValue, setKeywordValue] = useState("");

  const handleSearchSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setSearchParams((params) => {
      keywordValue ? params.set("keyword", keywordValue) : params.delete("keyword");
      params.set("pageNum", "1");
      return params;
    });
  };

  const handleResetDate = () => {
    searchParams.delete("from");
    searchParams.delete("to");
    searchParams.delete("keyword");
    searchParams.set("pageNum", "1");
    resetDate();
    setKeywordValue("");
    setSearchParams(searchParams);
  };

  const handleKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeywordValue(e.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSearchSubmit}>
        <div className={styles.dateBox}>
          <span>기간검색</span>

          <DateRange startDate={startDate} endDate={endDate} onChange={onChangeDate} setParams />

          <input
            placeholder="검색어를 입력하세요"
            className={styles.searchKeyword}
            value={keywordValue}
            onChange={handleKeywordChange}
          />

          <DefaultBtn size="small" onClick={handleSearchSubmit}>
            검색
          </DefaultBtn>
        </div>

        {(searchParams.get("keyword") || searchParams.get("from") || searchParams.get("to")) && (
          <button type="button" className={styles.searchClearBtn} onClick={handleResetDate}>
            <RerollIcon width="24px" height="24px" />
            초기화하기
          </button>
        )}
      </form>
    </div>
  );
}
