import { useMutation } from "react-query";
import { AxiosError } from "axios";

import AgreementPopup from "./AgreementPopup";
import { ErrorModal } from "common/modal";
import { postPrivacyAgree } from "api/management";
import { ErrorCode, ErrorMessage } from "constants/errorCode";
import useModal from "hooks/useModal";

import styles from "style/pages/login/privacyAgree.module.scss";

type TProps = {
  email: string;
  onNext: () => void;
};

export default function PrivacyAgree({ email, onNext }: TProps) {
  const [isErrorShowing, setIsErrorShowing] = useModal();

  const { mutate, error } = useMutation(postPrivacyAgree, {
    onSuccess: () => onNext(),
    onError: (err: AxiosError) => {
      if (err.message === ErrorMessage[ErrorCode.ERR_DATA_DUPLICATED]) {
        onNext();
        return;
      }

      setIsErrorShowing();
    },
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <AgreementPopup isPopup handleSubmit={() => mutate({ email })} />
        {isErrorShowing && <ErrorModal error={error} onConfirmHandler={setIsErrorShowing} />}
      </div>
    </div>
  );
}
