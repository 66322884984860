import { useQuery } from "react-query";
import { useSetRecoilState } from "recoil";
import { tutorialStatusAtom } from "recoils/management";
import { getCompanyDetail } from "api/company";
import queryKey from "constants/queryKey";

export default function useCompanyDetail() {
  const setTutorialStatus = useSetRecoilState(tutorialStatusAtom);

  const { data, isLoading, isError, error } = useQuery([queryKey.GET_DETAIL_COMPANY], getCompanyDetail, {
    onSuccess: (el) => el && !el.tutorialStatus && setTutorialStatus(false),
  });

  return { isLoading, isError, error, data };
}
