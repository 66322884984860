import { atom } from "recoil";
import { TRewardCharge } from "types/reward";

export const rewardCharge = atom<TRewardCharge>({
  key: "rewardChargeAtom",
  default: {
    price: 0,
    chargePoint: 0,
  },
});
