import SearchInput from "common/input/SearchInput";
import styles from "style/pages/management/innerHeader.module.scss";

export default function InnerHeader({ dataLength }: { dataLength: number }) {
  return (
    <div className={styles.container}>
      <div className={styles.total}>
        전체&nbsp;<span>{dataLength || 0}명</span>
      </div>

      <SearchInput placeholder="사용자명을 입력하세요" withoutIcon />
    </div>
  );
}
