import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FieldErrors, FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Content, Wrapper } from "common/container";
import { LabelInput } from "common/input";
import { Title, InfoItem, ButtonBox } from "common/other";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { getLoginCompanyData, postUserData } from "api/management";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { TCreateStaffInput, TLoginStaffCompany } from "types/management";

import styles from "style/pages/management/staff/staffRegister.module.scss";
import ROLE from "constants/role";

export default function StaffRegister() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isRegisterModal, setIsRegisterModal] = useModal();
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isValidateCheck, setIsValidateCheck] = useModal();
  const [errorMsg, setErrorMsg] = useState("");

  const methods = useForm<TCreateStaffInput>({ mode: "onChange" });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  // [GET] 로그인 유저의 기업 정보 조회
  const {
    data,
    isFetching,
    error: getError,
  } = useQuery([queryKey.GET_LOGIN_COMPANY], getLoginCompanyData, {
    onError: setIsErrorShowing,
  });

  // 담당자 계정 등록 API 연동
  const { mutate: postMutation, error, isLoading } = useMutation(postUserData);

  const { companyName, companyId, businessType } = (data as TLoginStaffCompany) || {};
  const isOverseas = businessType === "1"; // '1': 해외, '0': '국내'

  const handleRegister: SubmitHandler<TCreateStaffInput> = (input) => {
    const { staffName, department, jobPosition, email, phone, tel } = input;
    const body = { staffName, department, jobPosition, email, phone, tel, companyId, role: ROLE.B2B_MANAGER };

    setIsConfirmModal();

    postMutation(body, {
      onSuccess: () => {
        setIsRegisterModal();
        queryClient.invalidateQueries([queryKey.GET_STAFF_LIST]);
      },
      onError: setIsErrorShowing,
    });
  };

  const handleError = (err: FieldErrors) => {
    if (err) {
      const errorMessages = Object.values(err).filter((v) => v?.message);
      if (errorMessages.length) {
        setErrorMsg(String(errorMessages[0]?.message));
      }
      setIsValidateCheck();
    }
  };

  if (isFetching || isLoading) return <LoadingModal />;

  return (
    <Wrapper>
      <Title text="담당자 계정 등록" />
      <Content>
        <FormProvider {...methods}>
          <form className={styles.content} onSubmit={handleSubmit(setIsConfirmModal, handleError)}>
            <LabelInput
              label="이름"
              id="staffName"
              placeholder="담당자명을 입력하세요"
              isNull={!!errors.staffName}
              maxLength={50}
            />
            <InfoItem label="기업 / 기관명" info={companyName} />
            <InfoItem label="소재지" info={isOverseas ? "해외" : "국내"} />
            <InfoItem label="권한" info="담당자" />
            <LabelInput
              label="부서명"
              id="department"
              placeholder="부서명을 입력하세요"
              isNull={!!errors.department}
              maxLength={50}
            />
            <LabelInput
              label="직급"
              id="jobPosition"
              placeholder="직급을 입력하세요"
              isNull={!!errors.jobPosition}
              maxLength={50}
            />
            <LabelInput
              label="이메일주소"
              id="email"
              type="email"
              placeholder="이메일주소를 입력하세요"
              isNull={!!errors.email}
              validate="email"
            />
            <LabelInput
              label="개별 연락처"
              type="number"
              id="phone"
              placeholder="'-'를 제외한 숫자만 입력하세요"
              isNull={!!errors.phone}
              validate="tel"
            />
            <LabelInput
              label="직통번호"
              type="number"
              id="tel"
              placeholder="'-'를 제외한 숫자만 입력하세요"
              isNull={!!errors.tel}
              validate="tel"
            />

            <ButtonBox back="목록으로" next="등록" />
          </form>
        </FormProvider>
      </Content>

      {/* Modals */}
      {isConfirmModal && (
        <Dialog
          description="담당자 계정을 등록하시겠습니까?"
          btnType="submit"
          handleClose={setIsConfirmModal}
          handleSubmit={handleSubmit(handleRegister, handleError)}
        />
      )}

      {isRegisterModal && (
        <Dialog description="계정이 등록되었습니다." btnType="confirm" handleClose={() => navigate("/management")} />
      )}

      {isValidateCheck && <Dialog description={errorMsg} btnType="confirm" handleClose={setIsValidateCheck} />}

      {isErrorShowing && <ErrorModal error={error || getError} onConfirmHandler={setIsErrorShowing} />}
    </Wrapper>
  );
}
