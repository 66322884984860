import { useState } from "react";
import styles from "style/pages/advertisement/adRegister/adDropdown.module.scss";
import { Dialog } from "common/modal";

type temp = {
  label: React.ReactNode;
  value: string;
};

type TProps = {
  placeholder: string;
  handleChange: (value: string) => void;
  dataList: temp[] | undefined;
  defaultValue: React.ReactNode;
};

export default function AdDropdown({ dataList, placeholder, handleChange, defaultValue }: TProps) {
  const [isActive, setIsActive] = useState(false);
  const [text, setText] = useState<React.ReactNode>(defaultValue);
  const [error, setError] = useState("");

  const handleClose = () => setIsActive(false);

  const handleOptionChange = (el: string, target: React.ReactNode) => {
    handleChange(String(el));
    setText(target);
    setIsActive(false);

    if (dataList?.length === 0) {
      setError("진행 중인 거래가 없습니다.");
    }
  };

  const handleOpenOrClose = () => setIsActive((prev) => !prev);

  return (
    <div className={styles.wrapper}>
      <button
        name="selected"
        type="button"
        onClick={handleOpenOrClose}
        onBlur={handleClose}
        className={styles.buttonStyle}
      >
        {text || placeholder}
      </button>

      {isActive && (
        <ul className={styles.option}>
          {dataList?.map(({ label, value }) => (
            <li key={value}>
              <button type="button" onMouseDown={() => handleOptionChange(value, label)}>
                {label}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <Dialog description={error} btnType="confirm" handleClose={() => setError("")} />}
    </div>
  );
}
