// 숫자 7자리 이상 단위 변경
/**
 * 숫자 7자리 이상일 경우 B -> KB -> MB -> GB 단위로 변경
 * Dashboard 내에서 데이터 거래량 단위 변경시 사용
 */
export const formatByteSize = (bytes: number) => {
  const units = ["B", "KB", "MB", "GB", "TB"];

  let value = bytes;

  for (let i = 0; i < units.length - 1; i++) {
    if (value < 1000000) {
      return `${Math.floor(value).toLocaleString()}${units[i]}`;
    }
    value /= 1024;
  }
  return `${Math.floor(value).toLocaleString()}b`;
};
