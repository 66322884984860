import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { TMarketResponse, TMarketResponseDetail } from "types/market";
import HandleTableBtn from "../marketComponents/HandleTableBtn";
import StatusChip from "../marketComponents/StatusChip";
import useTempCreateData from "../createMarketPage/submitCreateProposal/useTempCreateData";
import useTableMutate from "./useTableMutate";
import { syncProgressPeriod } from "utils/market";
import overrideStatus from "../marketComponents/overrideStatus";

type TTable = {
  columns: (string[] | string)[];
  dataList: never[] | (string | number | JSX.Element | null)[][];
};

export default function useDataToTable(data?: TMarketResponse) {
  const { deleteTableData, revokeTableData } = useTableMutate();
  const navigate = useNavigate();
  const { handleResumeCreate } = useTempCreateData();

  const tableData: TTable = {
    columns: [
      ["NO", "62px"],
      ["등록일", "164px"],
      ["상태", "100px"],
      "제목",
      ["진행기간", "204px"],
      ["목표인원", "107px"],
      ["최대 지급 포인트", "114px"],
      ["", "87px"],
    ],
    dataList: [],
  };
  if (!data) return tableData;

  // 기존 목록에 임시저장 목록 결합
  const allMarketData = data.result.proposalList.concat(
    data.result.tempList.map((obj) => {
      return {
        ...obj,
        status: 5,
      };
    }),
  );

  // 생성일 기준 정렬
  allMarketData.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

  // 제목 클릭 시 라우팅
  const handleMarketRoute = (status: number, proposalId?: string | null, id?: number) => {
    status === 5 ? handleResumeCreate(id || 0) : navigate(`/market/detail?proposalId=${proposalId}`);
  };

  // 버튼 기능
  const onClickTableBtn = (id: string | number, type: "revoke" | "delete") => {
    type === "delete" ? deleteTableData(id as number) : revokeTableData(id as string);
  };

  // 테이블 생성
  tableData.dataList = allMarketData?.map((item: TMarketResponseDetail, idx: number) => [
    item.status === 5 ? `temp-${item.id}` : item.proposalId,
    allMarketData.length - idx,
    dayjs(item.createdAt).format("YYYY.MM.DD HH:mm"),
    <StatusChip
      key={item.proposalId}
      status={overrideStatus({ status: item.status, startDate: item.notBefore, revokedAt: item.revokedAt })}
    />,
    <button
      type="button"
      id="route"
      onClick={() => handleMarketRoute(item.status, item.proposalId, item.id)}
      key={item.proposalId}
    >
      {item?.title || "-"}
    </button>,
    item.notBefore === null
      ? "-"
      : `${dayjs(item.notBefore).format("YYYY.MM.DD")}~${syncProgressPeriod(dayjs(item.notAfter)).format(
          "YYYY.MM.DD",
        )}`,
    item.maxIssuable ? `${Number(item.maxIssuable).toLocaleString("en-US")}명` : "-",
    item.depositPoint ? `${Number(item.depositPoint).toLocaleString("en-US")}P` : "-",
    HandleTableBtn(item, onClickTableBtn),
    dayjs(item.notBefore).format("YYYY.MM.DD HH:mm"),
    item?.title,
  ]);

  return tableData;
}
