import styles from "style/pages/market/createMarketPage/sectionBudget/pointLabel.module.scss";

type TProps = {
  point: number;
  lineThrough?: boolean;
};

export default function PointLabel({ point, lineThrough }: TProps) {
  return (
    <p className={styles.point} style={{ textDecorationLine: lineThrough ? "line-through" : "none" }}>
      {point?.toLocaleString("en-US")} P
    </p>
  );
}
