import { MouseEventHandler, useState } from "react";
import cx from "classnames";
import { Dialog } from "common/modal";
import styles from "style/common/input/dropdown.module.scss";

interface Props {
  dataList: string[];
  value: string;
  handleChange: (value: string) => void;
  width?: string;
  height?: string;
  fontSize?: string;
  maxHeight?: string;
  disabled?: boolean;
  borderColor?: "default" | "deep" | "none" | "soft";
  mode?: "primary" | "secondary" | "third";
  optionTxtPosition?: "start" | "end" | "center";
  placeholder?: string;
}

export default function Dropdown({
  dataList,
  value,
  handleChange,
  width,
  height,
  fontSize = "14px",
  borderColor = "default",
  disabled = false,
  maxHeight,
  mode = "primary",
  optionTxtPosition = "start",
  placeholder,
}: Props) {
  const [isActive, setIsActive] = useState(false);

  const handleClose = () => setIsActive(false);

  const handleOpenOrClose = () => setIsActive((prev) => !prev);

  const handleOptionChange: MouseEventHandler<HTMLButtonElement> = (e) => {
    handleChange(String(e.currentTarget.textContent));
    setIsActive(false);
  };

  return (
    <div className={cx(styles.wrapper, styles[borderColor], styles[mode])} style={{ width, fontSize }}>
      <button
        type="button"
        name="selected"
        onClick={handleOpenOrClose}
        onBlur={handleClose}
        style={{ height }}
        disabled={disabled}
        className={cx({ [styles.placeholder]: !value })}
      >
        {value || placeholder}
      </button>

      {isActive && dataList.length !== 0 && (
        <ul className={cx(styles.option, styles[mode])} style={{ maxHeight }}>
          {dataList?.map((data) => (
            <li key={data}>
              <button type="button" onMouseDown={handleOptionChange} style={{ textAlign: optionTxtPosition }}>
                {data}
              </button>
            </li>
          ))}
        </ul>
      )}

      {isActive && dataList.length === 0 && (
        <Dialog description="목록이 존재하지 않습니다." btnType="confirm" handleClose={handleClose} />
      )}
    </div>
  );
}
