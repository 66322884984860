import axios, { AxiosRequestConfig } from "axios";
import { TAxiosReturn, TUploadRes, TUserInfo } from "types/common";
import { TGetAccessRole } from "types/login";
import { Axios } from "./base";
import { errorHandling } from "./errorHandling";

export const getUserInfo = async () => {
  const { data } = await Axios.get("/api/auth/user-info");

  return { ...data.data, roles: data.data.roles.replace("ROLE_", "") } as TUserInfo;
};

export const accessRole = async (): Promise<TGetAccessRole> => {
  const { data } = await Axios.get<TAxiosReturn<TGetAccessRole>>("/api/access/role");

  return data.data;
};

export const uploadApi = async (token: string, formData: FormData, endpoint: string): Promise<TUploadRes> => {
  const config: AxiosRequestConfig = {
    headers: {
      "X-Auth-Token": token,
    },
  };

  try {
    const { data } = await axios.post(endpoint, formData, config);
    return data.data.urlList[0];
  } catch (error) {
    throw errorHandling(Error(`파일을 업로드하지 못했습니다.\n다시 시도해 주세요.`));
  }
};

export const uploadCommonImgApi = async (body: { did: string; token: string; file: File }) => {
  const { did, token, file } = body;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("did", did);

  return uploadApi(token, formData, `${window.API_URL}/b2b/advertise/images`);
};
