import { Dispatch, SetStateAction } from "react";
import { ModalPortal } from "common/other";
import styles from "./pointRefundInfo.module.scss";

type TProps = {
  setIsNotiPopup: Dispatch<SetStateAction<boolean>>;
};

export default function PrivatePolicy({ setIsNotiPopup }: TProps) {
  return (
    <ModalPortal>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.header}>
            <h1>포인트 환불 안내</h1>
            <button type="button" onClick={() => setIsNotiPopup(false)}>
              &times;
            </button>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.box}>
              <div className={styles.title}>[포인트 환불 정책]</div>
              <ul>
                <li>환불 요청 포인트는 1P 당 1원으로 환불됩니다.</li>
                <li>포인트 충전 시 선지급한 플랫폼 사용료 20%는 제외하고 포인트 잔고만 환불됩니다.</li>
                <li>프로모션으로 제공된 포인트는 환불할 수 없습니다.</li>
              </ul>
            </div>
            <div className={styles.box}>
              <div className={styles.title}>[포인트 환불 문의]</div>
              <ul>
                <li>
                  담당 부서 연락처
                  <br />
                  -전화: 070-5067-1224
                  <span className={styles.gap}>
                    <br />
                  </span>
                  -이메일: myd@snplab.io
                </li>
              </ul>
            </div>
            <div className={styles.box}>
              <div className={styles.title}>[포인트 환불 절차]</div>
              <ul>
                <li>
                  포인트 환불 절차는 다음과 같습니다.
                  <span className={styles.gap}>
                    <br />
                  </span>
                  <span className={styles.underLine}>
                    포인트 환불 요청 → 검토(7일) → 세금계산서 발행(마이너스 세금계산서)(7일) → 포인트 차감(3일) →
                    입금(매월 10일)
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
}
