import { AccessResSchema, DownloadResSchema } from "types/dpia";
import { Axios } from "./base";

type TDpiaLogParam = {
  from: string;
  to: string;
  username: string;
  ip: string;
  pageNum: string;
};

export const getAccessLog = async ({ from, to, username, ip, pageNum }: TDpiaLogParam) => {
  const { data } = await Axios.post("/api/v3/user-access-history/search", {
    pageNumber: Number(pageNum) > 0 ? Number(pageNum) - 1 : Number(pageNum),
    pageSize: 10,
    startDate: from,
    endDate: to,
    userName: username,
    ipAddress: ip,
  });

  const result = AccessResSchema.parse(data.data);

  return result;
};

export const getDownloadLog = async ({ from, to, username, ip, pageNum }: TDpiaLogParam) => {
  const { data } = await Axios.post("/api/v3/download-history/search", {
    pageNumber: Number(pageNum) > 0 ? Number(pageNum) - 1 : Number(pageNum),
    pageSize: 10,
    startDate: from,
    endDate: to,
    userName: username,
    ipAddress: ip,
  });

  const result = DownloadResSchema.parse(data.data);

  return result;
};

// TODO: 서버 자동화되면 삭제
export const registerDowLog = async (proposalId: string, downloadType: "JSON" | "EXCEL") => {
  const { data } = await Axios.post("/api/v3/download-history/register", {
    proposalId,
    downloadType,
  });

  return data.data;
};
