import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FieldErrors, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { LabelInput } from "common/input";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { ButtonBox, InfoItem } from "common/other";
import { Content } from "common/container";
import { TStaff } from "types/common";
import { TDefaultStaffInput } from "types/management";
import queryKey from "constants/queryKey";
import { updateUserData } from "api/management";
import { useModal } from "hooks";

import styles from "style/pages/management/staff/staffEditor.module.scss";
import ROLE from "constants/role";
import { getUserInfo } from "api/common";

type TProps = {
  data: TStaff;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
};

export default function StaffEditor({ data, setIsEditMode }: TProps) {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: userInfo } = useQuery(queryKey.GET_USERINFO, getUserInfo);
  const isB2bAdminLogin = userInfo?.roles === ROLE.B2B_ADMIN; // 로그인 유저가 대표담당자인지 확인

  const { name, department, jobPosition, email, phone, tel, company, role } = data;
  const isB2bAdmin = role === ROLE.B2B_ADMIN;
  const isOverseas = company.businessType === "1"; // '1': 해외, '0': '국내'

  const [isUpdateModal, setIsUpdateModal] = useModal();
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isValidateCheck, setIsValidateCheck] = useModal();
  const [errorMsg, setErrorMsg] = useState("");

  const defaultValues = { staffName: name, department, jobPosition, phone, tel };

  const methods = useForm<TDefaultStaffInput>({ mode: "onChange", defaultValues });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  // 담당자 계정 수정 API 연동
  const { mutate: updateMutation, error: updateError, isLoading } = useMutation(updateUserData);

  const handleUpdate: SubmitHandler<TDefaultStaffInput> = (input) => {
    const body = {
      ...input,
      id,
      email,
      companyId: Number(company.id),
      type: company.businessType,
      role: isB2bAdmin ? ROLE.B2B_ADMIN : ROLE.B2B_MANAGER,
    };

    setIsConfirmModal();

    updateMutation(body, {
      onSuccess: setIsUpdateModal,
      onError: setIsErrorShowing,
    });
  };

  // 수정 완료 모달 닫기 버튼 클릭 이벤트
  const handleClickConfirm = () => {
    setIsUpdateModal();
    setIsEditMode(false);
    queryClient.invalidateQueries([queryKey.GET_DETAIL_STAFF]);
  };

  const handleError = (err: FieldErrors) => {
    if (err) {
      const errorMessages = Object.values(err).filter((v) => v?.message);
      if (errorMessages.length) {
        setErrorMsg(String(errorMessages[0]?.message));
      }
      setIsValidateCheck();
    }
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Content>
      <FormProvider {...methods}>
        <form className={styles.container} onSubmit={handleSubmit(setIsConfirmModal, handleError)}>
          <LabelInput
            label="이름"
            id="staffName"
            placeholder="담당자명을 입력하세요"
            isNull={!!errors.staffName}
            maxLength={50}
          />
          <InfoItem label="기업 / 기관명" info={company.name} />
          <InfoItem label="소재지" info={isOverseas ? "해외" : "국내"} />
          <InfoItem label="권한" info={isB2bAdmin ? "대표담당자" : "담당자"} />
          <LabelInput
            label="부서명"
            id="department"
            placeholder="부서명을 입력하세요"
            isNull={!!errors.department}
            maxLength={50}
          />
          <LabelInput
            label="직급"
            id="jobPosition"
            placeholder="직급을 입력하세요"
            isNull={!!errors.jobPosition}
            maxLength={50}
          />
          <InfoItem label="이메일" info={email} />
          <LabelInput
            label="개별 연락처"
            type="number"
            id="phone"
            placeholder="'-'를 제외한 숫자만 입력하세요"
            isNull={!!errors.phone}
            validate="tel"
          />
          <LabelInput
            label="직통번호"
            type="number"
            id="tel"
            placeholder="'-'를 제외한 숫자만 입력하세요"
            isNull={!!errors.tel}
            validate="tel"
          />

          {isB2bAdminLogin && <ButtonBox type="submit" next="수정" back="목록으로" />}

          {isB2bAdminLogin && <ButtonBox back="목록으로" next="수정" handleClickPrev={() => navigate("/management")} />}

          {/* Modals */}
          {isValidateCheck && <Dialog description={errorMsg} btnType="confirm" handleClose={setIsValidateCheck} />}

          {isConfirmModal && (
            <Dialog
              description={`${isB2bAdmin ? "대표담당자" : "담당자"} 정보를 수정하시겠습니까?`}
              btnType="submit"
              handleClose={setIsConfirmModal}
              handleSubmit={handleSubmit(handleUpdate, handleError)}
            />
          )}

          {isUpdateModal && (
            <Dialog
              description={`${isB2bAdmin ? "대표담당자" : "담당자"} 정보가 수정되었습니다.`}
              btnType="confirm"
              handleClose={handleClickConfirm}
            />
          )}

          {isErrorShowing && <ErrorModal error={updateError} onConfirmHandler={setIsErrorShowing} />}
        </form>
      </FormProvider>
    </Content>
  );
}
