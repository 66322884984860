import { Path, FieldValues, useFormContext } from "react-hook-form";
import cx from "classnames";
import styles from "style/pages/management/company/labelInputRadioBox.module.scss";

type TOption = {
  id: string;
  value: any;
  label: string;
  DefaultValue?: boolean;
};
type TProps = {
  must?: boolean;
  id: Path<FieldValues>;
  label?: string;
  isNull?: boolean;
  options: TOption[];
  labelWidth?: string;
};

export default function LabelInputRadioBox({ must = true, label, id, isNull, labelWidth, options }: TProps) {
  const { register } = useFormContext();

  return (
    <div className={cx(styles.container, { [styles.nullStyle]: isNull })}>
      <label htmlFor={id} style={{ width: labelWidth }}>
        {label}
        {must && <span>*</span>}
      </label>

      {options.map((option) => {
        return (
          <div key={option.id} className={styles.radioBox}>
            <input
              type="radio"
              value={option.value}
              defaultChecked={option.DefaultValue}
              id={option.id}
              {...register(id, { required: "필수 항목을 모두 입력해 주세요." })}
            />
            <label htmlFor={option.id} className={styles.radioLabel}>
              {option.label}
            </label>
          </div>
        );
      })}
    </div>
  );
}
