import styles from "style/pages/advertisement/adUI/label.module.scss";

type TProps = {
  children: React.ReactNode;
  must?: boolean;
};

export default function Label({ children, must = true }: TProps) {
  return (
    <div className={styles.wrapper}>
      {children}
      {must && <span>*</span>}
    </div>
  );
}
