import styles from "style/pages/market/marketComponents/checkTitle.module.scss";

type TProps = {
  label: string;
  width?: string;
  height?: string;
};

export default function CheckTitle({ label, width, height }: TProps) {
  return (
    <div className={styles.wrapper} style={{ width, height }}>
      {label}
    </div>
  );
}
