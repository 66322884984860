/* eslint-disable react/button-has-type */
import React, { ComponentProps } from "react";
import cx from "classnames";
import styles from "style/common/button/chip.module.scss";

type TDefaultBtnProps = ComponentProps<"button"> & {
  mode?: "color" | "line" | "default";
  width?: string;
  height?: string;
  children?: React.ReactNode;
};

/**
 * Chip - 범용 버튼 컴포넌트
 * mode에 따라 디자인 변경 가능
 * width, hegiht로 버튼 크기 조절 가능
 */

export default function Chip({ mode = "line", width, height, children, style, ...props }: TDefaultBtnProps) {
  return (
    <button style={{ ...style, width, height }} className={cx(styles.button, styles[mode])} {...props}>
      {children}
    </button>
  );
}
