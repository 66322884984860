import cx from "classnames";
import { useQuery } from "react-query";

import PreviewHeader from "./PreviewHeader";
import { translateTargetMap } from "constants/market";
import queryKey from "constants/queryKey";
import { formatDataType } from "pages/market/createMarketPage/sectionContent/formatDataType";

import styles from "style/pages/advertisement/previewSidebar/previewCard.module.scss";
import { getCompanyDetail } from "api/company";

type TProps = {
  cardData: {
    bannerUrl: string;
    title: string;
    rewardPolicyAmount: number;
    maxIssuable: number;
    collectTarget: string[];
    privacyPolicy: number;
  };
};

export default function PreviewCard({ cardData }: TProps) {
  const { bannerUrl, title, rewardPolicyAmount, maxIssuable, collectTarget, privacyPolicy } = cardData;
  const { data: companyData } = useQuery(queryKey.GET_DETAIL_COMPANY, getCompanyDetail);

  const formatCollectTarget = () => {
    if (collectTarget) {
      if (collectTarget && collectTarget.length === 0) return "수집데이터";
      if (collectTarget && collectTarget.length === 1) return `${collectTarget[0]}데이터`;
      return `${formatDataType(collectTarget)}데이터`;
    }
    return "수집데이터 ";
  };

  return (
    <div className={styles.wrapper}>
      <PreviewHeader />
      {bannerUrl ? (
        <img src={bannerUrl} alt="거래를 선택해 주세요" className={styles.bannerImage} />
      ) : (
        <div className={styles.emptyImg}>{`거래를\n선택해 주세요`}</div>
      )}

      <div className={styles.infoWrapper}>
        <div className={styles.company}>{companyData?.companyName}</div>
        <div className={styles.title}>{title || "거래를 선택해 주세요"}</div>
        <div className={styles.mockButtons}>
          <div className={styles.highlight}>
            {rewardPolicyAmount ? `${rewardPolicyAmount.toLocaleString()}P` : "지급포인트"}
          </div>
          <div className={cx({ [styles.highlight]: privacyPolicy === 1 })}>{privacyPolicy ? "실명" : "익명"}거래</div>
          <div>{maxIssuable ? `${maxIssuable.toLocaleString()}명` : "목표인원수"}</div>
          <div>{translateTargetMap[formatCollectTarget()] || formatCollectTarget()}</div>
        </div>
      </div>
    </div>
  );
}
