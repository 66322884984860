import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { utils, writeFile } from "xlsx";

import Label from "pages/advertisement/adUI/Label";
import { DefaultBtn } from "common/button";
import DateRange from "common/input/DatePicker";
import { Pagination } from "common/navigation";
import { Table } from "common/table";
import useDateRange from "hooks/useDateRange";
import { TAdDashboard } from "types/advertisement";

import { RerollIcon } from "assets/svgs/icon";
import styles from "style/pages/advertisement/adDetail/adDayPerformance.module.scss";

// 광고 일별 성과 내역 컴포넌트 (진행중/종료 상태에서 표시)
export default function AdDayPerformance({ data }: { data: TAdDashboard[] }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchStartDate = searchParams.get("from") || "";
  const searchEndDate = searchParams.get("to") || "";
  const todayDate = dayjs(new Date()).format("YYYYMMDDHHmmss"); // today
  const { startDate, endDate, onChangeDate, resetDate } = useDateRange();

  let filterData = [...data];

  // date filter
  if (searchStartDate && searchEndDate) {
    filterData = filterData?.filter((el) =>
      dayjs(el.date).isBetween(dayjs(searchStartDate), dayjs(searchEndDate), "day", "[]"),
    );
  }

  // excel download data format
  const excelData = filterData?.map((item) => {
    const 일자 = item.date.split("-").join(".");
    const 노출수 = item.expsCnt.toLocaleString();
    const 클릭수 = item.clickCnt.toLocaleString();
    const 클릭률 = `${Math.round((item.clickCnt / item.expsCnt) * 100)}%`;

    return { 일자, 노출수, 클릭수, 클릭률 };
  });

  // excel download
  const handleExport = () => {
    const sheet = utils.json_to_sheet(excelData);
    const sheetWithoutData = utils.aoa_to_sheet([["일자", "노출수", "클릭수", "클릭률"]]);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, data.length === 0 ? sheetWithoutData : sheet, `adv_stat_${todayDate}`);
    writeFile(workbook, `AdvStat_${todayDate}.xlsx`);
  };

  const dataLength = filterData?.length;
  const pageNum = Number(searchParams.get("pageNum") || 1);

  const totalPages = Math.ceil(dataLength! / 10) || 1;
  filterData = filterData?.slice((pageNum - 1) * 10, pageNum * 10);

  const handleClickSearch = () => {
    if (startDate && endDate) {
      setSearchParams(
        (params) => {
          startDate ? params.set("from", dayjs(startDate).format("YYYY-MM-DD")) : params.delete("from");
          endDate ? params.set("to", dayjs(endDate).format("YYYY-MM-DD")) : params.delete("to");
          return params;
        },
        { replace: true },
      );
    }
  };

  const tableInfo = filterData?.map((item, idx: number) => [
    idx + 1,
    item.date.split("-").join("."),
    item.expsCnt.toLocaleString(),
    item.clickCnt.toLocaleString(),
    `${Math.round((item.clickCnt / item.expsCnt) * 100)}%`,
  ]);

  const tableData = {
    columns: [
      ["일자", "274px"],
      ["노출수", "274px"],
      ["클릭수", "274px"],
      ["클릭률", "274px"],
    ],
    dataList: tableInfo || [],
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <Label must={false}>일별 성과 내역</Label>

        <div className={styles.searchContainer}>
          <div className={styles.datepickerContainer}>
            <DateRange startDate={startDate} endDate={endDate} onChange={onChangeDate} />
            <DefaultBtn size="small" onClick={handleClickSearch}>
              검색
            </DefaultBtn>
          </div>

          <div className={styles.resetContainer}>
            {startDate ? (
              <button type="button" className={styles.searchClearBtn} onClick={() => resetDate()}>
                <RerollIcon width="24px" height="24px" />
                초기화하기
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <Table columns={tableData.columns} dataList={tableData.dataList} />

        <div className={styles.btnBox}>
          <DefaultBtn onClick={handleExport}>엑셀 다운로드</DefaultBtn>
        </div>

        <Pagination data={{ totalPages, pageSize: 10 }} />
      </div>
    </div>
  );
}
