import { useState } from "react";
import { useMutation } from "react-query";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";

import { DefaultBtn } from "common/button";
import { LabelInput } from "common/input";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { Content } from "common/container";
import { Footer, InfoItem } from "common/other";
import { updateCompanyData } from "api/company";
import { accessRole, uploadCommonImgApi } from "api/common";
import { useModal } from "hooks";
import { TCompanyInput, TUserInfo } from "types/common";

import styles from "style/pages/management/company/companyEditor.module.scss";
import ImageItem from "pages/management/company/ImageItem";
import LabelInputRadioBox from "pages/management/company/LabelInputRadioBox";

type TProps = {
  data: TUserInfo;
  onNext: () => void;
};

export default function CompanyWrite({ data, onNext }: TProps) {
  const [isNullErrorModal, setIsNullErrorModal] = useModal();
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isUpdateModal, setIsUpdateModal] = useModal();
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [errorMsg, setErrorMsg] = useState("");

  const defaultValues = {
    businessNumber: "",
    businessHeadOfficeAddress: "",
    businessRepresentativeName: "",
    tel: "",
    privacyPolicyUrl: "",
    businessRegistrationImageUrl: "",
    privacyPolicyOfficer: "",
    privacyPolicyOfficerTel: "",
    problemHandlingDept: "",
    problemHandlingDeptTel: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    businessDigitalTaxInvoiceEmail: "",
    homepageUrl: "",
    bankbookCopyImageUrl: "",
    logoUrl: "",
  };

  const methods = useForm<TCompanyInput>({ mode: "onChange", defaultValues });

  const {
    handleSubmit,
    formState: { errors },
    getValues,
  } = methods;

  // 기업 정보 수정 API 연동
  const { mutate: updateMutation, error: updateError, isLoading } = useMutation(updateCompanyData);
  const { mutateAsync: uploadImgMutation, error: uploadImgError } = useMutation(uploadCommonImgApi);

  const handleClickConfirm = () => {
    onNext();
  };

  const handleErrorModal = (error: FieldErrors) => {
    if (error) {
      const errorMessages = Object.values(error).filter((v) => v?.message);

      if (errorMessages.length) {
        setErrorMsg(String(errorMessages[0]?.message));
      } else if (
        getValues(["businessRegistrationImageUrl", "bankbookCopyImageUrl", "logoUrl"]).map((el) => el === null)
      ) {
        setErrorMsg("파일을 업로드해 주세요.");
      } else {
        setErrorMsg("알 수 없는 오류가 발생했습니다.");
      }
    }
    setIsNullErrorModal();
  };

  const handleUpdate = async (input: TCompanyInput) => {
    const formatImg = async (file: File | string) => {
      const result = await accessRole();

      const typeFile = file as File;
      const body = { did: data.did, token: result.token, file: typeFile };

      const imgLink =
        typeof file === "string"
          ? file
          : await uploadImgMutation(body, {
              onError: () => {
                setIsConfirmModal();
                setIsErrorShowing();
              },
            }).then((el) => el.fullUrlPath);

      return imgLink;
    };

    const body = {
      id: String(data.companyId),
      name: data.companyName,
      businessNumber: input.businessNumber,
      businessHeadOfficeAddress: input.businessHeadOfficeAddress,
      tel: input.tel,
      businessRepresentativeName: input.businessRepresentativeName,
      businessRegistrationImageUrl: await formatImg(input.businessRegistrationImageUrl),
      bankbookCopyImageUrl: await formatImg(input.bankbookCopyImageUrl),
      logoUrl: await formatImg(input.logoUrl),
      bankAccountNumber: [input.bankName, input.accountHolderName, input.accountNumber].join(","),
      businessType: data.businessType || "", // 소재지
      piiUrl: data.piiUrl, // 데이터 수집 서버 URL
      privacyPolicyUrl: input.privacyPolicyUrl, // 개인정보 처리방침 URL
      businessDigitalTaxInvoiceEmail: input.businessDigitalTaxInvoiceEmail, // 전자세금계산서용 이메일 주소
      businessUnitTaxation: input.businessUnitTaxation, // 사업자 단위 과세 적용사업자 여부
      homepageUrl: input.homepageUrl ?? "", // 홈페이지 URL
      privacyPolicyInfo: JSON.stringify({
        privacyPolicyOfficer: input.privacyPolicyOfficer, // 개인정보 보호 책임자 명
        privacyPolicyOfficerTel: input.privacyPolicyOfficerTel, // 개인정보 보호 책임자 연락처
        problemHandlingDept: input.problemHandlingDept, // 고충처리 담당부서 명
        problemHandlingDeptTel: input.problemHandlingDeptTel, // 고충처리 담당부서 연락처
      }),
    };

    setIsConfirmModal();
    updateMutation(body, {
      onSuccess: setIsUpdateModal,
      onError: () => {
        setIsConfirmModal();
        setIsErrorShowing();
      },
    });
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Content>
      <FormProvider {...methods}>
        <form className={styles.contentContainer} onSubmit={handleSubmit(setIsConfirmModal, handleErrorModal)}>
          <InfoItem labelWidth="290px" label="기업명" info={data.companyName} />
          <InfoItem labelWidth="290px" label="소재지" info={data.businessType === "1" ? "해외" : "국내"} />
          <InfoItem labelWidth="290px" label="기업 DID" info={data.did} />
          <InfoItem labelWidth="290px" label="데이터 수집 서버 URL" info={data.piiUrl} />

          <ImageItem
            id="businessRegistrationImageUrl"
            accept=".pdf, .png, .jpg, .jpeg"
            sizeLimit={20}
            labelName="사업자등록증"
          />

          <LabelInput
            labelWidth="290px"
            label="사업자등록번호"
            id="businessNumber"
            placeholder="'-'를 제외한 숫자만 입력하세요."
            isNull={!!errors.businessNumber}
            type="number"
            validate="businessNumber"
          />

          <LabelInput
            labelWidth="290px"
            label="주소"
            id="businessHeadOfficeAddress"
            placeholder="주소를 입력하세요"
            isNull={!!errors.businessHeadOfficeAddress}
            maxLength={150}
          />

          <LabelInput
            labelWidth="290px"
            label="대표자명"
            id="businessRepresentativeName"
            placeholder="대표자명을 입력하세요"
            isNull={!!errors.businessRepresentativeName}
            maxLength={50}
          />

          <LabelInput
            labelWidth="290px"
            label="대표번호"
            id="tel"
            placeholder="'-'를 제외한 숫자만 입력하세요"
            isNull={!!errors.tel}
            type="number"
            validate="tel"
          />

          <LabelInput
            labelWidth="290px"
            label="개인정보 처리방침 URL"
            id="privacyPolicyUrl"
            placeholder="생성하려는 기업의 개인정보처리방침 페이지 주소를 입력하세요"
            isNull={!!errors.privacyPolicyUrl}
            validate="url"
            maxLength={200}
          />

          <LabelInput
            labelWidth="290px"
            label="개인정보 보호 책임자명"
            id="privacyPolicyOfficer"
            placeholder="개인정보 보호 책임자명을 입력하세요"
            isNull={!!errors.privacyPolicyOfficer}
            maxLength={50}
          />

          <LabelInput
            labelWidth="290px"
            label="개인정보 보호 책임자 연락처"
            id="privacyPolicyOfficerTel"
            placeholder="개인정보 보호 책임자 혹은 담당 부서의 연락처를 입력하세요"
            isNull={!!errors.privacyPolicyOfficerTel}
            type="number"
            validate="tel"
          />

          <LabelInput
            labelWidth="290px"
            label="고충처리 담당부서명"
            id="problemHandlingDept"
            placeholder="고객 고충처리 담당부서명을 입력하세요"
            isNull={!!errors.problemHandlingDept}
            maxLength={50}
          />

          <LabelInput
            labelWidth="290px"
            label="고충처리 담당부서 연락처"
            id="problemHandlingDeptTel"
            placeholder="고충처리 담당부서 연락처를 입력하세요"
            isNull={!!errors.problemHandlingDeptTel}
            type="number"
            validate="tel"
          />

          <ImageItem id="bankbookCopyImageUrl" accept=".pdf, .png, .jpg, .jpeg" sizeLimit={20} labelName="통장 사본" />

          <LabelInput
            labelWidth="290px"
            label="은행명"
            id="bankName"
            placeholder="은행명을 입력하세요"
            isNull={!!errors.bankName}
            maxLength={30}
          />

          <LabelInput
            labelWidth="290px"
            label="예금주명"
            id="accountHolderName"
            placeholder="예금주명을 입력하세요"
            isNull={!!errors.accountHolderName}
            maxLength={50}
          />

          <LabelInput
            labelWidth="290px"
            label="계좌번호"
            id="accountNumber"
            placeholder="계좌번호를 입력하세요"
            isNull={!!errors.accountNumber}
            type="number"
            validate="numberLength20"
          />

          <LabelInput
            labelWidth="290px"
            label="전자세금계산서용 이메일 주소"
            id="businessDigitalTaxInvoiceEmail"
            placeholder="전자세금계산서 발행에 사용할 이메일 주소를 입력하세요"
            isNull={!!errors.businessDigitalTaxInvoiceEmail}
            validate="email"
            maxLength={100}
          />

          <LabelInputRadioBox
            label="사업자 단위 과세 적용사업자 여부"
            labelWidth="290px"
            options={[
              {
                id: "unitTaxationOn",
                value: true,
                label: "여",
              },
              {
                id: "unitTaxationOff",
                value: false,
                label: "부",
              },
            ]}
            id="businessUnitTaxation"
            isNull={!!errors.businessUnitTaxation}
          />

          <LabelInput
            labelWidth="290px"
            label="홈페이지 URL"
            id="homepageUrl"
            validate="url"
            placeholder="기업 홈페이지 주소를 입력하세요"
            must={false}
            maxLength={512}
          />

          <ImageItem id="logoUrl" accept=".png, .jpg, .jpeg" labelName="기업 로고" />

          <Footer>
            <DefaultBtn type="submit" size="medium">
              등록
            </DefaultBtn>
          </Footer>

          {/* Modals */}
          {isNullErrorModal && <Dialog description={errorMsg} btnType="confirm" handleClose={setIsNullErrorModal} />}

          {isConfirmModal && (
            <Dialog
              title="기업 정보를 등록하시겠습니까?"
              description="등록된 정보는 수정할 수 있습니다."
              btnType="submit"
              handleClose={setIsConfirmModal}
              handleSubmit={handleSubmit(handleUpdate, handleErrorModal)}
            />
          )}

          {isUpdateModal && (
            <Dialog description="기업 정보가 등록되었습니다." btnType="confirm" handleClose={handleClickConfirm} />
          )}

          {isErrorShowing && <ErrorModal error={updateError || uploadImgError} onConfirmHandler={setIsErrorShowing} />}
        </form>
      </FormProvider>
    </Content>
  );
}
