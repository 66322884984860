import cx from "classnames";
import { ComponentProps } from "react";

import styles from "style/pages/market/didFilterPage/components/label.module.scss";

type TProps = { required?: boolean } & ComponentProps<"label">;

function Label({ children, className, required = false, ...otherProps }: TProps) {
  return (
    <label className={cx(styles.label, className)} {...otherProps}>
      {children}
      <span className={cx({ [styles.required]: required })}>*</span>
    </label>
  );
}

export default Label;
