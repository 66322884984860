import { ComponentPropsWithRef } from "react";
import cx from "classnames";
import styles from "style/common/other/footer.module.scss";

export default function Footer({ children, className, ...props }: ComponentPropsWithRef<"div">) {
  return (
    <div className={cx(styles.wrapper, className)} {...props}>
      {children}
    </div>
  );
}
