import { SyntheticEvent, forwardRef, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import dayjs from "dayjs";
import ko from "date-fns/locale/ko";
import cx from "classnames";
import { CalendarIcon, SingleAngleBracketIcon } from "assets/svgs/icon";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./datePicker.module.scss";
import "./datePicker.css";

type TStartProps = {
  value?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  mode?: "rangeInput" | "dateInput";
};

const formatDate = (date?: Date | null) => {
  return date ? dayjs(date).format("YYYY.MM.DD") : "";
};

export const CustomInput = forwardRef<HTMLButtonElement, TStartProps>(function CustomStartInput(
  { value, onClick, width, height, mode = "rangeInput" },
  ref
) {
  return (
    <button
      type="button"
      ref={ref}
      onClick={onClick}
      className={cx(styles.customInput, styles[mode])}
      style={{ width, height }}
    >
      {value || (
        <p className={styles.customInputPlaceholder}>
          {mode === "rangeInput" ? "연도.월.일 ~ 연도.월.일" : "연도.월.일"}
        </p>
      )}
      <CalendarIcon width={14} height={14} />
    </button>
  );
});

export function CustomHeader({
  date,
  decreaseMonth,
  increaseMonth,
  increaseYear,
  decreaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: ReactDatePickerCustomHeaderProps) {
  return (
    <div className={styles.customHeader}>
      <div className={styles.decrease}>
        <button type="button" disabled={prevMonthButtonDisabled} className={styles.decreaseYear} onClick={decreaseYear}>
          <SingleAngleBracketIcon />
          <SingleAngleBracketIcon />
        </button>
        <button
          type="button"
          disabled={nextMonthButtonDisabled}
          className={styles.decreaseMonth}
          onClick={decreaseMonth}
        >
          <SingleAngleBracketIcon />
        </button>
      </div>
      <h1>{`${dayjs(date).month() + 1}월 ${dayjs(date).year()}년`}</h1>
      <div className={styles.increase}>
        <button type="button" disabled={prevMonthButtonDisabled} className={styles.increaseYear} onClick={increaseYear}>
          <SingleAngleBracketIcon />
          <SingleAngleBracketIcon />
        </button>
        <button
          type="button"
          disabled={nextMonthButtonDisabled}
          className={styles.increaseMonth}
          onClick={increaseMonth}
        >
          <SingleAngleBracketIcon />
        </button>
      </div>
    </div>
  );
}

type TDateRangeProps = {
  width?: string;
  height?: string;
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  onChange: (date: [Date | null, Date | null]) => void;
  setParams?: boolean;
};

/** DateRange hook : src/hooks/useDateRange.tsx */
export default function DateRange({ width, height, startDate, endDate, onChange, setParams }: TDateRangeProps) {
  const dateToString = startDate || endDate ? `${formatDate(startDate)} ~ ${formatDate(endDate)}` : "";

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!setParams) return;
    setSearchParams(
      (params) => {
        startDate ? params.set("from", dayjs(startDate).format("YYYY-MM-DD")) : params.delete("from");
        endDate ? params.set("to", dayjs(endDate).format("YYYY-MM-DD")) : params.delete("to");
        return params;
      },
      { replace: true }
    );
  }, [startDate, endDate, setSearchParams, setParams]);

  return (
    <div className={styles["react-datepicker"]}>
      <DatePicker
        className={styles["react-datepicker"]}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        value={dateToString}
        onChange={onChange}
        customInput={<CustomInput width={width} height={height} />}
        renderCustomHeader={(headerProps) => <CustomHeader {...headerProps} />}
        locale={ko}
      />
    </div>
  );
}
