import dayjs from "dayjs";
import { TMarketStateData } from "types/market";
import { nonPeriodicCollect } from "../sectionTargetData/CollectPeriod";

export const validateSubmitData = ({
  targetState,
  budgetState,
  contentState,
  privacyState,
}: TMarketStateData): string | false => {
  const notComplete = [];
  if (privacyState.personalIdentification === "nonAnonymous" && privacyState.thirdPartyStatus === 1) {
    !privacyState.company && notComplete.push("개인식별정보 제공 기업");
    !privacyState.provisionPurpose?.filter((v) => v).length && notComplete.push("개인식별정보 제공 목적");
  }
  if (targetState.collectData.length === 0) notComplete.push("수집 데이터");
  if (targetState.dataPurpose.length === 0) notComplete.push("데이터 거래 목적");
  if (
    !budgetState.transactionPeriod[0] ||
    !budgetState.transactionPeriod[1] ||
    dayjs(budgetState.transactionPeriod[1]).add(1, "day").isBefore(budgetState.transactionPeriod[0])
  )
    notComplete.push("거래 기간");
  if (
    targetState.collectData.filter((collect) => !nonPeriodicCollect.includes(collect)).length !== 0 &&
    targetState.collectPeriod === ""
  )
    notComplete.push("수집 기간");
  if (dayjs(budgetState.transactionPeriod[0]).isBefore(dayjs(), "day")) notComplete.push("거래 시작 날짜");
  if (budgetState.maxIssuable <= 0) notComplete.push("목표 인원");
  if (/\[(\d{1,3}(,\d{3})+명)|\[(\d+명)/g.test(contentState.transactionName)) {
    if (
      budgetState.maxIssuable.toString() !==
      (contentState.transactionName.replace(",", "").match(/\d+/)![0] as unknown as string)
    )
      notComplete.push("타이틀 목표 인원 수 불일치");
  }
  if (/(\d{1,3}(,\d{3})*(\.\d+)?)\s*포인트/.test(contentState.transactionName)) {
    if (
      `${(budgetState.customReward || budgetState.reward).toLocaleString("en-US")} 포인트` !==
      (contentState.transactionName.match(/(\d{1,3}(,\d{3})*(\.\d+)?)\s*포인트/)![0] as unknown as string)
    )
      notComplete.push("타이틀 포인트 불일치");
  }
  if (!contentState.transactionName) notComplete.push("데이터 거래명");
  if (!contentState.banner && targetState.product.type === "myd") notComplete.push("데이터 거래 배너");
  if (notComplete.length > 0) return notComplete.join(", ");
  return false;
};
