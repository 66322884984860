import { TQueryData } from "types/proposal";
import {
  TRealmQueryCondition,
  formatDate,
  getBirthYearFromAge,
  getStartDate,
  makeRealmQuery,
} from "utils/realm/makeRealmQuery";

/**
 * 사용자 타켓팅 mapping table
 */
export const USER_KEY_MAP = {
  userInfo: [
    {
      schema: "UserVerified",
      query: (data: TQueryData) => {
        const { gender, ageGroup } = data;

        const [minAge, maxAge] = ageGroup.split("-");
        // 나이 -> 연도날짜
        const minBirthDate = new Date(`${getBirthYearFromAge(Number(maxAge))}-01-01 00:00:00`);
        const maxBirthDate = new Date(`${getBirthYearFromAge(Number(minAge))}-12-31 24:00:00`);

        return makeRealmQuery({
          conditions: [
            { field: "gender", operator: "==", value: gender === "male" ? 1 : 0, disable: gender === "all" },
            { field: "birthDate", operator: ">=", value: formatDate(minBirthDate), disable: ageGroup === "all" },
            { field: "birthDate", operator: "<", value: formatDate(maxBirthDate), disable: ageGroup === "all" },
          ],
          logicalOperator: "AND",
        });
      },
    },
    {
      schema: "UserProfile",
      query: (data: TQueryData) => {
        const { regionOfResidence } = data;

        const newRegion = regionOfResidence.replace(/-전체/g, "");
        const regionConditions: TRealmQueryCondition[] = newRegion.split(",").map((region) => ({
          field: "regionOfResidence",
          operator: "LIKE",
          value: `'${region}*'`,
          disable: regionOfResidence === "전체",
        }));

        return makeRealmQuery({
          conditions: regionConditions,
          logicalOperator: "OR",
        });
      },
    },
  ],
};

/**
 * 데이터 타켓팅 mapping table
 */
export const DATA_KEY_MAP = {
  // 건강
  medicalCheckup: [
    {
      schema: "MedicalCheckup",
      query: () => "results.@count >= 1",
    },
  ],
  medicationRecord: [
    {
      schema: "MedicalRecord",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);

        const newEndDate = getStartDate("2M", endDate);
        const startDate = getStartDate(data.term, newEndDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "treatmentStartDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "treatmentStartDate",
              operator: "<",
              value: formatDate(newEndDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  patientEMR: [
    {
      schema: "PatientEMR",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "recordedDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "recordedDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  exerciseResult: [
    {
      schema: "ExerciseResult",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "finishedDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "finishedDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],

  // 금융
  bankTransaction: [
    {
      schema: "BankTransaction",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "transactionTime",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "transactionTime",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  card: [
    {
      schema: "CardDomesticTransaction",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "approvedTime",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "approvedTime",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  securities: [
    {
      schema: "StockAccount",
      query: () => "assets.@count >= 1",
    },
  ],
  insurance: [
    {
      schema: "Insurance",
      query: () => "contracts.@count >= 1 OR carContracts.@count >= 1",
    },
  ],

  // 생활
  googleYoutubeKeyword: [
    {
      schema: "Keyword",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "searchDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "searchDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  shoppingMallOrder: [
    {
      schema: "ShoppingOrder",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "orderDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "orderDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
};

/**
 * 데이터 수집 mapping table
 */
export const COLLECT_KEY_MAP = {
  // 사용자 정보
  userInfo: [
    {
      schema: "UserInfo",
      query: () => "*",
    },
  ],

  // 건강
  medicalCheckup: [
    {
      schema: "MedicalCheckup",
      query: () => "results.@count >= 1",
    },
  ],
  medicationRecord: [
    {
      schema: "MedicalRecord",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);

        const newEndDate = getStartDate("2M", endDate);
        const startDate = getStartDate(data.term, newEndDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "treatmentStartDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "treatmentStartDate",
              operator: "<",
              value: formatDate(newEndDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  patientEMR: [
    {
      schema: "PatientEMR",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "recordedDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "recordedDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  exerciseResult: [
    {
      schema: "ExerciseResult",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "finishedDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "finishedDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],

  // 금융
  bankTransaction: [
    {
      schema: "BankTransaction",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "transactionTime",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "transactionTime",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  card: [
    {
      schema: "CardDomesticTransaction",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "approvedTime",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "approvedTime",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
    {
      schema: "CardForeignTransaction",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "approvedTime",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "approvedTime",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  securities: [
    {
      schema: "StockAccount",
      query: () => "assets.@count >= 1",
    },
  ],
  insurance: [
    {
      schema: "Insurance",
      query: () => "contracts.@count >= 1 OR carContracts.@count >= 1",
    },
  ],

  // 생활
  googleYoutubeKeyword: [
    {
      schema: "Keyword",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "searchDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "searchDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
  shoppingMallOrder: [
    {
      schema: "ShoppingOrder",
      query: (data: TQueryData) => {
        const endDate = new Date(data.notBefore);
        const startDate = getStartDate(data.term, endDate);

        return makeRealmQuery({
          conditions: [
            {
              field: "orderDate",
              operator: ">=",
              value: formatDate(startDate),
            },
            {
              field: "orderDate",
              operator: "<",
              value: formatDate(endDate),
            },
          ],
          logicalOperator: "AND",
        });
      },
    },
  ],
};

export const CATEGORY_MAP = {
  medicalCheckup: 110101, // 건강검진
  medicationRecord: 110102, // 진료기록
  patientEMR: 110201, // EMR
  exerciseResult: 110301, // 운동

  bankTransaction: 100100, // 은행
  card: 100200, // 카드
  securities: 100300, // 증권
  insurance: 100400, // 보험

  googleYoutubeKeyword: 120101, // 검색어
  shoppingMallOrder: 120200, // 쇼핑
};
