export const finelabDataPurposeList = [
  { label: "이벤트 행사", value: "이벤트 행사" },
  { label: "포인트 지급", value: "포인트 지급" },
  { label: "상품 및 서비스 홍보 또는 안내", value: "상품 및 서비스 홍보 또는 안내" },
];

export const finelabPrivacyDataList = [
  {
    label: "익명 데이터 거래",
    value: "anonymous",
    disabled: true,
  },
  {
    label: "실명 데이터 거래",
    value: "nonAnonymous",
    disabled: false,
  },
];

export const finelabCollectDataList = [
  { label: "건강검진 데이터", value: "medicalCheckup", disabled: true },
  { label: "병원진료 및 투약 데이터", value: "medicationRecord", disabled: true },
  { label: "은행(예적금) 데이터", value: "bankTransaction", disabled: false },
  { label: "카드 데이터", value: "card", disabled: true },
  { label: "증권 데이터", value: "securities", disabled: true },
  { label: "보험 데이터", value: "insurance", disabled: false },
  { label: "Google/Youtube 검색 데이터", value: "googleYoutubeKeyword", disabled: true },
  { label: "쇼핑 데이터", value: "shoppingMallOrder", disabled: true },
];
