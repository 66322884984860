import Title from "common/other/Title";
import { Table, TableNav } from "common/table";
import styles from "style/pages/market/market.module.scss";
import { DefaultBtn } from "common/button";
import { Pagination } from "common/navigation";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getMarketList } from "api/market";
import queryKey from "constants/queryKey";
import { useRecoilState, useRecoilValue } from "recoil";
import { marketDialogAtom, marketErrorAtom, marketLoadingAtom } from "recoils/market";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { DateSearchBox } from "common/input";
import { Content } from "common/container";
import { useDataToTable, useFilterTableData, useFilterTableNav } from "./marketHooks";
import useResetMarketState from "./marketHooks/useResetMarketState";

export default function Market() {
  const { data, isLoading, isFetching } = useQuery([queryKey.GET_MARKET], getMarketList, {
    onError: (error) => setErrorState((prev) => ({ ...prev, error, isShow: true })),
  });

  const dialogState = useRecoilValue(marketDialogAtom);
  const isMutateLoading = useRecoilValue(marketLoadingAtom);
  const resetMarketState = useResetMarketState();
  const [{ error, isShow: isErrorShow, buttonText }, setErrorState] = useRecoilState(marketErrorAtom);

  const { columns, dataList, page: totalPages } = useFilterTableData(useDataToTable(data));
  const tableNavData = useFilterTableNav(data);

  const navigate = useNavigate();
  const handleMarketCreate = () => {
    resetMarketState();
    navigate("create");
  };
  const handleErrorModal = () => setErrorState((prev) => ({ ...prev, isShow: false }));

  return (
    <>
      <Title text="데이터 거래 목록" />

      {(isLoading || isFetching || isMutateLoading) && <LoadingModal />}

      <Content>
        <div className={styles.tableHeader}>
          <TableNav data={tableNavData} />
          <DateSearchBox />
        </div>

        <Table columns={columns} dataList={dataList!} />

        <div className={styles.buttonWrapper}>
          <DefaultBtn onClick={handleMarketCreate}>신규 등록</DefaultBtn>
        </div>

        <Pagination data={{ totalPages, pageSize: 10 }} />
      </Content>

      {isErrorShow && <ErrorModal error={error} buttonText={buttonText} onConfirmHandler={handleErrorModal} />}

      {dialogState.isShow && (
        <Dialog
          title={dialogState.title}
          description={dialogState.description}
          btnType={dialogState.btnType}
          submitBtnName={dialogState.submitBtnName}
          cancelBtnName={dialogState.cancelBtnName}
          handleClose={() => dialogState.handleClose()}
          handleSubmit={() => dialogState.handleSubmit!()}
        />
      )}
    </>
  );
}
