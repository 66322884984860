import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import cx from "classnames";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

import StatusChip from "./StatusChip";
import { useFilterAdTableData, useFilterAdTableNav } from "./useFilterAdTable";
import { DefaultBtn, Chip } from "common/button";
import { Pagination } from "common/navigation";
import { Title } from "common/other";
import { Table } from "common/table";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { cancelAd, deleteAd, getAdList } from "api/advertisement";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { TAdList } from "types/advertisement";
import { adPageAtom } from "recoils/ad";
import { TableTop } from "pages/advertisement/adUI";
import { formatDuration } from "utils/ad";

import styles from "style/pages/advertisement/adList/adList.module.scss";

dayjs.extend(isBetween);

export default function AdList() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setPageInfo = useSetRecoilState(adPageAtom);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum") || 1);
  const tabIdx = searchParams.get("tab") || "";
  const status = searchParams.get("status") || "";
  const isApproved = tabIdx !== "1";

  const [clickId, setClickId] = useState(0);
  const [adItemStatus, setAdItemStatus] = useState(-1);
  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isCheckModal, setIsCheckModal] = useModal();
  const [isErrorShowing, toggleIsErrorShowing] = useModal();

  const {
    data = [],
    isLoading,
    error,
  } = useQuery([queryKey.GET_AD_LIST, isApproved], () => getAdList(isApproved), {
    onError: toggleIsErrorShowing,
  });

  let result = data.sort((a, b) =>
    (isApproved ? a.target?.createDate < b.target?.createDate : a.createDate < b.createDate) ? 1 : -1,
  );

  if (!isApproved) result = result.filter((el) => !el.approved);
  else result = result?.filter((el) => el.approved);

  const {
    filteredData = [],
    size,
    totalPages,
  } = useFilterAdTableData(result, !isApproved ? navMap1 : navMap0, isApproved);
  const navData = useFilterAdTableNav({ nav: !isApproved ? navMap1 : navMap0, size });

  const handleClickTab = (idx: number) => {
    if (isNaN(idx)) return;
    searchParams.set("tab", String(idx));
    searchParams.set("status", "all");
    searchParams.set("pageNum", "1");
    searchParams.delete("keyword");
    setSearchParams(searchParams);
  };

  // 임시저장/요청취소/반려 광고 삭제
  const { mutate: deleteMutation, error: deleteError } = useMutation(deleteAd, {
    onSuccess: () => {
      setIsCheckModal();
      queryClient.invalidateQueries([queryKey.GET_AD_LIST]);
    },
    onError: toggleIsErrorShowing,
  });

  // 광고 승인 요청 취소
  const { mutate: cancelMutation, error: cancelError } = useMutation(cancelAd, {
    onSuccess: () => {
      setIsCheckModal();
      queryClient.invalidateQueries([queryKey.GET_AD_LIST]);
    },
    onError: toggleIsErrorShowing,
  });

  const handleClickBtn = (statusClick: number, id: number) => {
    setIsConfirmModal();
    setAdItemStatus(statusClick);
    setClickId(id);
  };

  const handleClickContinue = () => {
    if (adItemStatus === 0 || adItemStatus === 2 || adItemStatus === 3) {
      deleteMutation(clickId); // 광고 삭제
    }

    if (adItemStatus === 1) {
      cancelMutation({ id: clickId, status: 2 }); // 광고 승인 요청 취소
    }

    setIsConfirmModal();
  };

  if (isLoading) return <LoadingModal />;

  const tableInfo = filteredData.slice((pageNum - 1) * 6, pageNum * 6).map((item: TAdList, idx: number) => [
    idx + 1,
    filteredData.length - idx - (pageNum - 1) * 6,
    <StatusChip
      status={item.status}
      key={item.proposalId}
      startDate={dayjs(item.notBefore)}
      approved={item.approved}
    />,
    <div key={item.proposalId} className={styles.bannerImg}>
      {item.fileUrl ? (
        <img src={item.fileUrl} alt="banner" width="180px" height="60px" />
      ) : (
        <div style={{ height: "60px" }} />
      )}
    </div>,
    !item.approved && item.status === 0 ? (
      <button
        type="button"
        className={styles.titleBtnStyle}
        onClick={() => navigate(`/advertise/register?id=${String(item.id)}`)}
      >
        {item.title || "-"}
      </button>
    ) : (
      <Link
        to={`/advertise/info/${item.id}?id=${item.proposalId}`}
        key={item.proposalId}
        onClick={() => setPageInfo({ status, pageNum })}
      >
        {item.title || "-"}
      </Link>
    ),
    item.notBefore ? formatDuration(item.notBefore, item.notAfter) : "-",
    isApproved ? (
      item.clickCnt.toLocaleString() || 0
    ) : (
      <Chip
        key={item.proposalId}
        onClick={() => handleClickBtn(item.status, item.id)}
        width={item.status === 1 ? "87px" : "58px"}
      >
        {adStatusMap[item.status][0]}
      </Chip>
    ),
  ]);

  const tableData = {
    columns: [
      ["NO", "62px"],
      ["상태", "80px"],
      ["배너", "270px"],
      ["제목", "365px"],
      ["진행기간", "204px"],
      [isApproved ? "클릭 수" : "관리", "115px"],
    ],
    dataList: tableInfo || [],
  };

  return (
    <>
      <Title text="광고 목록" />

      <div className={styles.container}>
        <div className={styles.tabContainer}>
          {tabArr.map((item) => (
            <button
              type="button"
              key={item.idx}
              onClick={() => handleClickTab(item.idx)}
              className={cx(
                styles.tabItem,
                isApproved === Boolean(item.idx) || styles.focused,
                item.idx === 0 ? styles.left : styles.right,
              )}
            >
              {item.title}
            </button>
          ))}
        </div>

        <div className={styles.contentContainer}>
          <TableTop data={navData} />

          <Table columns={tableData.columns} dataList={tableData.dataList} />

          <div className={styles.btnBox}>
            <Link to="/advertise/register">
              <DefaultBtn>신규 등록</DefaultBtn>
            </Link>
          </div>

          <Pagination data={{ totalPages, pageSize: 6 }} />
        </div>
      </div>

      {/* modals */}
      {isConfirmModal && (
        <Dialog
          title={`${adStatusMap[adItemStatus][1]}을 ${adItemStatus === 1 ? "취소" : "삭제"}하시겠습니까?`}
          description={`${adItemStatus === 1 ? "취소" : "삭제"}하실 경우 복구하실 수 없습니다.\n계속 진행하시겠습니까?`}
          btnType="submit"
          handleClose={setIsConfirmModal}
          handleSubmit={handleClickContinue}
        />
      )}

      {isCheckModal && (
        <Dialog
          description={`${adStatusMap[adItemStatus][1]}이 ${adItemStatus === 1 ? "취소" : "삭제"}되었습니다.`}
          btnType="confirm"
          handleClose={setIsCheckModal}
        />
      )}

      {isErrorShowing && (
        <ErrorModal error={error || deleteError || cancelError} onConfirmHandler={toggleIsErrorShowing} />
      )}
    </>
  );
}

const adStatusMap: { [key: number]: string[] } = {
  0: ["삭제", "임시저장 내역"],
  1: ["요청 취소", "광고 승인 요청"],
  2: ["삭제", "승인 요청 취소내역"],
  3: ["삭제", "승인 반려 내역"],
};

const tabArr = [
  { idx: 0, title: "예정 / 진행중 / 종료" },
  { idx: 1, title: "임시저장 / 승인요청 / 요청취소 / 반려" },
];

const navMap0 = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "예정",
    status: "notStarted",
  },
  {
    label: "진행중",
    status: "inProgress",
  },
  {
    label: "종료",
    status: "ended",
  },
];

const navMap1 = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "임시저장",
    status: "temporary",
  },
  {
    label: "승인요청",
    status: "pending",
  },
  {
    label: "요청취소",
    status: "cancel",
  },
  {
    label: "반려",
    status: "reject",
  },
];
