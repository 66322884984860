import { postPasswordFind, postPasswordChange } from "api/login";
import { useMutation } from "react-query";

export function usePassword() {
  // 비밀번호 찾기
  const {
    mutate: passwordFindMutate,
    isLoading: isPasswordFindLoading,
    isError: isPasswordFindError,
    error: passwordFindError,
  } = useMutation(postPasswordFind);

  // 비밀번호 변경
  const {
    mutate: passwordChangeMutate,
    isLoading: isPasswordChangeLoading,
    isError: isPasswordChangeError,
    error: passwordChangeError,
  } = useMutation(postPasswordChange);

  return {
    passwordFindMutate,
    passwordChangeMutate,
    isLoading: isPasswordFindLoading || isPasswordChangeLoading,
    isError: isPasswordFindError || isPasswordChangeError,
    error: passwordFindError || passwordChangeError,
  };
}
