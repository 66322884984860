export class MiddlewareError {
  message: string;
  httpStatus: number;
  code: number;

  constructor({ message, httpStatus, code }: { message: string; httpStatus: number; code: number }) {
    this.message = message;
    this.httpStatus = httpStatus;
    this.code = code;
  }
}
