import styles from "style/common/container/content.module.scss";
import cx from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
  borderRadius?: string;
  marginBottom?: string;
  gap?: string;
};

/**
 * 콘텐츠 내용 넣는 White Box Container 컴포넌트
 */

export default function Content({ children, className, borderRadius, marginBottom, gap }: Props) {
  return (
    <section style={{ borderRadius, marginBottom, gap }} className={cx(styles.content, className)}>
      {children}
    </section>
  );
}
