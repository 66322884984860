import { useEffect, useState } from "react";
import useAccessRole from "hooks/useAccessRole";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Controller, FieldErrors, FieldValues, FormProvider, useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { Label, Input } from "pages/advertisement/adUI";
import AdDropdown from "./AdDropdown";
import { SidebarLayout, CreatePreviewSidebar } from "../previewSidebar";
import { Wrapper } from "common/container";
import { ButtonBox, Title } from "common/other";
import { FileInput, RadioBox } from "common/input";
import { Dialog, LoadingModal, ErrorModal } from "common/modal";
import { uploadCommonImgApi } from "api/common";
import { getAdDetail, getAdProposalList, postAd } from "api/advertisement";
import useModal from "hooks/useModal";
import queryKey from "constants/queryKey";
import { formatDuration } from "utils/ad";
import { TGetAccessRole } from "types/login";

import styles from "style/pages/advertisement/adRegister/adRegister.module.scss";

export default function AdRegister() {
  const accessInfo = useAccessRole()?.data;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const tempSaveId = Number(searchParams.get("id"));

  const [type, setType] = useState("BANNER");
  const [loc, setLoc] = useState("DATA_MARKET");
  const [moveLink, setMoveLink] = useState("1");
  const [errorMsg, setErrorMsg] = useState("");
  const [isTempSave, setIsTempSave] = useState(false);

  const [isConfirmModal, setIsConfirmModal] = useModal();
  const [isPostModal, setIsPostModal] = useModal();
  const [isNullErrorModal, setIsNullErrorModal] = useModal();
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isNoneProposal, setIsNoneProposal] = useModal();

  const isMoveLink = moveLink === "1";

  const methods = useForm<FieldValues>({ mode: "onChange" });
  const { register, control, setValue, handleSubmit, watch } = methods;

  const { mutateAsync: uploadImgMutation, error: uploadImgError } = useMutation(uploadCommonImgApi);

  // 거래 진행중/예정인 Proposal List 조회
  const {
    data: adProposalData,
    isLoading,
    error: getListError,
  } = useQuery([queryKey.GET_AD_PROPOSAL_LIST], getAdProposalList, {
    onSuccess: (el) => el.length === 0 && setIsNoneProposal(),
    onError: setIsErrorShowing,
  });

  // 광고 등록
  const {
    mutate: postMutation,
    isLoading: postLoading,
    error: postError,
  } = useMutation(postAd, {
    onSuccess: () => {
      if (!isTempSave) {
        setIsConfirmModal();
        setIsPostModal();
      } else navigate(-1);
    },
    onError: setIsErrorShowing,
  });

  // 광고 임시저장 데이터 조회
  const { data: tempSaveData } = useQuery([queryKey.GET_DETAIL_AD, tempSaveId], () => getAdDetail(tempSaveId), {
    onSuccess: (item) => {
      !item?.advertisements?.useUrl ? setMoveLink("0") : setMoveLink("1");
    },
    enabled: !!tempSaveId,
  });

  const proposalId = useWatch({ control, name: "proposalId" }) as string;
  const adBannerImg = useWatch({ control, name: "adBannerImg" }) || "";

  const sortAdProposalData = adProposalData?.sort((a, b) => (a.notBefore < b.notBefore ? 1 : -1));

  const proposalFormat = sortAdProposalData?.map(({ title, notBefore, notAfter, proposalId: id }) => ({
    label: (
      <div className={styles.dropdownLabel}>
        {title}
        <span>{formatDuration(notBefore, notAfter)}</span>
      </div>
    ),
    value: id,
  }));
  const chooseData = adProposalData?.filter((el) => el.proposalId === proposalId);

  const body = async (v: FieldValues) => {
    const { did, token } = accessInfo as TGetAccessRole;
    const imgBody = { did, token, file: adBannerImg };

    const imgLink =
      typeof adBannerImg === "string"
        ? adBannerImg
        : await uploadImgMutation(imgBody, {
            onError: () => {
              if (isTempSave) setIsTempSave(false);
              else setIsPostModal();

              setIsErrorShowing();
            },
          }).then((el) => el.fullUrlPath);

    return {
      type,
      loc,
      title: v.title,
      fileUrl: imgLink,
      useUrl: Boolean(+moveLink),
      webUrl: v.webUrl || "",
      proposalId: v.proposalId || "",
      status: isTempSave ? 0 : 1,
      notBefore: (chooseData && chooseData[0]?.notBefore) || "",
      notAfter: (chooseData && chooseData[0]?.notAfter) || "",
      id: Number(tempSaveId) || 0,
    };
  };

  const handleRegister = async (v: FieldValues) => {
    if (isTempSave) postMutation(await body(v));
    else setIsPostModal();
  };

  const handlePostMutation = async (v: FieldValues) => {
    const condition = adProposalData?.filter(
      (el) => el.proposalId === (proposalId || tempSaveData?.proposals?.proposalId)
    );

    if (condition?.length === 0) {
      setErrorMsg("거래를 다시 지정해 주세요");
      setIsPostModal();
      setIsNullErrorModal();
    } else {
      postMutation(await body(v));
    }
  };

  const handleError = (error: FieldErrors) => {
    if (error) {
      if (isTempSave && error.title) {
        setIsTempSave(false);
        setErrorMsg("광고 제목을 입력해 주세요.");
      } else if (error.webUrl?.type === "pattern") {
        setErrorMsg("올바른 URL 형식으로 입력해 주세요.");
      } else if (error.title || error.webUrl || error.proposalId) {
        setErrorMsg("필수 항목을 모두 입력해 주세요.");
      } else if (error.adBannerImg) {
        setErrorMsg("파일을 업로드해 주세요.");
      }
    }
    setIsNullErrorModal();
  };

  const handleClickConfirm = () => {
    queryClient.invalidateQueries([queryKey.GET_AD_LIST]);
    navigate("/advertise?tab=1");
  };

  const handleChangemoveLink = (el: string) => {
    setMoveLink(el);
    moveLink === "0" && setValue("webUrl", "");
  };

  useEffect(() => {
    if (!location.search) return;

    tempSaveData?.advertisements?.fileUrl && setValue("adBannerImg", tempSaveData?.advertisements?.fileUrl);

    setValue("proposalId", tempSaveData?.proposals?.proposalId);
  }, [tempSaveData, setValue, location.search]);

  if (isLoading || postLoading) return <LoadingModal />;

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <form className={styles.wrapper} onSubmit={handleSubmit(handleRegister, handleError)}>
          <Title text="광고 등록" />

          <div className={styles.container}>
            <div className={styles.line} />
            <div className={styles.contentContainer}>
              <div className={styles.itemBox}>
                <Label>광고 유형</Label>
                <RadioBox
                  name="ad-type"
                  dataList={[{ label: "배너광고", value: "BANNER" }]}
                  value={type}
                  handleCheck={(el) => setType(el)}
                />
              </div>

              <div className={styles.itemBox}>
                <Label>노출 위치</Label>
                <RadioBox
                  name="show-position"
                  dataList={[{ label: "자사 진행 거래", value: "DATA_MARKET" }]}
                  value={loc}
                  handleCheck={(el) => setLoc(el)}
                />
              </div>

              <div className={styles.itemBox}>
                <Label>거래 지정</Label>
                <div className={styles.inputBox}>
                  <span>거래 목록</span>
                  <Controller
                    name="proposalId"
                    control={control}
                    rules={{ required: !isTempSave }}
                    render={({ field: { onChange } }) => {
                      return (
                        <AdDropdown
                          placeholder="진행 중인 거래 중 광고를 등록할 거래를 선택해 주세요"
                          dataList={proposalFormat}
                          defaultValue={
                            tempSaveData?.proposals && (
                              <div className={styles.dropdownLabel}>
                                {tempSaveData?.proposals?.title}
                                <span>
                                  {formatDuration(
                                    tempSaveData?.proposals?.notBefore,
                                    tempSaveData?.proposals?.notAfter
                                  )}
                                </span>
                              </div>
                            )
                          }
                          handleChange={onChange}
                        />
                      );
                    }}
                  />
                </div>
              </div>

              <div className={styles.itemBox}>
                <Label>제목</Label>
                <Input
                  id="title"
                  placeholder="광고 제목을 입력해 주세요"
                  register={register}
                  isRequired={isTempSave ? "광고 입력" : true}
                  defaultValue={tempSaveData?.advertisements?.title}
                />
              </div>

              <div className={styles.itemBox}>
                <Label>광고 배너</Label>
                <FileInput id="adBannerImg" isRequired={!isTempSave} limitWidthHeight={[360, 120]} />

                <div className={styles.infoBox}>
                  <div>• 배너 규격은 360*120 픽셀 입니다.</div>
                  <div>• 파일형식 : .jpg .jpeg .png</div>
                </div>
              </div>

              <div className={styles.itemBox}>
                <Label>랜딩 URL</Label>
                <RadioBox
                  name="is-landing-url"
                  dataList={[
                    { label: "이동 링크 입력", value: "1" },
                    { label: "이동 안함", value: "0" },
                  ]}
                  value={moveLink}
                  handleCheck={(el) => handleChangemoveLink(el)}
                />

                {isMoveLink && (
                  <Input
                    id="webUrl"
                    placeholder="배너 클릭 시 이동할 웹페이지 url을 입력해주세요. 이동 안함 선택 시 배너가 클릭되지 않습니다."
                    register={register}
                    isRequired={!isTempSave}
                    defaultValue={tempSaveData?.advertisements?.webUrl}
                  />
                )}
              </div>
            </div>
          </div>

          <ButtonBox
            back="목록으로"
            next="승인 요청"
            handleClickPrev={() => setIsTempSave(true)}
            handleClickNext={handleSubmit(handleRegister, handleError)}
          />

          <SidebarLayout>
            <CreatePreviewSidebar data={chooseData!} adBannerUrl={adBannerImg} />
          </SidebarLayout>

          {/* modal */}
          {isPostModal && (
            <Dialog
              title="광고를 요청하시겠습니까?"
              description={
                <div className={styles.modalText}>
                  <span>{`${watch("title")}\n`}</span>
                  광고 승인을 요청합니다.
                </div>
              }
              btnType="submit"
              handleClose={setIsPostModal}
              handleSubmit={handleSubmit(handlePostMutation)}
            />
          )}

          {isTempSave && (
            <Dialog
              title="임시저장 하시겠습니까?"
              description={`등록 페이지를 나갑니다.\n지금까지 입력된 정보를 임시저장 하시겠습니까?`}
              btnType="submit"
              handleClose={() => navigate(-1)}
              handleSubmit={handleSubmit(handleRegister, handleError)}
            />
          )}

          {isConfirmModal && (
            <Dialog
              title="승인 요청되었습니다"
              description="영업일 기준 3일 이내로 광고가 승인됩니다."
              btnType="confirm"
              handleClose={handleClickConfirm}
            />
          )}

          {isNullErrorModal && <Dialog description={errorMsg} btnType="confirm" handleClose={setIsNullErrorModal} />}

          {isNoneProposal && (
            <Dialog
              description={`자사 진행 거래에 광고를 등록할 수 있습니다.\n광고를 게재할 거래를 등록해 주세요.`}
              btnType="confirm"
              handleClose={() => navigate(-1)}
            />
          )}

          {isErrorShowing && (
            <ErrorModal error={getListError || postError || uploadImgError} onConfirmHandler={setIsErrorShowing} />
          )}
        </form>
      </FormProvider>
    </Wrapper>
  );
}
