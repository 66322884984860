import { AxiosError } from "axios";
import { ErrorCode, ErrorMessage } from "constants/errorCode";
import { MiddlewareError } from "utils/errorPackage";

export const errorHandling = (e: unknown) => {
  if (e instanceof AxiosError) {
    if (e.message === "timeout of 10000ms exceeded") throw new Error(ErrorMessage[ErrorCode.ERR_UNKNOWN_ERROR]);
    return new Error(ErrorMessage[e.response?.status || ErrorCode.ERR_UNKNOWN_ERROR]);
  }

  if (e instanceof MiddlewareError) {
    const cause = e.httpStatus === 429 ? e.message : "";
    return new Error(ErrorMessage[e.code] || ErrorMessage[e.httpStatus] || ErrorMessage[ErrorCode.ERR_UNKNOWN_ERROR], {
      cause,
    });
  }

  if (e instanceof Error) {
    return new Error(e.message);
  }

  return new Error(ErrorMessage[ErrorCode.ERR_UNKNOWN_ERROR]);
};
