import { Label, InputWithLabel } from "pages/advertisement/adUI";
import { TAdDetail } from "types/advertisement";
import { formatDuration } from "utils/ad";
import styles from "style/pages/advertisement/adDetail/adDetailInfo.module.scss";

export default function AdDetailInfo({ data }: { data: TAdDetail }) {
  const { fileUrl, title, notBefore, notAfter, type, loc, webUrl } = data.advertisements;
  const bannerImgFileName = fileUrl.split("/");

  return (
    <div className={styles.container}>
      <div className={styles.line} />

      <div className={styles.contentContainer}>
        <Label must={false}>{title}</Label>

        <InputWithLabel label="광고 기간">{formatDuration(notBefore, notAfter)}</InputWithLabel>

        <InputWithLabel label="광고 유형">{type === "BANNER" ? "배너 광고" : type}</InputWithLabel>

        <InputWithLabel label="노출 위치">
          {`${loc === "DATA_MARKET" ? "자사 진행 거래" : loc} / ${data.proposals.title}`}
        </InputWithLabel>

        <InputWithLabel label="배너 이미지" url={fileUrl}>
          {bannerImgFileName[bannerImgFileName.length - 1]}
        </InputWithLabel>

        <InputWithLabel label="랜딩 URL" url={webUrl}>
          {webUrl || "-"}
        </InputWithLabel>
      </div>
    </div>
  );
}
