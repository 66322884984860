import cx from "classnames";
import styles from "style/common/button/statusChip.module.scss";

type TStatusChipProps = {
  status: number;
};

const rewardStatusMap: { [key: number]: string[] } = {
  1: ["사용", "use"],
  2: ["환급", "refund"],
  3: ["충전", "charge"],
  4: ["요청", "request"],
};

export default function StatusChip({ status }: TStatusChipProps) {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[rewardStatusMap[status][1]])}>{rewardStatusMap[status][0]}</div>
    </div>
  );
}
