enum ErrorCode {
  // common
  INVALID_ARGUMENT = 400,
  UNAUTHORIZED = 401,
  RESOURCE_NOT_FOUND = 404,
  UNSUPPORTED_HTTPVERB = 405,
  ONPREMISE_WORKING = 429,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,

  // proposal
  ERR_ALREADY_REVOKEDPROPOSAL = 1003,

  // middleware
  ERR_UNKNOWN_ERROR = 3012,
  ERR_NOT_FOUND_DATA = 3022,
  ERR_PASSWORD_NOT_CHANGED = 3038,
  ERR_DATA_DUPLICATED = 3039,
  ERR_INPUT_PARAMETER = 6006,
  ERR_NOT_REGISTER = 6201,
  ERR_DISABLED = 6303,
  ERR_POINT_NOT_REQUEST = 6304,
  ERR_INPUT_NOT_EXIST = 6305,
  ERR_INACTIVATE_ADVERTISE = 6306,
  ERR_AUTHORIZED_ONPREMISE = 7800,
}

const ErrorMessage: Record<number, string> = {
  // common
  [ErrorCode.INVALID_ARGUMENT]: "유효하지 않은 요청입니다.",
  [ErrorCode.UNAUTHORIZED]: "권한이 없습니다.",
  [ErrorCode.RESOURCE_NOT_FOUND]: "유효하지 않는 경로입니다.",
  [ErrorCode.UNSUPPORTED_HTTPVERB]: "HTTP Method 확인 바랍니다.",
  [ErrorCode.ONPREMISE_WORKING]: "JSON 다운로드 또는 데이터 가공이 진행중입니다.\n잠시후 다시 시도해주세요.",
  [ErrorCode.INTERNAL_SERVER_ERROR]: "서버에 문제가 발생했습니다.",
  [ErrorCode.SERVICE_UNAVAILABLE]: "서버 과부하 발생",

  // proposal
  [ErrorCode.ERR_ALREADY_REVOKEDPROPOSAL]: "이미 철회한 거래입니다.",

  // middleware
  [ErrorCode.ERR_UNKNOWN_ERROR]: "알 수 없는 에러입니다.",
  [ErrorCode.ERR_NOT_FOUND_DATA]: "데이터를 찾을 수 없습니다.",
  [ErrorCode.ERR_PASSWORD_NOT_CHANGED]: `동일한 비밀번호를 사용할 수 없습니다.\n새로운 비밀번호를 입력해 주세요.`,
  [ErrorCode.ERR_DATA_DUPLICATED]: `이미 사용 중인 계정입니다.\n다른 이메일로 등록해 주세요.`,
  [ErrorCode.ERR_INPUT_PARAMETER]: `입력 정보가 일치하지 않습니다.\n다시 확인해 주세요.`,
  [ErrorCode.ERR_NOT_REGISTER]: "제안서를 생성할 수 없습니다.",
  [ErrorCode.ERR_DISABLED]: "비활성화 되어있습니다.",
  [ErrorCode.ERR_POINT_NOT_REQUEST]: "포인트를 입력하세요",
  [ErrorCode.ERR_INPUT_NOT_EXIST]: "필수로 입력해야 하는 값이 없습니다.",
  [ErrorCode.ERR_INACTIVATE_ADVERTISE]: "유효하지 않은 거래 프로포절 입니다.",
  [ErrorCode.ERR_AUTHORIZED_ONPREMISE]: `데이터가 존재하지 않거나\n조회 중 오류가 발생하여 데이터를 불러올 수 없습니다.`,
};

export { ErrorCode, ErrorMessage };
