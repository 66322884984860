import styles from "style/pages/mypage/passwordInput.module.scss";

type TProps = React.ComponentProps<"input"> & {
  errorMsg?: string;
  isErrMsg?: boolean;
};

export default function PasswordInput({ id, value, onChange, placeholder, errorMsg, isErrMsg }: TProps) {
  return (
    <div className={styles.wrapper}>
      <input
        type="password"
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        className={errorMsg && styles.errStyle}
        required
      />
      {errorMsg && isErrMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
    </div>
  );
}
