import { Axios } from "./base";
import { TCreatePromotionCode, TPromotionProduct, TPromotionParams } from "types/promotion";
import { errorHandling } from "./errorHandling";

// 기업별 프로모션코드 조회
export const getCompanyPromotionCode = async () => {
  const { data } = await Axios.get(`/api/v2/promotion/code`);
  return data.data.promotionCode;
};

// 프로모션코드 생성
export const postPromotion = async ({ hash, subscribedAt, staffId, promotionCode, itemCode }: TCreatePromotionCode) => {
  try {
    const body = { hash, subscribedAt, staffId, promotionCode, itemCode };
    const { data } = await Axios.post(`/api/v2/promotion/apply`, body);
    return data;
  } catch {
    throw errorHandling(Error(`프로모션 코드 생성 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

// 가입 상품 리스트 조회
export const getPromotionProductList = async ({
  promotionCode,
  itemMonthDate,
}: TPromotionParams): Promise<TPromotionProduct[]> => {
  const { data } = await Axios.get(`/api/v2/promotion/itemList`, { params: { promotionCode, itemMonthDate } });
  return data.data.itemList;
};
