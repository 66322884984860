import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Controller, FieldErrors, FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { SHA256 } from "crypto-js";

import { getCompanyPromotionCode, getPromotionProductList, postPromotion } from "api/promotion";
import { Content, Wrapper } from "common/container";
import { Dropdown, LabelInput } from "common/input";
import { Dialog, ErrorModal } from "common/modal";
import { ButtonBox, Title } from "common/other";
import useModal from "hooks/useModal";
import { ErrorCode, ErrorMessage } from "constants/errorCode";
import queryKey from "constants/queryKey";
import { TCreateDetail } from "types/management";

import styles from "style/pages/promotion/promotion.module.scss";
import { TCretaePromotionInput } from "types/promotion";

export default function Promotion() {
  const queryClient = useQueryClient();

  const methods = useForm<TCretaePromotionInput>({ mode: "onChange" });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = methods;

  const itemMonthDate = dayjs(new Date()).format("YYYYMM");

  const { staffId } = (queryClient.getQueryData([queryKey.GET_DETAIL_COMPANY]) as TCreateDetail) || {};

  const { data: promotionCode } = useQuery([queryKey.GET_COMPANY_PROMOTION_CODE], getCompanyPromotionCode);

  const [isCheckModal, setIsCheckModal] = useModal(); // 등록 버튼 클릭시 코드 생성 확인 모달
  const [isConfirmModal, setIsConfirmModal] = useModal(); // 코드 생성 완료 확인 모달
  const [isAlreadyNoticeModal, setIsAlreadyNoticeModal] = useModal(); // 이미 등록된 가입자 안내 모달
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [validateCheckMsg, setValidateCheckMsg] = useState(""); // 유효성 체크 안내 모달

  // 가입상품 리스트 조회
  const { data = [] } = useQuery(
    [queryKey.GET_PROMOTION_PRODUCT_LIST, promotionCode],
    () => getPromotionProductList({ promotionCode, itemMonthDate }),
    { enabled: !!promotionCode && !!itemMonthDate }
  );

  const productNameList = data.map((el) => el.itemName);

  const { mutate, error: postError } = useMutation(postPromotion, {
    onSuccess: setIsConfirmModal,
    onError: (err: AxiosError) => {
      if (err.message === ErrorMessage[ErrorCode.ERR_DATA_DUPLICATED]) {
        setIsAlreadyNoticeModal();
      } else if (err.message === ErrorMessage[ErrorCode.ERR_INPUT_PARAMETER]) {
        setValidateCheckMsg("유효한 날짜 형식으로 8자리 숫자를 입력해주세요.");
      } else {
        setIsErrorShowing();
      }
    },
  });

  const onSubmit: SubmitHandler<TCretaePromotionInput> = (input) => {
    const { name, birthDate, phone, subscribedAt, promotionPlanName } = input;
    const itemCode = data.filter((el) => el.itemName === promotionPlanName).map((el) => el.itemCode)[0];
    const plainText = `${promotionCode}${name}${phone}${birthDate}`;
    const hash = SHA256(plainText).toString();

    setIsCheckModal();
    mutate({ hash, subscribedAt, staffId, promotionCode, itemCode });
  };

  const onErrors = (error: FieldErrors) => {
    for (const fieldName in error) {
      if (error[fieldName]) {
        setValidateCheckMsg(String(error[fieldName]?.message));
      }
    }
  };

  return (
    <Wrapper>
      <Title text="가입자별 프로모션 코드 생성하기" />

      <Content>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(setIsCheckModal, onErrors)}>
            <div className={styles.contentContainer}>
              <div className={styles.subTitle}>가입자 정보</div>
              <LabelInput label="이름" id="name" placeholder="이름을 입력하세요" isNull={errors.name && true} />

              <LabelInput
                label="생년월일"
                id="birthDate"
                type="number"
                placeholder="생년월일 입력하세요 (YYYYMMDD)"
                isNull={errors.birthDate && true}
                validate="date"
              />

              <LabelInput
                label="전화번호"
                id="phone"
                type="number"
                placeholder="전화번호를 입력하세요"
                isNull={errors.phone && true}
                validate="tel"
              />

              <LabelInput
                label="가입일"
                id="subscribedAt"
                type="number"
                placeholder="가입일을 입력하세요 (YYYYMMDD)"
                isNull={errors.subscribedAt && true}
                validate="date"
              />

              <div className={styles.dropdown}>
                <div>
                  가입 상품<span>*</span>
                </div>
                <Controller
                  name="promotionPlanName"
                  control={control}
                  rules={{
                    required: "필수 항목을 모두 입력해 주세요.",
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Dropdown
                        placeholder="가입 상품군을 선택해 주세요"
                        dataList={productNameList}
                        value={value}
                        handleChange={onChange}
                        width="383px"
                        borderColor="soft"
                        mode="secondary"
                      />
                    );
                  }}
                />
              </div>
            </div>

            <ButtonBox back="초기화" next="등록" handleClickPrev={() => reset()} />
          </form>
        </FormProvider>
      </Content>

      {/* Modals */}
      {isCheckModal && (
        <Dialog
          title="코드를 생성하시겠습니까?"
          description={`입력한 정보가 정확한지 확인 후\n생성 버튼을 눌러주세요`}
          btnType="submit"
          handleClose={setIsCheckModal}
          handleSubmit={handleSubmit(onSubmit, onErrors)}
        />
      )}

      {validateCheckMsg && (
        <Dialog description={validateCheckMsg} btnType="confirm" handleClose={() => setValidateCheckMsg("")} />
      )}

      {isConfirmModal && (
        <Dialog
          title="코드가 생성되었습니다"
          description="페이지가 새로고침됩니다"
          btnType="confirm"
          handleClose={() => window.location.reload()}
        />
      )}

      {isAlreadyNoticeModal && (
        <Dialog
          description="이미 코드가 생성된 가입자입니다."
          btnType="confirm"
          handleClose={() => {
            setIsAlreadyNoticeModal();
            reset();
          }}
        />
      )}

      {isErrorShowing && <ErrorModal error={postError} onConfirmHandler={setIsErrorShowing} />}
    </Wrapper>
  );
}
