import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import AuthToggle from "./staff/AuthToggle";
import InnerHeader from "./InnerHeader";
import { Content } from "common/container";
import { Title } from "common/other";
import { Pagination } from "common/navigation";
import Table from "common/table/Table";
import { DefaultBtn } from "common/button";
import { LoadingModal } from "common/modal";
import { getUserListData } from "api/management";
import queryKey from "constants/queryKey";

import styles from "style/pages/management/management.module.scss";
import { getUserInfo } from "api/common";
import ROLE from "constants/role";

export default function Management() {
  const { data: userInfo } = useQuery(queryKey.GET_USERINFO, getUserInfo);
  const isB2bAdminLogin = userInfo?.roles === ROLE.B2B_ADMIN;

  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchKeyword") || "";
  const pageNum = Number(searchParams.get("pageNum") || 1);

  // [GET] 사용자 목록 조회
  const { data = [], isLoading, isFetching } = useQuery([queryKey.GET_STAFF_LIST], getUserListData);

  // name 오름차순 정렬 && role이 B2B_ADMIN인 경우 최상단에 위치하도록 정렬
  let result = data
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .sort((a, b) => {
      if (a.role === ROLE.B2B_ADMIN && b.role !== ROLE.B2B_ADMIN) return -1;
      if (a.role !== ROLE.B2B_ADMIN && b.role === ROLE.B2B_ADMIN) return 1;
      return 0;
    });

  let dataLength = result.length;

  // 검색어가 있는 경우
  if (searchText) {
    result = data.filter((el) => el.name.toLowerCase().trim().includes(searchText.toLowerCase()));
    dataLength = result.length;
  }

  // pagination
  const totalPages = Math.ceil(dataLength / 10) || 1;
  result = result.slice((pageNum - 1) * 10, pageNum * 10);

  const tableInfo = result.map((item, idx) => [
    idx + 1,
    dataLength - idx - (pageNum - 1) * 10,
    <AuthToggle key={item.id} item={item} canEdit={isB2bAdminLogin} />,
    item.department,
    <Link to={`/management/staff/info/${item.id}`} key={item.id}>
      {item.name}
    </Link>,
    item.email,
  ]);

  const tableData = {
    columns: [
      ["NO", "59px"],
      ["권한", "154px"],
      ["부서명", "240px"],
      ["이름", "184px"],
      ["이메일 아이디", "451px"],
    ],
    dataList: tableInfo || [],
  };

  if (isLoading || isFetching) return <LoadingModal />;

  return (
    <>
      <Title text="사용자 목록" />

      <Content gap="16px">
        <InnerHeader dataLength={dataLength!} />

        <Table columns={tableData.columns} dataList={tableData.dataList} />

        <div className={styles.btnBox}>
          {isB2bAdminLogin && (
            <Link to="/management/staff/register">
              <DefaultBtn>계정 등록</DefaultBtn>
            </Link>
          )}
        </div>

        <Pagination data={{ totalPages, pageSize: 1 }} />
      </Content>
    </>
  );
}
