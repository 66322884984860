import { TAd, TAdDashboard, TAdDetail, TAdList, TAdProposal } from "types/advertisement";
import { Axios } from "./base";

// 광고 등록시 예정/진행중인 거래 proposal list 조회
export const getAdProposalList = async (): Promise<TAdProposal[]> => {
  const { data } = await Axios.get(`/api/advertisement/v2/getList`);
  return data.data.proposalRequests;
};

// 광고 등록
export const postAd = async (json: TAd) => {
  const { data } = await Axios.post(`/api/advertisement/v2/create`, { json });
  return data.data;
};

// 광고 목록 조회
export const getAdList = async (approved: boolean): Promise<TAdList[]> => {
  const { data } = await Axios.get(`/api/advertisement/v2/index`, { params: { approved } });
  return data?.data?.index;
};

// 광고 삭제
export const deleteAd = async (id: number) => {
  const { data } = await Axios.get(`/api/advertisement/v2/delete`, { params: { id } });
  return data;
};

// 광고 status 변경 (승인 요청 취소)
export const cancelAd = async (json: { id: number; status: number }) => {
  const { data } = await Axios.post(`/api/advertisement/v2/changeStatus`, { json });
  return data;
};

// 광고 상세정보 조회
export const getAdDetail = async (id: number): Promise<TAdDetail> => {
  const { data } = await Axios.get(`/api/advertisement/v2/detail`, { params: { id } });
  return data.data;
};

// 광고 상세정보 - 대시보드 조회
export const getAdDashboard = async (proposalId: string): Promise<TAdDashboard[]> => {
  const { data } = await Axios.get(`/api/advertisement/v2/dashboard`, { params: { proposalId } });
  return data.data.dashBoard;
};
