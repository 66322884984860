import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { TMarketResponse } from "types/market";
import { isIncludedPeriod } from "utils/market";
import { marketStatusMap } from "constants/market";
import overrideStatus from "../marketComponents/overrideStatus";

export default function useFilterTableNav(marketData?: TMarketResponse) {
  const [searchParams] = useSearchParams();
  const [beforeSearch, afterSearch] = [searchParams.get("from"), searchParams.get("to")];
  const keywordSearch = searchParams.get("keyword");
  const count = [0, 0, 0, 0]; // [진행중, 예정, 종료, 철회]

  const tempList = marketData?.result.tempList
    .filter(({ title }) => {
      // 임시저장 된 것들 중 필터 걸렸는데 데이터가 없는 것들
      if (keywordSearch && !title) {
        return false;
      }

      return true;
    })
    .filter(({ notBefore }) => {
      // 임시저장 된 것들 중 필터 걸렸는데 데이터가 없는 것들

      if ((beforeSearch || afterSearch) && !notBefore) {
        return false;
      }
      return true;
    })
    .filter(({ title }) => {
      if (keywordSearch) {
        return title.includes(keywordSearch);
      }

      return true;
    })
    .filter(({ notBefore }) => {
      if (beforeSearch || afterSearch) {
        return isIncludedPeriod(dayjs(notBefore), dayjs(beforeSearch || "0000"), dayjs(afterSearch || "9999-12-12"));
      }
      return true;
    });

  if (marketData) {
    marketData.result.proposalList
      .filter(({ title, notBefore }) => {
        if (keywordSearch) {
          return title.toLowerCase().includes(keywordSearch.toLowerCase());
        }
        if (beforeSearch || afterSearch) {
          return isIncludedPeriod(dayjs(notBefore), dayjs(beforeSearch || "0000"), dayjs(afterSearch || "9999-12-12"));
        }
        return true;
      })
      .forEach((proposal) => {
        if (
          !isIncludedPeriod(
            dayjs(proposal.notBefore),
            dayjs(beforeSearch || "0000"),
            dayjs(afterSearch || "9999-12-12")
          )
        )
          return;
        const statusNumber = overrideStatus({
          status: proposal.status,
          startDate: proposal.notBefore,
          revokedAt: proposal.revokedAt,
        });

        // proposalList에 임시저장 데이터가 없다.(임시저장: 5)
        switch (statusNumber) {
          case 0: // inProgress
            count[0]++;
            break;
          case 1: // notStarted
            count[1]++;
            break;
          case 2: // revoked
            count[3]++;
            break;
          default: // ended
            count[2]++;
        }
      });
  }

  return {
    전체: {
      num: count.reduce((a, b) => a + b, 0) + (tempList ? tempList.length : 0),
      status: "all",
    },
    진행중: {
      num: count[0],
      status: marketStatusMap[0][1], // inProgress
    },
    예정: {
      num: count[1],
      status: marketStatusMap[1][1], // notStarted
    },
    임시저장: {
      num: tempList?.length || 0,
      status: marketStatusMap[5][1], // temporary
    },
    종료: {
      num: count[2],
      status: marketStatusMap[4][1], // ended
    },
    철회: {
      num: count[3],
      status: marketStatusMap[2][1], // revoked
    },
  };
}
