import dayjs from "dayjs";
import { useState } from "react";
import { useQueries } from "react-query";
import { useSearchParams } from "react-router-dom";

import { getDashboardAll, getDashboardPeriod, getDashboardYear } from "api/dashboard";
import { Content } from "common/container";
import { Dropdown } from "common/input";
import { ErrorModal, LoadingModal } from "common/modal";
import { Title } from "common/other";
import queryKey from "constants/queryKey";
import { useAccessRole, useCheckPromotion, useCompanyDetail, useModal } from "hooks";
import { Chart, DashboardInfo, DateRange, PeriodFilterBtn, Statistic } from ".";
import useDashboardDateRange from "./useDashboardDateRange";

import styles from "style/pages/dashboard/dashboard.module.scss";

export default function Dashboard() {
  const nowDate = dayjs();
  const { isPromotion } = useCheckPromotion();

  const [searchParams] = useSearchParams();
  const { startDate, endDate, setStartDate, setEndDate, onChangeDate, onResetDate } = useDashboardDateRange();
  const [prevDate, nextDate] = searchParams.get("period")
    ? searchParams.get("period")!.split("to")
    : [nowDate.subtract(1, "year").format("YYYY-MM-DD"), nowDate.format("YYYY-MM-DD")];

  const did = useAccessRole().data?.did!;
  const periodBody = { did, startDate: prevDate, endDate: nextDate };
  const yearBody = { did, endDate: nowDate.format("YYYY-MM-DD") };

  const [chartSelect, setChartSelect] = useState("월별 소진 포인트");
  const [isErrorShowing, setIsErrorShowing] = useModal();

  const { data: companyData, isLoading: companyIsLoading, error: companyError } = useCompanyDetail();
  const { tutorialStatus, staffName } = companyData || {};

  const results = useQueries([
    {
      queryKey: [queryKey.GET_DASHBOARD_ALL, did],
      queryFn: () => getDashboardAll(did),
      enabled: !!did && !isPromotion && !!tutorialStatus,
      onError: setIsErrorShowing,
    },
    {
      queryKey: [queryKey.GET_DASHBOARD_PERIOD, periodBody],
      queryFn: () => getDashboardPeriod(periodBody),
      enabled: !!did && !!prevDate && !!nextDate && !isPromotion && !!tutorialStatus,
      onError: setIsErrorShowing,
    },
    {
      queryKey: [queryKey.GET_DASHBOARD_YEAR, yearBody],
      queryFn: () => getDashboardYear(yearBody),
      enabled: !!did && !isPromotion && !!tutorialStatus,
      onError: setIsErrorShowing,
    },
  ]);

  const [dashboardAllData, dashboardPeriodData, dashboardYearData] = results;

  const isQueriesFetching = results.some((queryResult) => queryResult.isFetching);
  // enabled 가 false 일때 데이터를 아직 불러오지 않은 경우. idle 상태이다
  const isQueriesIdle = results.some((queryResult) => queryResult.isIdle);
  
  if (companyIsLoading || isQueriesIdle || isQueriesFetching) {
    return <LoadingModal />;
  }

  return (
    <>
      <Title text={`안녕하세요 ${staffName || ""}님`} />

      <Content marginBottom="24px">
        <p className={styles.title}>대시보드 &#40;전체&#41;</p>
        <DashboardInfo data={dashboardAllData.data!} />
      </Content>

      <Content marginBottom="24px">
        <div className={styles.title}>
          최근 1년 데이터 추이
          <Dropdown
            dataList={["월별 소진 포인트", "월별 데이터 거래 인원", "월별 데이터 거래량"]}
            value={chartSelect}
            handleChange={setChartSelect}
            width="220px"
            borderColor="none"
          />
        </div>

        <div className={styles.chart}>
          <Chart data={dashboardYearData.data!} chartSelect={chartSelect} />
        </div>
      </Content>

      <Content>
        <div className={styles.statisticsTop}>
          <p className={styles.title}>기간 별 통계</p>

          <DateRange startDate={startDate} endDate={endDate} onChange={onChangeDate} onResetDate={onResetDate} />
        </div>

        <div className={styles.statistics}>
          <PeriodFilterBtn setStartDate={setStartDate} setEndDate={setEndDate} />

          {dashboardPeriodData.isFetching ? (
            <div className={styles.spinner} />
          ) : (
            <Statistic data={dashboardPeriodData.data!} />
          )}
        </div>
      </Content>

      {isErrorShowing && (
        <ErrorModal
          error={companyError || dashboardAllData.error || dashboardPeriodData.error || dashboardYearData.error}
          onConfirmHandler={setIsErrorShowing}
        />
      )}
    </>
  );
}
