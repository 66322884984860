import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import AdDayPerformance from "./AdDayPerformance";
import AdInProgress from "./AdInProgress";
import { getAdDashboard } from "api/advertisement";
import { TAdDetail } from "types/advertisement";
import queryKey from "constants/queryKey";

// 광고 상세 대시보드 컴포넌트 조합
export default function AdDashboard({ data }: { data: TAdDetail }) {
  const [searchParams] = useSearchParams();
  const tabIdx = searchParams.get("tab");
  const id = searchParams.get("id");

  const { data: chartData = [] } = useQuery([queryKey.GET_AD_DASHBOARD, id], () => getAdDashboard(id!));

  const totalClick = chartData.reduce((acc, { clickCnt }: { clickCnt: number }) => acc + clickCnt, 0); // 누적 클릭수
  const totalExps = chartData.reduce((acc, { expsCnt }: { expsCnt: number }) => acc + expsCnt, 0); // 누적 노출수
  const clickPercent = Math.round((totalClick / totalExps) * 100) || 0; // 누적 클릭률

  return (
    <>
      <AdInProgress data={data} totalClick={totalClick} totalExps={totalExps} clickPercent={clickPercent} />
      {tabIdx === "1" || <AdDayPerformance data={chartData} />}
    </>
  );
}
