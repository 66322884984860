import { errorHandling } from "api/errorHandling";
import { postPiZipJob, getPiZip, getPiZipStatus } from "api/market";
import { WhiteLoadingSpinnerIcon } from "assets/svgs/market";
import cx from "classnames";
import { DefaultBtn } from "common/button";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { ErrorCode, ErrorMessage } from "constants/errorCode";
import queryKey from "constants/queryKey";
import dayjs from "dayjs";
import { useModal } from "hooks";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import styles from "style/pages/market/marketDetailPage/processedDataPannel.module.scss";
import { TGetAccessRole } from "types/login";
import { TMarketProposalData } from "types/market";
import { createFile } from "utils";

import AlertDescription from "./AlertDescription";
import { registerDowLog } from "api/dpia";

type TProps = {
  accessData?: TGetAccessRole;
  proposalId: string;
  proposalInfo?: TMarketProposalData;
};

export default function ProcessedDataPannel({ accessData, proposalId, proposalInfo }: TProps) {
  const [isAlertOpen, onToggleAlert] = useModal();
  const [isJobLoading, setIsJobLoading] = useState(false);
  const [isDialogShowing, setIsDialogShowing] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [errorState, setErrorState] = useState<Error | false>(false);

  const { did, piiUrl, token } = accessData || { did: "", piiUrl: "", token: "" };
  const title = proposalInfo?.proposal.title;
  const totalResponseCount = proposalInfo?.ticketInfo.totalResponseCount;

  const { data: statusData, refetch } = useQuery(
    [queryKey.GET_PI_ZIP_STATUS],
    () => getPiZipStatus({ did, piiUrl, token, proposalId }),
    {
      onSuccess: (res) => {
        if (res) {
          if (res?.Status === 1) {
            setIsJobLoading(true);
            return;
          }

          setIsJobLoading(false);
        }
      },

      refetchInterval: (res, query) => {
        const timer = query.state.dataUpdateCount > 6 ? 30000 : 10000;
        return isJobLoading ? timer : false;
      },
    }
  );

  const { mutate: zipDownloadMutate, isLoading: zipDownloadLoading } = useMutation(getPiZip, {
    onSuccess: async (res) => {
      try {
        if (res?.type === "application/json") {
          const responseData = JSON.parse(await res.text());
          if (
            responseData.status >= 500 &&
            Error(ErrorMessage[responseData.status] || ErrorMessage[ErrorCode.ERR_UNKNOWN_ERROR])
          ) {
            setErrorState(Error(ErrorMessage[responseData.status] || ErrorMessage[ErrorCode.ERR_UNKNOWN_ERROR]));
            return;
          }
        }
        // eslint-disable-next-line no-useless-escape
        createFile(res, "application/zip", "zip", title ? title.replace(/[\\/:\*\?"<>|\ ]/g, "") : "가공데이터");
      } catch {
        setErrorState(Error("가공 데이터 엑셀 다운로드를 실패하였습니다."));
      }
    },
    onError: (err) => setErrorState(errorHandling(err)),
  });

  const { mutate: jobMutate } = useMutation(postPiZipJob, {
    onSuccess: (res) => {
      if (res?.data.Status !== "success") {
        setIsJobLoading(false);
        setErrorState(Error("데이터 가공 요청을 실패하였습니다."));
      }
    },
    onError: (err: Error) => {
      if (err.cause === "OnPremiseError") {
        setErrorState(err);
      } else {
        setErrorState(Error("데이터 가공 요청을 실패하였습니다."));
      }
      setIsJobLoading(false);
    },
    onSettled: () => refetch(),
  });

  const onClickDownloadBUtton = () => {
    onToggleAlert();

    // TODO: 서버 자동화되면 삭제
    registerDowLog(proposalId, "EXCEL");

    statusData?.Status === 3
      ? setIsDialogShowing(!isDialogShowing)
      : zipDownloadMutate({ did, piiUrl, token, proposalId });
  };

  const submitDownloadButton = () => {
    zipDownloadMutate({ did, piiUrl, token, proposalId });
    setIsDialogShowing(false);
  };

  const handleJobMutate = () => {
    jobMutate({ did, piiUrl, token, proposalId });
    setIsJobLoading(true);
    setIsConfirmModal(false);
  };

  return (
    <div className={`${styles.innerPannel} ${styles.dataDownload}`}>
      <div className={styles.infoWrapper}>
        가공 데이터 보기
        <div>
          {statusData?.CreatedAt && `| 마지막 가공일 ${dayjs(statusData?.CreatedAt).format("YYYY.MM.DD HH:mm")}`}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={cx(styles.buttonOverlay, { [styles.hidden]: !isJobLoading })}>
          <WhiteLoadingSpinnerIcon width="20" height="20" />
          최대 10분
        </div>
        <DefaultBtn
          size="short"
          mode="lineBlue"
          type="button"
          className={styles.button}
          onClick={() => setIsConfirmModal(true)}
          disabled={statusData?.Status === 2 || !totalResponseCount}
        >
          데이터 가공 요청
        </DefaultBtn>
        <DefaultBtn
          size="short"
          mode="lineBlue"
          type="button"
          className={styles.button}
          onClick={onToggleAlert}
          disabled={
            !(statusData?.Status === 2 || statusData?.Status === 3) || !statusData?.CreatedAt || !totalResponseCount
          }
        >
          엑셀 다운로드
        </DefaultBtn>
      </div>

      {isDialogShowing && (
        <Dialog
          title="이전 데이터를 다운로드 하시겠습니까?"
          description={"업데이트된 데이터가 있습니다.\n가공 요청 없이 다운로드 하시겠습니까?"}
          btnType="submit"
          handleClose={() => setIsDialogShowing(false)}
          handleSubmit={submitDownloadButton}
        />
      )}

      {isConfirmModal && (
        <Dialog
          title="데이터 가공을 진행하시겠습니까?"
          description={
            "데이터 가공 시 최대 10분이 소요되며,\n페이지를 새로고침하여 엑셀 다운로드 가능 상태를 확인해 주세요"
          }
          btnType="submit"
          handleClose={() => setIsConfirmModal(false)}
          handleSubmit={handleJobMutate}
        />
      )}

      {isAlertOpen && (
        <Dialog
          description={<AlertDescription />}
          btnType="submit"
          confirmBtnName="취소"
          submitBtnName="다운로드"
          handleClose={onToggleAlert}
          handleSubmit={onClickDownloadBUtton}
          height="836px"
        />
      )}

      {errorState && <ErrorModal error={errorState} onConfirmHandler={() => setErrorState(false)} />}
      {zipDownloadLoading && <LoadingModal />}
    </div>
  );
}
