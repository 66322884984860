import dayjs from "dayjs";
import { TDownloadRes } from "types/dpia";

export default function useDownloadRows(data: TDownloadRes | undefined) {
  if (!data || data.content.length === 0) return [];
  const { totalElements, pageable, content } = data;

  const result = content.map((item, idx) => [
    totalElements - (idx + pageable.offset),
    dayjs(item.createdAt).format("YYYY.MM.DD HH:mm:ss"),
    item.proposalTitle,
    item.downloadType,
    item.staffName,
    item.ipAddress,
  ]);

  return result;
}
