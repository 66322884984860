import { ChangeEvent, useState } from "react";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import { isNumber } from "lodash";

import { CheckInput, RadioBox, Dropdown } from "common/input";
import { introList, titleTypeList, marketTitleList } from "constants/market";
import { marketBudgetAtom, marketContentAtom, marketTargetAtom } from "recoils/market";
import { transactionNaming } from "./transactionNaming";
import { CheckTitle } from "pages/market/marketComponents";
import { formatDataType } from "./formatDataType";
import queryKey from "constants/queryKey";
import { getCompanyDetail } from "api/company";

import styles from "style/pages/market/createMarketPage/sectionContent/contentName.module.scss";

type TContentName = {
  isMydProduct: boolean;
  showFinelabOptions: boolean;
};

export default function ContentName({ isMydProduct, showFinelabOptions }: TContentName) {
  const targetState = useRecoilValue(marketTargetAtom);
  const [{ titleType, intro, transactionName }, setContentState] = useRecoilState(marketContentAtom);
  const { maxIssuable, reward, customReward } = useRecoilValue(marketBudgetAtom);

  const [isDirectInput, setIsDirectInput] = useState(false); // 거래명 직접 입력 여부
  const [tempContents, setTempContents] = useState({ titleType, intro, transactionName }); // 직접 입력 클릭시 기존에 선택된 데이터 거래명 항목들 임시 저장

  const { collectData } = targetState;
  const point = isNumber(customReward) ? customReward.toLocaleString("en-US") : reward.toLocaleString("en-US");

  const handleCheckBox = (state: "titleType" | "intro", value: string) => {
    setContentState((prev) => ({ ...prev, [state]: value }));
  };

  const handletransactionName = (name: string) => setContentState((prev) => ({ ...prev, transactionName: name }));

  const dataType = formatDataType(collectData);

  const { data: companyData } = useQuery([queryKey.GET_DETAIL_COMPANY], getCompanyDetail);
  const isAdminCompany = companyData?.companyId === 2;

  const handleClickDirectInput = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDirectInput(!isDirectInput);

    if (e.target.checked) {
      setTempContents({ titleType, intro, transactionName });
      setContentState((prev) => ({ ...prev, titleType: "", intro: "", transactionName: "" }));
    } else {
      setContentState((prev) => ({ ...prev, ...tempContents }));
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>데이터 거래명</p>
      <div className={styles.titleWrapper}>
        <CheckTitle label="수집 데이터" height="34px" />
        <span className={styles.dataType}>{dataType}</span>
      </div>

      {isMydProduct && (
        <div className={styles.titleWrapper}>
          <CheckTitle label="문구 타입" height="34px" />
          <RadioBox
            dataList={titleTypeList}
            name="문구 타입"
            handleCheck={(value) => handleCheckBox("titleType", value)}
            value={titleType}
            disabled={isDirectInput}
          />
        </div>
      )}

      <div className={styles.titleWrapper}>
        <CheckTitle label="머리말" height="34px" />
        <RadioBox
          dataList={introList}
          name="머리말"
          handleCheck={(value) => handleCheckBox("intro", value)}
          value={intro}
          disabled={isDirectInput}
        />
      </div>

      <div className={styles.titleWrapper}>
        <CheckTitle label="거래명" height="34px" />
        {isDirectInput ? (
          <input
            className={styles.titleDirectInput}
            value={transactionName}
            onChange={(e) => handletransactionName(e.target.value)}
            placeholder="데이터 거래명을 직접 입력해 주세요"
          />
        ) : (
          <Dropdown
            dataList={transactionNaming({
              marketTitleList,
              showFinelabOptions,
              titleType: isMydProduct ? titleType : "기본형",
              dataType,
              intro,
              point,
              maxIssuable,
            })}
            value={transactionName}
            placeholder="거래명 선택"
            handleChange={handletransactionName}
            width="600px"
            mode="secondary"
            borderColor="soft"
            optionTxtPosition="center"
          />
        )}

        {isAdminCompany && (
          <CheckInput
            handleCheck={handleClickDirectInput}
            name="titleDirectInput"
            text="직접입력"
            value={isDirectInput}
          />
        )}
      </div>
    </div>
  );
}
