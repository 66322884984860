import dayjs from "dayjs";
import styles from "style/pages/dashboard/periodFilterBtn.module.scss";
import { useSearchParams } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";

type TProps = {
  setStartDate: Dispatch<SetStateAction<Date | null>>;
  setEndDate: Dispatch<SetStateAction<Date | null>>;
};

export default function PeriodFilterBtn({ setStartDate, setEndDate }: TProps) {
  const [, setSearchParams] = useSearchParams();

  const onClickPeriodBtn = (target: string) => {
    setSearchParams((params) => {
      params.set("period", target);
      return params;
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    const [startDate, endDate] = value.split("to").map((el) => new Date(el));

    onClickPeriodBtn(value);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <div>
      {filter.map(({ value, text }) => (
        <button type="button" key={value} value={value} className={styles.button} onClick={handleClick}>
          {text}
        </button>
      ))}
    </div>
  );
}

const nowDate = dayjs();
const nowYear = nowDate.get("year");
const filter = [
  { value: `${nowYear}-01-01to${nowYear}-03-31`, text: "1분기" },
  { value: `${nowYear}-04-01to${nowYear}-06-30`, text: "2분기" },
  { value: `${nowYear}-07-01to${nowYear}-09-30`, text: "3분기" },
  { value: `${nowYear}-10-01to${nowYear}-12-31`, text: "4분기" },
  { value: `${nowYear}-01-01to${nowYear}-06-30`, text: "상반기" },
  { value: `${nowYear}-07-01to${nowYear}-12-31`, text: "하반기" },
  { value: `${nowDate.subtract(1, "year").format("YYYY-MM-DD")}to${nowDate.format("YYYY-MM-DD")}`, text: "1년" },
  { value: `${nowDate.subtract(2, "year").format("YYYY-MM-DD")}to${nowDate.format("YYYY-MM-DD")}`, text: "2년" },
  { value: `${nowDate.subtract(3, "year").format("YYYY-MM-DD")}to${nowDate.format("YYYY-MM-DD")}`, text: "3년" },
];
