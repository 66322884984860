import { useModal } from "hooks";
import { MouseEventHandler, useState } from "react";
import { useMutation } from "react-query";

import { deleteDidFilterRequest } from "api/market";
import { DefaultBtn } from "common/button";
import { Content } from "common/container";
import { Dialog } from "common/modal";
import { Title } from "common/other";
import styles from "style/pages/market/didFilterPage/didRevoke.module.scss";
import DidFilteredList from "./DidFilteredList";
import Label from "./components/Label";
import useDidFilterListQuery from "./useDidFilterListQuery";

type TProps = {
  proposalId: string;
};

export default function DidFilterRevoke({ proposalId }: TProps) {
  const [errMsg, setErrMsg] = useState("");
  const [selectedDidFilterId, setSelectedDidFilterId] = useState("");

  const { data: didFilteredList, invalidateDidFilterQuery } = useDidFilterListQuery(proposalId);

  const { mutateAsync: revokeMuta, isLoading } = useMutation({
    mutationFn: async (did: string) => deleteDidFilterRequest(did),
    onSuccess: () => invalidateDidFilterQuery(),
    onError: () => {
      setErrMsg("DID 필터링 취소에 실패했습니다");
    },
    onSettled: () => {
      onNotifyToggle();
    },
  });

  const [isConfirmModalOpen, onConfirmToggle] = useModal();
  const [isNotifyOpen, onNotifyToggle] = useModal();

  const handleRevokeClick = () => {
    if (!selectedDidFilterId) {
      return;
    }
    revokeMuta(selectedDidFilterId);
  };

  const handleFormSubmit = () => {
    onConfirmToggle();
  };

  const resetErrMsg = () => setErrMsg("");

  const handleNotifyToggle = () => {
    onNotifyToggle();
    resetErrMsg();
  };

  const handleClickItem: MouseEventHandler<HTMLButtonElement> = (e) => {
    setSelectedDidFilterId(e.currentTarget.id);
  };

  return (
    <section className={styles.container}>
      <Title text="DID 필터링 적용 정보" />
      <Content>
        <Label>취소할 필터링 ID: {selectedDidFilterId === "" ? "미선택" : selectedDidFilterId}</Label>
        {didFilteredList && (
          <DidFilteredList
            handleClickItem={handleClickItem}
            didFilteredList={didFilteredList}
            selectedDidFilterId={selectedDidFilterId}
          />
        )}
        <div className={styles.did_revoke_container}>
          <DefaultBtn onClick={handleRevokeClick} size="medium">
            필터링 등록 취소
          </DefaultBtn>
        </div>
      </Content>
      {isConfirmModalOpen && (
        <Dialog
          btnType="submit"
          title="취소하시겠습니까?"
          description="입력 정보에 따라 DID 필터링을 취소합니다"
          submitBtnName="확인"
          handleClose={onConfirmToggle}
          handleSubmit={handleFormSubmit}
        />
      )}
      {isNotifyOpen && (
        <Dialog
          btnType="confirm"
          title="요청이 완료되었습니다"
          description={errMsg || "DID 필터링이 적용이 취소되었습니다"}
          submitBtnName="확인"
          handleClose={handleNotifyToggle}
        />
      )}
    </section>
  );
}
