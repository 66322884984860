import queryKey from "constants/queryKey";
import { useQuery } from "react-query";
import { Navigate, Outlet } from "react-router-dom";
import { getIsLogined, getToken } from "utils/access";

export default function PublicRoute() {
  const isLogined = getIsLogined();
  const { data } = useQuery(queryKey.PUBLIC_ROUTE_TOKEN, getToken, { enabled: isLogined });
  const isNotValidToken = data === null;

  return (
    <>
      {(!isLogined || isNotValidToken) && <Outlet />}
      {data && <Navigate to="/" replace />}
    </>
  );
}
