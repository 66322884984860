import { Axios } from "./base";
import { TDashboard, TDashboardPayload } from "types/dashboard";
import { ErrorMessage } from "constants/errorCode";
import { errorHandling } from "./errorHandling";

// 대시보드 전체 정보 조회
export const getDashboardAll = async (did: string): Promise<TDashboard> => {
  try {
    const { data } = await Axios.post(`/api/dashboard/info/all`, { did });
    return data.data.TransactionInfo;
  } catch (error) {
    throw errorHandling(Error(ErrorMessage[7800]));
  }
};

// 대시보드 최근 1년 데이터 추이 조회
export const getDashboardYear = async (body: TDashboardPayload): Promise<TDashboard> => {
  try {
    const { data } = await Axios.post(`/api/dashboard/info/year`, body);
    return data.data.TransactionInfo;
  } catch (error) {
    throw errorHandling(Error(ErrorMessage[7800]));
  }
};

// 대시보드 기간별 통계 조회
export const getDashboardPeriod = async (body: TDashboardPayload): Promise<TDashboard> => {
  try {
    const { data } = await Axios.post(`/api/dashboard/info/period`, body);
    return data.data.TransactionInfo;
  } catch (error) {
    throw errorHandling(Error(ErrorMessage[7800]));
  }
};
