import dayjs from "dayjs";
import { Label, InputWithLabel } from "pages/advertisement/adUI";
import { TAdDetail } from "types/advertisement";
import { Content } from "common/container";

// 결제 사유 컴포넌트 (결재 완료 후 예정 / 반려 상태에서 표시)
export default function AdApprovalReason({ data }: { data: TAdDetail }) {
  const { approvedDate, updateDate, status, approved, reason } = data.advertisements;

  // '결재일' 분기
  const resultDate = () => {
    if (resultStatus() === "승인") {
      return dayjs(approvedDate).format("YYYY.MM.DD");
    }
    return dayjs(updateDate).format("YYYY.MM.DD");
  };

  // '결과' 분기
  const resultStatus = () => {
    if (String(status) === "3") return "반려";
    if (approved && String(status) === "0") return "승인";
    return "";
  };

  return (
    <Content borderRadius="12px">
      <Label must={false}>결재사유</Label>
      <InputWithLabel label="결재일">{resultDate()}</InputWithLabel>
      <InputWithLabel label="결과">{resultStatus()}</InputWithLabel>
      <InputWithLabel label="반려사유">{reason || "-"}</InputWithLabel>
    </Content>
  );
}
