import { Path, FieldValues } from "react-hook-form";
import { FileInput } from "common/input";
import styles from "style/pages/management/company/imageItem.module.scss";

type TProps = {
  id: Path<FieldValues>;
  btnText?: string;
  accept?: string;
  sizeLimit?: number;
  labelName: string;
};

export default function ImageItem({ id, btnText = "파일 선택", accept, sizeLimit = 1, labelName }: TProps) {
  return (
    <div className={styles.container}>
      <label>
        {labelName}
        <span>*</span>
      </label>

      <FileInput id={id} btnText={btnText} accept={accept} sizeLimit={sizeLimit} />
    </div>
  );
}
