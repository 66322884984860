import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { CheckInput } from "common/input";
import { WarningCircleIcon } from "assets/svgs/icon";
import styles from "style/pages/login/agreementPopup.module.scss";
import { Axios } from "api/base";
import { setToken } from "utils/access";

type TProps = {
  isPopup?: boolean;
  handleSubmit: () => void;
};

export default function AgreementPopup({ isPopup, handleSubmit }: TProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(false);
  const [isDisagree, setIsDisagree] = useState(false);

  const handleCancel = async () => {
    await Axios.post("/api/auth/sign-out");
    setToken(null);
    queryClient.clear();
    navigate("/login", { replace: true });
  };

  return (
    <div>
      {isPopup && (
        <div className={styles.background}>
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <div>이용약관 동의</div>
              <button type="button" onClick={handleCancel}>
                &times;
              </button>
            </div>
            <h1>개인정보 수집 동의서</h1>
            <div className={styles.content}>
              <div className={styles.title}>{isDisagree ? `\n` : "아래와 같이 개인정보를 처리합니다."}</div>
              <hr />

              {isDisagree ? (
                <div className={styles.disagreeContent}>
                  <WarningCircleIcon />
                  <div>{`개인정보 수집에 동의하지 않으면\n서비스를 이용하실 수 없습니다.`}</div>
                </div>
              ) : (
                <>
                  <div className={styles.itemBox}>
                    <div>
                      <div>항</div>
                      <div>목</div>
                    </div>
                    <div className={styles.item}>이름, 전화번호, 이메일, ID/PW</div>
                  </div>
                  <div className={styles.itemBox}>
                    <div>수집목적</div>
                    <div className={styles.item}>{`B2B 서비스 사용(광고게재, 데이터 거래\n서비스 이용 목적)`}</div>
                  </div>
                  <div className={styles.itemBox}>
                    <div>보유기간</div>
                    <div className={styles.item}>영구(탈퇴 시 5년간 보관 후 영구 삭제)</div>
                  </div>
                  <div className={styles.checkStyle}>
                    <CheckInput
                      name="auto-login"
                      text={
                        <Link
                          to={`${window.CDN_URL}/snplab/b2b-static/private_policy.html`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          위와 같이 개인정보를 수집∙이용하는 데 동의하십니까?
                        </Link>
                      }
                      value={isCheck}
                      handleCheck={() => setIsCheck(!isCheck)}
                    />
                  </div>
                </>
              )}

              <div className={styles.buttonBox}>
                {isDisagree ? (
                  <button type="button" className={styles.primary} onClick={handleCancel}>
                    확인
                  </button>
                ) : (
                  <>
                    <button type="button" onClick={() => setIsDisagree(!isDisagree)}>
                      미동의
                    </button>
                    <button type="button" className={styles.primary} disabled={!isCheck} onClick={handleSubmit}>
                      동의
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
