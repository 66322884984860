import { CheckInput } from "common/input";
import { useRecoilState } from "recoil";
import { marketTargetAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/collectData.module.scss";

export default function CollectSdkData() {
  const [{ collectData, product }, setTargetState] = useRecoilState(marketTargetAtom);

  const formatCollectData = () => {
    if (!product?.selectData) return [{ label: "", value: "" }];
    const result = product.selectData.flatMap((v) =>
      v.selectPeriod.map(({ period }) => {
        return {
          label: `${period.replace(/w/g, "주").replace(/M/g, "개월")} ${v.title}`,
          value: `${period} ${v.title}`,
        };
      })
    );
    return result;
  };

  const handleDataCheck = (target: string) => {
    const targetPeriod = target.split(" ")[0];
    const targetTitle = target.slice(targetPeriod.length + 1);

    if (collectData.filter((data) => data.includes(targetTitle)).length) {
      collectData.includes(target)
        ? setTargetState((prev) => ({
            ...prev,
            collectData: collectData.filter((data) => data !== target),
            collectPeriod: "2w",
          }))
        : setTargetState((prev) => ({
            ...prev,
            collectData: collectData.filter((data) => !data.includes(targetTitle)).concat(target),
            collectPeriod: targetPeriod as any,
          }));
    } else {
      collectData.includes(target)
        ? setTargetState((prev) => ({
            ...prev,
            collectData: collectData.filter((data) => data !== target),
            collectPeriod: targetPeriod as any,
          }))
        : setTargetState((prev) => ({
            ...prev,
            collectData: collectData.concat(target),
            collectPeriod: targetPeriod as any,
          }));
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>수집 데이터</p>
      <div className={styles.dataWrapper}>
        {formatCollectData().map(({ label, value }) => {
          return (
            <CheckInput
              handleCheck={() => handleDataCheck(value)}
              name={label}
              text={label}
              value={collectData.includes(value)}
              key={value}
            />
          );
        })}
      </div>
    </div>
  );
}
