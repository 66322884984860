import styles from "style/pages/market/createMarketPage/sectionContent/bannerRadioBox.module.scss";
import { getBannerUrl } from "utils/market";

interface Props {
  name: string;
  dataList: string[];
  value: string;
  handleCheck: (value: any) => void;
}

export default function BannerRadioBox({ name, dataList, value, handleCheck }: Props) {
  const handleClickBanner = (imageName: string) => handleCheck(imageName);

  return (
    <div className={styles.wrapper}>
      {dataList.map((data, idx) => (
        <div key={data} className={styles.radioWrapper}>
          <div className={styles.radio}>
            <input
              type="radio"
              id={data}
              name={name}
              value={value}
              onChange={() => handleClickBanner(data)}
              checked={data === value}
            />
            <label htmlFor={data}>{`배너 ${idx + 1}`}</label>
          </div>
          <img src={getBannerUrl(data)} alt={data} onClick={() => handleClickBanner(data)} role="presentation" />
        </div>
      ))}
    </div>
  );
}
