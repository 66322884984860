import styles from "style/pages/market/marketDetailPage/alertDescription.module.scss";

import { AlertIcon, AlertImg } from "assets/svgs/market";

export default function AlertDescription() {
  return (
    <div className={styles.wrapper}>
      <AlertImg className={styles.img} />

      <div className={styles.contents}>
        <h3>
          <AlertIcon />
          익명 정보 다운로드 알림
        </h3>

        <ul>
          <li className={styles.firstLine}>
            해당 데이터는 개인 식별 정보를 포함하지 않는 <span>익명 처리된 데이터</span>입니다. 그러나 특정 상황에서는
            다른 데이터와 결합하거나 고도의 분석을 통해 <span>특정 개인을 식별할 가능성</span>이 있을 수 있습니다.
            데이터를 사용하는 과정에서 다음 사항을 반드시 준수해 주시기 바랍니다:
          </li>
          <li>
            데이터는 <span>의도적으로 개인을 식별하려는 시도를 하지 않는 목적으로 제공</span>
            됩니다.
          </li>
          <li>
            데이터를 타 데이터와 결합하거나 분석하여 <span>특정 개인을 식별하는 모든 행위는 금지</span>됩니다.
          </li>
          <li>
            데이터 사용 목적은 <span>법적 및 윤리적 기준을 철저히 준수</span>해야 합니다. <span>위반 시 법적 책임</span>
            을 질 수 있으며, 사용자는 이를 숙지한 후 데이터를 사용해야 합니다.
          </li>
          <li>
            데이터를 취급하는 동안 <span>보안 규정을 준수</span>하고, 필요하지 않은 경우 <span>즉시 삭제</span>
            하십시오.
          </li>
        </ul>
      </div>

      <div className={styles.contents}>
        <h3>
          <AlertIcon />
          정보 제공 시 주의사항
        </h3>

        <ul>
          <li>
            <span>비식별 정보라도 안심하고 공유해서는 안 됩니다.</span>
          </li>
          <li>
            개인정보 침해 사고 발생 시, <span>책임은 정보를 공유한 자에게 있습니다.</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
