import { ComponentProps } from "react";

import styles from "style/pages/market/didFilterPage/components/radio.module.scss";

type TProps = {} & ComponentProps<"input">;

export default function Radio({ ...otherProps }: TProps) {
  return (
    <div className={styles.container}>
      <input type="radio" {...otherProps} />
    </div>
  );
}
