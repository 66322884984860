import { TableNav } from "common/table";
import { DateSearchBox } from "common/input";

import styles from "style/pages/advertisement/adUI/tableTop.module.scss";

type TDataInfo = {
  num: number;
  status: number | string;
};

type TProps = {
  data: { [key: string]: TDataInfo };
};

export default function TableTop({ data }: TProps) {
  return (
    <div className={styles.wrapper}>
      <TableNav data={data} />
      <DateSearchBox />
    </div>
  );
}
