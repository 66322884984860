import { getAmountPoint, postRewardCharge } from "api/reward";
import queryKey from "constants/queryKey";
import { useMutation, useQuery } from "react-query";

export default function useRewardCharge() {
  const {
    data,
    isLoading: isGetAmountPointLoading,
    isError: isGetAmountPointError,
    error: getAmountPointError,
    isFetching,
  } = useQuery([queryKey.GET_AMOUNT_POINT], getAmountPoint);

  const {
    mutate,
    isLoading: isPostRewardChargeLoading,
    isError: isPostRewardChargeError,
    error: postRewardChargeError,
  } = useMutation(postRewardCharge);

  return {
    data,
    mutate,
    isLoading: isGetAmountPointLoading || isPostRewardChargeLoading,
    isFetching,
    isError: isGetAmountPointError || isPostRewardChargeError,
    error: getAmountPointError || postRewardChargeError,
  };
}
