import { useState } from "react";
import cx from "classnames";
import { DoubleAngleBracketIcon as Arrow } from "assets/svgs/icon";
import SidebarErrorBoundary from "./SidebarErrorBoundary";
import styles from "style/pages/advertisement/previewSidebar/sidebarLayout.module.scss";

type TSidebarProps = {
  defaultOpen?: boolean;
  children: React.ReactNode;
  getIsOpen?: (isOpen: boolean) => void;
};

export default function SidebarLayout({ children, getIsOpen, defaultOpen = true }: TSidebarProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleSidebar = () => {
    getIsOpen && getIsOpen(!isOpen);
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={cx(styles.wrapper, { [styles.wrapperOpen]: isOpen })}>
      <button type="button" className={cx(styles.sidebarOpenBtn)} onClick={handleSidebar}>
        <Arrow className={cx({ [styles.closeArrow]: isOpen })} />
      </button>
      <SidebarErrorBoundary>
        <div className={styles.innerWrapper}>{children}</div>
      </SidebarErrorBoundary>
    </div>
  );
}
