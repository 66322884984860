import styles from "style/pages/market/createMarketPage/sectionTargetData/targetFiltering/ageTargetBox.module.scss";
import { marketTargetAtom } from "recoils/market";
import { useRecoilState } from "recoil";
import Dropdown from "common/input/Dropdown";
import { Chip } from "common/button";
import { CheckTitle } from "pages/market/marketComponents";
import { TARGET_MAX_AGE, TARGET_MIN_AGE } from "constants/market";

export default function AgeTargetBox() {
  const [{ age: ageState }, setTargetState] = useRecoilState(marketTargetAtom);
  const [minAge, maxAge] = ageState;

  const handleAgeReset = () => {
    setTargetState((prev) => ({ ...prev, age: [0, 0] }));
  };
  const handleMinAgeSelect = (age: string) => {
    const ageNumber = Number(age.replace(/[^\d]/g, "")) || 0;
    setTargetState((prev) => ({ ...prev, age: [ageNumber, maxAge] }));
  };
  const handleMaxAgeSelect = (age: string) => {
    const ageNumber = Number(age.replace(/[^\d]/g, "")) || 0;
    setTargetState((prev) => ({ ...prev, age: [minAge, ageNumber] }));
  };
  const minAgeData = Array.from(
    Array(maxAge ? maxAge - TARGET_MIN_AGE + 2 : TARGET_MAX_AGE - TARGET_MIN_AGE + 2),
    (_, index) => {
      if (index === 0) return "선택안함";
      const age = index + TARGET_MIN_AGE - 1;
      return `${age}세`;
    }
  );
  const maxAgeData = Array.from(
    Array(minAge ? TARGET_MAX_AGE + 2 - minAge : TARGET_MAX_AGE - TARGET_MIN_AGE + 2),
    (_, index) => {
      if (index === 0) return "선택안함";
      const age = minAge ? index + minAge - 1 : index + TARGET_MIN_AGE - 1;
      return `${age}세`;
    }
  );

  return (
    <div className={styles.wrapper}>
      <CheckTitle label="연령대" />
      <div className={styles.buttonWrapper}>
        <Chip mode={!minAge && !maxAge ? "color" : "default"} onClick={handleAgeReset} width="58px">
          전체
        </Chip>
      </div>
      <div className={styles.inputWrapper}>
        <Dropdown
          dataList={minAgeData}
          handleChange={handleMinAgeSelect}
          value={minAge ? `${minAge}세` : ""}
          placeholder={minAge ? "" : "선택안함"}
          width="220px"
          borderColor="deep"
          maxHeight="320px"
          mode="secondary"
          disabled
        />
        <span>이상</span>
      </div>
      <span>~</span>
      <div className={styles.inputWrapper}>
        <Dropdown
          dataList={maxAgeData}
          handleChange={handleMaxAgeSelect}
          value={maxAge ? `${maxAge}세` : ""}
          placeholder={maxAge ? "" : "선택안함"}
          width="220px"
          borderColor="deep"
          maxHeight="320px"
          mode="secondary"
          disabled
        />
        <span>이하</span>
      </div>
    </div>
  );
}
