import { Cell, Label, Pie, PieChart } from "recharts";
import styles from "style/pages/advertisement/adDetail/chart/donutChart.module.scss";

type TProps = {
  clickPercent: number; // 클릭률
};

// 광고 상세 대시보드 - 클릭률 도넛 그래프 컴포넌트
export default function DonutChart({ clickPercent }: TProps) {
  const data = [
    { name: "클릭률", value: clickPercent },
    { name: "total", value: clickPercent >= 100 ? 0 : 100 - clickPercent },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>클릭률</div>
      <div className={styles.donutChart}>
        <PieChart width={300} height={200}>
          <Pie
            data={data}
            cx="35%"
            cy="45%"
            dataKey="value"
            innerRadius={60}
            outerRadius={85}
            fill="#f0f0f0"
            startAngle={90}
            endAngle={-270}
          >
            {data.map((el, index) => {
              if (index === 1) return <Cell key={el.name} fill="#f0f0f0" width={100} height={100} />;
              return <Cell key={el.name} fill="#0051A3" />;
            })}

            <Label
              value={`${data[0].value}%`}
              position="center"
              fill="#0051A3"
              style={{
                fontSize: "24px",
                fontWeight: "700",
              }}
            />
          </Pie>
        </PieChart>
      </div>
    </div>
  );
}
