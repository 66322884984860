import { useState } from "react";
import styles from "style/pages/advertisement/previewSidebar/previewInfo.module.scss";

type TProps = {
  infoData: {
    adBannerUrl: string | File;
  };
};
export default function PreviewInfo({ infoData }: TProps) {
  const { adBannerUrl } = infoData;
  const haveCdnImgUrl = typeof adBannerUrl === "string";

  const [img, setImg] = useState("");

  const handleFormatImg = (imgFile: File) => {
    if (!imgFile) return;

    const reader = new FileReader();

    reader.onloadend = () => {
      const fileString = reader.result?.toString() || "";
      setImg(fileString);
      return fileString;
    };

    reader.readAsDataURL(imgFile);
  };

  if (!haveCdnImgUrl) handleFormatImg(adBannerUrl);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabWrapper}>
        <div>상세정보</div>
        <div>기업정보</div>
      </div>
      <div className={styles.innerWrapper}>
        {adBannerUrl ? (
          <img src={haveCdnImgUrl ? adBannerUrl : img} alt="광고 영역" className={styles.bannerImage} />
        ) : (
          <div className={styles.emptyAdImg}>광고 영역</div>
        )}
      </div>
    </div>
  );
}
