import { useState } from "react";
import { useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";

import { LogoutIcon, ManualIcon, MyPageIcon } from "assets/svgs/skeleton";
import { Dialog } from "common/modal";

import styles from "style/common/other/skeleton/top.module.scss";

import { Axios } from "api/base";
import manual from "assets/files/MyD_connect_user_manual.pdf";
import { deleteIsLogined, setToken } from "utils/access";

export default function Top() {
  const queryClient = useQueryClient();
  const [isLogout, setIsLogout] = useState(false);
  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    queryClient.clear();

    await Axios.post("/api/auth/sign-out");
    setToken(null);
    deleteIsLogined();
    navigate("/login", { replace: true });
  };

  return (
    <div className={styles.top}>
      <a download href={manual}>
        사용설명서
        <ManualIcon />
      </a>
      <Link to="mypage">
        마이페이지
        <MyPageIcon />
      </Link>

      <button type="button" onClick={() => setIsLogout(true)}>
        로그아웃
        <LogoutIcon />
      </button>

      {isLogout && (
        <Dialog
          description="로그아웃 하시겠습니까?"
          btnType="submit"
          handleClose={() => setIsLogout(false)}
          handleSubmit={handleLogoutClick}
        />
      )}
    </div>
  );
}
