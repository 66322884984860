import { Dropdown } from "common/input";
import { CheckTitle } from "pages/market/marketComponents";
import useResetMarketState from "pages/market/marketHooks/useResetMarketState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { marketPrivacyAtom, marketTargetAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/selectProduct.module.scss";
import { TMarketProductData } from "types/market";

type TProps = {
  productsData?: TMarketProductData[];
};

export default function SelectProduct({ productsData }: TProps) {
  const [{ product }, setTargetState] = useRecoilState(marketTargetAtom);
  const setPrivacyState = useSetRecoilState(marketPrivacyAtom);
  const resetMarketState = useResetMarketState();

  const productList = productsData?.map(({ title }) => title) || [];

  const handleSelectProduct = (target: string) => {
    const targetProduct = productsData?.find(({ title }) => title === target);

    if (!targetProduct || targetProduct.title === product.title) return;
    resetMarketState();
    setTargetState((prev) => ({ ...prev, product: targetProduct! }));
    setPrivacyState((prev) => ({
      ...prev,
      personalIdentification: targetProduct.selectPrivacy ? "nonAnonymous" : "anonymous",
    }));
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>구매 데이터 상품</p>

      <div className={styles.dropdownWrapper}>
        <CheckTitle label="상품선택" height="34px" />
        <Dropdown
          dataList={productList}
          handleChange={handleSelectProduct}
          value={product.title}
          width="600px"
          mode="secondary"
          maxHeight="320px"
          borderColor="soft"
        />
      </div>
    </div>
  );
}
