import { Axios } from "./base";
import { TStaff } from "types/common";
import { TCreateStaff, TLoginStaffCompany, TModifyStaff } from "types/management";
import { errorHandling } from "./errorHandling";
import { ErrorMessage } from "constants/errorCode";

// 사용자 목록 조회
export const getUserListData = async (): Promise<TStaff[]> => {
  try {
    const { data } = await Axios.get(`/api/manager/v2/index`);
    return data.data.staffList;
  } catch {
    throw errorHandling(Error(`사용자 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

// 담당자 상세 정보 조회
export const getSingleUserData = async (id: string): Promise<TStaff> => {
  try {
    const { data } = await Axios.get(`/api/manager/v2/info`, { params: { id } });
    return data.data.staff;
  } catch {
    throw errorHandling(Error(`담당자 정보 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

// 담당자 계정 등록
export const postUserData = async (staffCreate: TCreateStaff): Promise<TStaff> => {
  try {
    const { data } = await Axios.post(`/api/manager/v2/new`, { staffCreate });
    return data.data.resultStaff;
  } catch (error) {
    if (error instanceof Error && error.message !== ErrorMessage[3039]) {
      throw errorHandling(
        Error(`계정을 등록하지 못했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`)
      );
    } else throw error;
  }
};

// 담당자 계정 삭제
export const deleteUserData = async (id: string) => {
  try {
    const { data } = await Axios.get(`/api/manager/v2/delete`, { params: { id } });
    return data;
  } catch {
    throw errorHandling(Error(`계정을 삭제하지 못했습니다.\n다시 시도해 주세요.`));
  }
};

// 담당자 정보 수정
export const updateUserData = async (staffModify: TModifyStaff): Promise<TStaff> => {
  try {
    const { data } = await Axios.post(`/api/manager/v2/modify`, { staffModify });
    return data.data.resultStaff;
  } catch {
    throw errorHandling(Error(`담당자 정보를 수정하지 못했습니다.\n다시 시도해 주세요.`));
  }
};

// 담당자 권한 변경
export const changeAuth = async (id: string): Promise<{ role: string }> => {
  try {
    const { data } = await Axios.get(`/api/manager/v2/authority/modify`, { params: { id } });
    return data.data.resultStaff[0].role;
  } catch {
    throw errorHandling(
      Error(
        `대표 담당자 권한을 변경하지 못했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`
      )
    );
  }
};

// 로그인 유저의 기업 정보 조회
export const getLoginCompanyData = async (): Promise<TLoginStaffCompany> => {
  const { data } = await Axios.get(`/api/manager/v2/new`);
  return data.data;
};

// 개인정보 수집 동의 여부 조회
export const getPrivacyAgree = async (email: string): Promise<{ result: string }> => {
  const { data } = await Axios.get(`/api/manager/v2/agree/check`, { params: { email } });
  return data.data;
};

// 개인정보 수집 동의로 변경
export const postPrivacyAgree = async (body: { email: string }) => {
  try {
    const { data } = await Axios.post(`/api/manager/v2/agree`, body);
    return data.data;
  } catch (error) {
    if (error instanceof Error && error.message === ErrorMessage[3039]) {
      throw errorHandling(Error(`이미 동의하셨습니다.`));
    } else throw error;
  }
};
