import { TDashboard } from "types/dashboard";
import { formatByteSize } from "utils/formatByteSize";
import styles from "style/pages/dashboard/dashboardInfo.module.scss";

export default function DashboardInfo({ data }: { data: TDashboard }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <p>포인트 잔고</p>
        <p>{data?.remindPoint.toLocaleString() || 0}P</p>
      </div>

      <div className={styles.info}>
        <p>진행 중인 거래</p>
        <p>{data?.ongoingTransaction.toLocaleString() || 0}건</p>
      </div>

      <div className={styles.info}>
        <p>완료된 거래</p>
        <p>{data?.completeTransaction.toLocaleString() || 0}건</p>
      </div>

      <div className={styles.info}>
        <p>누적 데이터 거래 인원</p>
        <p>{data?.participants.toLocaleString() || 0}명</p>
      </div>

      <div className={styles.info}>
        <p>누적 데이터 거래량</p>
        <p>{formatByteSize(data?.totalTransactionAmount || 0)}</p>
      </div>
    </div>
  );
}
