import { CheckInput } from "common/input";
import { dataPurposeList } from "constants/market";
import { useRecoilState, useRecoilValue } from "recoil";
import { marketPrivacyAtom, marketTargetAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/dataPurpose.module.scss";
import { finelabDataPurposeList } from "../productSet/finelab";

const dataPurposeNonAnonymousList = [
  { label: "상품 및 서비스 홍보 또는 안내", value: "상품 및 서비스 홍보 또는 안내" },
  { label: "건강정보 제공", value: "건강정보 제공" },
  { label: "이벤트 행사", value: "이벤트 행사" },
  { label: "시장조사", value: "시장조사" },
  { label: "고객 만족도 조사", value: "고객 만족도 조사" },
  { label: "경품 지급", value: "경품 지급" },
];

export default function DataPurpose() {
  const [{ dataPurpose, product }, setTargetState] = useRecoilState(marketTargetAtom);
  const { personalIdentification } = useRecoilValue(marketPrivacyAtom);
  const isnonAnonymous = personalIdentification === "nonAnonymous";

  const handlePuerposeCheck = (target: string) => {
    dataPurpose.includes(target)
      ? setTargetState((prev) => ({ ...prev, dataPurpose: dataPurpose.filter((data) => data !== target) }))
      : setTargetState((prev) => ({ ...prev, dataPurpose: dataPurpose.concat(target) }));
  };
  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>데이터 거래 목적</p>
      <div className={styles.dataWrapper}>
        {(product.company === "finelab" ? finelabDataPurposeList : dataPurposeList).map(({ label, value }) => {
          return (
            <CheckInput
              handleCheck={() => handlePuerposeCheck(value)}
              name={label}
              text={label}
              value={dataPurpose.includes(value)}
              key={value}
            />
          );
        })}

        {isnonAnonymous &&
          product.company !== "finelab" &&
          dataPurposeNonAnonymousList.map(({ label, value }) => (
            <CheckInput
              handleCheck={() => handlePuerposeCheck(value)}
              name={`${label}-purpose`}
              text={label}
              value={dataPurpose.includes(value)}
              key={value}
            />
          ))}
      </div>
    </div>
  );
}
