import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { TReward } from "types/reward";
import StatusChip from "./StatusChip";

export default function useRewardList({ data }: { data?: TReward["rewardViewList"] }): React.ReactNode[][] {
  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum")) || 1;

  let rewardViewList = data || [];
  let rewardList: React.ReactNode[][] = [];
  rewardViewList = rewardViewList.map((reward) => {
    const { message, type } = reward;

    let title = message || "";

    // 포인트 사용
    if (type === 1) {
      title = `포인트 사용 (${message})`;
    }

    // 포인트 환급
    if (type === 2) {
      title = `포인트 환급 (${message})`;
    }

    return {
      ...reward,
      message: title,
    };
  });

  // type을 status로 변환
  const formatStatus = (rewardData: TReward["rewardViewList"][0]) => {
    if (rewardData.type === 0) {
      if (/(충전 요청|요청 취소)/.test(rewardData.message)) {
        return 4;
      }
      return 3;
    }
    if (rewardData.type === 1) return 1;
    if (rewardData.type === 2) return 2;
    return 0;
  };

  rewardViewList.forEach((reward, index) => {
    const { id, message, createdAt, changes, type, amount } = reward;

    let requestPointText = "-";
    let collectedPointText = "-";
    let expendedPointText = "-";

    // 포인트 충전, 포인트 충전 요청, 포인트 요청 취소
    if (type === 0) {
      if (message === "포인트 충전") {
        collectedPointText = `${(changes || 0).toLocaleString()}P`;
      } else {
        requestPointText = `${(changes || 0).toLocaleString()}P`;
      }
    }

    // 포인트 사용
    if (type === 1) {
      expendedPointText = `${(changes || 0).toLocaleString()}P`;
    }

    // 포인트 환급
    if (type === 2) {
      collectedPointText = `${(changes || 0).toLocaleString()}P`;
    }

    const amountPointText = amount !== 0 ? `${amount?.toLocaleString()}P` : "-";

    rewardList.push([
      `${rewardViewList.length - index}-${type}`,
      rewardViewList.length - index,
      <StatusChip key={id} status={formatStatus(reward)} />,
      message,
      dayjs(createdAt).format("YYYY.MM.DD HH:mm:ss"),
      requestPointText,
      collectedPointText,
      expendedPointText,
      amountPointText,
    ]);
  });

  // 필터된 리스트 넘버링
  rewardList = rewardList.map((item, idx) => {
    item[1] = rewardList.length - idx;
    return item;
  });
  // 리스트 페이징
  rewardList = rewardList.slice((pageNum - 1) * 10, pageNum * 10);

  return rewardList;
}
