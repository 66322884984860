import { Path, FieldValues, useFormContext } from "react-hook-form";
import cx from "classnames";
import dayjs from "dayjs";
import styles from "style/common/input/labelInput.module.scss";

type TProps = React.ComponentProps<"input"> & {
  must?: boolean;
  id: Path<FieldValues>;
  label?: string;
  isNull?: boolean;
  labelWidth?: string;
  validate?: string;
};

export default function LabelInput({
  placeholder,
  type = "text",
  id,
  label,
  must = true,
  isNull,
  labelWidth,
  validate,
  ...props
}: TProps) {
  const { register } = useFormContext();

  return (
    <div className={cx(styles.container, { [styles.nullStyle]: isNull })}>
      <label htmlFor={id} style={labelWidth ? { flex: `0 0 ${labelWidth}` } : {}}>
        {label}
        {must && <span>*</span>}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        id={id}
        className={cx({ [styles.nullStyle]: isNull })}
        min={type === "number" ? 0 : undefined}
        onKeyDown={(evt) =>
          type === "number" &&
          (evt.key === "e" || evt.key === "E" || evt.key === "+" || evt.key === "-") &&
          evt.preventDefault()
        }
        {...register(id, {
          validate: (value) => validate && validatePattern(value, validate),
          required: must && "필수 항목을 모두 입력해 주세요.",
        })}
        {...props}
      />
    </div>
  );
}

const validatePattern = (value: string, validate: string) => {
  if (value) {
    if (validate === "email") {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(value)) {
        return "이메일 주소가 이메일 형식에 맞지 않습니다.";
      }
    }

    if (validate === "url") {
      if (!/^http[s]?:\/\/([\S]{3,})/i.test(value)) {
        return "올바른 URL 형식으로 입력해 주세요.";
      }
    }

    if (validate === "number") {
      if (!/^\d+$/.test(value)) {
        return "숫자만 입력해 주세요.";
      }
    }

    if (validate === "tel") {
      if (!/^\d+$/.test(value)) {
        return "숫자만 입력해 주세요.";
      }
      if (value.length > 11) {
        return `연락처는 11자리를 초과하여 입력할 수 없습니다.`;
      }
    }

    if (validate === "businessNumber") {
      if (!/^\d+$/.test(value)) {
        return "숫자만 입력해 주세요.";
      }
      if (value.length !== 10) {
        return `사업자등록번호는 10자리 수만 입력할 수 있습니다.`;
      }
    }

    if (validate === "date") {
      if (!dayjs(value, "YYYYMMDD", true).isValid()) {
        return "유효한 날짜 형식으로 8자리 숫자를 입력해주세요.";
      }
    }
  }

  if (validate.includes("numberLength")) {
    const maxNumber = isNaN(Number(validate.replace("numberLength", "")))
      ? 20
      : Number(validate.replace("numberLength", ""));

    if (value.length > maxNumber) {
      return `${maxNumber}자리를 초과하여 입력할 수 없습니다.`;
    }

    if (!/^\d+$/.test(value)) {
      return "숫자만 입력해 주세요.";
    }
  }

  return true;
};
