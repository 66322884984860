import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function useDateRange() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setStartDate(searchParams.get("from") ? new Date(searchParams.get("from")!) : null);
    setEndDate(searchParams.get("to") ? new Date(searchParams.get("to")!) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDate = (update: any) => {
    const [prev, next] = update;
    setStartDate(prev as any);
    setEndDate(next as any);
    setSearchParams((params) => {
      params.set("pageNum", "1");
      return params;
    });
  };

  const resetDate = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchParams((params) => {
      params.delete("from");
      params.delete("to");
      return params;
    });
  };

  return { startDate, endDate, setStartDate, setEndDate, onChangeDate, resetDate };
}
