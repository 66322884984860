import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

export default function useDashboardDateRange() {
  const today = new Date();
  const oneYearAgo = dayjs().subtract(1, "year").toDate();

  const [, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<Date | null>(oneYearAgo);
  const [endDate, setEndDate] = useState<Date | null>(today);

  const onChangeDate = (date: any) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);
  };

  const onResetDate = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchParams((params) => {
      params.delete("period");
      return params;
    });
  };

  return { startDate, endDate, setStartDate, setEndDate, onChangeDate, onResetDate };
}
