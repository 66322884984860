// import styles from "style/common/other/buttonBox.module.scss";
import { useNavigate } from "react-router-dom";
import { DefaultBtn } from "common/button";
import Footer from "./Footer";

interface Props {
  back?: string;
  next?: string;
  text?: string;
  type?: "confirm" | "submit" | "goback";
  handleClickPrev?: () => void;
  handleClickNext?: () => void;
  handleClickConfirm?: () => void;
}

export default function ButtonBox({
  back = "뒤로가기",
  next = "완료",
  text = "확인",
  type = "submit",
  handleClickPrev,
  handleClickNext,
  handleClickConfirm,
}: Props) {
  const navigate = useNavigate();
  const handleClickBack = () => navigate(-1);

  const btnEle = {
    submit: (
      <>
        <DefaultBtn type="button" size="medium" mode="lineGray" onClick={handleClickPrev || handleClickBack}>
          {back}
        </DefaultBtn>
        <DefaultBtn type="submit" size="medium" onClick={handleClickNext}>
          {next}
        </DefaultBtn>
      </>
    ),

    confirm: (
      <DefaultBtn type="button" size="medium" onClick={handleClickConfirm || handleClickBack}>
        {text}
      </DefaultBtn>
    ),

    goback: (
      <DefaultBtn type="button" size="medium" mode="lineGray" onClick={handleClickBack}>
        {text}
      </DefaultBtn>
    ),
  }[type];

  return <Footer>{btnEle}</Footer>;
}
