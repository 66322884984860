import { atom } from "recoil";
import {
  TMarketBudgetData,
  TMarketErrorData,
  TMarketContentData,
  TMarketTargetData,
  TMarketDialogData,
  TMarketPrivacyData,
} from "types/market";

export const marketLoadingAtom = atom({
  key: "marketLoadingAtom",
  default: false,
});

export const marketErrorAtom = atom<TMarketErrorData>({
  key: "marketErrorAtom",
  default: {
    isShow: false,
    error: Error,
    buttonText: "닫기",
  },
});

export const marketDialogAtom = atom<TMarketDialogData>({
  key: "marketDialogAtom",
  default: {
    isShow: false,
    title: "",
    btnType: "submit",
    description: "등록하시겠습니까?",
    submitBtnName: "등록하기",
    handleClose: () => "",
    handleSubmit: () => "",
  },
});

export const marketPrivacyAtom = atom<TMarketPrivacyData>({
  key: "marketPrivacyAtom",
  default: {
    personalIdentification: "anonymous",
    thirdPartyStatus: 0,
    company: "",
    provisionPurpose: [],
  },
});

export const marketTargetAtom = atom<TMarketTargetData>({
  key: "marketTargetAtom",
  default: {
    gender: "all",
    age: [0, 0],
    area: ["전체"],
    mobile: "all",
    collectData: [],
    collectPeriod: "",
    dataPurpose: [],
    product: {
      title: "마이디 - 올데이터 프리미엄",
      type: "myd",
    },
    isApplyDidFiltering: "false"
  },
});

export const marketBudgetAtom = atom<TMarketBudgetData>({
  key: "marketBudgetAtom",
  default: {
    transactionPeriod: ["", ""],
    transactionTime: "0",
    maxIssuable: 0,
    reward: 0,
    customReward: null,
  },
});

export const marketContentAtom = atom<TMarketContentData>({
  key: "marketContentAtom",
  default: {
    dataType: "",
    titleType: "기본형",
    intro: "선택안함",
    transactionName: "",
    banner: "",
  },
});
