import { TDashboard } from "types/dashboard";
import { formatByteSize } from "utils/formatByteSize";
import { transferImg, profitImg, moneyImg, friendImg, cloudImg } from "assets/images/pages/dashboard";
import styles from "style/pages/dashboard/statistic.module.scss";

export default function Statistic({ data }: { data: TDashboard }) {
  const totalTransaction = data && (data.completeTransaction + data.ongoingTransaction).toLocaleString();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.top}>
          <span>총 거래 수</span>
          <img src={transferImg} alt="transfer" />
        </div>
        <p>{totalTransaction || 0}건</p>
      </div>

      <div className={styles.container}>
        <div className={styles.top}>
          <span>소진 포인트</span>
          <img src={profitImg} alt="profit" />
        </div>
        <p>{data?.exhaustedPoint.toLocaleString() || 0}P</p>
      </div>

      <div className={styles.container}>
        <div className={styles.top}>
          <span>충전 포인트</span>
          <img src={moneyImg} alt="money" />
        </div>
        <p>{data?.purchasePoint.toLocaleString() || 0}P</p>
      </div>

      <div className={styles.container}>
        <div className={styles.top}>
          <span>데이터 거래 인원</span>
          <img src={friendImg} alt="friend" />
        </div>
        <p>{data?.participants.toLocaleString() || 0}명</p>
      </div>

      <div className={styles.container}>
        <div className={styles.top}>
          <span>데이터 거래량</span>
          <img src={cloudImg} alt="cloud" />
        </div>
        <p>{formatByteSize(data?.totalTransactionAmount || 0)}</p>
      </div>
    </div>
  );
}
