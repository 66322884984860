import styles from "style/pages/market/createMarketPage/sectionBudget/caution.module.scss";

function Caution() {
  return (
    <ul className={styles.caution}>
      <li>데이터 거래 진행 기간은 30일 입니다.</li>
      <li>30일 내 포인트 예산 소진 시 데이터 거래가 조기 종료됩니다.</li>
      <li>거래 시작 시각 설정 시 지정한 시각에 거래가 시작됩니다.</li>
      <li>시작 시각과 상관없이 30일 후 23:59:59에 거래가 종료됩니다. (조기 종료 제외)</li>
    </ul>
  );
}

export default Caution;
