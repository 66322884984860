import { createContext, useContext } from "react";

type TContext = {
  defaultTab: string;
};

export const TableTabContext = createContext<TContext>({
  defaultTab: "",
});

export const useTableTabContext = () => useContext(TableTabContext);
