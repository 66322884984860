import styles from "./policy.module.scss";

export default function TermsOfService() {
  return (
    <div className={styles.wrap}>
      <p className={styles.title}>마이디 플랫폼 이용 약관</p>

      <ul>
        <li>
          <p>제1조 (목적)</p>본 약관은 ㈜ 에스앤피랩(이하 ‘회사’라 한다)가 제공하는 마이디 플랫폼(이하 ‘플랫폼’이라
          한다) 이용과 관련하여 회사, 데이터 제공기관, 데이터 이용기관, 이용자 간의 권리, 의무 및 책임사항, 기타 필요한
          사항을 규정함을 목적으로 합니다.
        </li>
        <li>
          <p>제2조 (정의)</p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <ul>
            <li style={{ listStyleType: "①  " }}>
              ‘플랫폼’이란 개인이 직접 개별 서비스의 인증을 받은 후 개별 서비스에 저장된 개인 데이터를 개인 단말로
              가져와 저장한 데이터 또는 직접 입력한 데이터에 대한 데이터 이용기관의 이용 및 제공을 개인이 직접
              승인함으로써 데이터 이용기관이 이를 활용하여 서비스를 제공할 수 있도록 회사가 제공하는 서비스 및 기타 제반
              기능을 통칭합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              ‘마이디 앱(이하 ‘앱’이라 한다)’이란 플랫폼을 이용하기 위한 어플리케이션을 말합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              ‘마이디커넥트 웹(이하 ‘마이디커넥트 웹’이라 한다)’이란 기업담당자가 플랫폼을 이용하기 위한 웹 서비스를
              말합니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              ‘데이터 제공기관’이란 개인이 플랫폼을 통해 개인 데이터를 확보할 수 있는 기업 및 기관을 지칭합니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              ‘데이터 이용기관’이란 플랫폼을 통해 개인 데이터를 이용할 수 있는 기업 및 기관을 지칭합니다.
            </li>
            <li style={{ listStyleType: "⑤  " }}>
              ‘제안’이란 플랫폼 내에서 개인 데이터 이용을 위해 필요한 ‘개인 데이터 이용권‘을 획득하기 위해 데이터
              이용기관이 개인에게 제시하는 계약서 초안을 지칭합니다. 계약서 초안에는 이용하려는 개인 데이터, 이용의
              목적, 기간 및 횟수 등이 명시됩니다.
            </li>
            <li style={{ listStyleType: "⑥  " }}>‘개인 데이터 이용권’이란 이용자가 승인한 ‘제안’을 말합니다.</li>
            <li style={{ listStyleType: "⑦  " }}>
              ‘이용자’란 앱을 통해 플랫폼을 사용하는 개인을 지칭하며 이를 위해서 개인은 앱을 통해 개인 데이터 지갑 및
              DID를 생성합니다.
            </li>
            <li style={{ listStyleType: "⑧  " }}>
              ‘DID(Decentralized ID)’란 이용자가 본인의 단말에서 임의로 생성한 값으로서, 데이터 이용기관은 이를 통해
              개인을 식별하지 못하며, 본인만 스스로를 식별할 수 있는 탈중앙화된 ID입니다.
            </li>
            <li style={{ listStyleType: "⑨  " }}>
              ‘개인 키’는 DID 소유자가 전자서명을 하기 위해 생성하는 키를 지칭합니다.
            </li>
            <li style={{ listStyleType: "⑩  " }}>
              ‘공개 키’는 개인 키와 함께 생성되는 키로서 블록체인에 공개되어 누구나 해당 데이터의 위·변조를 검증할 수
              있습니다.
            </li>
            <li style={{ listStyleType: "⑪  " }}>
              ‘이용자 저장 데이터’는 이용자가 플랫폼을 이용하여 개별 서비스의 데이터 제공기관으로부터 다운로드한 데이터
              및 이용자가 직접 입력을 통해 저장한 개인 데이터로 개인 단말에 저장된 데이터를 의미합니다. 위 데이터에는
              이용자가 직접 생성한 DID, 개인 키 등이 포함됩니다.
            </li>
            <li style={{ listStyleType: "⑫  " }}>
              ‘블록체인 저장 데이터’는 개인으로부터 전달된 DID, DID 공개 키 등을 포함한 블록체인에 기록되는 데이터를
              의미하되, 개인식별 정보 등 개인정보는 저장되지 않습니다.
            </li>
            <li style={{ listStyleType: "⑬  " }}>
              ‘LDP 데이터’란 Local Differential Privacy 기술을 통해 생성된 데이터를 말하며, 통계 정보와 같은 개인을
              식별할 수 없도록 비식별화된 형태의 데이터로 이용됩니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제3조 (약관의 효력 및 변경)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 본 약관의 내용을 이용자가 알 수 있도록 플랫폼 내 또는 별도의 연결화면에 게시합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 ‘약관의 규제에 관한 법률’ ‘정보통신망이용촉진 및 정보보호 등에 관한 법률’ 등 관련 법령을 위배하지
              않는 범위에서 본 약관을 개정할 수 있습니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              회사가 본 약관을 개정할 경우에는 개정약관의 적용일자 및 개정 내용, 개정 사유 등을 명시하여 제1항의 방식에
              따라 그 개정약관을 적용일 7일 전부터 공지합니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              이용자가 개정약관에 대해 동의하지 않는 경우 회사 또는 이용자는 플랫폼 이용계약을 해지할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제4조 (약관 외 준칙)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 필요한 경우 개별 플랫폼에 관하여 적용될 사항을 위해 개별약관이나 이용준칙을 정하여 운영할 수
              있으며, 해당 내용은 플랫폼 내 또는 별도의 홈페이지 등을 통해 공지합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 「약관의 규제에 관한 법률」,
              「정보통신망이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령 또는 상관례에 따릅니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제5조 (서비스의 제공 및 변경)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              플랫폼은 다음과 같은 업무를 수행합니다. 단, 플랫폼은 관련 법령이나 정책 변경에 따라 달라질 수 있습니다.
              <ul>
                <li style={{ listStyleType: "1.  " }}>개인 데이터를 개인 단말기(휴대전화 등)에 저장하여 관리</li>
                <li style={{ listStyleType: "2.  " }}>
                  데이터 이용기관의 개인 데이터 이용에 대한 개인의 허가 및 허가내용 관리
                </li>
                <li style={{ listStyleType: "3.  " }}>개인 데이터 이용 및 관리로 발생한 리워드 관리</li>
                <li style={{ listStyleType: "4.  " }}>발생된 리워드로 쿠폰 구매 및 구매한 쿠폰의 관리</li>
                <li style={{ listStyleType: "5.  " }}>기타 플랫폼에서 제공하는 업무</li>
              </ul>
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화
              또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여
              현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              회사는 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등 원활한
              서비스 제공을 위해 운영상 또는 기술상의 필요에 따른 사유로 변경할 경우 그 사유를 이용자에게 플랫폼 내 또는
              별도 연결화면으로 즉시 통지합니다. 다만, 버그.오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가
              있는 경우 또는 중대한 변경에 해당하지 않는 경우에는 사후에 공지할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제6조 (서비스의 중단)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 다음 각 호의 경우에는 서비스의 전부 또는 일부를 일시 중단할 수 있습니다. 이 경우 회사는 사전에 그
              정지의 사유와 기간을 마이디 초기화면이나 서비스 공지사항 등에 공지합니다. 다만, 사전에 공지할 수 없는
              부득이한 사정이 있는 경우 사후에 공지할 수 있습니다.
              <ul>
                <li style={{ listStyleType: "1.  " }}>
                  회사 또는 데이터 제공기관 또는 데이터 이용기관의 시스템 정기점검, 서버의 증설 및 교체, 네트워크의
                  불안정 등의 시스템 운영상 필요한 경우
                </li>
                <li style={{ listStyleType: "2.  " }}>
                  정전, 서비스 설비의 장애, 서비스 이용 폭주, 기간통신사업자의 설비 보수 또는 장애 및 점검 등으로 인하여
                  정상적인 서비스 제공이 불가능한 경우
                </li>
                <li style={{ listStyleType: "3.  " }}>
                  본 약관 제22조 제2항에 해당하는 경우, 천재지변, 전쟁, 폭동, 테러, 해킹, DDOS, 또는 이에 준하는
                  국가비상사태 등 회사가 통제할 수 없는 상황이 발생한 경우
                </li>
                <li style={{ listStyleType: "4.  " }}>
                  서비스 제공을 위해 계약을 체결하고 있는 데이터 제공기관 또는 데이터 이용기관 등의 시스템 운영 상황이나
                  계약 체결 상황에 따라서 서비스 운영상 상당한 이유가 있는 경우
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 파산 신청, 기업회생절차 개시 신청, 현저한 수익 악화 등 정상적인 영업활동이 곤란하여 서비스를 지속할
              수 없는 때에는 이용자에게 이를 통지한 날로부터 30일이 경과한 날부터 서비스를 중단할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제7조 (회원가입)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              플랫폼 사용을 위한 회원가입은 기업대표 담당자는 회사의 담당자와의 계약에 따라 등록하며 기업 담당자의 경우
              기업 대표 담당자가 등록하는 절차를 통해 회원가입을 진행 할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제8조 (회원 탈퇴 및 자격 상실 등)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              이용자는 마이디커넥트 웹서비스에서 삭제 또는 대표담당자가 계정 삭제를 통해 회원 자격을 상실할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제9조 (이용자에 대한 통지)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              플랫폼이 이용자에 대한 통지를 하는 경우, 기 수집된 이메일, 전화번호 등을 통해 통지 할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제10조 (청약철회 등)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사와 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조
              제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는
              재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 청약의 철회를 할 수 있습니다. 다만,
              청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에
              따릅니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              이용자는 재화 등을 전달 받은 경우 다음 각 호의 1에 해당하는 경우에는 환불을 요청할 수 없습니다.
              <ul>
                <li style={{ listStyleType: "1.  " }}>
                  이용자가 마이디커넥트 웹을 통해 앱 개인 이용자에게 지급이 끝난 포인트
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "③  " }}>
              제2항의 경우에 회사가 사전에 청약철회 등이 제한되는 사실을 이용자가 쉽게 알 수 있는 곳에 명기하거나
              시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게
              이행된 때에는 당해 재화 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일
              이내에 청약철회 등을 할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제11조 (청약철회 등의 효과)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 이용자로부터 재화 등을 반환요청을 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금(구매시
              사용한 포인트)을 환급합니다. 이 경우 회사가 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여
              「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의3에서 정하는 지연이자율을 곱하여 산정한
              지연이자를 지급합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담하나, 회사는 이용자에게 청약철회
              등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나
              계약내용과 다르게 이행되어 청약철회 등을 하는 경우, 재화 등의 반환에 필요한 비용은 플랫폼이 부담합니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 플랫폼은 청약철회 시 그 비용을 누가 부담하는지를
              이용자가 알기 쉽도록 명확하게 표시합니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제12조 (개인정보 보호)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 관련 법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에
              대해서는 관련 법령에 따릅니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              이용자 데이터는 안전한 방식으로 저장됩니다. 회사에서는 이용자의 별도 동의 없이는 이용자 저장 데이터를
              수집, 보관, 관리하지 않으며, 회사는 이용자의 단말에 저장된 데이터에 대한 폐기, 복구 의무가 없습니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              이용자가 이용자 데이터를 단말에 내려 받기 위해 데이터 제공기관에 직접 제출하는 개인정보나 인증정보의 수집
              및 보관에 대한 책임은 데이터 제공기관에 있으며 회사는 이에 대한 일체의 책임을 부담하지 않습니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              회사는 이용자의 귀책사유로 이용자 저장 데이터 및 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지
              않습니다.
            </li>
            <li style={{ listStyleType: "⑤  " }}>
              이용자는 언제든지 자신의 개인정보에 대해 앱을 통해 열람 및 오류정정을 할 수 있습니다. 이용자가 오류를
              정정한 경우에는 그 정정된 데이터를 서비스에 즉시 반영합니다.
            </li>
            <li style={{ listStyleType: "⑥  " }}>
              본 플랫폼은 정보 주체인 이용자가 단말에 직접 저장한 구매정보, 검색어 등의 개인정보를 본인 소유의 모바일
              단말기에 직접 저장, 관리, 제출하는 자기주권형 개인정보 관리 기능을 제공합니다. 이용자는 이용자 저장
              데이터의 안전한 보관 및 관리에 대한 책임이 있으며 제3자에게 데이터가 유출되거나 도용되지 않도록 할 책임이
              있습니다. 이용자의 고의 또는 과실로 이용자 저장 데이터가 유출 또는 도용되어 발생한 책임에 대해 회사는
              책임을 부담하지 않습니다.
            </li>
            <li style={{ listStyleType: "⑦  " }}>
              이용자의 바이러스 감염, 해킹, 비밀번호 유출 또는 생체인식 오류 등 문제가 발생하여 이용자가 이용자 단말 내
              저장 데이터를 이용할 수 없게 되거나 제3자에게 노출된 경우 회사는 이에 대한 일체의 책임을 부담하지
              않습니다. 단, 위 문제 발생이 회사의 고의 또는 중과실에 의한 경우에는 그러지 아니합니다.
            </li>
            <li style={{ listStyleType: "⑪  " }}>
              이용자는 블록체인 저장 데이터에 대한 소유권, 이동권, 삭제권 등의 권리를 회사에 주장할 수 없습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제13조 (회사의 의무)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 관련 법령 및 본 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 이용자가 안전하게 플랫폼을 이용할 수 있도록 개인정보(이용자 저장 ·데이터 포함) 보호를 위해 필요한
              보안시스템을 갖추어야 합니다. 회사는 이용자의 개인정보가 이용자의 동의없이 제3자에게 제공되지 않도록
              합니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              회사는 계속적이고 안정적인 플랫폼의 제공을 위하여 최선을 다하여 노력합니다. 서비스 또는 설비에 장애가
              생겼을 경우 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한
              지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              회사는 플랫폼 이용과 관련하여 이용자가 장애나 개선사항에 대해 회사에 전달할 수 있는 방법을 플랫폼 내 또는
              별도의 연결 화면을 통해 제공 또는 고지합니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제14조 (비밀번호에 대한 의무)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              마이디커넥트 웹 사용시 비밀번호에 관한 관리책임은 이용자에게 있습니다.
            </li>
            <li style={{ listStyleType: "②  " }}>이용자는 자신의 비밀번호를 제3자에게 이용·제공해서는 안 됩니다.</li>
            <li style={{ listStyleType: "③  " }}>
              이용자가 자신의 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 마이디커넥트 웹을
              통해 다른 비밀번호로 갱신해야 하며, 필요에 따라 데이터를 백업 후 앱을 재설치하여 신규 등록절차를 진행해야
              합니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제15조 (이용자의 의무)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              이용자는 본 약관 및 개정약관, 회사가 플랫폼 화면에서 고지하는 내용을 확인하고 준수하여야 하며, 본 약관,
              개정약관 및 고지 내용을 위반하거나 이행하지 아니하여 발생하는 손실 및 손해에 대하여 책임을 부담합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              이용자는 플랫폼 내에서 데이터 제공기관 또는 데이터 이용기관이 제공하는 상품 등에 가입 또는 구매 시
              스스로의 책임 하에 가입 또는 구매하여야 하며, 회사는 상품 등의 내용과 거래 조건에 대해서 어떠한 보증이나
              책임도 부담하지 않습니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              이용자는 자신의 명의가 도용되거나 제3자에게 부정하게 사용된 것을 인지한 경우 즉시 그 사실을 회사에
              통보하여야 합니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              이용자는 플랫폼을 이용하여 얻은 정보를 회사의 사전 동의 없이 영리목적으로 이용하거나 제3자에게 이용하게
              하는 행위를 할 수 없으며, 플랫폼의 이용권한 및 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없습니다.
            </li>
            <li style={{ listStyleType: "⑤  " }}>
              이용자는 회사가 플랫폼을 안전하게 제공할 수 있도록 회사에 협조하여야 하며, 회사가 이용자의 본 약관
              위반행위를 발견하여 이용자에게 해당 위반행위에 대하여 소명을 요청할 경우 회사의 요청에 적극 응하여야
              합니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제16조 (이용자의 금지행위)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 플랫폼의 신뢰성을 제공하고 안전한 플랫폼 이용이 이루어질 수 있도록 아래와 같은 이용자의 행위를
              금지합니다.
              <ul>
                <li style={{ listStyleType: "1.  " }}>
                  회사가 제공하는 플랫폼 이용방법에 의하지 아니하고 비정상적인 방법으로 플랫폼을 이용하거나 시스템에
                  접근하는 행위
                </li>
                <li style={{ listStyleType: "2.  " }}>
                  회사에서 제공하는 서비스를 본래의 이용 목적 이외의 용도로 사용하는 행위
                </li>
                <li style={{ listStyleType: "3.  " }}>
                  타인의 명의, 휴대전화 정보, 계좌 정보, 생체인식 정보, 신분증 등을 도용하여 플랫폼 이용을 신청하거나
                  플랫폼을 이용하는 행위
                </li>
                <li style={{ listStyleType: "4.  " }}>회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                <li style={{ listStyleType: "5.  " }}>
                  회사 및 기타 제3자의 저작권, 초상권 등 지식재산권에 대한 침해행위
                </li>
                <li style={{ listStyleType: "6.  " }}>
                  회사가 제공하는 플랫폼을 이용하여 얻은 회사 또는 제3자의 정보를 정보 소유자의 사전 동의 없이 복제 또는
                  유통시키거나 상업적으로 이용하는 행위
                </li>
                <li style={{ listStyleType: "7.  " }}>
                  회사의 동의 없이 영리, 영업, 광고, 정치활동, 불법선거운동 등을 목적으로 서비스를 이용하는 행위
                </li>
                <li style={{ listStyleType: "8.  " }}>
                  기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 행위 및 법령에 위배되는 행위
                </li>
              </ul>
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 이용자가 제1항의 금지행위를 행하는 경우 제24조에 따라 플랫폼 이용을 제한할 수 있으며, 필요한 경우
              이용자의 금지행위 사실을 관련 정부기관 또는 사법기관에 통지할 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제17조 (저작권의 귀속)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사가 플랫폼에 작성한 저작물에 대한 저작권 기타 지식재산권은 회사에 귀속합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              이용자는 플랫폼을 이용함으로써 얻은 정보 중 회사의 지식재산권으로 귀속된 정보를 회사의 사전 승낙 없이
              복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안
              됩니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통지하여야 합니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제18조 (이용제한)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 다음 각 호의 어느 하나에 해당하는 사유가 발생하는 경우 이용자의 서비스 이용제한 및 기타 조치를 할
              수 있습니다.
              <ul>
                <li style={{ listStyleType: "1.  " }}>본 약관 제14조의 이용자의 의무를 이행하지 않은 경우</li>
                <li style={{ listStyleType: "2.  " }}>본 약관 제15조 제2항에 해당하는 경우</li>
                <li style={{ listStyleType: "3.  " }}>기타 이용자가 본 약관을 위반하거나 부당한 행위를 한 경우</li>
              </ul>
            </li>
            <li style={{ listStyleType: "②  " }}>
              제1항의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 이용자가 입은 손해를 배상하지 않습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제19조 (정보의 제공 및 광고의 게재)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 이용자가 플랫폼 이용을 위해 필요하다고 인정되는 다양한 정보를 플랫폼 내 혹은 별도의 홈페이지에
              게시하거나 기타 방법으로 이용자에게 제공할 수 있습니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 본 플랫폼 등을 유지하기 위하여 광고를 게재할 수 있으며, 이용자는 서비스 이용 시 노출되는 광고
              게재에 대하여 동의합니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              앱 내에 노출되는 제3자가 주체인 제2항의 광고에 응하여 이용자가 참여하거나 거래를 함으로써 발생하는 손실과
              손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제20조 (손해 배상)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 고의
              또는 과실이 없는 경우에는 그러하지 아니 합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              이용자가 서비스를 이용함에 있어 본인이 행한 불법행위 또는 본 약관을 위반한 행위로 회사가 당해 이용자 외의
              제3자로부터 손해배상청구 또는 소송 등을 비롯한 각종 이의제기를 받는 경우 당해 이용자는 자신의 책임과
              비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여 회사에 발생한 모든
              손해를 배상할 책임이 있습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제21조 (분쟁해결)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
              설치·운영합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 이용자로부터 제출되는 불만사항 및 의견이 있을 경우, 우선적으로 그 사항을 처리합니다. 다만, 신속한
              처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보합니다.
              <ul>
                <li style={{ listStyleType: "-  " }}>전화번호: 070-5067-1224</li>
                <li style={{ listStyleType: "-  " }}>이메일: myd@snplab.io</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <p>제22조 (회사의 면책)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              회사는 회사의 귀책사유가 없는 한, 이용자와 데이터 제공기관 또는 데이터 이용기관 상호간의 거래에 대한
              어떠한 책임도 부담하지 않습니다.
            </li>
            <li style={{ listStyleType: "②  " }}>
              회사는 회사의 귀책사유 없이 발생한 서비스의 이용장애에 대하여 책임을 부담하지 않습니다.
            </li>
            <li style={{ listStyleType: "③  " }}>
              회사는 이용자가 플랫폼을 이용하여 기대하는 수익을 달성하지 못했거나 상실한 것에 대해서 책임을 지지 않으며,
              그 밖에 플랫폼을 통하여 얻은 정보로 인하여 발생한 손해에 대하여도 책임을 부담하지 않습니다.
            </li>
            <li style={{ listStyleType: "④  " }}>
              이용자가 자신의 개인정보, 이용자 저장 데이터, 비밀번호 (생체등록 정보 포함), 플랫폼 이용 관련 정보 등을
              타인에게 제공하거나, 이용자의 관리소홀로 유출됨으로써 발생하는 피해에 대해서 회사는 책임을 지지 않습니다.
            </li>
            <li style={{ listStyleType: "⑤  " }}>
              회사는 이용자가 본인의 단말 내에 있는 이용자 저장 데이터를 삭제하여 서비스 이용이 제한되는 경우 이에 대해
              책임을 지지 않습니다.
            </li>
            <li style={{ listStyleType: "⑥  " }}>
              회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 기타 이에 준하는 사유로 발생한 손해에 대하여 책임을
              지지 않습니다. 다만, 회사의 고의 또는 과실에 의한 경우에는 그러하지 아니합니다.
            </li>
            <li style={{ listStyleType: "⑦  " }}>
              이용자가 단말의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로
              인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다.
            </li>
          </ul>
        </li>
        <li>
          <p>제23조 (재판권 및 준거법)</p>
          <ul>
            <li style={{ listStyleType: "①  " }}>
              서비스 이용과 관련하여 회사와 이용자 간에 발생한 분쟁에 관한 소송은 민사소송법 등 관련 법률상의 관할법원에
              제기합니다.
            </li>
            <li style={{ listStyleType: "②  " }}>회사와 이용자 간에 제기된 소송은 대한민국 법을 적용합니다.</li>
          </ul>
        </li>
        <li>
          <br />
          &lt;부칙&gt; 본 개정약관은 2023년 07월 13일부터 시행합니다.
        </li>
      </ul>
    </div>
  );
}
