import styles from "style/common/container/pannel.module.scss";

type Props = {
  children: React.ReactNode;
};

/**
 * Pannel - Root Wrapper
 */
export default function Pannel({ children }: Props) {
  return <section className={styles.wrapper}>{children}</section>;
}
