import styles from "style/pages/reward/list/tableTop.module.scss";
import { TableNav } from "common/table";
import { DateSearchBox } from "common/input";

type TProps = {
  tableNavData: {
    [key: string]: {
      num: number;
      status: number | string;
    };
  };
};

export default function TableTop({ tableNavData }: TProps) {
  return (
    <div className={styles.wrapper}>
      <TableNav data={tableNavData} />
      <DateSearchBox />
    </div>
  );
}
