import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Pannel } from "common/container";
import Top from "common/other/skeleton/Top";
import Navigation from "Navigation";
import { MydLogo, SnplabIcon } from "assets/svgs/skeleton";
import styles from "style/common/other/skeleton/skeleton.module.scss";

type TProps = {
  children: ReactNode;
};

export default function Layout({ children }: TProps) {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Link to="/">
            <MydLogo />
          </Link>
        </div>
        <Top />
      </div>

      <div className={styles.content}>
        <div className={styles.nav}>
          <Navigation />

          <div className={styles.navBottom}>
            <SnplabIcon />
            <Link to="/termsOfService" target="_blank">
              이용약관
            </Link>
            <Link to="/privatePolicy" target="_blank">
              개인정보처리방침
            </Link>
            <p>이용 문의 (영업지원팀)</p>
            <p>02-6959-2199</p>
            <p>(평일) 10:00 ~ 17:00</p>
            <p> (점심시간) 12:30 ~ 13:30</p>
          </div>
        </div>

        <Pannel>{children}</Pannel>
      </div>
    </>
  );
}
