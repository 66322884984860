export default {
  GET_USERINFO: ["GET_USERINFO"],
  PUBLIC_ROUTE_TOKEN: ["PUBLIC_ROUTE_TOKEN"],
  PRIVATE_ROUTE_TOKEN: ["PRIVATE_ROUTE_TOKEN"],

  // useAccessRole
  GET_ROLE: "GET_ROLE",

  // login account's company & staff info
  GET_DETAIL_COMPANY: "GET_DETAIL_COMPANY",

  // password/change
  GET_USER_EMAIL: "GET_USER_EMAIL",

  // reward
  GET_REWARD: "GET_REWARD",
  GET_AMOUNT_POINT: "GET_AMOUNT_POINT",

  // market
  GET_MARKET: "GET_MARKET",
  GET_MY_ACCOUNT: "GET_MY_ACCOUNT",
  GET_MARKET_PRODUCTS: "GET_MARKET_PRODUCTS",
  GET_MARKET_PROPOSAL: "GET_MARKET_PROPOSAL",
  GET_PI_ZIP_STATUS: "GET_PI_ZIP_STATUS",
  GET_DID_FILTER_LIST: "GET_DID_FILTER_LIST",

  // management/staff
  GET_STAFF_LIST: "GET_STAFF_LIST",
  GET_DETAIL_STAFF: "GET_DETAIL_STAFF",
  GET_AUTH_CHANGE: "GET_AUTH_CHANGE",
  DELETE_STAFF: "DELETE_STAFF",

  // management/company
  GET_COMPANY: "GET_COMPANY",
  GET_LOGIN_COMPANY: "GET_LOGIN_COMPANY",

  // myage
  GET_MYPAGE: "GET_MYPAGE",
  GET_PASSWORD_CONFIRM: "GET_PASSWORD_CONFIRM",

  // dashboard
  GET_DASHBOARD_ALL: "GET_DASHBOARD_ALL",
  GET_DASHBOARD_YEAR: "GET_DASHBOARD_YEAR",
  GET_DASHBOARD_PERIOD: "GET_DASHBOARD_PERIOD",

  // privacy agree
  GET_PRIVACY_AGREE: "GET_PRIVACY_AGREE",

  // advertisement
  GET_AD_LIST: "GET_AD_LIST",
  GET_AD_PROPOSAL_LIST: "GET_AD_PROPOSAL_LIST",
  GET_DETAIL_AD: "GET_DETAIL_AD",
  GET_AD_DASHBOARD: "GET_AD_DASHBOARD",
  DELETE_AD: "DELETE_AD",
  CANCEL_AD: "CANCEL_AD",

  // promotion
  GET_PROMOTION_PRODUCT_LIST: "GET_PROMOTION_PRODUCT_LIST",
  GET_COMPANY_PROMOTION_CODE: "GET_COMPANY_PROMOTION_CODE",

  // dpia
  GET_DPIA_LOG: (type: string, from: string, to: string, admin: string, ip: string, pageNum: string) =>
    ["GET_DPIA_LOG", type, from, to, admin, ip, pageNum] as const,
};
