import { getBgJobStatus, getMarketProposal, postJsonJobStart, getPiZip, revokeMarketProposal } from "api/market";
import queryKey from "constants/queryKey";
import { useMutation, useQuery } from "react-query";

export default function useDetail(proposalId: string) {
  const {
    data: proposalInfo,
    isLoading: queryISLoading,
    isFetching,
    isError: queryIsError,
    error: queryError,
  } = useQuery([queryKey.GET_MARKET_PROPOSAL, proposalId], () => getMarketProposal(proposalId));

  const {
    mutate: revokeMutate,
    isLoading: mutateIsLoading,
    isError: mutateIsError,
    error: mutateError,
  } = useMutation(revokeMarketProposal);

  const {
    mutate: piJsonJobStartMutate,
    isError: piJsonJobStartIsError,
    error: piError,
  } = useMutation(postJsonJobStart);

  const {
    mutateAsync: piJsonJobStatusAscMutate,
    isError: piJsonJobStatusIsError,
    error: piJsonJobStatusError,
  } = useMutation(getBgJobStatus);

  const {
    mutate: piZipMutate,
    isLoading: piZipIsLoading,
    isError: piZipIsError,
    error: piZipError,
  } = useMutation(getPiZip);

  return {
    proposalInfo,
    isLoading: queryISLoading || mutateIsLoading || piZipIsLoading,
    isFetching,
    isError: queryIsError || mutateIsError || piJsonJobStartIsError || piZipIsError || piJsonJobStatusIsError,
    error: queryError || mutateError || piError || piZipError || piJsonJobStatusError,
    revokeMutate,
    piJsonJobStartMutate,
    piZipMutate,
    piJsonJobStatusAscMutate,
  };
}
