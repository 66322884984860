import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export class FilterData<T extends Record<string, any>> {
  private dataList: T[];

  constructor(dataList: T[]) {
    this.dataList = dataList;
  }

  sortDesc(key: string) {
    this.dataList.sort((a, b) => {
      const bValue = getNestedProperty(b, key);
      const aValue = getNestedProperty(a, key);
      return Date.parse(bValue) - Date.parse(aValue);
    });

    return this;
  }

  filterByDate(key: string, from: string, to: string) {
    if (from || to) {
      this.dataList = this.dataList.filter((data) => {
        const value = getNestedProperty(data, key);
        const isWithinDateRange =
          dayjs(value).isSameOrAfter(from || "0") && dayjs(value).isSameOrBefore(to || "9999", "day");

        if (isWithinDateRange) {
          return true;
        }

        return false;
      });
    }

    return this;
  }

  /**
   * 대소문자 구분 안함
   */
  filterByKeyword(key: string, keyword: string) {
    if (keyword) {
      this.dataList = this.dataList.filter((data) => {
        const value = getNestedProperty(data, key);
        if (!value) {
          return false;
        }

        return value.toLowerCase().includes(keyword.toLowerCase());
      });
    }

    return this;
  }

  filterByStatus(key: string, status: string, statusMap: Record<number, string[]>) {
    if (status) {
      this.dataList = this.dataList.filter((data) => {
        const value = getNestedProperty(data, key);
        return statusMap[value][1] === status;
      });
    }

    return this;
  }

  filterByPage(pageNum: number) {
    this.dataList = this.dataList.slice((pageNum - 1) * 10, pageNum * 10);

    return this;
  }

  getResult() {
    return this.dataList;
  }
}

function getNestedProperty(obj: any, path: string): any {
  return path
    .split(".")
    .reduce((acc: any, key: string) => (acc && acc[key] !== "undefined" ? acc[key] : undefined), obj);
}

export type TWithNo<T> = T & { no: number };
