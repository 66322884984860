import { Link, useLocation, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { getPageData, range } from "utils";
import styles from "style/common/navigation/pagination.module.scss";
import { LeftArrowIcon, RightArrowIcon } from "assets/svgs/icon";

interface Props {
  data: {
    totalPages: number;
    pageSize: number;
  };
}

const pageRange = 5;
export default function Pagination({ data }: Props) {
  const { totalPages } = data;
  const { search } = useLocation();

  const [searchParams] = useSearchParams();
  const pageNum = Number(searchParams.get("pageNum")) || 1;

  const { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext } = getPageData({
    pageNum,
    totalPages,
    pageRange,
  });

  // location.search에 맞춰 쿼리스트링을 리턴
  const nextQuery = (num: number, base?: number) => {
    const containsPageNum = /(pageNum=)(\d+)/g.test(search);
    if (!search) return `?pageNum=${base ? base + num : num}`;
    if (containsPageNum) return search.replace(/(pageNum=)(\d+)/g, `pageNum=${base ? base + num : num}`);
    return `${search}&pageNum=${num}`;
  };

  return (
    <section className={styles.wrapper}>
      <Link to={nextQuery(-1, startNumberOfPageRange)} className={cx(styles.prev, { [styles.disabled]: !isPrev })}>
        <LeftArrowIcon stroke={isPrev ? "#595A5E" : "#C0C1C5"} />
      </Link>

      {range(startNumberOfPageRange, endNumberOfPageRange).map((item) => (
        <Link key={item} to={nextQuery(item)} className={cx({ [styles.active]: item === pageNum })}>
          {item}
        </Link>
      ))}

      <Link to={nextQuery(endNumberOfPageRange, 1)} className={cx(styles.next, { [styles.disabled]: !isNext })}>
        <RightArrowIcon stroke={isNext ? "#595A5E" : "#C0C1C5"} />
      </Link>
    </section>
  );
}
