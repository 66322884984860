export const range = (start: number, end: number) => {
  const array = [];
  for (let i = start; i <= end; ++i) {
    array.push(i);
  }
  return array;
};

export const getPageData = ({
  pageNum,
  totalPages,
  pageRange,
}: {
  pageNum: number;
  totalPages: number;
  pageRange: number;
}) => {
  const startNumberOfPageRange = Math.trunc((pageNum - 1) / pageRange) * pageRange + 1;
  const tmpEnd = startNumberOfPageRange + (pageRange - 1);
  const endNumberOfPageRange = tmpEnd > totalPages ? totalPages : tmpEnd;

  const isPrev = startNumberOfPageRange > pageRange;
  const isNext = endNumberOfPageRange < totalPages;

  return { startNumberOfPageRange, endNumberOfPageRange, isPrev, isNext };
};

export const makeQueryString = (data: any) =>
  Object.entries(data)
    .map((item) => item.join("="))
    .join("&");

/**
 *
 * @param sec ms
 * @returns Promise
 */
// eslint-disable-next-line no-promise-executor-return
export const delay = (sec: number) => new Promise((resolve) => setTimeout(resolve, sec));

/**
 *
 * @param data 파일 안에 들어갈 내용
 * @param type 파일 유형
 * @param extension 확장자
 * @param filename 파일명
 */
export const createFile = (data: any, type: string, extension: string, filename?: string) => {
  const file = new Blob([data], { type });

  const a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.download = `${filename || "data"}.${extension}`;

  a.click();

  URL.revokeObjectURL(a.href);
  a.remove();
};

export const setB2BEnv = () => {
  const { host } = window.location;

  if (host.includes("dev")) {
    window.RUN_MODE = "dev";
    window.B2B_URL = process.env.REACT_APP_ENV_DEV;
    window.API_URL = process.env.REACT_APP_API_ENV_DEV;
    window.CDN_URL = process.env.REACT_APP_CDN_ENV_DEV;
    window.ADMIN_URL = process.env.REACT_APP_ADMIN_ENV_DEV;
    window.MYD_WEB_URL = process.env.REACT_APP_MYD_WEB_ENV_DEV;
    window.PUBLIC_KEY = process.env.REACT_APP_WEB_PUBLIC_KEY_DEV;
    return;
  }

  if (host.includes("stag")) {
    window.RUN_MODE = "stag";
    window.B2B_URL = process.env.REACT_APP_ENV_STAG;
    window.API_URL = process.env.REACT_APP_API_ENV_STAG;
    window.CDN_URL = process.env.REACT_APP_CDN_ENV_STAG;
    window.ADMIN_URL = process.env.REACT_APP_ADMIN_ENV_STAG;
    window.MYD_WEB_URL = process.env.REACT_APP_MYD_WEB_ENV_STAG;
    window.PUBLIC_KEY = process.env.REACT_APP_WEB_PUBLIC_KEY_STAG;
    return;
  }

  if (host.includes("gs")) {
    window.RUN_MODE = "gs";
    window.B2B_URL = process.env.REACT_APP_ENV_GS;
    window.API_URL = process.env.REACT_APP_API_ENV_GS;
    window.CDN_URL = process.env.REACT_APP_CDN_ENV_GS;
    window.ADMIN_URL = process.env.REACT_APP_ADMIN_ENV_STAG;
    window.MYD_WEB_URL = process.env.REACT_APP_MYD_WEB_ENV_STAG;
    window.PUBLIC_KEY = process.env.REACT_APP_WEB_PUBLIC_KEY_STAG;
    return;
  }

  if (host.includes("prod")) {
    window.RUN_MODE = "prod";
    window.B2B_URL = process.env.REACT_APP_ENV_PROD;
    window.API_URL = process.env.REACT_APP_API_ENV_PROD;
    window.CDN_URL = process.env.REACT_APP_CDN_ENV_PROD;
    window.ADMIN_URL = process.env.REACT_APP_ADMIN_ENV_PROD;
    window.MYD_WEB_URL = process.env.REACT_APP_MYD_WEB_ENV_PROD;
    window.PUBLIC_KEY = process.env.REACT_APP_WEB_PUBLIC_KEY_PROD;
    return;
  }

  // localhost
  window.RUN_MODE = "local";
  window.B2B_URL = process.env.REACT_APP_ENV_LOCAL;
  window.API_URL = "http://localhost:8080";
  // window.API_URL = process.env.REACT_APP_API_ENV_DEV;
  window.CDN_URL = process.env.REACT_APP_CDN_ENV_DEV;
  window.ADMIN_URL = process.env.REACT_APP_ADMIN_ENV_LOCAL;
  window.MYD_WEB_URL = process.env.REACT_APP_MYD_WEB_ENV_DEV;
  window.PUBLIC_KEY = process.env.REACT_APP_WEB_PUBLIC_KEY_DEV;
};
