import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import isBetween from "dayjs/plugin/isBetween";
import { TReward } from "types/reward";

dayjs.extend(isBetween);

/**
 * searchParams를 기반으로 data를 필터링해주는 훅입니다. (searchParams 사용 예시: /market?status=all&pageNum=1&from=2023-06-08&to=2023-06-09&keyword=타이틀)
 * 페이지 별 data와 status가 다르니 해당 파일을 복사하여 개별적으로 관리하는 것을 추천합니다.
 *
 * useFilterTableData는 api 요청을 통해 받은 data를 조건에 맞춰 필터링 해주는 훅 입니다.
 * useFilterTableNav는 필터링된 data를 common component <TableNav />에 필요한 data로 만들어 줍니다.
 */

/**
 * useFilterTableData를 통해 필터링 한 데이터를 TableNav 컴포넌트에서 사용할 수 있도록 핸들링해줍니다.
 * @param navMap : {label: string; status: string}[] label은 UI로 보여줄 텍스트, status는 data의 status 텍스트값을 넣어줍니다.
 * @param size ?: { [key: string | number]: number } useFilterTableData에서 반환한 size를 넣어줍니다.
 */
export const useFilterRewardTableNav = (
  navMap: {
    label: string;
    status: string;
  }[],
  size?: { [key: string | number]: number }
): TNavData => {
  const navData: TNavData = {};
  if (!size) return {};
  navMap.forEach(({ label, status }) => {
    navData[label] = {
      num: size[status] || 0,
      status,
    };
  });

  return navData;
};

/**
 * 데이터를 기간, 검색어, 상태, 날짜 기준으로 필터링 해주는 hook입니다.
 * @param data api로 전달받은 data를 넣어줍니다.
 * @param navMap : {label: string; status: string}[] label은 UI로 보여줄 텍스트, status는 data의 status 텍스트값을 넣어줍니다.
 */
export const useFilterRewardTableData = (
  data?: TReward["rewardViewList"], // any를 data 타입에 맞춰 수정하는 것을 권장합니다.
  navMap?: {
    label: string;
    status: string;
  }[]
): {
  filteredData?: TReward["rewardViewList"];
  size?: { [key: string | number]: number };
  totalPages: number;
} => {
  const [searchParams] = useSearchParams();
  if (!data) return { totalPages: 1 };
  let result = data;

  /* 필요한 searchParams 선언 */
  // 기한 선언
  const [from, to] = [searchParams.get("from"), searchParams.get("to")];

  // 상태 선언
  const status = searchParams.get("status") || "all";
  const statusMap: { [key: string]: number } = navMap
    ? Object.fromEntries(navMap.map(({ status: navStatus }) => [navStatus, 0]))
    : {};

  // 검색어 선언
  const keyword = searchParams.get("keyword");

  // 날짜 필터링
  if (from) {
    // createdAt 기준 필터링
    result = result.filter(({ createdAt }) => {
      if (dayjs(createdAt).isBetween(from || "0000-00-00", to || "9999-12-12", "day", "[]")) return true;
      return false;
    });
  }

  // 키워드 필터링
  if (keyword) {
    result = result.filter(({ message }) => message.toLowerCase().includes(keyword.toLowerCase()));
  }

  // type을 status로 변환
  const formatStatus = (rewardData: TReward["rewardViewList"][0]) => {
    if (rewardData.type === 0) {
      if (/(충전 요청|요청 취소)/.test(rewardData.message)) {
        return "request";
      }
      return "charge";
    }
    if (rewardData.type === 1) return "use";
    if (rewardData.type === 2) return "refund";
    return "";
  };

  // 상태 필터링 전 size 계산
  if (status && navMap) {
    result.forEach((rewardData) => {
      statusMap[formatStatus(rewardData)]++;
      statusMap[navMap[0].status]++; // "전체" size 계산
    });
  }

  // 상태 필터링
  if (status && navMap && status !== navMap[0].status) {
    result = result.filter((rewardData) => {
      // "전체"
      if (status === navMap[0].status) {
        return true;
      }
      return status === formatStatus(rewardData);
    });
  }

  // 키워드 필터링
  if (keyword) {
    result = result.filter(({ message }) => message.toLowerCase().includes(keyword.toLowerCase()));
  }

  // 생성일 기준 정렬
  result.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());

  const totalPages = Math.floor(result.length / 10) + (result.length % 10 && 1) || 1;

  return { filteredData: result, size: statusMap, totalPages };
};

type TNavData = {
  [key: string]: {
    num: number;
    status: string;
  };
};
