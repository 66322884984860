import { DefaultBtn } from "common/button";
import { Content, Wrapper } from "common/container";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { ButtonBox, PercentBar, Title } from "common/other";
import { translateTargetMap } from "constants/market";
import queryKey from "constants/queryKey";
import dayjs from "dayjs";
import { useAccessRole, useModal } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "style/pages/market/marketDetailPage/marketDetail.module.scss";
import { makeQueryString } from "utils";
import { excludeExtraDataCategory, syncProgressPeriod } from "utils/market";
import { DidFilteringBoard } from "../didFilterPage";
import DetailPreviewSidebar from "../previewSidebar/DetailPreviewSidebar";
import SidebarLayout from "../previewSidebar/SidebarLayout";
import AlertDescription from "./AlertDescription";
import ProcessedDataPannel from "./ProcessedDataPannel";
import useDetail from "./useDetail";
import { registerDowLog } from "api/dpia";

export default function MarketDetail() {
  const queryClient = useQueryClient();

  const { data: accessData } = useAccessRole();

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const proposalId = params.get("proposalId") || "";

  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isJsonErrorShowing, setIsJsonErrorShowing] = useModal();
  const [isAlertOpen, onToggleAlert] = useModal();

  const [isDialogShowing, setIsDialogShowing] = useState(false);
  const [isConfirmDialogShowing, setIsConfirmDialogShowing] = useState(false);
  const [errorState, setErrorState] = useState<Error | boolean>(false);

  const [progress, setProgress] = useState(0);
  const [isDwnldLoading, setIsDwnldLoading] = useState(false);

  const {
    isLoading: isApiLoading,
    isFetching,
    proposalInfo,
    revokeMutate,
    error,
    piJsonJobStartMutate,
    piJsonJobStatusAscMutate,
  } = useDetail(proposalId);

  const [isFinelabProduct, setIsFinelabProduct] = useState(false);
  const intervalId = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (proposalInfo?.proposalRequest.staff.company.piiUrl) {
      const { maxIssuable, totalResponseCount } = proposalInfo.ticketInfo;
      setProgress(Math.ceil((totalResponseCount / maxIssuable) * 100));
    }
    setIsFinelabProduct(
      proposalInfo?.proposalRequest.contentObject?.privacyPolicy?.identifier === 0 &&
        proposalInfo?.proposalRequest.staff.company.id === 0
    );
  }, [proposalInfo]);
  const reportDataList = [
    // @TODO: 데이터 상품, 개인식별 여부, 제3자 제공여부 임시 구현 상태
    "데이터 상품",
    !isFinelabProduct ? `마이디 - 올데이터 프리미엄` : "마이디 - 올데이터 프리미엄(파인랩 실명거래용)",
    "노출 대상",
    [
      proposalInfo?.proposalRequest.genderParse || "",
      /(\d{2}-\d{2})/.test(proposalInfo?.proposalRequest.ageGroupParse || "")
        ? proposalInfo?.proposalRequest.ageGroupParse
            .split("-")
            .map((v) => `${v}세`)
            .join("-")
        : proposalInfo?.proposalRequest.ageGroupParse || "",
      proposalInfo?.proposalRequest.regionOfResidenceParse || "",
    ].join(", ") || "",
    "수집 데이터",
    `${
      excludeExtraDataCategory(proposalInfo?.proposalRequest.selectData)
        .map((v) => translateTargetMap[v] || v)
        .join(", ") || ""
    } ${
      proposalInfo?.proposalRequest.selectRangeText && proposalInfo?.proposalRequest.selectRangeText !== "최근 "
        ? `/ ${proposalInfo?.proposalRequest.selectRangeText}`
        : ""
    }` || "",
    "개인식별 여부",
    proposalInfo?.proposalRequest.contentObject?.privacyPolicy?.identifier === 1
      ? "실명 데이터 거래"
      : "익명 데이터 거래",
    "제3자 제공여부",
    proposalInfo?.proposalRequest.contentObject?.privacyPolicy?.thirdParty?.processing === 1
      ? `제공 / ${proposalInfo?.proposalRequest.contentObject?.privacyPolicy?.thirdParty?.name}(${proposalInfo?.proposalRequest.contentObject?.privacyPolicy?.thirdParty?.purpose})`
      : proposalInfo?.proposalRequest.contentObject?.privacyPolicy?.thirdParty?.processing === 0 && "미제공",
    "거래 목적",
    proposalInfo?.proposalRequest.contentObject?.description.detail?.replace(",", ", ") || "",
    "1인당 지급 포인트",
    `${(proposalInfo?.proposal.rewardPolicy.amount || 0).toLocaleString()}P`,
    "목표 인원 수",
    `${(proposalInfo?.ticketInfo.maxIssuable || 0).toLocaleString()}명`,
    "거래 진행기간",
    `${dayjs(proposalInfo?.proposal.notBefore).format("YYYY.MM.DD HH:mm")} ~ ${syncProgressPeriod(
      dayjs(proposalInfo?.proposal.notAfter)
    ).format("YYYY.MM.DD")} 23:59`,
  ].filter((item, idx, list) => item && (list[idx + 1] || idx === list.length - 1)); //  빈 값은 표시하지 않음

  const handleNextBtnClick = () => {
    revokeMutate(
      { ProposalID: proposalId, token: accessData?.token || "", did: accessData?.did || "" },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKey.GET_MARKET_PROPOSAL, proposalId]);
          queryClient.invalidateQueries([queryKey.GET_MARKET]);
          setIsConfirmDialogShowing(true);
        },
        onError: () => setIsErrorShowing(),
        onSettled: () => setIsDialogShowing(false),
      }
    );
  };

  const piJsonStartDownloadReq = async () => {
    onToggleAlert();
    setIsDwnldLoading(true);

    // TODO: 서버 자동화되면 삭제
    registerDowLog(proposalId, "JSON");

    piJsonJobStartMutate(
      {
        proposalId,
        token: accessData?.token || "",
        did: accessData?.did || "",
        piiUrl: accessData?.piiUrl || "",
      },
      {
        onError: (err) => {
          setIsDwnldLoading(false);
          const axiosError = err as Error;

          if (axiosError.cause === "OnPremiseError") {
            setErrorState(false);
            setIsJsonErrorShowing();
          } else {
            setErrorState(Error("JSON 다운로드 중 서버에 문제가 발생하였습니다."));
          }
        },
        onSuccess: (token) => {
          const checkUrl = `${accessData?.piiUrl}/pi/json/job/status`;
          const qs = `?${makeQueryString({ dataToken: token })}`;
          const checkUrlWithQs = `${checkUrl}${qs}`;

          if (token) {
            checkPiJsonJob(checkUrlWithQs, token);
          }
        },
      }
    );
  };

  const checkPiJsonJob = async (checkUrl: string, dataToken: string) => {
    if (intervalId.current) {
      return;
    }

    const downloadCheckInterval = 5000;
    // 먼저 실행 하고 결과(startInterval)에 따라서
    // interval 을 시작할지 안할지 정하는 로직
    let startInterval = true;

    await piJsonJobStatusAscMutate(checkUrl, {
      onError: () => {
        setErrorState(Error("JSON 다운로드 중 서버에 문제가 발생하였습니다."));
        setIsDwnldLoading(false);
      },
      onSuccess: ({ result }) => {
        if (result === "working") {
          return;
        }
        // job success. reset loading, interval, intervalId
        setIsDwnldLoading(false);
        startInterval = false;

        const dwnldUrl = `${accessData?.piiUrl}/pi/json/download`;

        dwnldWithUrl(dwnldUrl, `?${makeQueryString({ dataToken, proposalId })}`);
      },
    });

    if (!startInterval) {
      return;
    }

    intervalId.current = setInterval(() => {
      piJsonJobStatusAscMutate(checkUrl, {
        onError: () => {
          setErrorState(Error("JSON 다운로드 중 서버에 문제가 발생하였습니다."));
          setIsDwnldLoading(false);
          clearInterval(intervalId.current!);
        },
        onSuccess: ({ result }) => {
          if (result === "working") {
            return;
          }
          // job success. reset loading, interval, intervalId
          setIsDwnldLoading(false);
          clearInterval(intervalId.current!);
          intervalId.current = null;

          const dwnldUrl = `${accessData?.piiUrl}/pi/json/download`;

          dwnldWithUrl(dwnldUrl, `?${makeQueryString({ dataToken })}`);
        },
      });
    }, downloadCheckInterval);
  };

  const dwnldWithUrl = (url: string, qs: string) => {
    const dwnldUrlWithQs = `${url}${qs}`;

    window.open(dwnldUrlWithQs, "_self"); // pop up 차단 설정 우회를 위해 target 을 _self 로 지정
  };

  // 체크 interval 중 page 빠져나갈경우 interval 해제
  useEffect(() => {
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  const DID_FILTER_ACCESS_ROLES = ["B2B_ADMIN", "SNP_MANAGER"];

  return (
    <Wrapper>
      <Title text="상세 정보" />

      {isApiLoading || isFetching || isDwnldLoading ? (
        <LoadingModal />
      ) : (
        <>
          <Content borderRadius="12px" marginBottom="24px" gap="24px">
            <div className={styles.header}>
              {proposalInfo?.proposal.title || ""}

              <div className={styles.dateWrapper}>
                <p>등록일 {dayjs(proposalInfo?.proposal.createdAt).format("YYYY.MM.DD")}</p>
              </div>
            </div>

            <div className={styles.reportWrapper}>
              {reportDataList.map((report, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={i} className={i % 2 ? styles.reportContent : styles.reportTitle}>
                  {report}
                </p>
              ))}
            </div>

            <div className={styles.responseWrapper}>
              응답현황
              <div className={styles.responseDetail}>
                <p className={styles.responseTitle}>거래 참여자 수</p>
                <p className={styles.responseTitle}>포인트 예산</p>
                <p className={styles.responseTitle}>소진 포인트</p>
                <p>{`${(proposalInfo?.ticketInfo.totalResponseCount || 0).toLocaleString()}명`}</p>
                <p>{`${(proposalInfo?.ticketInfo.depositPoint || 0).toLocaleString()}P`}</p>
                <p>{`${(
                  (proposalInfo?.proposal.rewardPolicy.amount || 0) * (proposalInfo?.ticketInfo.totalResponseCount || 0)
                ).toLocaleString()}P`}</p>
              </div>
            </div>

            <PercentBar percent={`${progress}%`} tootip label="목표 도달률" />
          </Content>

          <div className={styles.dataDownload}>
            로우 데이터 보기
            <div>
              <DefaultBtn size="short" mode="lineBlue" type="button" onClick={onToggleAlert}>
                JSON 다운로드
              </DefaultBtn>
            </div>
          </div>

          <ProcessedDataPannel accessData={accessData} proposalId={proposalId} proposalInfo={proposalInfo} />
          {/* 데이터 거래 생성중에 필터링 활성을 선택했을시, hidden 이 true 로 변경됨. 해당 케이스만 did 필터링 노출. 
          현재 사내에서만 사용. ROLE 체크  */}
          {proposalInfo?.proposalRequest.hidden &&
            accessData?.role &&
            DID_FILTER_ACCESS_ROLES.includes(accessData?.role) && <DidFilteringBoard />}
        </>
      )}

      {proposalInfo && (
        <SidebarLayout defaultOpen={false}>
          <DetailPreviewSidebar proposalInfo={proposalInfo} />
        </SidebarLayout>
      )}

      {proposalInfo && proposalInfo.proposal.status === 0 ? (
        <ButtonBox
          type="submit"
          next="철회"
          back="목록으로"
          handleClickNext={() => setIsDialogShowing(true)}
          handleClickPrev={() => navigate("/market")}
        />
      ) : (
        <ButtonBox type="confirm" text="확인" handleClickConfirm={() => navigate(-1)} />
      )}

      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={() => setIsErrorShowing()} />}

      {isJsonErrorShowing && (
        <Dialog
          description={`기업 서버에서 진행 중인 작업이 있습니다.\n잠시 후 다시 시도해 주세요.`}
          btnType="confirm"
          handleClose={() => setIsJsonErrorShowing()}
        />
      )}

      {isDialogShowing && (
        <Dialog
          title="진행 중인 거래를 철회하시겠습니까?"
          description={"철회하신 거래는 재개할 수 없습니다.\n계속 진행하시겠습니까?"}
          btnType="submit"
          handleClose={() => setIsDialogShowing(false)}
          handleSubmit={handleNextBtnClick}
        />
      )}

      {isConfirmDialogShowing && (
        <Dialog
          description="철회가 완료되었습니다."
          btnType="confirm"
          handleClose={() => setIsConfirmDialogShowing(false)}
        />
      )}
      {errorState && <ErrorModal error={errorState} onConfirmHandler={() => setErrorState(false)} />}

      {isAlertOpen && (
        <Dialog
          description={<AlertDescription />}
          btnType="submit"
          confirmBtnName="취소"
          submitBtnName="다운로드"
          handleSubmit={piJsonStartDownloadReq}
          handleClose={onToggleAlert}
          height="836px"
        />
      )}
    </Wrapper>
  );
}
