import dayjs from "dayjs";
import {
  TMarketBudgetData,
  TMarketContentData,
  TMarketPostProposal,
  TMarketPrivacyData,
  TMarketTargetData,
} from "types/market";
import { formatAgeGroup, formatCollectDataGroup, formatSubmitDate } from "./formatDataHandler";
import { getBannerUrl } from "utils/market";
import { isNumber } from "lodash";

type TProps = {
  targetState: TMarketTargetData;
  contentState: TMarketContentData;
  budgetState: TMarketBudgetData;
  privacyState: TMarketPrivacyData;
  requestId?: number;
  type?: "accept" | "temp" | "charge" | "register";
  hidden?: boolean;
};

export default function formatSubmitData({
  targetState,
  contentState,
  budgetState,
  privacyState,
  requestId,
  type = "accept",
  hidden,
}: TProps): TMarketPostProposal {
  const formattedData = {
    requestId: requestId && type !== "accept" ? requestId : undefined,
    title: contentState.transactionName,
    status: type,
    hidden,
    query: {
      target: {
        gender: targetState.gender,
        ageGroup: formatAgeGroup(targetState.age),
        mobileNetworkOperator: targetState.mobile,
        regionOfResidence: targetState.area.map((area) => area.replace(/\s/g, "-")).join(","),
      },
      collect: {
        ...formatCollectDataGroup(targetState.collectData),
        other: "",
      },
      privacyPolicy: {
        identifier: privacyState.personalIdentification === "nonAnonymous" ? 1 : 0,
      },
    },
    content: {
      bannerImageCdn: getBannerUrl(contentState.banner),
      term: targetState.collectPeriod,
      selectData: [...targetState.collectData, "userInfo"].join(","),
      disclaimer: targetState.dataPurpose.join(","),
      thirdParty: {
        processing: privacyState.personalIdentification === "nonAnonymous" ? privacyState.thirdPartyStatus : undefined,
        name: privacyState.thirdPartyStatus === 1 ? privacyState.company : undefined,
        purpose: privacyState.thirdPartyStatus === 1 ? privacyState.provisionPurpose.join(",") : undefined,
      },
    },
    pointBudget: {
      startDate:
        budgetState.transactionPeriod[1] === "" && type !== "accept"
          ? dayjs().format("YYYY-MM-DDT00:00:00Z")
          : formatSubmitDate(budgetState)[0], // 임시저장 시 날짜선택하지 않으면 오늘 날짜 기입
      endDate:
        budgetState.transactionPeriod[1] === "" && type !== "accept"
          ? dayjs().add(30, "day").format("YYYY-MM-DDT00:00:00Z")
          : formatSubmitDate(budgetState)[1],
      maxIssuable: budgetState.maxIssuable,
      rewardPolicyAmount: isNumber(budgetState.customReward) ? budgetState.customReward : budgetState.reward,
    },
  };
  return formattedData;
}
