import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { DefaultBtn } from "common/button";
import { InfoItem, Footer } from "common/other";
import { Content } from "common/container";
import { TStaff } from "types/common";

import ROLE from "constants/role";
import { getUserInfo } from "api/common";
import queryKey from "constants/queryKey";

type TProps = {
  data: TStaff;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  setIsDeleteModal: () => void;
};

export default function StaffDetailItem({ data, setIsEditMode, setIsDeleteModal }: TProps) {
  const navigate = useNavigate();
  const { data: userInfo } = useQuery(queryKey.GET_USERINFO, getUserInfo);
  const isB2bAdminLogin = userInfo?.roles === ROLE.B2B_ADMIN; // 로그인 유저가 대표담당자인지 확인

  const { name, department, jobPosition, email, phone, tel, company, role } = data;
  const isB2bAdmin = role === ROLE.B2B_ADMIN; // 상세 정보 조회한 유저가 대표담당자인지 확인
  const isOverseas = company.businessType === "1";

  return (
    <Content>
      <InfoItem label="이름" info={name} />
      <InfoItem label="기업 / 기관명" info={company.name} link={company.id} canEdit={isB2bAdminLogin && isB2bAdmin} />
      <InfoItem label="소재지" info={isOverseas ? "해외" : "국내"} />
      <InfoItem label="권한" info={isB2bAdmin ? "대표담당자" : "담당자"} />
      <InfoItem label="부서명" info={department} />
      <InfoItem label="직급" info={jobPosition} />
      <InfoItem label="이메일 주소" info={email} />
      <InfoItem label="개별 연락처" info={phone} />
      <InfoItem label="직통번호" info={tel} />

      <Footer>
        <DefaultBtn size="medium" mode="lineGray" onClick={() => navigate(-1)}>
          목록으로
        </DefaultBtn>

        {!isB2bAdmin && isB2bAdminLogin && (
          <DefaultBtn size="medium" mode="lineBlue" onClick={setIsDeleteModal}>
            삭제
          </DefaultBtn>
        )}

        {isB2bAdminLogin && (
          <DefaultBtn size="medium" onClick={() => setIsEditMode(true)}>
            수정
          </DefaultBtn>
        )}
      </Footer>
    </Content>
  );
}
