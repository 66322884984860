import { useSearchParams } from "react-router-dom";
import cx from "classnames";
import styles from "style/common/table/tableNav.module.scss";

type TDataInfo = {
  num: number;
  status: number | string;
};

type Props = { [key: string]: TDataInfo };

export default function TableNav({ data }: { data: Props }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dataList = Object.entries(data);
  const status = searchParams.get("status") || (dataList[0] && dataList[0][1].status);

  const handleClickStatus = (statusKey: number | string) => () =>
    setSearchParams((prev) => {
      prev.set("status", String(statusKey));
      prev.get("pageNum") && prev.set("pageNum", "1");
      return prev;
    });

  return (
    <section className={styles.wrapper}>
      <div className={styles.statusFilterBox}>
        {dataList.map((item, idx) => (
          <button
            key={item[0]}
            type="button"
            className={cx({ [styles.active]: status === item[1].status })}
            onClick={handleClickStatus(item[1].status)}
          >
            {item[0]}
            <span className={cx({ [styles.active]: status === item[1].status })}>{`${item[1].num}${
              idx === 0 ? "건" : ""
            }`}</span>
          </button>
        ))}
      </div>
    </section>
  );
}
