import Dropdown from "common/input/Dropdown";
import { CheckTitle } from "pages/market/marketComponents";
import { useRecoilState } from "recoil";
import { marketTargetAtom } from "recoils/market";
import { KOREA_WIDE_AREA } from "constants/areaList";
import { Chip } from "common/button";
import styles from "style/pages/market/createMarketPage/sectionTargetData/targetFiltering/areaTargetBox.module.scss";

export default function AreaTargetBox() {
  const [{ area: areaState }, setTargetState] = useRecoilState(marketTargetAtom);
  const isAreaTargetting = !!areaState.length;
  const dropdownAreaMap = Object.keys(KOREA_WIDE_AREA).filter((area) => !areaState.includes(KOREA_WIDE_AREA[area]));
  const toggleAreaMap = Object.entries(KOREA_WIDE_AREA).reduce((acc: { [key: string]: string }, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  const handleAddArea = (target: string) => {
    if (areaState.length === Object.keys(KOREA_WIDE_AREA).length - 1) {
      setTargetState((prev) => ({ ...prev, area: ["전체"] }));
    } else {
      setTargetState((prev) => ({
        ...prev,
        area: [...prev.area.filter((v) => v !== "전체"), KOREA_WIDE_AREA[target]],
      }));
    }
  };

  const handleRemoveArea = (target: string) => {
    if (areaState.length === 1) {
      setTargetState((prev) => ({ ...prev, area: ["전체"] }));
    } else setTargetState((prev) => ({ ...prev, area: prev.area.filter((area) => area !== target) }));
  };

  const handleClearArea = () => {
    setTargetState((prev) => ({ ...prev, area: ["전체"] }));
  };

  return (
    <div className={styles.wrapper}>
      <CheckTitle label="거주지역" height="36px" />
      <div className={styles.areaWrapper}>
        <Chip
          mode={areaState[0] === "전체" || !isAreaTargetting ? "color" : "default"}
          onClick={handleClearArea}
          width="58px"
        >
          전체
        </Chip>

        <Dropdown
          dataList={dropdownAreaMap}
          handleChange={handleAddArea}
          value=""
          placeholder="지역선택"
          width="220px"
          mode="secondary"
          borderColor="deep"
          maxHeight="320px"
          disabled
        />

        {isAreaTargetting &&
          areaState.map(
            (area) =>
              area !== "전체" && (
                <Chip key={area} mode="color" onClick={() => handleRemoveArea(area)}>
                  {toggleAreaMap[area]}
                </Chip>
              )
          )}
      </div>
    </div>
  );
}
