import dayjs from "dayjs";
import { ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { marketStatusMap } from "constants/market";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type TProps = {
  columns: (string[] | string)[];
  dataList?: (string | number | null | ReactElement<HTMLDivElement>)[][];
};

export default function useFilterTableData(data: TProps): TProps & { page: number } {
  const [searchParams] = useSearchParams();
  if (!data.dataList) return { ...data, page: 1 };
  let result = data.dataList;

  const [from, to] = [searchParams.get("from"), searchParams.get("to")];
  const keyword = searchParams.get("keyword");
  const status = searchParams.get("status");
  const pageNum = searchParams.get("pageNum") ? Number(searchParams.get("pageNum")) : 1;

  // 날짜 필터링
  if (from || to) {
    result = result.filter((item: any[]) => {
      const target = item[9].replace(/\./g, "-"); // proposal list의 not before 로 변경
      if (dayjs(target).isSameOrAfter(from || "0") && dayjs(target).isSameOrBefore(to || "9999", "day")) {
        return true;
      }
      return false;
    });
  }

  // 키워드 필터링
  if (keyword) {
    result = result.filter((item) => {
      if (item[10] === null || item[10] === undefined) {
        return false;
      }
      return (item[10] as string).toLowerCase().includes(keyword.toLowerCase());
    });
  }

  // 상태 필터링
  if (status && status !== "all") {
    result = result.filter((item) => {
      const targetStatus = (item[3] as unknown as ReactElement).props.status;
      return marketStatusMap[targetStatus][1] === status;
    });
  }

  // 필터된 리스트 넘버링
  result = result.map((item, idx) => {
    item[1] = result.length - idx;
    return item;
  });

  // 페이지 필터링
  const totalPages = Math.floor(result.length / 10) + (result.length % 10 && 1) || 1;
  result = result.slice((pageNum - 1) * 10, pageNum * 10);
  data.dataList = result;

  return { columns: data.columns, dataList: data.dataList, page: totalPages };
}
