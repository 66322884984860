import { NavLink } from "react-router-dom";
import {
  DashBoardIcon,
  ManagementIcon,
  MarketIcon,
  PointIcon,
  AdvertisementIcon,
  NoteIcon,
} from "assets/svgs/skeleton";
import styles from "style/common/other/skeleton/navigation.module.scss";

export default function Navigation() {
  return (
    <div className={styles.wrapper}>
      <NavLinkItem to="/" icon={<DashBoardIcon />} text="대시보드" />
      <NavLinkItem to="/reward" icon={<PointIcon />} text="포인트 충전" />
      <NavLinkItem to="/market" icon={<MarketIcon />} text="데이터 거래" />
      <NavLinkItem to="/advertise" icon={<AdvertisementIcon />} text="광고" />
      <NavLinkItem to="/management" icon={<ManagementIcon />} text="사용자 관리" />
      <NavLinkItem to="/dpia" icon={<NoteIcon />} text="DPIA 기록" />
    </div>
  );
}

type TProps = {
  to: string;
  icon: React.ReactNode;
  text: string;
};

function NavLinkItem({ to, icon, text }: TProps) {
  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? styles.active : styles.default)}>
      {icon}
      {text}
    </NavLink>
  );
}
