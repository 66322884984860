import { z } from "zod";

const AccessLogSchema = z.object({
  createdAt: z.string(),
  id: z.number(),
  staffId: z.number(),
  staffName: z.string(),
  ipAddress: z.string(),
});

const DownloadLogSchema = z.object({
  createdAt: z.string(),
  proposalId: z.string(),
  proposalTitle: z.string().nullable(),
  downloadType: z.string(),
  staffId: z.number(),
  staffName: z.string(),
  ipAddress: z.string(),
});

const SortSchema = z.object({
  empty: z.boolean(),
  unsorted: z.boolean(),
  sorted: z.boolean(),
});

const PageableSchema = z.object({
  sort: SortSchema,
  offset: z.number(),
  pageNumber: z.number(),
  pageSize: z.number(),
  paged: z.boolean(),
  unpaged: z.boolean(),
});

const CommonResSchema = z.object({
  pageable: PageableSchema,
  last: z.boolean(),
  totalElements: z.number(),
  totalPages: z.number(),
  number: z.number(),
  first: z.boolean(),
  sort: SortSchema,
  size: z.number(),
  numberOfElements: z.number(),
  empty: z.boolean(),
});

export const AccessResSchema = CommonResSchema.extend({
  content: z.array(AccessLogSchema),
});

export const DownloadResSchema = CommonResSchema.extend({
  content: z.array(DownloadLogSchema),
});

export type TAccessRes = z.infer<typeof AccessResSchema>;

export type TDownloadRes = z.infer<typeof DownloadResSchema>;
