import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { promotionAtom } from "recoils/promotion";
import { TCreateDetail } from "types/management";
import queryKey from "constants/queryKey";

/**
 * 프로모션 기업 여부 체크
 */
export default function useCheckPromotionCompany() {
  const queryClient = useQueryClient();
  const { KTCompanyId, SKBCompanyId } = useRecoilValue(promotionAtom);
  const [isPromotion, setIsPromotion] = useState(false);

  const { companyId } = (queryClient.getQueryData([queryKey.GET_DETAIL_COMPANY]) as TCreateDetail) || {};

  useEffect(() => {
    (companyId === KTCompanyId || companyId === SKBCompanyId) && setIsPromotion(true);
  }, [KTCompanyId, SKBCompanyId, companyId]);

  return { isPromotion };
}
