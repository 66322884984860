import styles from "style/pages/reward/list/rewardInfo.module.scss";
import { TRewardInfo } from "types/reward";

export default function RewardInfo({ myPoint, refundablePoint, amountPoint }: TRewardInfo) {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <p>총 보유 포인트</p>
        <p>{myPoint.toLocaleString()}P</p>
      </div>
      <div className={styles.info}>
        <p>사용 중인 포인트</p>
        <p>{refundablePoint.toLocaleString()}P</p>
      </div>
      <div className={styles.info}>
        <p>잔여 포인트</p>
        <p>{amountPoint.toLocaleString()}P</p>
      </div>
    </div>
  );
}
