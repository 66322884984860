import { useRecoilState } from "recoil";
import { marketBudgetAtom } from "recoils/market";

import { Dropdown } from "common/input";

function StartTime() {
  const [budgetState, setBudgetState] = useRecoilState(marketBudgetAtom);

  const handleTime = (date: string) => {
    const hour = date.slice(0, -1);
    setBudgetState((prev) => ({ ...prev, transactionTime: hour }));
  };

  return (
    <Dropdown
      dataList={Array.from({ length: 24 }, (_, i) => `${i}시`)}
      value={`${budgetState.transactionTime}시`}
      handleChange={handleTime}
      height="36px"
      width="206px"
      maxHeight="320px"
      mode="secondary"
      borderColor="soft"
    />
  );
}

export default StartTime;
