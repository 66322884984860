import React, { ReactNode } from "react";
import styles from "style/pages/market/previewSidebar/sidebarErrorBoundary.module.scss";

interface Props {
  children?: ReactNode;
  fallback?: React.ElementType;
}

interface State {
  hasError: boolean;
  info: Error | null;
}

class SidebarErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      info: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, info: error };
  }

  // componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  //   // eslint-disable-next-line no-console
  //   console.log("error: ", error);
  //   // eslint-disable-next-line no-console
  //   console.log("errorInfo: ", errorInfo);
  // }

  render() {
    const { hasError, info } = this.state;
    const { children } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (hasError && this.props.fallback) {
      <this.props.fallback error={info} />;
    } else if (hasError) {
      return (
        <div className={styles.wrapper}>
          {"사이드바 정보를 불러오는 데 실패하였습니다.\n잠시후에 다시 시도해주세요."}
        </div>
      );
    }
    return children;
  }
}

export default SidebarErrorBoundary;
