import styles from "style/common/container/wrapper.module.scss";

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

/**
 * 하단에 버튼 박스가 존재하는 경우 버튼 박스를 제외한 나머지 영역 레이아웃 컴포넌트
 */

export default function Wrapper({ children, style }: Props) {
  return (
    <section className={styles.wrapper} style={style}>
      {children}
    </section>
  );
}
