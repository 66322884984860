import axios from "axios";
import { IS_LOGINED } from "constants/access";
import { deleteCookie, getCookie, setCookie } from "./cookie";

let token: string | null = null;
let refreshTokenPromise: Promise<string | null> | null = null;

export const getToken = async () => {
  // console.log("getToken", token);
  if (!token) {
    // 갱신 중인 경우 기존의 Promise 사용
    if (!refreshTokenPromise) {
      refreshTokenPromise = refreshToken();
    }

    const newToken = await refreshTokenPromise;
    setToken(newToken);
    refreshTokenPromise = null;
  }
  return token;
};

export const setToken = (argToken: string | null) => {
  token = argToken;
};

export const refreshToken = async () => {
  try {
    const response = await axios.post(`/api/auth/refresh`);
    return response.data.data.accessToken;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400 || error.response?.status === 401) {
        return null;
      }
    }
    throw error;
  }
};

export const getIsLogined = () => {
  try {
    const isLogined = getCookie(IS_LOGINED);

    return isLogined === "0" || isLogined === "1";
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const setIsLogined = (isSessionCookie: boolean) => {
  const aWeek = 7;
  const value = `${isSessionCookie ? 1 : 0}`;
  const duration = isSessionCookie ? aWeek : 0;

  try {
    setCookie(IS_LOGINED, value, duration);
  } catch (err) {
    console.log(err);
  }
};

export const deleteIsLogined = () => {
  try {
    deleteCookie(IS_LOGINED);
  } catch (err) {
    console.log(err);
  }
};
