import cx from "classnames";
import dayjs from "dayjs";
import { isProgressNow } from "utils/market";
import styles from "style/common/button/statusChip.module.scss";

type TStatusChipProps = {
  approved: boolean;
  status: number;
  startDate?: dayjs.Dayjs;
};

const adStatusMap: { [key: number]: string[] } = {
  0: ["임시저장", "temporary"],
  1: ["승인요청", "pending"],
  2: ["요청취소", "cancel"],
  3: ["반려", "reject"],
  11: ["종료", "ended"],
  12: ["진행중", "inProgress"],
  13: ["예정", "notStarted"],
};

export default function StatusChip({ approved, status, startDate }: TStatusChipProps) {
  const statusFilter = () => {
    const num = Number(approved) * 10 + status;
    if (num === 10) {
      return isProgressNow(startDate!) ? num + 2 : num + 3;
    }
    return num;
  };

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.chip, styles[adStatusMap[statusFilter()][1]])}>{adStatusMap[statusFilter()][0]}</div>
    </div>
  );
}
