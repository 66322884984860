import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import IconInput from "./IconInput";
import { usePassword } from "./usePassword";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { DefaultBtn } from "common/button";
import { TPasswordChange, TPasswordFind } from "types/login";
import { isPasswordValid } from "utils/validation";
import useModal from "hooks/useModal";
import { postUserEmail } from "api/login";
import queryKey from "constants/queryKey";

import { MydLogo } from "assets/svgs/skeleton";
import styles from "style/pages/login/password.module.scss";

export default function Password() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isPathContainFind = /find/gi.test(pathname);

  const [errorMsg, setErrorMsg] = useState<Error>();
  const [isShowing, setIsShowing] = useState(false);
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const { register, handleSubmit, getValues } = useForm<TPasswordFind & TPasswordChange>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      passwordCheck: "",
    },
  });

  const { passwordFindMutate, passwordChangeMutate, isLoading, error } = usePassword();

  const enc = new URLSearchParams(window.location.search).get("enc") || "";

  // pw change page - user email 조회
  const { data: email, error: emailError } = useQuery([queryKey.GET_USER_EMAIL, enc], () => postUserEmail(enc), {
    enabled: !isPathContainFind,
    onError: () => setIsErrorShowing(),
  });

  const passwordMutate = () => {
    const password = getValues("password");
    const passwordCheck = getValues("passwordCheck");

    // 비밀번호 찾기
    if (isPathContainFind) {
      setIsShowing(false);

      passwordFindMutate(
        { name: getValues("name"), email: getValues("email") },
        {
          onSuccess: () => navigate("/login"),
          onError: () => setIsErrorShowing(),
        }
      );
    }

    // 비밀번호 변경
    if (!isPathContainFind) {
      if (!isPasswordValid(password) || !isPasswordValid(passwordCheck)) {
        setErrorMsg(Error(`비밀번호는 8자 이상,\n영문 대/소문자, 숫자, 특수문자를 포함해야 합니다`));
        setIsErrorShowing();
        return;
      }

      if (password !== passwordCheck) {
        setErrorMsg(Error(`비밀번호가 일치하지 않습니다.\n다시 입력해 주세요`));
        setIsErrorShowing();
        return;
      }

      passwordChangeMutate(
        { password, passwordCheck },
        {
          onSuccess: () => setIsShowing(true),
          onError: () => setIsErrorShowing(),
        }
      );
    }
  };

  const handleSubmitForm = () => {
    if (!isPathContainFind) {
      passwordMutate();
      return;
    }

    setIsShowing(true);
  };

  const handleModalConfirm = () => {
    setIsErrorShowing();
    setErrorMsg(undefined);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className={styles.container}>
            <Link to="/login">
              <MydLogo />
            </Link>

            <p className={styles.title}>비밀번호 {isPathContainFind ? "찾기" : "변경"}</p>
            <p className={styles.subtitle}>
              {isPathContainFind
                ? `비밀번호 찾기를 하시려면 이름과 가입한 이메일\n(아이디)를 입력하여 주십시오`
                : `${email || ""} 님의 비밀번호를 변경합니다.`}
            </p>

            {isPathContainFind ? (
              <div className={styles.inputWrapper}>
                <IconInput type="text" placeholder="이름" register={register("name", { required: true })} />
                <IconInput
                  type="email"
                  placeholder="가입한 이메일 주소 입력"
                  register={register("email", { required: true })}
                />
              </div>
            ) : (
              <div className={styles.inputWrapper}>
                <IconInput
                  type="password"
                  placeholder="새로운 비밀번호"
                  register={register("password", { required: true })}
                />
                <IconInput
                  type="password"
                  placeholder="새로운 비밀번호 확인"
                  register={register("passwordCheck", { required: true })}
                />
              </div>
            )}

            <div className={styles.btnBox}>
              <DefaultBtn type="button" size="medium" mode="lineBlue" onClick={() => navigate("/login")}>
                취소
              </DefaultBtn>

              <DefaultBtn type="submit" size="medium">
                비밀번호 {isPathContainFind ? "찾기" : "변경"}
              </DefaultBtn>
            </div>
          </div>
        </form>
      </div>

      {isLoading && <LoadingModal />}

      {isShowing && (
        <Dialog
          btnType={isPathContainFind ? "submit" : "confirm"}
          submitBtnName="발송"
          title={isPathContainFind ? "비밀번호 변경 이메일 발송" : "비밀번호가 변경되었습니다"}
          description={
            isPathContainFind
              ? `입력하신 이메일 아이디로\n비밀번호 변경 링크가 포함된 이메일이 발송됩니다\n\n`
              : `변경된 비밀번호로 재로그인 부탁드립니다`
          }
          handleClose={() => {
            isPathContainFind ? setIsShowing(false) : navigate("/login");
          }}
          handleSubmit={passwordMutate}
        />
      )}

      {isErrorShowing && <ErrorModal error={errorMsg || error || emailError} onConfirmHandler={handleModalConfirm} />}
    </>
  );
}
