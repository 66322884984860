import { useEffect, useState } from "react";
import { getPrivacyAgree } from "api/management";
import { getUserInfo } from "api/common";
import PrivacyAgree from "./PrivacyAgree";

export default function PrivacyValidator({ onNext }: { onNext: () => void }) {
  const [isViewAgree, setIsViewAgree] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    (async () => {
      const result = await getUserInfo();

      try {
        await getPrivacyAgree(result.email);
        onNext();
      } catch (error) {
        if (error instanceof Error && error.message === "데이터를 찾을 수 없습니다.") {
          setEmail(result.email);
          setIsViewAgree(true);
          return;
        }

        throw error;
      }
    })();
  }, []);

  if (isViewAgree) return <PrivacyAgree email={email} onNext={onNext} />;
  return null;
}
