import { useResetRecoilState } from "recoil";
import { marketContentAtom, marketBudgetAtom, marketTargetAtom, marketPrivacyAtom } from "recoils/market";

export default function useResetMarketState() {
  const resetPrivacy = useResetRecoilState(marketPrivacyAtom);
  const resetTarget = useResetRecoilState(marketTargetAtom);
  const resetBudget = useResetRecoilState(marketBudgetAtom);
  const resetContent = useResetRecoilState(marketContentAtom);

  const resetMarketState = () => {
    resetPrivacy();
    resetTarget();
    resetBudget();
    resetContent();
  };

  return resetMarketState;
}
