import { TAdDetail } from "types/advertisement";
import PreviewCard from "./PreviewCard";
import PreviewInfo from "./PreviewInfo";

type TProps = {
  data: TAdDetail;
};

export default function DetailPreviewSidebar({ data }: TProps) {
  const cardProps = {
    bannerUrl: data?.proposals?.contentObject.imageURL.banner!,
    title: data?.proposals?.title!,
    rewardPolicyAmount: data?.proposals?.rewardPolicyAmount!,
    privacyPolicy: data?.proposals?.contentObject?.privacyPolicy?.identifier!,
    maxIssuable: data?.proposals?.maxIssuable!,
    collectTarget: data?.proposals?.selectDataList!,
  };

  const infoProps = {
    adBannerUrl: data?.advertisements?.fileUrl!,
  };

  return (
    <div>
      <PreviewCard cardData={cardProps} />
      <PreviewInfo infoData={infoProps} />
    </div>
  );
}
