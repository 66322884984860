import axios from "axios";
import { MiddlewareError } from "utils/errorPackage";
import { errorHandling } from "./errorHandling";
import { getToken, setToken } from "utils/access";

let isAuthErrorRetry = false;

export const Axios = axios.create({
  timeout: 100000,
});

Axios.interceptors.request.use(
  async (config) => {
    const accessToken = await getToken();
    if (accessToken === null) {
      window.location.href = "/login?invalid-token";

      return config;
    }

    config.headers["X-Auth-Token"] = accessToken;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    isAuthErrorRetry = false;
    return response;
  },
  async (error) => {
    if (error.response?.status === 401 && !isAuthErrorRetry) {
      isAuthErrorRetry = true;
      setToken(null);
      return Axios(error.config);
    }
    isAuthErrorRetry = false;

    if (/ECONNABORTED|ERR_NETWORK/gi.test(error.code)) {
      return Promise.reject(error);
    }

    // middleware error
    if (error.response.data.code) {
      // json download 500s
      throw errorHandling(
        new MiddlewareError({
          message: error.response.data.message,
          httpStatus: error.response.status,
          code: error.response.data.code,
        })
      );
    }
    if (error.response.status === 429) {
      throw errorHandling(
        new MiddlewareError({
          message: "OnPremiseError",
          httpStatus: error.response.status,
          code: error.response.data.code,
        })
      );
    }

    throw errorHandling(error);
  }
);
