import { deleteMarketProposal, revokeMarketProposal } from "api/market";
import { AxiosError } from "axios";
import { ErrorMessage } from "constants/errorCode";
import queryKey from "constants/queryKey";
import useAccessRole from "hooks/useAccessRole";
import { useMutation, useQueryClient } from "react-query";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { marketDialogAtom, marketErrorAtom, marketLoadingAtom } from "recoils/market";

export default function useTableMutate() {
  const queryClient = useQueryClient();
  const setDialogState = useSetRecoilState(marketDialogAtom);
  const resetDialogState = useResetRecoilState(marketDialogAtom);
  const setErrorState = useSetRecoilState(marketErrorAtom);
  const setLoadingState = useSetRecoilState(marketLoadingAtom);
  const { data } = useAccessRole();

  const { mutate: deleteMutate } = useMutation(deleteMarketProposal, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([queryKey.GET_MARKET]);
      setDialogState({
        isShow: true,
        btnType: "confirm",
        title: "",
        confirmBtnName: "확인",
        description: "삭제가 완료되었습니다.",
        handleClose: () => {
          resetDialogState();
        },
      });
    },
    onError: (error) => setErrorState((prev) => ({ ...prev, error, isShow: true })),
    onSettled: () => setLoadingState(false),
  });

  const { mutate: revokeMutate } = useMutation(
    (ProposalID: string) => revokeMarketProposal({ ProposalID, token: data?.token!, did: data?.did! }),
    {
      onSuccess: () => {
        setDialogState({
          isShow: true,
          btnType: "confirm",
          title: "",
          confirmBtnName: "확인",
          description: "철회가 완료되었습니다.",
          handleClose: () => {
            resetDialogState();
            queryClient.invalidateQueries([queryKey.GET_MARKET]);
          },
        });
      },
      onError: (error: AxiosError) => {
        if (error.message === ErrorMessage[1003]) queryClient.invalidateQueries([queryKey.GET_MARKET]);
        setErrorState((prev) => ({ ...prev, error, isShow: true }));
      },
      onSettled: () => setLoadingState(false),
    },
  );

  const deleteTableData = (id: number) => {
    setDialogState({
      isShow: true,
      btnType: "submit",
      title: "임시저장 내역을 삭제하시겠습니까?",
      description: "삭제하실 경우 복구하실 수 없습니다.\n계속 진행하시겠습니까?",
      handleClose: () => {
        resetDialogState();
      },
      handleSubmit: () => {
        setLoadingState(true);
        deleteMutate(id);
        resetDialogState();
      },
    });
  };

  const revokeTableData = (id: string) => {
    setDialogState({
      isShow: true,
      btnType: "submit",
      title: "진행 중인 거래를 철회하시겠습니까?",
      description: "철회하신 거래는 재개할 수 없습니다.\n계속 진행하시겠습니까?",
      handleClose: () => {
        resetDialogState();
      },
      handleSubmit: () => {
        setLoadingState(true);
        revokeMutate(id);
        resetDialogState();
      },
    });
  };
  return {
    deleteTableData,
    revokeTableData,
    deleteMutate,
  };
}
