import styles from "style/common/modal/errorModal.module.scss";
import cx from "classnames";
import { ModalPortal } from "common/other";
import { useState } from "react";
import { DefaultBtn } from "common/button";

type Props = {
  title?: string;
  error: unknown;
  buttonText?: string;
  onConfirmHandler?: () => void;
};

/**
 * ErrorModal - 에러 메세지를 모달 형식으로 보여줄 때 사용합니다.
 *
 * @component
 * @example
 * const props = {
 *  error: error,
 * }
 *
 * <ErrorModal {...props} />
 */
export default function ErrorModal({ title, error, buttonText = "확인", onConfirmHandler }: Props) {
  const errorMessage =
    error instanceof Error && error.message ? error.message : `알 수 없는 오류가 발생하였습니다.\n 다시 시도해주세요.`;

  const [isClick, setIsClick] = useState(false);

  const handleClickConfirm = () => (onConfirmHandler ? onConfirmHandler() : setIsClick(true));

  if (!onConfirmHandler && isClick) return null;

  return (
    <ModalPortal>
      <div className={styles.errorModal}>
        <div className={styles.content}>
          <div>{title}</div>
          <p className={cx({ [styles.isTitle]: title })}>{errorMessage}</p>

          <DefaultBtn type="button" name="confirm" onClick={handleClickConfirm}>
            {buttonText}
          </DefaultBtn>
        </div>
      </div>
    </ModalPortal>
  );
}
