import { getCompanyDetail } from "api/company";
import { getMyAccount } from "api/market";
import { DefaultBtn } from "common/button";
import { CheckInput } from "common/input";
import { Dialog } from "common/modal";
import { LIMIT_PRICE } from "constants/market";
import queryKey from "constants/queryKey";
import useModal from "hooks/useModal";
import { isNumber } from "lodash";
import useMutateProposal from "pages/market/createMarketPage/submitCreateProposal/useMutateProposal";
import { ChangeEventHandler, useEffect } from "react";
import { useQueries } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  marketBudgetAtom,
  marketContentAtom,
  marketErrorAtom,
  marketPrivacyAtom,
  marketTargetAtom,
} from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionBudget/transactionPoint.module.scss";
import { getDelCommas } from "utils/validation";
import handleSubmitData from "../submitCreateProposal/formatSubmitData";
import PointLabel from "./PointLabel";
import calculateWeight from "./calculateWeight";

export default function TransactionPoint() {
  const targetState = useRecoilValue(marketTargetAtom);
  const contentState = useRecoilValue(marketContentAtom);
  const privacyState = useRecoilValue(marketPrivacyAtom);
  const [budgetState, setBudgetState] = useRecoilState(marketBudgetAtom);
  const setErrorState = useSetRecoilState(marketErrorAtom);
  const { maxIssuable, reward, customReward } = budgetState;
  const { isApplyDidFiltering } = targetState;

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const requestId = searchParams.get("requestId") ? Number(searchParams.get("requestId")) : undefined;

  const [isShowing, handleToggleModal] = useModal();
  const { mutateProposal } = useMutateProposal({ isApplyDidFiltering: isApplyDidFiltering === "true" });

  // targetState가 변경될 때 마다 포인트값 계산
  useEffect(() => {
    const calculatedReward = (Math.round(calculateWeight(targetState) * LIMIT_PRICE) * 10000) / 10000 || 0;
    setBudgetState((prev) => ({ ...prev, reward: calculatedReward }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetState]);

  // 임시저장 데이터를 불러올 때 기본 reward 포인트 설정
  useEffect(() => {
    if (isNumber(location.state)) {
      setBudgetState((prev) => ({ ...prev, reward: location.state }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ data: accountData, isLoading: accountLoading }, { data: companyData }] = useQueries([
    {
      queryKey: [queryKey.GET_MY_ACCOUNT],
      queryFn: getMyAccount,
      onError: () =>
        setErrorState({
          isShow: true,
          error: Error("포인트 잔고를 불러오는 데 실패했습니다."),
          buttonText: "닫기",
        }),
    },
    {
      queryKey: [queryKey.GET_DETAIL_COMPANY],
      queryFn: getCompanyDetail,
    },
  ]);

  const isAdminCompany = companyData?.companyId === 2;

  const calculatedBudget =
    accountData?.result &&
    (isNumber(customReward)
      ? accountData.result - customReward * maxIssuable
      : accountData.result - reward * maxIssuable);

  const handleChangePeople: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (/[^0-9,]/.test(e.target.value)) return;
    if (!reward) {
      setErrorState({
        isShow: true,
        error: Error("데이터 설정 항목을 모두 선택 후\n목표 인원을 입력해 주세요"),
        buttonText: "확인",
      });
      return;
    }
    let people = Number(getDelCommas(e.currentTarget.value) || 0);
    if (people > 20000) return;
    if (accountData!.result - reward * people < 0 || people < 0) {
      people = Math.floor(accountData!.result / reward);
      setErrorState({
        isShow: true,
        error: Error("포인트 잔고가 부족합니다.\n포인트를 충전해주세요"),
        buttonText: "확인",
      });
    }
    setBudgetState((prev) => ({ ...prev, maxIssuable: people }));
  };

  const handleChargeButton = async () => {
    mutateProposal(
      handleSubmitData({
        targetState,
        budgetState,
        contentState,
        privacyState,
        type: "charge",
        requestId,
        hidden: isApplyDidFiltering === "true",
      })
    );
  };

  const handleCustomReward = () =>
    setBudgetState((prev) => ({ ...prev, customReward: isNumber(customReward) ? null : 0 }));
  const handleChangeReward: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (/[^0-9,]/.test(e.target.value)) return;
    setBudgetState((prev) => ({ ...prev, customReward: Number(getDelCommas(e.currentTarget.value) || 0) }));
  };

  if (accountLoading) return <div />;

  return (
    <>
      <div className={styles.innerBox}>
        <p className={styles.subTitle}>1인당 지급 포인트</p>
        <div className={styles.rewardPointWrapper}>
          <PointLabel point={customReward === null ? reward : customReward} />
          {isAdminCompany && (
            <div className={styles.rewardInputWrapper}>
              <CheckInput
                handleCheck={handleCustomReward}
                name="customReward"
                text="직접입력"
                value={isNumber(customReward)}
              />
              <input
                type="text"
                onChange={handleChangeReward}
                value={customReward === null ? "" : customReward.toLocaleString()}
                placeholder="숫자만 입력해주세요"
                disabled={!isNumber(customReward)}
              />
              P
            </div>
          )}
        </div>
      </div>

      <div className={styles.innerBox}>
        <p className={styles.subTitle}>목표인원</p>
        <div className={styles.peopleInputWrapper}>
          <input
            type="text"
            onChange={handleChangePeople}
            value={maxIssuable ? maxIssuable.toLocaleString() : ""}
            placeholder="0"
          />
          명
        </div>
      </div>

      <div className={styles.innerBox}>
        <p className={styles.subTitle}>포인트 예산</p>
        <PointLabel point={isNumber(customReward) ? customReward * maxIssuable : reward * maxIssuable || 0} />
      </div>

      <div className={styles.innerBox}>
        <p className={styles.subTitle}>포인트 잔고</p>
        <div className={styles.managePointWrapper}>
          차감 전 <PointLabel point={accountData?.result || 0} />
          <DefaultBtn size="small" onClick={handleToggleModal}>
            충전
          </DefaultBtn>
        </div>
        <div className={styles.managePointWrapper}>
          차감 후 <PointLabel point={calculatedBudget! || 0} />
        </div>
      </div>

      {isShowing && (
        <Dialog
          title="포인트 충전화면으로 이동하시겠습니까?"
          description="지금까지 입력된 정보는 임시저장 됩니다."
          btnType="submit"
          handleClose={handleToggleModal}
          handleSubmit={handleChargeButton}
        />
      )}
    </>
  );
}
