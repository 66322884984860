import { getCompanyDetail } from "api/company";
import { getMarketList } from "api/market";
import { KOREA_WIDE_AREA } from "constants/areaList";
import { collectDataList } from "constants/market";
import queryKey from "constants/queryKey";
import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  marketBudgetAtom,
  marketContentAtom,
  marketErrorAtom,
  marketLoadingAtom,
  marketPrivacyAtom,
  marketTargetAtom,
} from "recoils/market";
import { getTempDataProduct } from "../productSet/getTempDataProduct";

export default function useTempCreateData() {
  const setTargetState = useSetRecoilState(marketTargetAtom);
  const setBudgetState = useSetRecoilState(marketBudgetAtom);
  const setContentState = useSetRecoilState(marketContentAtom);
  const setPrivacyState = useSetRecoilState(marketPrivacyAtom);
  const setLoadingState = useSetRecoilState(marketLoadingAtom);
  const setErrorState = useSetRecoilState(marketErrorAtom);
  const [requestId, setRequestId] = useState<number>();
  const navigate = useNavigate();
  const { data: companyData } = useQuery([queryKey.GET_DETAIL_COMPANY], getCompanyDetail);
  const { isError: isTempError } = useQuery([queryKey.GET_MARKET, requestId], getMarketList, {
    enabled: !!requestId,
    onSuccess: (marketList) => {
      const request = marketList?.result.tempList.find((item) => item.id === requestId);
      if (!request) return;
      try {
        setTargetState((prev) => ({
          ...prev,
          product: getTempDataProduct(request, companyData),
          gender: ["all", "female", "male"].includes(request.gender) ? request.gender : "all",
          age:
            !request?.ageGroup ||
            (request?.ageGroup && request?.ageGroup.replace(/\d{2}-\d{2}|all/g, "")) ||
            request.ageGroup === "all"
              ? [0, 0]
              : request.ageGroup.split("-").map((v) => Number(v)),
          area:
            request.regionOfResidence &&
            request.regionOfResidence
              .replace(/-/g, " ")
              .split(",")
              .filter((v) => Object.values(KOREA_WIDE_AREA).includes(v)).length
              ? request.regionOfResidence
                  .replace(/-/g, " ")
                  .split(",")
                  .filter((v) => Object.values(KOREA_WIDE_AREA).includes(v))
              : ["전체"],
          mobile: "all",
          collectData: request.selectData
            ? request.selectData
                .split(",")
                .filter((item) => item !== "userInfo" && collectDataList.map(({ value }) => value).includes(item))
            : [],
          collectPeriod: request.contentObject?.purchase?.selectRange || "2w",
          dataPurpose: request?.contentObject?.description?.detail.length
            ? request?.contentObject.description.detail.split(",")
            : [],
          isApplyDidFiltering: request.hidden ? "true" : "false",
        }));
        setBudgetState((prev) => ({
          ...prev,
          transactionPeriod: [
            dayjs(request.notBefore || undefined).format("YYYY-MM-DD"),
            dayjs(request.notAfter || undefined).format("YYYY-MM-DD"),
          ],
          transactionTime: dayjs(request.notBefore || new Date().toLocaleDateString()).format("H"),
          maxIssuable: request.maxIssuable,
        }));
        setContentState((prev) => ({
          ...prev,
          titleType: "기본형",
          intro: "선택안함",
          transactionName: request.title,
          banner: request.contentObject?.imageURL?.banner.includes("snplab/b2b-static/images")
            ? request.contentObject.imageURL.banner.split("/").pop()?.slice(0, -4)!
            : "",
        }));
        const privacyPolicy = request.contentObject?.privacyPolicy;
        setPrivacyState((prev) => ({
          ...prev,
          personalIdentification: privacyPolicy?.identifier === 1 ? "nonAnonymous" : "anonymous",
          company: privacyPolicy?.identifier === 1 ? privacyPolicy?.thirdParty?.name || "" : "",
          provisionPurpose: privacyPolicy?.identifier === 1 ? privacyPolicy?.thirdParty?.purpose?.split(",") || [] : [],
          thirdPartyStatus: privacyPolicy?.thirdParty?.processing || 0,
        }));
        navigate(`/market/create?requestId=${requestId}`, { state: request.rewardPolicyAmount });
      } catch (error) {
        setErrorState({
          isShow: true,
          error: Error("해당 임시저장 데이터는 불러올 수 없습니다."),
          buttonText: "닫기",
        });
      }
    },
    onError: (error) =>
      setErrorState({
        isShow: true,
        error,
        buttonText: "닫기",
      }),
    onSettled: () => setLoadingState(false),
  });

  const handleResumeCreate = (id: number) => {
    setLoadingState(true);
    setRequestId(id);
  };

  return { handleResumeCreate, isTempError };
}
