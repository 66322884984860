import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useForm, FieldErrors, FormProvider, SubmitHandler } from "react-hook-form";

import PasswordChange from "./PasswordChange";
import { updateMypage } from "api/mypage";
import Content from "common/container/Content";
import { LabelInput } from "common/input";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { ButtonBox, InfoItem } from "common/other";
import Chip from "common/button/Chip";
import { TStaff } from "types/common";
import { TDefaultStaffInput } from "types/management";
import { useModal } from "hooks";

import styles from "style/pages/mypage/myInfo.module.scss";
import queryKey from "constants/queryKey";
import { getUserInfo } from "api/common";
import ROLE from "constants/role";

export default function MyInfo({ data }: { data: TStaff }) {
  const { data: userInfo } = useQuery(queryKey.GET_USERINFO, getUserInfo);
  const isB2bAdminLogin = userInfo?.roles === ROLE.B2B_ADMIN; // 로그인 유저가 대표담당자인지 확인

  const { name, department, jobPosition, phone, tel, email, company } = data;

  const [isPwModal, setIsPwModal] = useModal(); // '비밀번호 변경하기' Modal
  const [isConfirmUpdate, setIsConfirmUpdate] = useModal(); // 정보 수정 완료 Modal
  const [isValidateCheck, setIsValidateCheck] = useModal(); // input validate check Modal
  const [isErrorShowing, setIsErrorShowing] = useModal(); // Error Modal
  const [errorMsg, setErrorMsg] = useState("");

  const defaultValues = { staffName: name, department, jobPosition, phone, tel };

  const methods = useForm<TDefaultStaffInput>({ mode: "onChange", defaultValues });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  // [POST] MyPage 정보 수정
  const { mutate: updateMypageMutation, error: updateError, isLoading } = useMutation(updateMypage);

  const handleUpdate: SubmitHandler<TDefaultStaffInput> = (input) => {
    const body = { ...input, email, companyName: company.name };

    updateMypageMutation(body, {
      onSuccess: setIsConfirmUpdate,
      onError: setIsErrorShowing,
    });
  };

  const handleError = (err: FieldErrors) => {
    if (err) {
      const errorMessages = Object.values(err).filter((v) => v?.message);
      if (errorMessages.length) {
        setErrorMsg(String(errorMessages[0]?.message));
      }
      setIsValidateCheck();
    }
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Content>
      <FormProvider {...methods}>
        <form className={styles.contentContainer} onSubmit={handleSubmit(handleUpdate, handleError)}>
          <LabelInput
            label="이름"
            id="staffName"
            placeholder="담당자명을 입력하세요"
            isNull={!!errors.staffName}
            maxLength={100}
          />

          <div className={styles.pwContainer}>
            <div className={styles.label}>비밀번호</div>

            <Chip type="button" width="120px" onClick={setIsPwModal}>
              비밀번호 변경하기
            </Chip>

            {isPwModal && <PasswordChange setIsPwModal={setIsPwModal} />}
          </div>

          <InfoItem label="기업/기관명" info={data?.company.name} link={data?.company.id} canEdit={isB2bAdminLogin} />

          <InfoItem label="소재지" info={data?.company.businessType === "1" ? "해외" : "국내"} />

          <InfoItem label="권한" info={isB2bAdminLogin ? "대표담당자" : "담당자"} />

          <LabelInput
            label="부서명"
            id="department"
            placeholder="부서명을 입력하세요"
            isNull={!!errors.department}
            maxLength={50}
          />

          <LabelInput
            label="직급"
            id="jobPosition"
            placeholder="직급을 입력하세요"
            isNull={!!errors.jobPosition}
            maxLength={20}
          />

          <InfoItem label="이메일 주소" info={data?.email} />

          <LabelInput
            label="개별 연락처"
            type="number"
            id="phone"
            placeholder="'-'를 제외한 숫자만 입력하세요"
            isNull={!!errors.phone}
            validate="tel"
          />

          <LabelInput
            label="직통번호"
            id="tel"
            placeholder="'-'를 제외한 숫자만 입력하세요"
            isNull={!!errors.tel}
            type="number"
            validate="tel"
          />

          <ButtonBox next="수정" />

          {/* Modals */}
          {isConfirmUpdate && (
            <Dialog description="담당자 정보가 수정되었습니다." btnType="confirm" handleClose={setIsConfirmUpdate} />
          )}

          {isValidateCheck && <Dialog description={errorMsg} btnType="confirm" handleClose={setIsValidateCheck} />}

          {isErrorShowing && <ErrorModal error={updateError} onConfirmHandler={setIsErrorShowing} />}
        </form>
      </FormProvider>
    </Content>
  );
}
