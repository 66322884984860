import { Link } from "react-router-dom";
import styles from "style/pages/advertisement/adUI/inputWithLabel.module.scss";

type TProps = React.ComponentProps<"input"> & {
  children: string;
  label: string;
  url?: string;
};

export default function InputWithLabel({ children, label, url }: TProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{label}</div>

      {url ? (
        <Link to={url} target="_blank" rel="noopener noreferrer">
          {children}
        </Link>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
}
