import { useMutation, useQueryClient } from "react-query";
import cx from "classnames";

import { Dialog, ErrorModal } from "common/modal";
import { changeAuth } from "api/management";
import { useModal } from "hooks";
import queryKey from "constants/queryKey";
import { TStaff } from "types/common";

import styles from "style/pages/management/staff/authToggle.module.scss";
import ROLE from "constants/role";
import { refreshToken, setToken } from "utils/access";

export default function AuthToggle({ item, canEdit }: { item: TStaff; canEdit: boolean }) {
  const { id, role } = item;
  const queryClient = useQueryClient();

  const isB2bAdmin = role === ROLE.B2B_ADMIN; // 상세 정보 조회한 유저가 대표담당자인지 확인

  const [isShowAuthModal, setIsShowAuthModal] = useModal();
  const [isClickConfirm, setIsClickConfirm] = useModal();
  const [isErrorShowing, setIsErrorShowing] = useModal();

  // 권한 변경 API 연동
  const { mutate, error } = useMutation(changeAuth, {
    onSuccess: async () => {
      const result = await refreshToken();
      setToken(result);

      setIsShowAuthModal();
      setIsClickConfirm();
    },
  });

  // 권한 변경 완료 modal 이벤트 핸들러
  const handleSuccessChangeAuth = () => {
    setIsClickConfirm();
    queryClient.invalidateQueries([queryKey.GET_STAFF_LIST]);
    queryClient.invalidateQueries([queryKey.GET_ROLE]);
    queryClient.invalidateQueries(queryKey.GET_USERINFO);
  };

  const authNoticeModal = isB2bAdmin ? (
    <Dialog
      description={`대표담당자는 공석일 수 없습니다.\n다른 담당자를 대표 담당자로 전환해주세요.`}
      btnType="confirm"
      handleClose={setIsShowAuthModal}
    />
  ) : (
    <Dialog
      title="대표담당자로 전환하시겠습니까?"
      description="나의 권한은 일반 담당자로 변경됩니다."
      btnType="submit"
      handleClose={setIsShowAuthModal}
      handleSubmit={() => mutate(String(id))}
    />
  );

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.container, {
          [styles.moveTo]: isB2bAdmin,
          [styles.bgc]: canEdit && !isB2bAdmin,
        })}
      >
        <div className={cx(styles.btnBox, { [styles.moveToLeft]: isB2bAdmin })}>
          <button type="button" onClick={() => canEdit && setIsShowAuthModal()}>
            {isB2bAdmin ? "대표담당자" : "담당자"}
          </button>
        </div>
      </div>

      {isShowAuthModal && authNoticeModal}

      {isClickConfirm && (
        <Dialog description="대표담당자가 변경되었습니다." btnType="confirm" handleClose={handleSuccessChangeAuth} />
      )}

      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={setIsErrorShowing} />}
    </div>
  );
}
