import { useEffect, useState, ChangeEventHandler, FormEventHandler } from "react";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";

import { DefaultBtn } from "common/button";
import searchImg from "assets/images/search.png";

import styles from "style/common/input/searchInput.module.scss";
import { RerollIcon } from "assets/svgs/icon";

type TProps = {
  placeholder: string;
  withoutIcon?: boolean;
  isBtn?: boolean;
};

export default function SearchInput({ placeholder, withoutIcon = false, isBtn = true }: TProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKeyword = searchParams.get("searchKeyword") || "";
  const [value, setValue] = useState("");

  const handlesearchKeywordChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const handleSearchSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setSearchParams((prev) => {
      prev.set("searchKeyword", value.trim());
      prev.set("pageNum", "1");
      return prev;
    });
  };

  const handleResetDate = () => {
    searchParams.delete("searchKeyword");
    setSearchParams(searchParams);
    setValue("");
  };

  useEffect(() => {
    setValue(searchKeyword);
  }, [searchKeyword]);

  return (
    <div className={styles.wrapper}>
      <form onSubmit={handleSearchSubmit} className={cx(styles.topBox, { [styles.gapStyle]: withoutIcon })}>
        <div className={styles.inputBox}>
          {withoutIcon || <img src={searchImg} alt="search" />}
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handlesearchKeywordChange}
            className={cx({ [styles.withoutIconStyle]: withoutIcon })}
          />
        </div>

        <DefaultBtn size="small" onClick={handleSearchSubmit}>
          검색
        </DefaultBtn>
      </form>

      {!!searchParams.get("searchKeyword") && isBtn && (
        <div className={styles.resetContainer}>
          <button type="button" className={styles.searchClearBtn} onClick={handleResetDate}>
            <RerollIcon width="24px" height="24px" />
            초기화하기
          </button>
        </div>
      )}
    </div>
  );
}
