import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AdDetail, AdList, AdRegister } from "pages/advertisement";
import Dashboard from "pages/dashboard/Dashboard";
import { Login, Password } from "pages/login";
import { CompanyDetail, Management, StaffDetail, StaffRegister } from "pages/management";
import { CreateMarket, Market } from "pages/market";
import MarketDetail from "pages/market/marketDetailPage/MarketDetail";
import Mypage from "pages/mypage/Mypage";
import NotFound from "pages/notFound";
import { PrivatePolicy, TermsOfService } from "pages/policy";
import Promotion from "pages/promotion/Promotion";
import { Reward, RewardCharge } from "pages/reward";

import FunnelPrivate from "FunnelPrivate";
import PublicRoute from "PublicRoute";
import DpiaList from "pages/dpia/DpiaList";
import "comp-lib/dist/style.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="privatePolicy" element={<PrivatePolicy />} />
        <Route path="termsOfService" element={<TermsOfService />} />

        <Route element={<PublicRoute />}>
          <Route path="login" element={<Login />} />

          <Route path="password">
            <Route path="find" element={<Password />} />
            <Route path="change" element={<Password />} />
          </Route>
        </Route>

        <Route element={<FunnelPrivate />}>
          <Route index element={<Dashboard />} />

          <Route path="reward">
            <Route index element={<Reward />} />
            <Route path="charge" element={<RewardCharge />} />
          </Route>

          <Route path="management">
            <Route index element={<Management />} />
            <Route path="staff">
              <Route path="register" element={<StaffRegister />} />
              <Route path="info/:id" element={<StaffDetail />} />
            </Route>
            <Route path="company">
              <Route path="info/:id" element={<CompanyDetail />} />
            </Route>
          </Route>

          <Route path="market">
            <Route index element={<Market />} />
            <Route path="create" element={<CreateMarket />} />
            <Route path="detail" element={<MarketDetail />} />
          </Route>

          <Route path="mypage" element={<Mypage />} />

          <Route path="advertise">
            <Route index element={<AdList />} />
            <Route path="register" element={<AdRegister />} />
            <Route path="info/:id" element={<AdDetail />} />
          </Route>

          <Route path="promotion" element={<Promotion />} />

          <Route path="dpia" element={<DpiaList />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
