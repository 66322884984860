import DateStart from "pages/market/marketComponents/DateStart";
import StartTime from "./StartTime";

function StartDateSetting() {
  return (
    <>
      <DateStart />
      <StartTime />
    </>
  );
}

export default StartDateSetting;
