import { useState } from "react";
import { useQuery } from "react-query";

import { MyInfo, MyCheck } from ".";
import { Title } from "common/other";
import { Wrapper } from "common/container";
import { ErrorModal, LoadingModal } from "common/modal";
import { getMypage } from "api/mypage";
import { useModal } from "hooks";
import queryKey from "constants/queryKey";

export default function Mypage() {
  const [checkMessage, setCheckMessage] = useState("");
  const [isErrorShowing, setIsErrorShowing] = useModal();

  const isSuccessCheck = checkMessage === "OK";

  const getCheckMessage = (message: string) => {
    message && setCheckMessage(message);
  };

  // [GET] MyPage 정보 조회
  const { data, isLoading, error } = useQuery([queryKey.GET_MYPAGE], getMypage, {
    enabled: isSuccessCheck,
    onError: setIsErrorShowing,
  });

  if (isLoading) return <LoadingModal />;

  return (
    <Wrapper>
      <Title text="마이페이지" />
      {isSuccessCheck ? <MyInfo data={data!} /> : <MyCheck getCheckMessage={getCheckMessage} />}
      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={setIsErrorShowing} />}
    </Wrapper>
  );
}
