import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQueries } from "react-query";
import { Content, Title, Table, Pagination } from "comp-lib";
import DpiaHeader from "./DpiaHeader";
import { getAccessLog, getDownloadLog } from "api/dpia";
import queryKey from "constants/queryKey";
import useAccessRows from "./useAccessRows";
import useDownloadRows from "./useDownloadRows";
import TableTab from "./tableTab";

export default function DpiaList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = "accessLog";
  const paramTab = searchParams.get("tab") ?? defaultTab;
  const paramPageNum = searchParams.get("pageNum") ?? "";
  const paramFrom = searchParams.get("from") ?? "";
  const paramTo = searchParams.get("to") ?? "";
  const paramUsername = searchParams.get("username") ?? "";
  const paramIp = searchParams.get("ip") ?? "";

  const results = useQueries([
    {
      queryKey: queryKey.GET_DPIA_LOG("accessLog", paramFrom, paramTo, paramUsername, paramIp, paramPageNum),
      queryFn: () =>
        getAccessLog({
          from: paramFrom,
          to: paramTo,
          username: paramUsername,
          ip: paramIp,
          pageNum: paramPageNum,
        }),
      enabled: paramTab === "accessLog",
      keepPreviousData: true,
    },
    {
      queryKey: queryKey.GET_DPIA_LOG("downloadLog", paramFrom, paramTo, paramUsername, paramIp, paramPageNum),
      queryFn: () =>
        getDownloadLog({
          from: paramFrom,
          to: paramTo,
          username: paramUsername,
          ip: paramIp,
          pageNum: paramPageNum,
        }),
      enabled: paramTab === "downloadLog",
      keepPreviousData: true,
    },
  ]);

  const totalIdx = paramTab === "downloadLog" ? 1 : 0;
  const totalCnt = results[totalIdx].data?.totalElements ?? 0;
  const totalPages = results[totalIdx].data?.totalPages ?? 0;

  const ACCESS_TABLE_COLUMNS = [
    { text: "NO", width: "100px" },
    { text: "접속일시" },
    { text: "관리자", width: "120px" },
    { text: "IP", width: "300px" },
  ];

  const DOWNLOAD_TABLE_COLUMNS = [
    { text: "NO", width: "100px" },
    { text: "다운로드일시", window: "220px" },
    { text: "데이터 거래" },
    { text: "타입", width: "120px" },
    { text: "관리자", width: "150px" },
    { text: "IP", width: "160px" },
  ];

  const accessRows = useAccessRows(results[0].data);
  const downloadRows = useDownloadRows(results[1].data);

  const [accessState, setAccessState] = useState({
    from: "",
    to: "",
    username: "",
    ip: "",
    pageNum: "",
  });

  const [downloadState, setDownloadState] = useState({
    from: "",
    to: "",
    username: "",
    ip: "",
    pageNum: "",
  });

  const handleAccessTabClick = () => {
    setDownloadState({
      from: paramFrom,
      to: paramTo,
      username: paramUsername,
      ip: paramIp,
      pageNum: paramPageNum,
    });

    const filteredParams = Object.fromEntries(Object.entries(accessState).filter(([_, value]) => value !== ""));

    setSearchParams({ ...filteredParams, tab: "accessLog" });
  };

  const handleDownloadTabClick = () => {
    setAccessState({
      from: paramFrom,
      to: paramTo,
      username: paramUsername,
      ip: paramIp,
      pageNum: paramPageNum,
    });

    const filteredParams = Object.fromEntries(Object.entries(downloadState).filter(([_, value]) => value !== ""));

    setSearchParams({ ...filteredParams, tab: "downloadLog" });
  };

  return (
    <Content>
      <Title text="DPIA 기록" />

      <TableTab defaultTab={paramTab}>
        <TableTab.TabBox>
          <TableTab.Tab label="접속기록" value="accessLog" onClick={handleAccessTabClick} />
          <TableTab.Tab label="다운로드 기록" value="downloadLog" onClick={handleDownloadTabClick} />
        </TableTab.TabBox>

        <DpiaHeader totalCnt={totalCnt} />

        <TableTab.Panel value="accessLog">
          <Table columns={ACCESS_TABLE_COLUMNS} rows={accessRows} />

          {accessRows.length > 0 && (
            <div style={{ marginTop: "30px" }}>
              <Pagination totalPages={totalPages} />
            </div>
          )}
        </TableTab.Panel>

        <TableTab.Panel value="downloadLog">
          <Table columns={DOWNLOAD_TABLE_COLUMNS} rows={downloadRows} />

          {downloadRows.length > 0 && (
            <div style={{ marginTop: "30px" }}>
              <Pagination totalPages={totalPages} />
            </div>
          )}
        </TableTab.Panel>
      </TableTab>
    </Content>
  );
}
