import { useState } from "react";
import { useQuery } from "react-query";
import { Navigate, Outlet } from "react-router-dom";

import Layout from "Layout";
import OutletErrorBoundary from "common/other/skeleton/OutletErrorBoundary";
import queryKey from "constants/queryKey";
import CompanyValidator from "pages/login/CompanyValidator";
import PrivacyValidator from "pages/login/PrivacyValidator";
import { getIsLogined, getToken } from "utils/access";

type TStep = "privacy" | "company" | "pass";

export default function FunnelPrivate() {
  const isLogined = getIsLogined();
  const { data } = useQuery(queryKey.PRIVATE_ROUTE_TOKEN, getToken, { enabled: isLogined });
  const [step, setStep] = useState<TStep>("privacy");
  const isNotValidToken = data === null;

  if (!isLogined || isNotValidToken) return <Navigate to="/login" replace />;
  if (data === undefined) return null;

  return (
    <>
      {step === "privacy" && <PrivacyValidator onNext={() => setStep("company")} />}
      {step === "company" && <CompanyValidator onNext={() => setStep("pass")} />}
      {step === "pass" && (
        <Layout>
          <OutletErrorBoundary>
            <Outlet />
          </OutletErrorBoundary>
        </Layout>
      )}
    </>
  );
}
