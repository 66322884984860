import { translateTargetMap } from "constants/market";
import { useRecoilValue } from "recoil";
import { marketBudgetAtom, marketContentAtom, marketPrivacyAtom, marketTargetAtom } from "recoils/market";
import PreviewCard from "./PreviewCard";
import PreviewInfo from "./PreviewInfo";
import { getBannerUrl } from "utils/market";
import { isNumber } from "lodash";

export default function CreatePreviewSidebar() {
  const targetState = useRecoilValue(marketTargetAtom);
  const budgetState = useRecoilValue(marketBudgetAtom);
  const contentState = useRecoilValue(marketContentAtom);
  const privacyState = useRecoilValue(marketPrivacyAtom);
  const cardProps = {
    bannerImageCdn: getBannerUrl(contentState.banner),
    title: contentState.transactionName,
    rewardPolicyAmount: budgetState.reward,
    maxIssuable: budgetState.maxIssuable,
    customReward: budgetState?.customReward,
    collectTarget: targetState.collectData,
    privacyState,
  };

  const infoProps = {
    rewardPolicyAmount: isNumber(budgetState.customReward) ? budgetState.customReward : budgetState.reward,
    maxIssuable: budgetState.maxIssuable,
    collectTarget: targetState.collectData.map((v) => translateTargetMap[v]),
    dataPurpose: targetState.dataPurpose,
    startDate: budgetState.transactionPeriod[0],
    endDate: budgetState.transactionPeriod[1],
    collectPeriod: targetState.collectPeriod,
    privacyState,
    product: targetState.product,
    transactionTime: budgetState.transactionTime,
  };

  return (
    <div>
      <PreviewCard cardData={cardProps} />
      <PreviewInfo infoData={infoProps} />
    </div>
  );
}
