import { Chip } from "common/button";
import { TMarketResponseDetail } from "types/market";

export default function HandleTableBtn(
  item: TMarketResponseDetail,
  onClickTableBtn: (id: number | string, type: "revoke" | "delete") => void,
) {
  const { status, id, proposalId } = item;
  const handleClickButton = (type: "delete" | "revoke") => {
    if (!id && !proposalId) return;
    type === "delete" ? onClickTableBtn(id!, type) : onClickTableBtn(proposalId!, type);
  };

  // "예정", "진행중"
  if (status === 0 || status === 1) {
    return (
      <Chip onClick={() => handleClickButton("revoke")} width="58px">
        철회
      </Chip>
    );
  }

  // "임시저장"
  if (status === 5) {
    return (
      <Chip onClick={() => handleClickButton("delete")} width="58px">
        삭제
      </Chip>
    );
  }

  return "-";
}
