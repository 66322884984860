import styles from "style/common/modal/dialog.module.scss";
import { ModalPortal } from "common/other";
import LoadingModal from "./LoadingModal";
import { DefaultBtn } from "common/button";
import cx from "classnames";

type Props = {
  isLoading?: boolean;
  title?: string;
  description: React.ReactNode;
  cancelBtnName?: string;
  submitBtnName?: string;
  confirmBtnName?: string;
  btnType: "submit" | "confirm";
  handleClose: () => void;
  handleSubmit?: () => void;
  height?: string;
};

/**
 * @description description에 개행이 필요한 경우 "\n"을 넣어주세요. 예) description={`Test\nTest`}
 */
export default function Dialog({
  isLoading = false,
  title,
  submitBtnName = "확인",
  cancelBtnName = "취소",
  confirmBtnName = "확인",
  description,
  btnType,
  handleClose,
  handleSubmit,
  height,
}: Props) {
  const btnEle = {
    submit: (
      <>
        <DefaultBtn type="button" mode="lineGray" onClick={handleClose}>
          {cancelBtnName}
        </DefaultBtn>
        <DefaultBtn type="submit" onClick={handleSubmit}>
          {submitBtnName}
        </DefaultBtn>
      </>
    ),
    confirm: (
      <DefaultBtn type="button" onClick={handleClose}>
        {confirmBtnName}
      </DefaultBtn>
    ),
  }[btnType];

  return (
    <ModalPortal>
      <div className={styles.dialog}>
        {!isLoading && (
          <div className={styles.content} style={{ height }}>
            <p>{title}</p>
            <div className={cx(styles.description, { [styles.isTitle]: title })}>{description}</div>

            <div className={styles.buttonBox}>{btnEle}</div>
          </div>
        )}

        {isLoading && <LoadingModal />}
      </div>
    </ModalPortal>
  );
}
