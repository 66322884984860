export type TRealmQueryCondition = {
  field: string;
  operator: "==" | "!=" | "<" | "<=" | ">" | ">=" | "BETWEEN" | "IN" | "LIKE";
  value: string | number | boolean;
  disable?: boolean;
};

type TRealmQueryOptions = {
  conditions: TRealmQueryCondition[];
  logicalOperator?: "AND" | "OR"; // 조건들 간의 논리 연산자. 기본값은 'AND'
  sortBy?: { field: string; direction: "ASC" | "DESC" }; // 정렬 옵션
  limit?: number; // 결과의 최대 개수
};

export const makeRealmQuery = (options: TRealmQueryOptions) => {
  const queryParts: string[] = [];

  // 조건들을 처리
  for (const condition of options.conditions) {
    // disable이 false인 경우 쿼리 조건문 추가
    if (!condition.disable) {
      queryParts.push(`${condition.field} ${condition.operator} ${condition.value}`);
    }
  }

  // 모든 조건들이 disable인 경우
  if (queryParts.length === 0) {
    return "*";
  }

  let query = "";

  if (queryParts.length === 1) {
    query = queryParts[0];
  } else {
    // 조건이 2개 이상인 경우, 논리 연산자 처리 (기본값은 'AND')
    query = queryParts.join(` ${options.logicalOperator || "AND"} `);
  }

  // 정렬 옵션 처리 (옵션이 있다면)
  if (options.sortBy) {
    const sortDirection = options.sortBy.direction || "ASC";
    query += ` SORT(${options.sortBy.field} ${sortDirection})`;
  }

  // 결과의 최대 개수 처리 (옵션이 있다면)
  if (options.limit) {
    query += ` LIMIT(${options.limit})`;
  }

  return query;
};

type TDurationSuffix = "d" | "w" | "M";

export const getStartDate = (period: string, endDate: Date) => {
  const durationValue = parseInt(period.slice(0, -1), 10);
  const durationType = period.slice(-1) as TDurationSuffix;

  const startDate = new Date(endDate);

  switch (durationType) {
    case "d":
      startDate.setDate(startDate.getDate() - durationValue);
      break;
    case "w":
      startDate.setDate(startDate.getDate() - durationValue * 7);
      break;
    case "M":
      startDate.setMonth(startDate.getMonth() - durationValue);
      break;
    default:
      throw new Error("Invalid duration type");
  }

  return startDate;
};

export const formatDate = (date: Date) => date.toISOString().replace("T", "@").slice(0, -5);

export const getBirthYearFromAge = (age: number) => {
  const currentYear = new Date().getFullYear();
  return currentYear - age;
};
