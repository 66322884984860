import styles from "style/pages/notFound/notFound.module.scss";
import { useNavigate } from "react-router-dom";
import notFoundImage from "assets/images/pages/notFound/404.png";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p>앗!</p>
          <p>{`요청하신 페이지를 찾을 수 없습니다.\n주소가 잘못되었거나 더 이상 제공되지 않는 페이지 입니다.`}</p>
        </div>
        <img alt="404" src={notFoundImage} />
      </div>

      <button className={styles.backButton} type="button" onClick={() => navigate(-1)}>
        이전화면으로
      </button>
    </div>
  );
}
