import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { Content, Wrapper } from "common/container";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { ButtonBox, Title } from "common/other";
import { marketProducts } from "constants/market";
import { marketDialogAtom, marketErrorAtom, marketLoadingAtom, marketTargetAtom } from "recoils/market";
import PreviewSidebar from "../previewSidebar/CreatePreviewSidebar";
import SidebarLayout from "../previewSidebar/SidebarLayout";
import { TransactionPeriod, TransactionPoint } from "./sectionBudget";
import { ContentBanner, ContentName } from "./sectionContent";
import {
  CollectData,
  CollectPeriod,
  CollectSdkData,
  DataPurpose,
  SelectProduct,
  TargetFiltering,
} from "./sectionTargetData";
import useMutateProposal from "./submitCreateProposal/useMutateProposal";

import styles from "style/pages/market/createMarketPage/createMarket.module.scss";
import SelectDidFilterTF from "./sectionTargetData/SelectDidFilterTF";

export default function CreateMarket() {
  const dialogState = useRecoilValue(marketDialogAtom);
  const loadingState = useRecoilValue(marketLoadingAtom);
  const { product, isApplyDidFiltering } = useRecoilValue(marketTargetAtom);
  const [{ error, isShow: isErrorShow, buttonText }, setErrorState] = useRecoilState(marketErrorAtom);
  const [submitConfirm, setSubmitConfirm] = useState("");

  // logic for figure out if user is finelab
  const showFinelabOptions = product.title === "마이디 - 올데이터 프리미엄(파인랩 실명거래용)";
  const isMydProduct = product?.type === "myd";

  const { setProposalDialog, setTempProposalDialog } = useMutateProposal({
    isApplyDidFiltering: isApplyDidFiltering === "true",
  });
  const handleClickSubmit = () => setProposalDialog();
  const handleErrorModal = () => setErrorState((prev) => ({ ...prev, isShow: false }));

  return (
    <Wrapper>
      <Title text="데이터 설정" />
      <Content marginBottom="24px">
        <div className={styles.targetData}>
          <SelectProduct productsData={marketProducts} />
          <SelectDidFilterTF />
          <TargetFiltering />
          {isMydProduct ? (
            <>
              <CollectData />
              <CollectPeriod />
            </>
          ) : (
            product && <CollectSdkData />
          )}
          <DataPurpose />
        </div>
      </Content>

      <Title text="진행 설정" />
      <Content marginBottom="24px" gap="16px">
        <TransactionPoint />
        <TransactionPeriod />
      </Content>

      <Title text="콘텐츠 설정" />
      <Content>
        <ContentName isMydProduct={isMydProduct} showFinelabOptions={showFinelabOptions} />
        {isMydProduct && <ContentBanner showFinelabOptions={showFinelabOptions} />}
      </Content>

      <ButtonBox
        back="목록으로"
        next="등록"
        handleClickPrev={() => setTempProposalDialog()}
        handleClickNext={handleClickSubmit}
      />

      {dialogState.isShow && (
        <Dialog
          title={dialogState.title}
          description={dialogState.description}
          btnType={dialogState.btnType}
          cancelBtnName={dialogState.cancelBtnName}
          submitBtnName={dialogState.submitBtnName}
          handleClose={() => dialogState.handleClose()}
          handleSubmit={() => dialogState.handleSubmit!()}
        />
      )}

      {submitConfirm && (
        <Dialog description={submitConfirm} btnType="confirm" handleClose={() => setSubmitConfirm("")} />
      )}

      {isErrorShow && <ErrorModal error={error} buttonText={buttonText} onConfirmHandler={handleErrorModal} />}

      {loadingState && <LoadingModal />}

      <SidebarLayout>
        <PreviewSidebar />
      </SidebarLayout>
    </Wrapper>
  );
}
