import { createContext, useContext } from "react";

type TContext = {
  activeTab: string;
  changeTab: (tab: string) => void;
};

export const TableTabContext = createContext<TContext>({
  activeTab: "",
  changeTab: () => {},
});

export const useTableTabContext = () => useContext(TableTabContext);
