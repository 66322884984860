import DateRange from "common/input/DatePicker";
import S from "./dpiaHeader.module.scss";
import { useSearchParams } from "react-router-dom";
import { FormEvent, useEffect, useState } from "react";
import dayjs from "dayjs";
import { RerollIcon } from "assets/svgs/icon";

type TProps = {
  totalCnt: number;
};

export default function DpiaHeader({ totalCnt }: TProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<{ startDate: Date | null; endDate: Date | null; username: string; ip: string }>({
    startDate: null,
    endDate: null,
    username: "",
    ip: "",
  });

  useEffect(() => {
    const paramFrom = searchParams.get("from");
    const paramTo = searchParams.get("to");
    const paramUsername = searchParams.get("username");
    const paramIp = searchParams.get("ip");

    setState({
      startDate: paramFrom ? new Date(paramFrom) : null,
      endDate: paramTo ? new Date(paramTo) : null,
      username: paramUsername ?? "",
      ip: paramIp ?? "",
    });
  }, [searchParams]);

  const handleDateChange = ([argStart, argEnd]: [Date | null, Date | null]) => {
    setState((prev) => ({ ...prev, startDate: argStart, endDate: argEnd }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    setSearchParams((prev) => {
      const { startDate, endDate, username, ip } = state;

      startDate ? prev.set("from", dayjs(startDate).format("YYYY-MM-DD")) : prev.delete("from");
      endDate ? prev.set("to", dayjs(endDate).format("YYYY-MM-DD")) : prev.delete("to");
      username ? prev.set("username", username) : prev.delete("username");
      ip ? prev.set("ip", ip) : prev.delete("ip");
      prev.delete("pageNum");

      return prev;
    });
  };

  const handleReset = () => {
    setState({
      startDate: null,
      endDate: null,
      username: "",
      ip: "",
    });

    setSearchParams((prev) => {
      prev.delete("from");
      prev.delete("to");
      prev.delete("username");
      prev.delete("ip");
      prev.delete("pageNum");
      return prev;
    });
  };

  return (
    <div className={S.wrapper}>
      <div className={S.cnt}>전체 {totalCnt}개</div>

      <form className={S.search} onSubmit={handleSubmit}>
        <div className={S.label}>
          <div className={S.txt}> 접속일시</div>
          <DateRange startDate={state.startDate} endDate={state.endDate} onChange={handleDateChange} />
        </div>

        <label>
          <div className={S.txt}>관리자</div>
          <input
            type="text"
            name="username"
            value={state.username}
            onChange={(e) => setState((prev) => ({ ...prev, username: e.target.value }))}
          />
        </label>

        <label>
          <div className={S.txt}>IP</div>
          <input
            type="text"
            name="ip"
            value={state.ip}
            onChange={(e) => setState((prev) => ({ ...prev, ip: e.target.value }))}
          />
        </label>

        <button type="submit" name="search">
          검색
        </button>
      </form>

      {(state.startDate || state.endDate || state.ip || state.username) && (
        <button type="button" name="reset" onClick={handleReset}>
          <RerollIcon width="24px" height="24px" />
          초기화하기
        </button>
      )}
    </div>
  );
}
