import cx from "classnames";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styles from "style/pages/advertisement/adDetail/chart/accumulateBarChart.module.scss";

type TProps = {
  click: number; // 클릭수
  exps: number; // 노출수
};

// 광고 상세 대시보드 - 누적성과 막대 그래프 컴포넌트
export default function AccumulateBarChart({ click, exps }: TProps) {
  const data = [{ 클릭수: click, 노출수: exps }];

  return (
    <div className={styles.wrapper}>
      <div className={styles.labelWrapper}>
        <div>
          <div className={styles.boxStyle} />
          노출수 <span>{exps && exps.toLocaleString()}</span>
        </div>
        <div>
          <div className={cx(styles.boxStyle, styles.color)} />
          클릭수 <span>{click && click.toLocaleString()}</span>
        </div>
      </div>

      <div className={styles.chartWrapper}>
        <div className={styles.labelContainer}>
          <div>노출수</div>
          <div>클릭수</div>
        </div>

        <div className={styles.barChartContainer}>
          <ResponsiveContainer width="70%" height={100}>
            <BarChart data={data} layout="vertical" barGap={20} margin={{ top: 0, right: 50, left: 0, bottom: 0 }}>
              <YAxis type="category" hide />
              <XAxis type="number" hide />
              <Bar dataKey="노출수" fill="#1890FF" animationDuration={1000} barSize={20} radius={[0, 4, 4, 0]} />
              <Bar dataKey="클릭수" fill="#0051A3" animationDuration={1000} barSize={20} radius={[0, 4, 4, 0]} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
