import { Path, FieldValues, UseFormRegisterReturn, RegisterOptions } from "react-hook-form";
import styles from "style/pages/advertisement/adUI/input.module.scss";

type TProps = React.ComponentProps<"input"> & {
  id: Path<FieldValues>;
  register: (id: string, options?: RegisterOptions) => UseFormRegisterReturn;
  label?: string;
  isRequired?: boolean | string;
};

export default function Input({ placeholder, type = "text", id, register, isRequired = true, defaultValue }: TProps) {
  return (
    <div className={styles.container}>
      <input
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        id={id}
        {...register(id, {
          required: isRequired,
          pattern: {
            value: id === "webUrl" && isRequired ? /^http[s]?:\/\/([\S]{3,})/i : /^.*$/,
            message: "올바른 URL 형식으로 입력해 주세요.",
          },
        })}
      />
    </div>
  );
}
