import styles from "style/pages/market/previewSidebar/previewCard.module.scss";
import selectImage from "assets/images/pages/market/select-banner.png";
import PreviewHeader from "./uiComponents/PreviewHeader";
import { getCompanyDetail } from "api/company";
import { useQuery } from "react-query";
import queryKey from "constants/queryKey";
import { translateTargetMap } from "constants/market";
import cx from "classnames";
import { formatDataType } from "../createMarketPage/sectionContent/formatDataType";
import { isNumber } from "lodash";

type TProps = {
  cardData: {
    bannerImageCdn: string;
    title: string;
    rewardPolicyAmount: number;
    privacyState: { personalIdentification: "nonAnonymous" | "anonymous" };
    maxIssuable: number;
    customReward?: number | null;
    collectTarget: string[];
  };
};

export default function PreviewCard({ cardData }: TProps) {
  const { bannerImageCdn, title, rewardPolicyAmount, privacyState, maxIssuable, customReward, collectTarget } =
    cardData;
  const { data: companyData } = useQuery(queryKey.GET_DETAIL_COMPANY, getCompanyDetail);
  const { personalIdentification: identify } = privacyState;

  const formatCollectTarget = () => {
    if (collectTarget.length === 0) return "수집데이터";
    return `${formatDataType(collectTarget)}데이터`;
  };

  const foramtPurposeType = () => {
    if (identify) {
      return identify === "anonymous" ? "익명거래" : "실명거래";
    }
    return "식별여부";
  };

  return (
    <div className={styles.wrapper}>
      <PreviewHeader />
      <img src={bannerImageCdn || selectImage} alt="배너 이미지" className={styles.bannerImage} />
      <div className={styles.infoWrapper}>
        <div className={styles.company}>{companyData?.companyName}</div>
        <div className={styles.title}>{title || "타이틀을 선택해 주세요"}</div>
        <div className={styles.mockButtons}>
          <div className={styles.highlight}>
            {rewardPolicyAmount || isNumber(customReward)
              ? `${isNumber(customReward) ? customReward.toLocaleString() : rewardPolicyAmount.toLocaleString()}P`
              : "지급포인트"}
          </div>
          <div className={cx({ [styles.highlight]: identify === "nonAnonymous" })}>{foramtPurposeType()}</div>
          <div>{maxIssuable ? `${maxIssuable.toLocaleString()}명` : "목표인원수"}</div>
          <div>{translateTargetMap[formatCollectTarget()] || formatCollectTarget()}</div>
        </div>
      </div>
    </div>
  );
}
