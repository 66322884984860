import { RadioBox } from "common/input";
import { collectPeriodList } from "constants/market";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { marketTargetAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/collectPeriod.module.scss";
import { TMarketCollectPeriod } from "types/market";

export const nonPeriodicCollect = ["securities", "insurance", "medicalCheckup"];

export default function CollectPeriod() {
  const [{ collectPeriod, collectData }, setTargetState] = useRecoilState(marketTargetAtom);
  const handlePeriodCheck = (target: TMarketCollectPeriod) => {
    setTargetState((prev) => ({ ...prev, collectPeriod: target }));
  };
  const isNonPeriodicCollect =
    collectData.length !== 0 &&
    collectData.filter((collect) => nonPeriodicCollect.includes(collect)).length === collectData.length;
  useEffect(() => {
    if (isNonPeriodicCollect) setTargetState((prev) => ({ ...prev, collectPeriod: "" }));
  }, [collectData, isNonPeriodicCollect, setTargetState]);

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>수집 기간</p>
      <div className={styles.dataWrapper}>
        <RadioBox
          dataList={collectPeriodList}
          handleCheck={handlePeriodCheck}
          name="기간"
          value={collectPeriod}
          disabled={isNonPeriodicCollect}
        />
      </div>
    </div>
  );
}
