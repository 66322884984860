import { CATEGORY_MAP, COLLECT_KEY_MAP, DATA_KEY_MAP, USER_KEY_MAP } from "constants/realmKeyMap";
import { TQueryData } from "types/proposal";

export const targetUser = (keys: string[], data: TQueryData) => {
  const result = keys.reduce((acc, key) => {
    // 런타임에서 key 가드를 한다. 하지만 타입스크립트는 컴파일타입에서 확인하기 때문에 타입에러가 발생한다.
    // 그래서 타입 단언을 사용한다.
    if (key in USER_KEY_MAP) {
      const mappedData = USER_KEY_MAP[key as keyof typeof USER_KEY_MAP].map((item) => ({
        schema: item.schema,
        query: item.query(data),
      }));

      return acc.concat(mappedData);
    }

    // 키가 USER_KEY_MAP에 없는 경우
    return acc;
  }, [] as { schema: string; query: string }[]);

  return result;
};

type TTargetDataRes = {
  category: number;
  predicate: {
    schema: string;
    query: string;
  }[];
};

export const targetData = (keys: string[], data: TQueryData) => {
  const result = keys.reduce((acc, key) => {
    if (key in DATA_KEY_MAP) {
      const mappedData = DATA_KEY_MAP[key as keyof typeof DATA_KEY_MAP].map((item) => ({
        schema: item.schema,
        query: item.query(data),
      }));

      const category = CATEGORY_MAP[key as keyof typeof DATA_KEY_MAP];
      return acc.concat({ category, predicate: mappedData });
    }

    return acc;
  }, [] as TTargetDataRes[]);

  return result;
};

export const collectData = (keys: string[], data: TQueryData) => {
  const result = keys.reduce((acc, key) => {
    if (key in COLLECT_KEY_MAP) {
      const mappedData = COLLECT_KEY_MAP[key as keyof typeof COLLECT_KEY_MAP].map((item) => ({
        schema: item.schema,
        query: item.query(data),
      }));

      return acc.concat(mappedData);
    }

    return acc;
  }, [] as { schema: string; query?: string }[]);

  return result;
};
