// 출력 텍스트 컴포넌트
import styles from "style/common/other/outputText.module.scss";

type TProps = {
  children?: React.ReactNode;
  value?: React.ReactNode | string;
  btn?: React.ReactNode;
};

export default function OutputText({ children, value, btn }: TProps) {
  return (
    <div className={styles.container}>
      <label>{children}</label>
      <div className={styles.contentBox}>
        {value}
        {btn}
      </div>
    </div>
  );
}
