import { genderList } from "constants/market";
import { useRecoilState } from "recoil";
import { marketTargetAtom } from "recoils/market";
import styles from "style/pages/market/createMarketPage/sectionTargetData/targetFiltering/targetFiltering.module.scss";
import AgeTargetBox from "./AgeTargetBox";
import AreaTargetBox from "./AreaTargetBox";
import TargetRadioBox from "./TargetRadioBox";

export default function TargetFiltering() {
  const [targetState, setTargetState] = useRecoilState(marketTargetAtom);
  const handleGenderCheck = (gender: string) => {
    setTargetState((prev) => ({ ...prev, gender }));
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>노출 대상</p>
      <TargetRadioBox
        value={targetState.gender}
        handleCheck={handleGenderCheck}
        dataList={genderList}
        name="성별"
        disabled
      />
      <AgeTargetBox />
      <AreaTargetBox />
    </div>
  );
}
