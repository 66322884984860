import { marketProducts } from "constants/market";
import { TCreateDetail } from "types/management";
import { TMarketProductData, TMarketResponseDetail } from "types/market";

/** 임시저장 데이터를 불러올 때 상품을 판단하는 함수입니다. */
export const getTempDataProduct = (
  request?: TMarketResponseDetail,
  companyData?: TCreateDetail
): TMarketProductData => {
  if (!request || !companyData) return marketProducts[0];
  //  companyData가 11(finelab)이고, 실명 데이터 거래 선택 시
  if (request.contentObject.privacyPolicy?.identifier === 1 && companyData?.companyId === 11) {
    const targetProduct = marketProducts?.find(
      ({ title }) => title === "마이디 - 올데이터 프리미엄(파인랩 실명거래용)"
    );
    return targetProduct || marketProducts[0];
  }
  return marketProducts[0]; // "마이디 - 올데이터 프리미엄"
};
