import styles from "style/pages/market/createMarketPage/sectionBudget/transactionPeriod.module.scss";
import StartDateSetting from "./StartDateSetting";
import Caution from "./Caution";

export default function TransactionPeriod() {
  return (
    <div className={styles.wrapper}>
      <p className={styles.subTitle}>데이터 거래 시작일시</p>
      <div className={styles.inputWrapper}>
        <StartDateSetting />
      </div>
      <Caution />
    </div>
  );
}
