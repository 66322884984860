import axios from "axios";
import { TGetUserMail, TPasswordChange, TPasswordFind } from "types/login";
import { TAxiosReturn } from "types/common";
import { errorHandling } from "./errorHandling";
import { ErrorMessage } from "constants/errorCode";

// 비밀번호 찾기
export const postPasswordFind = async ({ name, email }: TPasswordFind): Promise<{ result: boolean }> => {
  try {
    const { data } = await axios.post<TAxiosReturn<{ result: boolean }>>("/api/password/find", { name, email });
    return data.data;
  } catch (error) {
    if (error instanceof Error && error.message === ErrorMessage[6006]) {
      throw errorHandling(Error(`이름 또는 이메일 정보가 일치하지 않습니다.\n다시 입력해 주세요.`));
    }
    throw error;
  }
};

export const postPasswordChange = async ({
  password,
  passwordCheck,
}: TPasswordChange): Promise<{ result: boolean }> => {
  const enc = new URLSearchParams(window.location.search).get("enc");

  const { data } = await axios.post<TAxiosReturn<{ result: boolean }>>("/api/password/change", {
    password,
    passwordCheck,
    enc,
  });

  return data.data;
};

export const postUserEmail = async (enc: string): Promise<string> => {
  const { data } = await axios.post<TAxiosReturn<TGetUserMail>>("/api/password/email", { enc });

  return data?.data?.result;
};
