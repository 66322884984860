import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useResetRecoilState } from "recoil";

import { PointRefundInfo } from "pages/policy";
import { RewardInfo, TableTop } from ".";
import useRewardList from "./useRewardList";
import { useFilterRewardTableData, useFilterRewardTableNav } from "./useFilterRewardTable";
import { Content } from "common/container";
import { Chip, DefaultBtn } from "common/button";
import { Table } from "common/table";
import { Pagination } from "common/navigation";
import { ErrorModal, LoadingModal } from "common/modal";
import { Title } from "common/other";
import { getReward } from "api/reward";
import useModal from "hooks/useModal";
import queryKey from "constants/queryKey";
import { rewardCharge } from "recoils/reward";

import styles from "style/pages/reward/list/list.module.scss";

export default function List() {
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isNotiPopup, setIsNotiPopup] = useState(false);

  const { data, error, isLoading, isFetching } = useQuery([queryKey.GET_REWARD], getReward, {
    onError: () => setIsErrorShowing(),
  });

  const { totalPages, filteredData, size } = useFilterRewardTableData(data?.rewardViewList, navMap);
  const tableNavData = useFilterRewardTableNav(navMap, size);
  const rewardList = useRewardList({ data: filteredData });
  const resetRewardInfo = useResetRecoilState(rewardCharge);

  useEffect(() => {
    resetRewardInfo();
  }, [resetRewardInfo]);

  if (isLoading || isFetching) return <LoadingModal />;

  return (
    <>
      <Title text="포인트 이용 내역" />

      <section className={styles.section}>
        <RewardInfo
          myPoint={data?.myPoint || 0}
          refundablePoint={data?.refundablePoint || 0}
          amountPoint={data?.amountPoint || 0}
        />

        <Link to="charge" className={styles.linkPosition}>
          <DefaultBtn>충전하기</DefaultBtn>
        </Link>
      </section>

      <Content gap="16px">
        <TableTop tableNavData={tableNavData} />

        <Table
          columns={[
            ["NO", "60px"],
            ["상태", "74px"],
            "내역",
            ["일시", "220px"],
            ["요청 포인트", "120px"],
            ["적립 포인트", "120px"],
            ["차감 포인트", "120px"],
            ["잔여 포인트", "120px"],
          ]}
          dataList={rewardList}
        />

        <div className={styles.buttonWrapper}>
          <Chip width="100px" height="40px" onClick={() => setIsNotiPopup(true)}>
            &gt; 환불안내
          </Chip>
        </div>

        <Pagination data={{ totalPages, pageSize: 10 }} />
      </Content>

      {/* 포인트 환불 안내문 모달 */}
      {isNotiPopup && <PointRefundInfo setIsNotiPopup={setIsNotiPopup} />}

      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={() => setIsErrorShowing()} />}
    </>
  );
}

const navMap = [
  {
    label: "전체",
    status: "all",
  },
  {
    label: "사용",
    status: "use",
  },
  {
    label: "환급",
    status: "refund",
  },
  {
    label: "충전",
    status: "charge",
  },
  {
    label: "요청",
    status: "request",
  },
];
