import { TAxiosReturn } from "types/common";
import { TReward } from "types/reward";
import { Axios } from "./base";
import { errorHandling } from "./errorHandling";

export const getReward = async (): Promise<TReward> => {
  try {
    const { data } = await Axios.get<TAxiosReturn<TReward>>("/api/reward/v2/index");
    return data.data;
  } catch {
    throw errorHandling(Error(`포인트 목록 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

export const getAmountPoint = async (): Promise<{ amountPoint: number }> => {
  try {
    const { data } = await Axios.get<TAxiosReturn<{ amountPoint: number }>>("/api/reward/v2/charge");
    return data.data;
  } catch {
    throw errorHandling(Error(`포인트 조회 중 오류가 발생했습니다.\n다시 시도해 주세요.`));
  }
};

export const postRewardCharge = async (chargePoint: string): Promise<{ result: boolean }> => {
  try {
    const { data } = await Axios.post<TAxiosReturn<{ result: boolean }>>("/api/reward/v2/api/charge", {
      chargePoint,
    });
    return data.data;
  } catch {
    throw errorHandling(
      Error(`포인트 충전 중 오류가 발생했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`)
    );
  }
};
