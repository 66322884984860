import { ChangeEventHandler, useState } from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import PasswordInput from "./PasswordInput";
import { DefaultBtn } from "common/button";
import { Content } from "common/container";
import { ErrorModal } from "common/modal";
import { Footer } from "common/other";
import useModal from "hooks/useModal";
import { ErrorMessage } from "constants/errorCode";
import { postConfirmPassword } from "api/mypage";

import styles from "style/pages/mypage/myCheck.module.scss";

type TProps = {
  getCheckMessage: (v: string) => void;
};

export default function MyCheck({ getCheckMessage }: TProps) {
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isErrorShowing, setIsErrorShowing] = useModal();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.currentTarget.value);
  };

  // [POST] 비밀번호 확인
  const { mutate, error } = useMutation(postConfirmPassword, {
    onSuccess: (v) => v && getCheckMessage(v.message),
    onError: (err: AxiosError) => {
      if (err.message === ErrorMessage[6006]) {
        setErrorMsg(`비밀번호가 일치하지 않습니다. 다시 한 번 확인해 주세요.`);
      } else {
        setIsErrorShowing();
      }
    },
  });

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate({ password });
  };

  return (
    <Content>
      <form className={styles.contentBox} onSubmit={handleSubmit}>
        <div className={styles.title}>본인확인</div>
        <div className={styles.description}>
          개인정보 수정을 하기 전 본인 확인이 필요합니다. 사용하시는 비밀번호를 입력해주세요.
        </div>
        <div className={styles.inputBox}>
          <PasswordInput
            value={password}
            onChange={handleChange}
            placeholder="비밀번호를 입력해 주세요"
            errorMsg={errorMsg}
            isErrMsg
          />
        </div>

        <Footer>
          <DefaultBtn size="medium">진행</DefaultBtn>
        </Footer>
      </form>

      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={setIsErrorShowing} />}
    </Content>
  );
}
