import { Link, useSearchParams } from "react-router-dom";
import { DefaultBtn } from "common/button";
import styles from "style/common/other/infoItem.module.scss";

type TProps = {
  label: string;
  info: string | React.ReactNode;
  link?: string;
  canEdit?: boolean;
  labelWidth?: string;
};

export default function InfoItem({ label, info, link, canEdit, labelWidth }: TProps) {
  const [searchParams] = useSearchParams();
  searchParams.set("edit", "true");

  return (
    <div className={styles.infoBox}>
      <div className={styles.label} style={{ width: labelWidth }}>
        {label}
      </div>
      {link ? (
        <div className={styles.link}>
          <Link to={`/management/company/info/${link}`} className={styles.underline}>
            <div className={styles.info}>{info}</div>
          </Link>

          {link && canEdit && (
            <Link to={`/management/company/info/${link}?edit=true`}>
              <DefaultBtn type="button" size="short">
                기업정보 수정
              </DefaultBtn>
            </Link>
          )}
        </div>
      ) : (
        <div className={styles.info}>{info}</div>
      )}
    </div>
  );
}
