import { TMarketTargetData } from "types/market";

const defaultWeight = {
  gender: 0.025,
  ageGroup: 0.025,
  regionOfResidence: 0.05,
  mobileNetworkOperator: 0.05,
};

const dataWeight: { [key: string]: number } = {
  medicalCheckup: 0.022,
  medicationRecord: 0.022,
  patientEMR: 0.022,
  exerciseResult: 0.022,
  bankTransaction: 0.044,
  card: 0.022,
  securities: 0.022,
  insurance: 0.022,
  googleYoutubeKeyword: 0.022,
  shoppingMallOrder: 0.022,

  // 임시 하드코딩
  "2w 위치 데이터": 0.044,
  "1M 위치 데이터": 0.044,
  "3M 위치 데이터": 0.044,
  "1M 이동 데이터": 0.022,
  "3M 이동 데이터": 0.022,
  "1M 이용내역 데이터": 0.022,
  "3M 이용내역 데이터": 0.022,
  "6M 이용내역 데이터": 0.022,
  "2w 구매내역 데이터": 0.044,
  "1M 구매내역 데이터": 0.044,
  "3M 구매내역 데이터": 0.044,
  "6M 구매내역 데이터": 0.044,
  "3M 리뷰 데이터": 0.022,
  "6M 리뷰 데이터": 0.022,
  "1M 접속내역 데이터": 0.022,
  "3M 접속내역 데이터": 0.022,
  "6M 접속내역 데이터": 0.022,
};

const collectWeight: { [key: string]: any } = {
  medicalCheckup: { "": 0.063, "2w": 0.063, "1M": 0.063, "3M": 0.063, "6M": 0.063 },
  medicationRecord: { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  patientEMR: { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  exerciseResult: { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  bankTransaction: { "2w": 0.0756, "1M": 0.0882, "3M": 0.1008, "6M": 0.126 },
  card: { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  securities: { "": 0.063, "2w": 0.063, "1M": 0.063, "3M": 0.063, "6M": 0.063 },
  insurance: { "": 0.063, "2w": 0.063, "1M": 0.063, "3M": 0.063, "6M": 0.063 },
  googleYoutubeKeyword: { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  shoppingMallOrder: { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },

  // 임시 하드코딩
  "2w 위치 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "1M 위치 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "3M 위치 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "1M 이동 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "3M 이동 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "1M 이용내역 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "3M 이용내역 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "6M 이용내역 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "2w 구매내역 데이터": { "2w": 0.063, "1M": 0.063, "3M": 0.063, "6M": 0.063 },
  "1M 구매내역 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "3M 구매내역 데이터": { "2w": 0.0756, "1M": 0.0882, "3M": 0.1008, "6M": 0.126 },
  "6M 구매내역 데이터": { "2w": 0.063, "1M": 0.063, "3M": 0.063, "6M": 0.063 },
  "3M 리뷰 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "6M 리뷰 데이터": { "2w": 0.0378, "1M": 0.0441, "3M": 0.0504, "6M": 0.063 },
  "1M 접속내역 데이터": { "2w": 0.0756, "1M": 0.0882, "3M": 0.1008, "6M": 0.126 },
  "3M 접속내역 데이터": { "2w": 0.0756, "1M": 0.0882, "3M": 0.1008, "6M": 0.126 },
  "6M 접속내역 데이터": { "2w": 0.0756, "1M": 0.0882, "3M": 0.1008, "6M": 0.126 },
};

export default function calculateWeight(targetState: TMarketTargetData): number {
  const { gender, age, area, collectData, collectPeriod } = targetState;
  let weight = 0;
  // 기본 타겟팅
  if (gender !== "all") weight += defaultWeight.gender;
  if (age[0] || age[1]) weight += defaultWeight.ageGroup;
  if (area[0] && area[0] !== "전체") weight += defaultWeight.regionOfResidence;

  // 데이터 타겟팅
  weight += collectData ? collectData.map((v) => dataWeight[v]).reduce((a, b) => a + b, 0) : 0;

  // 수집 데이터
  weight += collectData ? collectData.map((v) => collectWeight[v][collectPeriod]).reduce((a, b) => a + b, 0) : 0;

  // 수수료
  weight *= 0.8;

  return Math.round(weight * 10000) / 10000; // 소수점 4째 자리 반올림
}
