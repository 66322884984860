import { ChangeEventHandler } from "react";
import { CheckIcon } from "assets/svgs/icon";
import styles from "style/common/input/checkInput.module.scss";
import cx from "classnames";

interface Props {
  name: string;
  text: React.ReactNode | string;
  value: boolean;
  handleCheck: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  mode?: "square" | "circle";
}

export default function CheckInput({ name, text, value, handleCheck, disabled = false, mode = "square" }: Props) {
  return (
    <div className={cx(styles.wrapper, styles[mode], { [styles.active]: value })}>
      <div className={cx(styles.checkBox, styles[mode], { [styles.active]: value })}>
        <input type="checkbox" id={name} name={name} checked={value} onChange={handleCheck} disabled={disabled} />
        <CheckIcon />
      </div>
      <label htmlFor={name}>{text}</label>
    </div>
  );
}
