import { useState } from "react";
import { useMutation } from "react-query";

import PasswordInput from "./PasswordInput";
import { changePassword } from "api/mypage";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import useModal from "hooks/useModal";
import { isPasswordValid } from "utils/validation";

import styles from "style/pages/mypage/passwordChange.module.scss";

type TProps = {
  setIsPwModal: () => void;
};

export default function PasswordChange({ setIsPwModal }: TProps) {
  const [{ password, passwordCheck }, setPwState] = useState({ password: "", passwordCheck: "" });
  const [errorMsg, setErrorMsg] = useState("");
  const [isErrorShowing, setIsErrorShowing] = useModal();
  const [isConfirmPwModal, setIsConfirmPwModal] = useModal();

  const handleChangePwModal = () => {
    setIsPwModal();
    setPwState({ password: "", passwordCheck: "" });
    setErrorMsg("");
  };

  const onChangePw = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    setPwState((prev) => {
      return { ...prev, [id]: value };
    });
  };

  // [POST] 비밀번호 변경
  const { mutate: pwChangeMutation, error: pwError, isLoading } = useMutation(changePassword);

  const handleSubmitPwChange = () => {
    if (!password || !passwordCheck) {
      return setErrorMsg("비밀번호를 입력해주세요");
    }

    if (!isPasswordValid(password) || !isPasswordValid(passwordCheck)) {
      return setErrorMsg("영문 대/소문자, 숫자, 특수문자를 포함하여 8자 이상 입력해야 합니다");
    }

    if (password !== passwordCheck) {
      return setErrorMsg("비밀번호가 일치하지 않습니다. 다시 입력해주세요");
    }

    return pwChangeMutation(
      { password, passwordCheck },
      {
        onSuccess: setIsConfirmPwModal,
        onError: setIsErrorShowing,
      },
    );
  };

  if (isLoading) return <LoadingModal />;

  return (
    <>
      {isConfirmPwModal ? (
        <Dialog
          description="비밀번호가 변경되었습니다."
          btnType="confirm"
          handleClose={() => {
            setIsConfirmPwModal();
            setIsPwModal();
          }}
        />
      ) : (
        <Dialog
          title="비밀번호 변경"
          description={
            <div className={styles.pwInputBox} onSubmit={handleSubmitPwChange}>
              <PasswordInput
                id="password"
                value={password}
                onChange={onChangePw}
                placeholder="새로운 비밀번호"
                errorMsg={errorMsg}
              />
              <PasswordInput
                id="passwordCheck"
                value={passwordCheck}
                onChange={onChangePw}
                placeholder="비밀번호 확인"
                errorMsg={errorMsg}
                isErrMsg
              />
            </div>
          }
          btnType="submit"
          handleClose={handleChangePwModal}
          handleSubmit={handleSubmitPwChange}
          submitBtnName="변경"
          height="280px"
        />
      )}

      {isErrorShowing && <ErrorModal error={pwError} onConfirmHandler={setIsErrorShowing} />}
    </>
  );
}
