import { useRef } from "react";
import { useRecoilState } from "recoil";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import ko from "date-fns/locale/ko";
import { CustomHeader, CustomInput } from "common/input/DatePicker";
import { marketBudgetAtom } from "recoils/market";
import "react-datepicker/dist/react-datepicker.css";
import styles from "style/common/input/datePicker.module.scss";

const addDays = (start: string, days: number) => {
  return start ? dayjs(start).add(days, "day").format("YYYY-MM-DD") : "";
};

export default function DateStart() {
  const today = new Date();
  const calRef = useRef<DatePicker | null>(null);

  const [budgetState, setBudgetState] = useRecoilState(marketBudgetAtom);
  const {
    transactionPeriod: [startDate],
  } = budgetState;

  const handleDate = (selectedDate: Date | null) => {
    const formatSelectedDate = (date?: Date | null) => {
      return date ? dayjs(date).format("YYYY-MM-DD") : "";
    };

    const thirtyDaysAfter = addDays(formatSelectedDate(selectedDate), 30);
    const initTransactionTime = "0";

    setBudgetState((prev) => ({
      ...prev,
      transactionPeriod: [formatSelectedDate(selectedDate), thirtyDaysAfter],
      transactionTime: initTransactionTime,
    }));
  };

  const handleOk = () => {
    calRef.current && calRef.current.setOpen(false);
  };

  const handleCancel = () => {
    handleDate(null);
    handleOk();
  };

  return (
    <div className={styles["react-datepicker"]}>
      <DatePicker
        ref={calRef}
        className={styles["react-datepicker"]}
        minDate={today}
        onChange={handleDate}
        value={startDate && formatDate(new Date(startDate))}
        customInput={<CustomInput mode="dateInput" />}
        renderCustomHeader={(headerProps) => <CustomHeader {...headerProps} />}
        locale={ko}
        selected={startDate ? new Date(startDate) : null}
      >
        <div className={styles.btnContainer}>
          <button type="button" className={styles.deleteBtn} onClick={handleCancel}>
            삭제
          </button>
          <button type="button" className={styles.okBtn} onClick={handleOk}>
            닫기
          </button>
        </div>
      </DatePicker>
    </div>
  );
}

const formatDate = (date: Date | null) => {
  return date ? dayjs(date).format("YYYY.MM.DD") : "";
};
