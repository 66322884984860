import { translateTargetMap } from "constants/market";

export const formatDataType = (collectData: string[]) => {
  if (!collectData) return "";
  if (collectData.length === 1) return translateTargetMap[collectData[0]] || "";
  if (collectData.length > 1) {
    if (
      collectData.length ===
      collectData.filter((data) =>
        [
          "medicalCheckup",
          "medicationRecord",
          "patientEMR",
          "exerciseResult",
          "건강검진",
          "진료 및 복약",
          "EMR",
          "운동",
        ].includes(data)
      ).length
    ) {
      return "건강";
    }
    if (
      collectData.length ===
      collectData.filter((data) =>
        ["bankTransaction", "card", "securities", "insurance", "은행 예적금", "카드", "증권", "보험"].includes(data)
      ).length
    ) {
      return "금융";
    }
    if (
      collectData.length ===
      collectData.filter((data) => ["googleYoutubeKeyword", "shoppingMallOrder", "구글/유튜브", "쇼핑"].includes(data))
        .length
    ) {
      return "생활";
    }
    return "결합";
  }
  return "";
};
