import { TAdProposal } from "types/advertisement";
import PreviewCard from "./PreviewCard";
import PreviewInfo from "./PreviewInfo";

type TProps = {
  data: TAdProposal[];
  adBannerUrl: string | File;
};

export default function CreatePreviewSidebar({ data, adBannerUrl }: TProps) {
  const cardProps = {
    bannerUrl: data[0]?.contentObject.imageURL.banner, // 거래 배너 이미지
    title: data[0]?.title, // 거래 제목
    rewardPolicyAmount: data[0]?.rewardPolicyAmount, // 지급 포인트
    privacyPolicy: data[0]?.contentObject?.privacyPolicy?.identifier, // 익명/실명거래 식별여부
    maxIssuable: data[0]?.maxIssuable, // 목표 인원
    collectTarget: data[0]?.selectDataList, // 수집데이터
  };

  const infoProps = {
    adBannerUrl,
  };

  return (
    <div>
      <PreviewCard cardData={cardProps} />
      <PreviewInfo infoData={infoProps} />
    </div>
  );
}
