import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import ko from "date-fns/locale/ko";

import { DefaultBtn } from "common/button";
import { CustomHeader, CustomInput } from "common/input/DatePicker";
import { RerollIcon } from "assets/svgs/icon";

import "style/common/input/datePicker.css";
import "react-datepicker/dist/react-datepicker.css";
import styles from "style/pages/dashboard/dateRange.module.scss";

type TProps = {
  startDate?: Date | null | undefined;
  endDate?: Date | null | undefined;
  onChange: (date: [Date | null, Date | null]) => void;
  onResetDate?: () => void;
};

const formatDate = (date?: Date | null | string) => {
  return date ? dayjs(date).format("YYYY.MM.DD") : "";
};

const paramsFormatDate = (date?: Date | null | string) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
};

export default function DateRange({ startDate, endDate, onChange, onResetDate }: TProps) {
  const [, setSearchParams] = useSearchParams();

  const handleSearchClick = () => {
    setSearchParams((params) => {
      params.set("period", `${paramsFormatDate(startDate)}to${paramsFormatDate(endDate)}`);
      return params;
    });
  };

  const dateToString = startDate || endDate ? `${formatDate(startDate)} ~ ${formatDate(endDate)}` : "";

  return (
    <div className={styles.wrapper}>
      <div className={styles.dateBox}>
        <span>기간검색</span>
        <div className={styles["react-datepicker"]}>
          <DatePicker
            className={styles["react-datepicker"]}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            value={dateToString}
            onChange={onChange}
            customInput={<CustomInput />}
            renderCustomHeader={(headerProps) => <CustomHeader {...headerProps} />}
            locale={ko}
          />
        </div>

        <DefaultBtn size="small" onClick={handleSearchClick}>
          검색
        </DefaultBtn>
      </div>

      {(startDate || endDate) && (
        <button type="button" className={styles.searchClearBtn} onClick={onResetDate}>
          <RerollIcon width="24px" height="24px" />
          초기화하기
        </button>
      )}
    </div>
  );
}
