import React, { ReactNode } from "react";
import styles from "style/common/other/skeleton/outletErrorBoundary.module.scss";

interface Props {
  children?: ReactNode;
  fallback?: React.ElementType;
}

interface State {
  hasError: boolean;
  info: Error | null;
}

class OutletErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      info: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, info: error };
  }

  render() {
    const { hasError, info } = this.state;
    const { children } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (hasError && this.props.fallback) {
      <this.props.fallback error={info} />;
    } else if (hasError) {
      return (
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div>
              <p>앗!</p>
              <p>{`페이지를 불러오던 중 오류가 발생했습니다.\n다시 시도하시고 동일한 문제가 반복될 경우 CS센터에 문의해 주세요.`}</p>
            </div>
          </div>

          <button className={styles.backButton} type="button" onClick={() => window.location.reload()}>
            새로고침
          </button>
        </div>
      );
    }
    return children;
  }
}

export default OutletErrorBoundary;
