import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useRecoilValue, useResetRecoilState } from "recoil";

import RewardInput from "./RewardInput";
import useRewardCharge from "./useRewardCharge";
import { Dialog, ErrorModal, LoadingModal } from "common/modal";
import { ButtonBox, OutputText, Title } from "common/other";
import queryKey from "constants/queryKey";
import { Content, Wrapper } from "common/container";
import useModal from "hooks/useModal";
import { rewardCharge } from "recoils/reward";

import styles from "style/pages/reward/charge/charge.module.scss";

export default function Charge() {
  const { data, isLoading, isFetching, error, mutate } = useRewardCharge();

  const queryClient = useQueryClient();

  const [isShowing, setIsShowing] = useState(false);
  const [isErrorShowing, setIsErrorShowing] = useModal();

  const { price, chargePoint } = useRecoilValue(rewardCharge);
  const resetRewardInfo = useResetRecoilState(rewardCharge);

  const navigate = useNavigate();

  const handleSubmitBtnClick = () => {
    mutate(chargePoint.toString(), {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.GET_REWARD]);
        queryClient.invalidateQueries([queryKey.GET_AMOUNT_POINT]);
        setIsShowing(false);
        resetRewardInfo();
        navigate("/reward");
      },
      onError: () => setIsErrorShowing(),
    });
  };

  if (isLoading || isFetching) return <LoadingModal />;

  return (
    <Wrapper>
      <Title text="포인트 충전" />

      <Content gap="40px">
        <OutputText value={`${(data?.amountPoint || 0).toLocaleString()}P`}>보유 포인트</OutputText>

        <div className={styles.pointWrapper}>
          <p>결제 금액</p>
          <RewardInput />
        </div>

        <OutputText
          value={
            <p className={styles.text}>
              <span className={styles.pointText}>{chargePoint.toLocaleString()}</span>P
            </p>
          }
        >
          충전 포인트
        </OutputText>
        <OutputText
          value={
            <p className={styles.text}>
              <span className={styles.pointText}>{(chargePoint + (data?.amountPoint || 0)).toLocaleString()}</span>P
            </p>
          }
        >
          충전 후 보유 포인트
        </OutputText>

        <div className={styles.line} />

        <OutputText value="신한은행 140 - 012 - 983438 (예금주 : 주식회사 에스앤피랩)">입금 계좌 안내</OutputText>
      </Content>

      <ButtonBox
        back="목록으로"
        next="충전"
        handleClickPrev={() => navigate("/reward")}
        handleClickNext={() => setIsShowing(true)}
      />

      {isShowing &&
        (price < 100000 ? (
          <Dialog
            btnType="confirm"
            description="최소 100,000원 이상 결제 금액을 입력해 주세요"
            handleClose={() => setIsShowing(!isShowing)}
            handleSubmit={handleSubmitBtnClick}
          />
        ) : (
          <Dialog
            btnType="submit"
            submitBtnName="확인"
            title="포인트를 충전하시겠습니까?"
            description={`포인트를 충전합니다.\n충전하고자 하는 포인트가 맞는지 확인해주세요\n충전 요청 포인트: ${Number(
              `${chargePoint}`,
            ).toLocaleString()}P`}
            handleClose={() => setIsShowing(!isShowing)}
            handleSubmit={handleSubmitBtnClick}
          />
        ))}

      {isErrorShowing && <ErrorModal error={error} onConfirmHandler={() => setIsErrorShowing()} />}
    </Wrapper>
  );
}
