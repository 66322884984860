import { useQuery } from "react-query";
import { getUserInfo } from "api/common";
import ROLE from "constants/role";
import queryKey from "constants/queryKey";
import CompanyWrite from "./CompanyWrite";
import { Wrapper } from "common/container";
import { Title } from "common/other";
import { useEffect, useState } from "react";
import { Dialog } from "common/modal";

// B2B_ADMIN 권한이고 tutorialStatus가 false이면 기업 상세정보 작성
export default function CompanyValidator({ onNext }: { onNext: () => void }) {
  const { data } = useQuery(queryKey.GET_USERINFO, getUserInfo);
  const isCreateCompany = data?.tutorialStatus === false && data?.roles === ROLE.B2B_ADMIN;
  const [isNotice, setIsNotice] = useState(true);

  useEffect(() => {
    if (!data) return;
    if (!isCreateCompany) onNext();
  }, [data]);

  if (isCreateCompany)
    return (
      <>
        <Wrapper style={{ padding: "40px" }}>
          <Title text={`기업 상세정보 등록 `} />
          <CompanyWrite data={data} onNext={onNext} />
        </Wrapper>

        {isNotice && (
          <Dialog
            title="환영합니다!"
            description="마이디 커넥트 사용을 위해 기업 정보를 등록해주세요."
            btnType="confirm"
            handleClose={() => setIsNotice(false)}
          />
        )}
      </>
    );

  return null;
}
